import React from "react";
import "./Footer.scss"
import { useSelector } from "react-redux";
import email from "../../assets/email.png"
import phone from "../../assets/phone-call.png"
import location from "../../assets/location-pin.png"
import logo from "../../assets/web-ic.png"
import MapLocationComponent from "./MapLocationComponent";
const Footer = () => {
    const companyDetails = useSelector((state) => state.companyData.companyDetails);

    return (
        <div className="Footer">
            {companyDetails?.settings?.ShowAddressInMap && <MapLocationComponent lat={companyDetails?.address?.lat}
                lng={companyDetails?.address?.lng}
            />}

            <div className="footer-innwr">

                <div className="footer-innwr-right">
                    <div className="logo-container">
                        <img alt="" className="logo-conr" src={companyDetails.bigLogoURL} />
                    </div>
                    <div className="menu-header-inner-header">
                        <div className="name">{companyDetails.shopName}</div>
                        <div className="address">
                            {companyDetails.address.city}<br />{companyDetails.address.state} .
                        </div>
                    </div>
                </div>
                {companyDetails?.settings?.ShowOpeningHours && <div className="footer-mid">
                    <div className="name">Opening Hours</div>

                    {companyDetails?.openingHours?.map((open, key) => {
                        return (<div key={key} className="opening-hours-text">
                            <div>{open.Day}</div><div className="dotted-line-eg"></div><div>{open?.Closed ? "Closed" : open.OpenHours?.map((date) => {
                                return (
                                    <div>{date.StartTime + " - " + date.EndTime}</div>
                                )
                            })}</div>
                        </div>)
                    })}
                </div>}
                <div className="menu-header-inner">
                    <div className="name">Contact Us</div>
                    <div className="address">
                        {companyDetails.email &&
                            <div className="cont"><img alt="" src={email} />{companyDetails.email}</div>}
                        {companyDetails.contactNumber && <div className="cont"><img alt="" src={phone} /> + 44 {companyDetails.contactNumber}    </div>}
                        <div className="cont"><img alt="" src={location} /><div>{companyDetails.address.line1},<br />
                            {companyDetails.address.city}, {companyDetails.address.postcode},<br /> {companyDetails.address.state}, {companyDetails.address.country}
                        </div> </div></div>
                </div>
            </div>
            <div className="witmeg-text">
                <img alt="" src={logo} />
                <div className="text-cont-wite">Powered by, WITMEG.
                </div></div>

        </div>


    )

}
export default Footer