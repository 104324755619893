import React from "react";
import close from "../../assets/close.svg"

import Accordion from "../../components/Accordion/Accordion";
const FiltersComponent = ({ isWeb, isOpen, toggleDrawer }) => {
    const getPriceContent = () => {
        return (
            <div>
                <div>$0-$10</div>
                <div>$10-$100</div>
                <div>$100 above</div>
            </div>
        )
    }

    const filters = [
        { title: "Price", content: getPriceContent() },
        { title: "Fabric", content: getPriceContent() },
        { title: "Type", content: getPriceContent() },
        { title: "Color", content: getPriceContent() },
    ];
    return (
        isWeb ? <div className="EcommerceProduct-left ">
            <div className="EcommerceProduct-left-header">FILTERS</div>
            <Accordion items={filters} isSmall />
        </div> : <div className={`EcommerceFilter-drawer ${isOpen ? 'open' : ''}`}>
            <div className="filter-drawer-header"><div className="header">FILTERS
            </div>
                <div className="close-container" onClick={toggleDrawer}><img alt="" src={close} /></div></div>
            <Accordion items={filters} isSmall />

        </div>
    )
}
export default FiltersComponent