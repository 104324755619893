import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import globalValues from "../../../../../utilities/apiRequests/globalValues";

const containerStyle = {
    width: "100%",
    height: "400px",
};




const MapLocationComponent = ({ lat, lng }) => {
    const center = {
        lat: lat,
        lng: lng,
    };
    return (
        <LoadScript googleMapsApiKey={globalValues.keys.REACT_APP_LOCATION_GOOGLE_API_KEY}>
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={18}>
                <Marker position={center} />
            </GoogleMap>
        </LoadScript>
    );
};

export default MapLocationComponent;
