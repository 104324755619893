import React from 'react';
import './Checkbox.scss';

const Checkbox = ({ label, checked, onChange }) => {
    return (
        <div className="checkbox-container">
            <input
                type="checkbox"
                id={`checkbox-${label}`}
                checked={checked}
                onChange={onChange}
                className="checkbox-input"
            />
            <label htmlFor={`checkbox-${label}`} className="checkbox-label">
                <span className="checkbox-custom"></span>
                {label}
            </label>
        </div>
    );
};

export default Checkbox;
