import React from "react";
import ImageSlider from "../ImageSlider/ImageSlider";
import "./HomeComponents.scss"
const HomeHeroOne = ({ component }) => {
    return (
        <div className="HomeHero">
            <ImageSlider component={component} />
        </div>
    )
}
export default HomeHeroOne