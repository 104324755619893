import React, { useState } from "react";
import Header from "../../components/Header/Header";
import "./EcommerceWishlist.scss"
import ItemComponent from "../../components/ItemComponent/ItemCopmonent";
import { useHistory } from "react-router";

const EcommerceWishlist = () => {
    const products = [
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616161936148.jpeg",
            "title": "Mangalya Silk ",
            price: "100.00",
            id: 1,

        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616162869639.jpeg",
            "title": "Thirumana Silk ",
            price: "120.00",
            id: 2,


        }, {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616162869639.jpeg",
            "title": "Thirumana Silk ",
            price: "120.00",
            id: 3,


        },
    ]
    const history = useHistory()
    const [items, setItems] = useState(products)

    const onClose = (item) => {
        const updated = items.filter((product) => product.id !== item.id)
        setItems(updated)
    }
    return (
        <div className="EcommerceWishlist">
            <div className="EcommerceWishlist-header"><Header text={"Wislist"} textAlign="center" /></div>
            <div className="EcommerceWishlist-Container">
                {items.map((product, key) => {
                    return (
                        <ItemComponent key={key} item={product} layout={"layout2"}
                            onClick={() => {
                                history.push(`/product/${product.id}`)
                            }} isClose onClose={() => { onClose(product) }} />
                    )
                })}

            </div>
        </div>
    )
}
export default EcommerceWishlist