import React, { useState } from "react";
import "./SearchComponent.scss";
import search from "../../assets/search.svg"
import close from "../../assets/close.svg"
import SearchItem from "./SearchItem/SearchItem";
import { useHistory } from "react-router";


const SearchComponent = ({ isOpen, setIsOpen }) => {
    const [query, setQuery] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const history = useHistory()

    const collection = [
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616161936148.jpeg",
            "title": "Mangalya Silk Collection",
            price: "100.00",
            id: 1
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616162869639.jpeg",
            "title": "Thirumana Silk Collection",
            price: "120.00",
            id: 2
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616166519893.jpeg",
            "title": "Thozhi Silk Collection",
            price: "90.00",
            id: 3

        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616166902711.jpeg",
            "title": "Sitha Silk Collection",
            price: "220.00",
            id: 4
        },
    ]

    const handleSearch = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setQuery(searchValue);

        const results = collection.filter((item) =>
            item.title.toLowerCase().includes(searchValue)
        );
        setFilteredData(results);
    };

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
        setQuery("")
    };

    return (
        <>
            <div
                className={`overlay ${isOpen ? "visible" : ""}`}
                onClick={toggleDrawer}
            ></div>
            <div className={`SearchComponent ${isOpen ? "open" : ""}`}>
                <div className="search-input-container">
                    <div className="middle-container">
                        <div className="search-input">
                            <input
                                type="text"
                                value={query}
                                onChange={handleSearch}
                                placeholder="Search our store"

                            />
                            <div className="search-icon" >
                                <img alt="" src={search} />

                            </div>
                        </div>
                        <div className="close-container" onClick={toggleDrawer}>
                            <img alt="" src={close} />

                        </div>
                    </div>

                    {query !== "" && <div className="search-results">
                        <div className="search-header">PRODUCTS</div>
                        <div className="search-results-container">

                            {query !== "" && filteredData.length > 0 ? (
                                filteredData.map((item, key) => (
                                    <SearchItem item={item} key={key} onClick={() => {
                                        toggleDrawer()
                                        history.push(`/product/${item.id}`)
                                    }} />
                                ))
                            ) : (
                                <div className="empty-result">No results found</div>
                            )}
                        </div>
                    </div>}
                </div>

            </div>
        </>
    );
};

export default SearchComponent;
