import Axios from "axios";
import { APIError, errorLogger } from "./apiErrorHandlers";
import endpointService from "./endpointService";
import { generateTokenForVoucher } from "../../../../../utilities/apiRequests/tokenGenerators";

//get ordeganoxation details
export const getOrgDetails = async (locationID) => {
    try {
        let token = await generateTokenForVoucher();
        if (token) {
            return new Promise(async (resolve, reject) => {
                const apiReqUrl = `${endpointService.witmegVoucherServer.getVoucherOrganizationDetails}${locationID}`
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };

                await Axios({
                    method: "GET",
                    url: apiReqUrl,
                    headers,
                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}
export const getTemplateData = async (locID) => {
    try {
        let token = await generateTokenForVoucher();
        if (token) {
            return new Promise(async (resolve, reject) => {
                const apiReqUrl = `${endpointService.witmegVoucherServer.getTemplateOrganizationDetails}${locID}`
                let headers = {
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                };
                await Axios({
                    method: "GET",
                    url: apiReqUrl,
                    headers,
                }).then((response) => {
                    const { Status, Result } = response.data;
                    let resolveValue = Status ? Result : null
                    resolve(resolveValue)
                }).catch((error) => {
                    reject(error);
                });
            })
        }
    } catch (error) {
        let customErrMsg = error.customErrMsg || "";
        if (error.custmErrType === "APIERROR") {
            switch (true) {
                default: {
                    customErrMsg = "Error Occurred. Please try again.";
                    break;
                }
            }
        }
        errorLogger(error, { customErrMsg });
        return Promise.reject(APIError(customErrMsg, error));
    }

}