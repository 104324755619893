import React from "react";
import "./Button.scss"
const Button = ({ text, onClick, invert, width, isLoading }) => {
    return (
        <div className={invert ? "Ecommorce-Button-invert " : "Ecommorce-Button"} onClick={onClick} style={{ width }}>
            {isLoading && <div className="loader-btn" />}
            {text}
        </div>
    )

}
export default Button