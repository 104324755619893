import React from "react";
import ContactUs from "../../components/ContactUs/ContactUs";
import "./EcommerceContact.scss"
import Header from "../../components/Header/Header";
import { sortByOrderAscending } from "../../helper/helper";
import { useSelector } from "react-redux";

const EcommerceContact = () => {
    const pages = useSelector((state) => state.companyData.pages)
    const page = pages.find((page) => page.PageId === "contactus")
    const Components = page?.Components || []
    const getComponents = (component) => {
        switch (component.ID) {
            case "Header":
                return <div className="EcommerceContact-header"><Header text={component.Props.content} textAlign={component.Props.textAlign} /></div>
            case "ContactUs":
                return <div className="EcommerceContact-Container"><ContactUs components={component.Components} /></div>
            default:
                return <div />
        }

    }
    return (
        <div className="EcommerceContact">
            {sortByOrderAscending(Components).map((component, key) => {
                return (<div className="EcommerceHome-container" key={key}>
                    {getComponents(component)}
                </div>)
            })}
        </div>
    )
}
export default EcommerceContact