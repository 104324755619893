import React, { useEffect, useState } from 'react'
import { DatePicker, Icon, InputBox, Label, Popup } from 'witmeg-ui-system';
import "./Popup.scss"
import close from "../../assets/close.svg"
import tick from "../../assets/tick.svg"
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart, getCartItemsToCart } from '../../../RestaurantWidget/utilities/api/restaurantsService';
import { getCurrencyFormat, getLocalStorageData, LOCAL_STORAGE_ITEMS_NAMES } from '../../../RestaurantWidget/utilities/helper/helper';
import { v4 as uuidv4 } from 'uuid';
import qrIMg from "../../assets/qr.png"
import { setOrderData } from '../../../../../../../redux/resturantWidgetSlice';

const CustomPopup = ({ visibility, onPopupClose, Data }) => {

    const { voucherData, currency,
    } = useSelector((state) => state.resturantData);
    let dateValue = voucherData?.Settings?.CustomVoucher?.ExpiryConfiguration?.EnableCustomDuration ? moment().toString() :
        moment().add(((voucherData?.Settings?.CustomVoucher?.ExpiryConfiguration?.ExpiryDuration - 1)), "days").toString()

    let amountValue = voucherData?.Settings?.CustomVoucher?.PriceConfiguration?.Amount
    const [selected, setSelected] = useState(Data[0])
    const [date, setDate] = useState(dateValue)
    const [value, setValue] = useState(0)
    const [quantity, setQuantity] = useState(1)
    const [error, setError] = useState("")
    const [validationError, setValidationError] = useState("")
    const customVoucher = JSON.parse(localStorage.getItem("ECOM_CUSTOM"))
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const { companyDetails } = useSelector((state) => state.companyData);
    const disabledDate = (current) => {
        return current && current < moment().startOf('day');
    };
    const currencyFormat = getCurrencyFormat(currency);
    const getItemPriceForCart = (priceValue, quantity) => {
        const createPriceObj = (price, quantity) => ({
            Amount: price * quantity * 100,
            FormattedText: currencyFormat((price * quantity)),
            FormattedAmount: Number(((price * quantity)).toFixed(2))
        });

        const getPriceData = (price, quantity) => ({
            Delivery: createPriceObj(price || 0, quantity),
            TakeAway: createPriceObj(price || 0, quantity),
            DineIn: createPriceObj(price || 0, quantity),
            CurrencyCode: "GB"
        });
        return {
            BaseUnitPrice: getPriceData(priceValue, quantity),
            BaseTotalPrice: getPriceData(priceValue, quantity)
        };
    };
    const addItemToCart = async () => {
        setIsLoading(true)
        const val = await getLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA);
        const userDetails = localStorage.getItem('fullUserDetails')
        let parsedUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'))
        let customerID = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID);

        let customer = {
            ID: customerID,
            Name: {
                DisplayName: userDetails ? parsedUserDetails.UserName : "",
                FirstName: userDetails ? parsedUserDetails.FirstName : "",
                LastName: userDetails ? parsedUserDetails.LastName : "",
            },
            Email: userDetails ? parsedUserDetails.Email : "",
            IsPrimaryCustomer: true

        }

        let cartObject = {
            "CartItemID": customVoucher?.MenuItemID ?? uuidv4(),
            "Title": customVoucher?.Title?.en ?? "Gift Voucher",
            "CustomerID": customerID,
            "Quantity": {
                "Unit": "pcs",
                "Amount": quantity
            },
            "DefaultQuantity": {
                "Amount": 1,
                "Unit": "pcs"
            },
            "CustomerRequests": {
                "Allergy": {
                    "Allergens": [],
                    "Instructions": ""
                },
                "SpecialInstructions": ""
            },
            SelectedModifierGroups: [],
            Id: uuidv4(),
            ID: "",
            ModifierGroupRules: [],
            Image: "",
            PriceInfo: getItemPriceForCart(value, 1),
            MetaData: {
                IsVoucher: true,
                Type: "CUSTOM",
                TemplateID: selected.TemplateID,
                ExpiryDate: moment(date).format("DD/MM/YYYY"),
                VoucherValue: Number(value) * 100,
                ProductID: customVoucher?.MetaData?.ProductID

            }

        }
        let cart = {
            "OrderID": localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) ? localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) : '',
            "StoreID": val.cloudLocationID,
            "ConsumerID": customerID,
            "OrderingPlatformType": "RETAILPACX",
            "CountryISOCode": "GB",
            "OrganizationID": val.organizationID,
            "CloudLocationID": val.cloudLocationID,
            "Name": companyDetails.shopName,
            "OrderFullFilmentType": "DELIVERY_BY_REWARDCONSUMER",
            "Customers": [customer],
            Cart: cartObject
        }
        await addToCart(cart).then((res) => {
            if (res) {
                getCartItemsToCart(customerID, val.cloudLocationID).then((response) => {
                    if (response) {
                        dispatch(setOrderData(response))
                        setIsLoading("")
                        setValue(0)
                        setError(false)
                        setQuantity(1)
                        onPopupClose()
                        setValidationError(false)

                    }
                })
            }
        })
    }
    useEffect(() => {
        setSelected(Data[0])
    }, [Data])
    useEffect(() => {
        if (amountValue) {
            setValue(((amountValue.Min / 100)).toFixed(2))
            setQuantity(1)
        }
    }, [visibility])
    const handleDate = async (value) => {
        if (value) {
            setDate(value)
        } else {
            setDate(moment().toISOString()
            );
        }
    }
    const generateRandomString = () => {
        return Math.random().toString(36).substring(2, 10).toUpperCase();
    }
    return (
        <Popup
            className='Custom-Popup-Voucher'
            modalVisible={visibility}
            modalTitle=""
            mask={true}
            centered
            closable={false}
        >
            <div className='popup-inner'>
                <div className='header-container'>
                    <div className='header'>
                        <div className='text'>Custom Vouchers</div>
                        <div className='sub'>Personalize your gift voucher</div></div>
                    <img alt="" src={close} className='close-cont' onClick={() => {
                        setIsLoading(false)
                        setValue(0)
                        setError(false)
                        setQuantity(1)
                        onPopupClose()
                        setValidationError(false)
                    }} />
                </div>
                <div className='inner-container'>
                    <div className='inner-container-left'>
                        <Label required >Select a template</Label>
                        <div className='templates-conatiner'>
                            {Data.map((value, key) => {
                                return (
                                    <div className='template-item '
                                        onClick={() => {

                                            setSelected(value)
                                        }} key={key}>
                                        <img alt='' src={value.Image} />
                                        {value.ID === selected?.ID
                                            &&
                                            <div className="tick">
                                                <img alt='' src={tick} />
                                            </div>}
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    <div className='inner-container-right'>
                        <div className='selected-image'
                        >
                            <img alt='' src={selected?.Image} />
                            <div class="voucher-details">
                                <div class="voucher-price">{value > 0 && `£${value}`}</div>
                                <div class="company-name">{selected && voucherData?.OrganizationDetails?.Name}</div>
                                <div class="gift-voucher-text">GIFT VOUCHER</div>
                                <div className='sub-text-vopucher'>We hope you enjoy the benefits this voucher brings. Kindly refer
                                    to the terms and conditions for any additional information</div>
                                <div class="valid-text">
                                    VALID TILL
                                    <div class="valid-date">{moment(date).format("DD-MM-YYYY")}</div>
                                </div>
                                <div className='qr-code'>
                                    <img alt='' src={qrIMg} />
                                </div>
                                <div className='qr-text'>{generateRandomString()}</div>

                            </div>
                        </div>

                        <div className='input-container'>
                            <Label required >Value</Label>
                            <div className='input-cont'>
                                <InputBox type="number"
                                    value={value}
                                    onChange={e => {
                                        if (!isLoading) {
                                            setValue(e.target.value)
                                            setError(false)
                                            setValidationError(false)
                                        }
                                    }}
                                    error={error}
                                    decimalPlaces={2}
                                />
                                {error && <div className='error-text'>Invalid voucher value</div>}
                                {validationError && <div className='error-text'>Invalid voucher value.
                                    Voucher value should be greater than {amountValue.Min / 100} and less than {amountValue.Max / 100}.</div>}
                            </div>
                        </div>

                        {voucherData?.Settings?.CustomVoucher?.ExpiryConfiguration?.EnableCustomDuration && <div className='input-container'>
                            <Label required >Expiring Date</Label>
                            <div className='input-cont'>
                                <DatePicker className='input-cont-date'
                                    value={moment(date)}
                                    onChange={handleDate}
                                    disabledDate={disabledDate}
                                /></div>

                        </div>}

                        <div className='input-container'>
                            <Label required >Quantity</Label>
                            <div className='item-cart-qty-container'>
                                <div className='quantity-ic-cont' onClick={() => {
                                    if (!isLoading) {
                                        if (quantity !== 1) {
                                            setQuantity((qau) => qau - 1)
                                        }
                                    }
                                }}> <Icon name="minus" className="quantity-ic" size={16} /></div>
                                <div className='qty-cont'>{quantity}</div>
                                <div className='quantity-ic-cont' onClick={() => {
                                    if (!isLoading) { setQuantity((qau) => qau + 1) }
                                }}> <Icon name="plus" className="quantity-ic" size={16} /></div>
                            </div>
                        </div>
                        <div className='input-container'>
                            <Label  >Total</Label>
                            <div className='input-text'>
                                £ {(Number(value) * quantity).toFixed(2)}</div></div>
                        <div className='rest-btn-conatiner'><div className="checkout-button" onClick={() => {
                            if (!isLoading) {
                                if (value > 0) {
                                    if (
                                        amountValue?.Min != null &&
                                        amountValue?.Max != null &&
                                        value >= (amountValue.Min / 100) &&
                                        value <= (amountValue.Max / 100)
                                    ) {
                                        addItemToCart()

                                    } else {
                                        setValidationError(true)
                                    }
                                } else {
                                    setError(true)

                                }
                            }
                        }} >
                            {isLoading && <div className="loader-btn" />} Add to Cart
                        </div></div>
                    </div>
                </div>

            </div>
        </Popup >
    );
}

export default CustomPopup;