import globalValues from "../global/globalValues";


const {
    REACT_APP_ENTITLEMENTREG__URL,
    REACT_APP__NEUTRIPOS_BASEURL,
    REACT_APP__APPREGISTRY_BASEURL,
    REACT_APP__RETAILPAXS__BASEURL,
    REACT_APP__EMAILREGISTRY__BASEURL,
    REACT_APP__SALES_REGISTRY_BASEURL,
    REACT_APP_PAYMENT_BASE_URL,
    REACT_APP__LOYALTY_REGISTRY_BASEURL,
    REACT_APP_API_URL_CUSTOMER,
    REACT_APP_VOUCHER_BASE_URL
} = globalValues.baseURLS;
const apiEndpointPaths = {
    // ************************** Retail Paths Server *********************
    witmegRetailPaxServer: {
        /* Layouts Paths */
        getCompanyByDomain: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/company/domain`,
        getCompanyAllHomeLayouts: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/homelayout/company`,
        getPageHomeLayout: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/homelayout/page`,
        getCompanyAllPagesInfo: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/pagesinfo/all/companyid`,
        getHomeLayoutById: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/homelayout/id`,
        getPageInfoById: `${REACT_APP__RETAILPAXS__BASEURL}/v1/retail/pagesinfo/id`,

        /* `Pricing Plan` paths */
        getTierDetails: `${REACT_APP_ENTITLEMENTREG__URL}/v2/tier/getall`,
        getVerticalPlan: `${REACT_APP_ENTITLEMENTREG__URL}/v1/witmeg/vertical/getall`,
        getFrequencyDetails: `${REACT_APP_ENTITLEMENTREG__URL}/v1/frequencydetails/all`,
        getProductFeatures: `${REACT_APP_ENTITLEMENTREG__URL}/v1/vertical/features/list`,


        // Add to cart

        addToCart: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/order/add`,
        addContact: `${REACT_APP__RETAILPAXS__BASEURL}/v1/contact-enquiry/submit`,
    },
    witmegVoucherServer: {
        getVoucherOrganizationDetails: `${REACT_APP_VOUCHER_BASE_URL}/v1/voucher-customer-onboarding/location/`
    },
    loyaltyRegistryServer: {
        /* Get All Restaurant Menu data */
        getAllRestaurantMenuItems: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/menu`,
    },
    /* Neutri POS server paths */
    neutriPosServer: {

        /* Get Restaurant Menu created Date */
        getRestaurantMenuCreatedDate: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/menudate`,

        /* Get Restaurant Main Menu IDs  */
        getRestaurantMainMenuIDs: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/mainmenu`,

        /* Get Restaurant Main Menu Data by IDs */
        getRestaurantMainMenuDataByIDs: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/submenu/mainmenuid`,

        /* Get Restaurant Sub Menu Data by IDs */
        getRestaurantSubMenuDataByIDs: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/menuproducts/submenuid`,

        /* Get Addon Prices for the specified Addon IDs */
        getAddonProductPrices: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/productprices`,

        /* Get `Restaurant Menu settings` data */
        getRestaurantMenuSettings: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/webordersettings/fetch`,

        /* Place an Restaurant Menu order */
        addRestaurantMenuOrder: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/weborder/add`,

        /* Get Delivery distance from one Postcode to another */
        getDeliveryDistanceByPostcode: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/deliverydistance/calculate`,

        /* Get QR Code settings for a location */
        getRestaurantQRCodeSettings: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/qrcode/fetch`,

        /* Get `Order Type List` data for Restaurant */
        getOrderTypeListData: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/ordertype/list`,
    },

    /* App Registry server paths */
    appRegistry: {
        /* Get all country list */
        getCountryList: `${REACT_APP__APPREGISTRY_BASEURL}/v1/countrylist`,
    },

    emailRegistry: {
        sendEmail: `${REACT_APP__EMAILREGISTRY__BASEURL}/v1/email/template`
    },

    salesRegistry: {
        getSettingsByOrgId: `${REACT_APP__SALES_REGISTRY_BASEURL}setting`
    },
    payment: {
        getStores: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/management/fetch/stores`,
        getPaymentMethods: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/paymentmethod/fetch`,
        instantPayment: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/payment/instant`,
        sendPaymentDetail: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/pay/paymentdetail`,
        payWithToken: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/pay/token`,
        fetchToken: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/payment/token/fetch`,
    },
    orgCustomerRegistry: {
        loginUser: `${REACT_APP_API_URL_CUSTOMER}login`,
        getUser: `${REACT_APP_API_URL_CUSTOMER}fetch`,
        findUser: `${REACT_APP_API_URL_CUSTOMER}find`,
        globalfindUser: `${REACT_APP_API_URL_CUSTOMER}globalfind`,
        joinUser: `${REACT_APP_API_URL_CUSTOMER}join`,
        addUser: `${REACT_APP_API_URL_CUSTOMER}add`,




    }

};

export default apiEndpointPaths;

