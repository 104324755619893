import React, { useState, useEffect } from "react";
import "./ImageSlider.scss";
import { sortByOrderAscending } from "../../helper/helper";


const ImageSlider = ({ component }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const delay = 3000;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % component.length);
        }, delay);
        return () => clearInterval(interval);
    }, []);

    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };
    const getsubheadersComponents = (obj) => {
        return (
            <div className="sub-text-content">
                {obj.map((val) => {
                    return (
                        <div className="text-content">{val.Props.content}</div>)

                })}
            </div>
        )
    }

    const getComponents = (obj) => {
        switch (obj.ID) {
            case "hero-image-header":
                return <div className="header-text-jack">{obj.Props.content}</div>

            case "hero-image-sub":
                return getsubheadersComponents(obj.Components)

            default:
                return <div />
        }

    }
    return (
        <div className="slider">
            <div className="slider-images">
                {component.map((comp, index) => (

                    <div
                        key={index}
                        className={`image-back ${index === currentIndex ? "active" : ""}`}
                        style={{ backgroundImage: `url(${comp.Props.src})` }}
                    > {comp?.Components && <div className="text">
                        <div>
                            {sortByOrderAscending(comp.Components).map((val, key) => {
                                return (<div key={key}>
                                    {getComponents(val)}
                                </div>)
                            })}
                        </div>
                    </div>}
                        <div className="curve" />
                    </div>
                ))}
            </div>
            <div className="dots">
                {component.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentIndex ? "active" : ""}`}
                        onClick={() => handleDotClick(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default ImageSlider;
