import React from "react";
import "./HomeComponentTwo.scss"
import Button from "../Button/Button";
import { sortByOrderAscending } from "../../helper/helper";
const HomeComponentTwo = ({ components }) => {
    const collection = [
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616161936148.jpeg",
            "title": "Mangalya Silk Collection",
            price: "100",
            id: 1
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616162869639.jpeg",
            "title": "Thirumana Silk Collection",
            price: "120",
            id: 2
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616166519893.jpeg",
            "title": "Thozhi Silk Collection",
            price: "90",
            id: 3

        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616166902711.jpeg",
            "title": "Sitha Silk Collection",
            price: "220",
            id: 4
        },
    ]
    const getComponents = (component) => {
        switch (component.ID) {
            case "curated-collection-header":
                return <div className="details-header">{component.Props.content}</div>

            case "curated-collection-list":
                return <div className="collection-row-one">
                    {collection.map((coll, key) => {
                        return (
                            <div className="item-collection" style={{
                                backgroundImage:
                                    `linear-gradient(
                                        to bottom,
                                        rgba(0, 0, 0, 0) 0%, 
                                        rgba(0, 0, 0, 0.8) 100%
                                      ), url(${coll.image})`
                            }} key={key}>
                                <div className="title-text">{coll.title}</div>
                                <div className="button-container">
                                    <Button text="SHOP NOW" invert /></div>
                            </div>
                        )
                    })}

                </div>
            default:
                return <div />
        }

    }
    return (
        <div className="HomeComponentTwo">
            {sortByOrderAscending(components).map((component) => {
                return getComponents(component)
            })}

        </div>
    )

}
export default HomeComponentTwo