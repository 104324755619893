import React from 'react'
import "./InputComponent.scss"
const InputComponent = ({ placeholder, value, onChange, layout = "layout1", type = "text", isError = false, errorMessage = "", disabled }) => {


    return (
        <div className='InputComponent-Wrapper'>
            <div className={`InputComponent-${layout}  ${isError ? "InputComponent-error" : ""}`}>
                <input placeholder={placeholder} value={value} onChange={onChange} type={type}
                    disabled={disabled}
                />
            </div>
            {isError && <div className='InputComponent-error'>{errorMessage}</div>}
        </div>

    )
}
export default InputComponent