import React from "react";
import "./RestaurantAboutus.scss"
import Content from "./Content";
import { useSelector } from "react-redux";
import { sortByOrderAscending } from "../../helper/helper";




const RestaurantAboutus = () => {
    const pages = useSelector((state) => state.companyData.pages)
    const page = pages.find((page) => page.PageId === "aboutus")
    const Components = page?.Components || []
    const getImageContainer = (items) => {
        return (<div className="gallery-container">
            {items.map((image, key) => {
                return (<div key={key} className="image-cont">
                    <img alt="" src={image.Props.src} />
                </div>)
            })}
        </div>)
    }
    const getComponents = (component) => {
        switch (component.ID) {
            case "Header":
                return <div className="About-header" style={{
                    "background": `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)),
    url(${component.Props.src})`
                }}>
                    <div className="text">
                        <div className="header-text-jack">{component.Props.content}</div>
                    </div>
                </div>
            case "AboutusContent":
                return <Content components={component.Components} />

            case "ImageGrid":
                return getImageContainer(component.Components)

            default:
                return <div />
        }

    }
    return (
        <div className="RestaurantAboutus">

            {sortByOrderAscending(Components).map((component, key) => {
                return (<div key={key}>
                    {getComponents(component)}
                </div>)
            })}

        </div>
    )
}
export default RestaurantAboutus