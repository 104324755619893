import React, { useEffect, useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import Drawer from "./components/Drawer/Drawer";
import LocationFinder from "./components/OrderPicker/LocationFinder/LocationFinder";
import Modal from "./components/Modal/Modal";
import "./index.scss"
import { useSelector } from "react-redux";

const HospitalityPageWrapper = ({ children }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [addDeliveryLoc, setAddDeliveryLoc] = useState(false);
    const [deliverySuccess, setDeliverySuccess] = useState(false);
    const [deliveryFailed, setDeliveryFailed] = useState(false);
    const [checkoutFailed, setCheckoutFailed] = useState("");
    const [isStoreClosed, setIsStoreClosed] = useState(false);
    const companyDetails = useSelector((state) => state.companyData.companyDetails);


    const isStoreOpen = () => {
        const now = new Date();
        const currentDay = now.toLocaleString('en-US', { weekday: 'long' });
        let currentHours = now.getHours();
        let currentMinutes = now.getMinutes();
        let currentTime = currentHours * 60 + currentMinutes;

        const today = companyDetails?.openingHours?.find(day => day.Day === currentDay);
        if (!today || today.Closed) return false;

        for (let hours of today.OpenHours) {
            let [startH, startM] = hours.StartTime.split(':').map(Number);
            let [endH, endM] = hours.EndTime.split(':').map(Number);
            let startTime = startH * 60 + startM;
            let endTime = endH * 60 + endM;

            if (endTime < startTime) {
                endTime += 24 * 60;
                if (currentTime < startTime) currentTime += 24 * 60;
            }

            if (currentTime >= startTime && currentTime <= endTime) {
                return true;
            }
        }
        return false;
    }
    useEffect(() => {
        if (companyDetails) {
            setIsStoreClosed(!isStoreOpen())
        }
    }, [companyDetails])
    return (
        <>
            <div className="page-wrapper">
                <LocationFinder visibility={addDeliveryLoc}
                    setAddDeliveryLoc={setAddDeliveryLoc}
                    onPopupClose={() => {
                        setAddDeliveryLoc(false)
                    }}

                    setDeliverySuccess={setDeliverySuccess}
                    setDeliveryFailed={setDeliveryFailed}
                />
                <Modal visibility={deliverySuccess} type="success" headerText="Great news! We deliver to your location" subText="Enjoy fresh and fast delivery straight to your doorstep. Place your order now!" onPopupClose={() => {
                    setDeliverySuccess(false)
                }} />
                <Modal visibility={deliveryFailed} type="failed" headerText="Uh-oh! Looks like we can’t reach you" subText={`Sorry, we don’t currently deliver to your area. Stay tuned for updates!`} onPopupClose={() => {
                    setDeliveryFailed(false)
                }} />
                <Modal visibility={checkoutFailed !== ""} type="failed" headerText="Sorry!" subText={checkoutFailed} onPopupClose={() => {
                    setCheckoutFailed("")
                }} />
                <Navbar setIsDrawerOpen={setIsDrawerOpen} setAddDeliveryLoc={setAddDeliveryLoc} setCheckoutFailed={setCheckoutFailed}
                    isStoreOpen={isStoreOpen} setIsStoreClosed={setIsStoreClosed} />
                {isDrawerOpen && <Drawer setIsDrawerOpen={setIsDrawerOpen} />}
                {children}
                <Modal visibility={isStoreClosed} type="closed" headerText=" We’ll Be Back Soon!" subText={
                    "We're sorry, but the store is currently closed. Please check our hours and visit us later!"
                } onPopupClose={() => {
                    setIsStoreClosed(false)
                }}
                    btnText={"VIEW STORE"} />
            </div>

        </>
    );
}

export default HospitalityPageWrapper;