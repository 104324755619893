import React, { useState } from "react";
import "./EcommerceRegister.scss"
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import InputComponent from "../../components/InputComponent/InputComponent";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useSelector } from "react-redux";
import globalValues from "../../global/globalValues";
import apiEndpointPaths from "../../helper/apiEndpointPaths";
import jwt from 'jsonwebtoken';
import Modal from "../../components/Modal/Modal";
import { useHistory } from "react-router";

const EcommerceRegisterAccount = () => {
    const companyDetails = useSelector((state) => state.companyData.company)

    const options = ['Billing', 'Delivery', 'Home', "Other"];
    const [userDetails, setUserDetails] = useState({
        firstName: "",
        lastName: "",
        email: sessionStorage.getItem("tmpEmail"),
        password: "",
        confirmPassword: "",
        userName: ""
    })
    const [contactDetails, setContactDetails] = useState({
        addressType: options[0],
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        postal: "",
        phoneNumber: "",
        landline: ""
    })
    const [errors, setErrors] = useState({
        password: "",
        email: "",
        firstName: "",
        lastName: "",
        userName: "",
        confirmPassword: "",
        addressType: "",
        address1: "",
        city: "",
        state: "",
        postal: "",
        country: "",
        phoneNumber: ""
    })
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isError2, setIsError2] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const history = useHistory()
    const onUserDetailsChange = (e, field) => {
        setUserDetails({ ...userDetails, [field]: e.target.value })
        setErrors({
            ...errors,
            [field]: "",
        });
    }
    const onContactDetailsChange = (e, field) => {
        setContactDetails({ ...contactDetails, [field]: e.target.value })
        setErrors({
            ...errors,
            [field]: "",
        });
    }
    const setSelectedOption = (value) => {
        setContactDetails({ ...contactDetails, addressType: value })
        setErrors({
            ...errors,
            [value]: "",
        });
    }
    const validateForm = () => {
        let valid = true;
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const newErrors = {
            password: "",
            email: "",
            firstName: "",
            lastName: "",
            userName: "",
            confirmPassword: "",
            addressType: "",
            address1: "",
            city: "",
            state: "",
            postal: "",
            country: "",
            phoneNumber: ""
        };
        if (userDetails.password.trim() === "") {
            newErrors.password = "*required";
            valid = false;
        }
        else if (!passwordRegex.test(userDetails.password)) {
            newErrors.password = "Password must be at least 8 characters long, include one uppercase letter, one number, and one special character.";
            valid = false;
        }
        if (userDetails.email === "") {
            newErrors.email = "*required";
            valid = false;
        }
        else if (!emailRegex.test(userDetails.email)) {
            newErrors.email = "Invalid email format";
            valid = false;
        }

        if (userDetails.firstName.trim() === "") {
            newErrors.firstName = "*required";
            valid = false;
        }
        if (userDetails.lastName.trim() === "") {
            newErrors.lastName = "*required";
            valid = false;
        }
        if (userDetails.userName.trim() === "") {
            newErrors.userName = "*required";
            valid = false;
        }
        if (userDetails.confirmPassword.trim() === "") {
            newErrors.confirmPassword = "*required";
            valid = false;
        }
        if (userDetails.confirmPassword !== "" && userDetails.confirmPassword !== userDetails.password) {
            newErrors.confirmPassword = "Password doesn't match"
            valid = false;
        }
        if (contactDetails.addressType.trim() === "") {
            newErrors.addressType = "*required";
            valid = false;
        }
        if (contactDetails.address1.trim() === "") {
            newErrors.address1 = "*required";
            valid = false;
        }
        if (contactDetails.city.trim() === "") {
            newErrors.city = "*required";
            valid = false;
        }
        if (contactDetails.state.trim() === "") {
            newErrors.state = "*required";
            valid = false;
        }
        if (contactDetails.country.trim() === "") {
            newErrors.country = "*required";
            valid = false;
        }
        if (contactDetails.postal.trim() === "") {
            newErrors.postal = "*required";
            valid = false;
        }
        if (contactDetails.phoneNumber.trim() === "") {
            newErrors.phoneNumber = "*required";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleRegister = () => {
        setIsLoading(true)
        let secondAddresss = {
            FirstName: userDetails.firstName,
            LastName: userDetails.lastName,
            UserName: userDetails.userName,
            Password: userDetails.password,
            Email: sessionStorage.getItem("tmpEmail"),
            Avatar: "",
            OriginatedOrganizationID: companyDetails?.OrganisationID,
            Addresses: [
                {
                    AddressType: contactDetails.addressType,
                    AddressLine1: contactDetails.address1,
                    AddressLine2: contactDetails.address2,
                    City: contactDetails.city,
                    StateProvinceRegion: contactDetails.state,
                    PostZipCode: contactDetails.postal,
                    Country: contactDetails.country,
                    Phone: contactDetails.phone,
                    Fax: contactDetails.landline,
                    Email: "",
                    CreatedDate: Date.now,
                    LastModifiedDate: Date.now,
                },
            ],
        };
        const requestOptions = {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + globalValues.keys.REACT_APP_LoginAuthorizationKey,
            },
            body: JSON.stringify(secondAddresss),
        };

        const requestEUserDtlsOptions = {
            method: "POST",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + globalValues.keys.REACT_APP_LoginAuthorizationKey,
            },
            body: JSON.stringify({
                Email: sessionStorage.getItem("tmpEmail").toLowerCase(),
                OrganizationID: companyDetails?.OrganisationID,
            }),
        };

        fetch(apiEndpointPaths.orgCustomerRegistry.globalfindUser, requestEUserDtlsOptions)
            .then((response) => response.json())
            .then(function (eudata) {

                if (eudata.Status) {

                    let secondAddresss = {
                        FirstName: userDetails.firstName,
                        LastName: userDetails.lastName,
                        UserName: userDetails.userName,
                        Password: userDetails.password,
                        Email: sessionStorage.getItem("tmpEmail"),
                        Avatar: "",
                        UserID: eudata.Result,
                        OriginatedOrganizationID: companyDetails?.OrganisationID,
                        Addresses: [
                            {
                                AddressType: contactDetails.addressType,
                                AddressLine1: contactDetails.address1,
                                AddressLine2: contactDetails.address2,
                                City: contactDetails.city,
                                StateProvinceRegion: contactDetails.state,
                                PostZipCode: contactDetails.postal,
                                Country: contactDetails.country,
                                Phone: contactDetails.phone,
                                Fax: contactDetails.landline,
                                Email: "",
                                CreatedDate: Date.now,
                                LastModifiedDate: Date.now,
                            },
                        ],
                    };
                    const requestJoinOptions = {
                        method: "POST",
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ` + globalValues.keys.REACT_APP_LoginAuthorizationKey,
                        },
                        body: JSON.stringify(secondAddresss),
                    };

                    fetch(apiEndpointPaths.orgCustomerRegistry.joinUser, requestJoinOptions)
                        .then((response) => response.json())
                        .then(function (data2) {
                            sessionStorage.setItem("AccessToken", data2.Result.AccessToken);
                            sessionStorage.setItem(
                                "RefreshToken",
                                data2.Result.RefreshToken
                            );
                            localStorage.setItem("isLogged", "true")
                            const expireTime =
                                new Date().getTime() + globalValues.jwtKeys.REACT_APP_CustjwtExp * 1000;

                            let payload = {
                                aud: globalValues.jwtKeys.REACT_APP_CustjwtAud,
                                exp: expireTime,
                                iss: globalValues.jwtKeys.REACT_APP_CustjwtIss,
                                usertoken: data2.Result.AccessToken,
                            };

                            let signOptions = {
                                algorithm: "RS256",
                            };

                            let privateKey = globalValues.keys.REACT_APP_LoginprivateKey;
                            let token = jwt.sign(payload, privateKey, signOptions);

                            const requestUserDtlsOptions = {
                                method: "POST",
                                headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ` + token,
                                },
                                body: JSON.stringify({
                                    Email: sessionStorage.getItem("tmpEmail").toLowerCase(),
                                    OrganizationID: companyDetails?.OrganisationID,
                                }),
                            };

                            fetch(
                                apiEndpointPaths.orgCustomerRegistry.getUser,
                                requestUserDtlsOptions
                            )
                                .then((response) => response.json())
                                .then(function (data) {

                                    sessionStorage.setItem("username", data.Result.UserName);
                                    sessionStorage.setItem(
                                        "userfirstname",
                                        data.Result.FirstName
                                    );
                                    sessionStorage.setItem(
                                        "userlastname",
                                        data.Result.LastName
                                    );
                                    sessionStorage.setItem("useremail", userDetails.userName);
                                    sessionStorage.setItem("UserID", data.Result.UserID);
                                    sessionStorage.setItem(
                                        "OriginatedOrganizationID",
                                        data.Result.OriginatedOrganizationID
                                    );
                                    localStorage.setItem("isLogged", "true")
                                    localStorage.setItem(
                                        "fullUserDetails",
                                        JSON.stringify(data.Result)
                                    );
                                    setIsLoading(false)
                                    setIsSuccess(true)

                                });
                        });
                } else {
                    fetch(apiEndpointPaths.orgCustomerRegistry.addUser, requestOptions)
                        .then((response) => response.json())
                        .then(function (data) {

                            if (data.Status === false) {
                                setIsLoading(false)
                                setIsError(true)
                            } else if (
                                data.Result ==
                                "Customer Already Exist in Global. Cannot insert again"
                            ) {
                                setIsLoading(false)
                                setIsError2(true)
                            } else {
                                sessionStorage.setItem(
                                    "AccessToken",
                                    data.Result.AccessToken
                                );
                                sessionStorage.setItem(
                                    "RefreshToken",
                                    data.Result.RefreshToken
                                );
                                sessionStorage.setItem("loggedin", "true");
                                localStorage.setItem("isLogged", "true")
                                const expireTime =
                                    new Date().getTime() + globalValues.jwtKeys.REACT_APP_CustjwtExp * 1000;

                                let payload = {
                                    aud: globalValues.jwtKeys.REACT_APP_CustjwtAud,
                                    exp: expireTime,
                                    iss: globalValues.jwtKeys.REACT_APP_CustjwtIss,
                                    usertoken: data.Result.AccessToken,
                                };

                                let signOptions = {
                                    algorithm: "RS256",
                                };

                                let privateKey = globalValues.keys.REACT_APP_LoginprivateKey;
                                let token = jwt.sign(payload, privateKey, signOptions);

                                const requestUserDtlsOptions = {
                                    method: "POST",
                                    headers: {
                                        "Access-Control-Allow-Origin": "*",
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ` + token,
                                    },
                                    body: JSON.stringify({
                                        Email: sessionStorage.getItem("tmpEmail").toLowerCase(),
                                        OrganizationID: companyDetails?.OrganisationID,
                                    }),
                                };

                                fetch(
                                    apiEndpointPaths.orgCustomerRegistry.getUser,
                                    requestUserDtlsOptions
                                )
                                    .then((response) => response.json())
                                    .then(function (data) {

                                        sessionStorage.setItem("username", data.Result.UserName);
                                        sessionStorage.setItem(
                                            "userfirstname",
                                            data.Result.FirstName
                                        );
                                        sessionStorage.setItem(
                                            "userlastname",
                                            data.Result.LastName
                                        );
                                        sessionStorage.setItem("useremail", userDetails.userName);
                                        sessionStorage.setItem("UserID", data.Result.UserID);
                                        sessionStorage.setItem(
                                            "OriginatedOrganizationID",
                                            data.Result.OriginatedOrganizationID
                                        );
                                        localStorage.setItem("isLogged", "true")

                                        localStorage.setItem(
                                            "fullUserDetails",
                                            JSON.stringify(data.Result)
                                        );
                                        setIsLoading(false)
                                        setIsSuccess(true)


                                    });
                            }
                        });
                }
            });

    };
    return (
        <div className="Ecommerceregister">
            <div className="EcommerceAccount-container">
                <div className="logo-container">
                    <img alt="" className="logo"
                        onClick={() => {
                            history.push("/")
                        }}
                        src={companyDetails?.Logo} />
                </div>
                <div className="register-form">
                    <Header text={"Create an Account"} fontSize={"26px"} fontWeight={"500"} />
                    <div className="register-sub-text">Please enter the details below to create your account.</div>

                    <div className="seperator">User Information</div>
                    <div className="input-grid-container">
                        <InputComponent layout="layout2"
                            placeholder="First Name*"
                            value={userDetails.firstName}
                            onChange={e => onUserDetailsChange(e, "firstName")}
                            isError={errors.firstName !== ""}
                            errorMessage={errors.firstName} />
                        <InputComponent layout="layout2"
                            placeholder="Last Name*"
                            value={userDetails.lastName}
                            onChange={e => onUserDetailsChange(e, "lastName")}
                            isError={errors.lastName !== ""}
                            errorMessage={errors.lastName} />
                    </div>
                    <div className="seperator">Email Login Information</div>

                    <div className="input-grid-container">
                        <InputComponent layout="layout2"
                            placeholder="Email*"
                            value={userDetails.email}
                            onChange={e => onUserDetailsChange(e, "email")}
                            isError={errors.email !== ""}
                            errorMessage={errors.email}
                            disabled />
                        <InputComponent layout="layout2"
                            placeholder="Username*"
                            value={userDetails.userName}
                            onChange={e => onUserDetailsChange(e, "userName")}
                            isError={errors.userName !== ""}
                            errorMessage={errors.userName}
                        />
                    </div>
                    <div className="input-grid-container">
                        <InputComponent layout="layout2"
                            placeholder="Password*"
                            value={userDetails.password}
                            onChange={e => onUserDetailsChange(e, "password")} type="password"
                            isError={errors.password !== ""}
                            errorMessage={errors.password} />
                        <InputComponent layout="layout2"
                            placeholder="Confirm Password*"
                            value={userDetails.confirmPassword}
                            onChange={e => onUserDetailsChange(e, "confirmPassword")} type="password"
                            isError={errors.confirmPassword !== ""}
                            errorMessage={errors.confirmPassword} />
                    </div>
                    <div className="seperator">Address Information</div>
                    <div className="input-grid-container">
                        <Dropdown options={options} selectedOption={contactDetails.addressType} setSelectedOption={setSelectedOption} />
                    </div>
                    <div className="input-container">
                        <InputComponent
                            layout="layout2"
                            placeholder="Address 1*"
                            value={contactDetails.address1}
                            onChange={e => onContactDetailsChange(e, "address1")}
                            isError={errors.address1 !== ""}
                            errorMessage={errors.address1} />
                    </div>
                    <div className="input-container">
                        <InputComponent
                            layout="layout2"
                            placeholder="Address 2"
                            value={contactDetails.address2}
                            onChange={e => onContactDetailsChange(e, "address2")} />
                    </div>
                    <div className="input-grid-container">
                        <InputComponent layout="layout2"
                            placeholder="City*"
                            value={contactDetails.city}
                            onChange={e => onContactDetailsChange(e, "city")}
                            isError={errors.city !== ""}
                            errorMessage={errors.city} />
                        <InputComponent layout="layout2"
                            placeholder="State*"
                            value={contactDetails.state}
                            onChange={e => onContactDetailsChange(e, "state")}
                            isError={errors.state !== ""}
                            errorMessage={errors.state} />
                    </div>
                    <div className="input-grid-container">
                        <InputComponent layout="layout2"
                            placeholder="Postal*"
                            value={contactDetails.postal}
                            onChange={e => onContactDetailsChange(e, "postal")}
                            isError={errors.postal !== ""}
                            errorMessage={errors.postal} />
                        <InputComponent layout="layout2"
                            placeholder="Country*"
                            value={contactDetails.country}
                            onChange={e => onContactDetailsChange(e, "country")}
                            isError={errors.country !== ""}
                            errorMessage={errors.country} />

                    </div>
                    <div className="input-grid-container">
                        <InputComponent layout="layout2"
                            placeholder="Phone Number*"
                            value={contactDetails.phoneNumber}
                            onChange={e => onContactDetailsChange(e, "phoneNumber")}
                            isError={errors.phoneNumber !== ""}
                            errorMessage={errors.phoneNumber} />
                        <InputComponent layout="layout2"
                            placeholder="Landline"
                            value={contactDetails.landline}
                            onChange={e => onContactDetailsChange(e, "landline")} /></div>
                    <div className="register-btn-container">
                        <Button text={"Continue"} onClick={() => {
                            if (validateForm() && !isLoading) {
                                handleRegister()
                            }
                        }} width={"inherit"} isLoading={isLoading} />
                    </div>

                </div>

            </div>
            {isError && <Modal header={"Sorry Something went wrong"} subText={"Account registration failed, Please try again"}
                isFailed
                onClick={() => {
                    setIsError(false)
                    history.push("account/login")
                }} />}
            {isError2 && <Modal header={"Please login to continue"} subText={"An account already exists. Please log in and continue shopping"}
                isFailed
                onClick={() => {
                    setIsError2(false)
                    history.push("account/login")
                }} />}
            {isSuccess && <Modal isSuccess header="Account registration success" subText="Click ok to continue to your account" onClick={() => {
                setIsSuccess(false)
                history.push("/pages/checkout")

            }} />}
        </div>
    )
}
export default EcommerceRegisterAccount