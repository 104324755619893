import React from "react";
import "./HomeComponentFour.scss"
import ItemComponent from "../ItemComponent/ItemCopmonent";
import { sortByOrderAscending } from "../../helper/helper";
import { useHistory } from "react-router";

const HomeComponentFour = ({ components }) => {
    const history = useHistory()
    const collection = [
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616161936148.jpeg",
            "title": "Mangalya Silk Collection",
            price: "100",
            id: 1
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616162869639.jpeg",
            "title": "Thirumana Silk Collection",
            price: "120",
            id: 2
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616166519893.jpeg",
            "title": "Thozhi Silk Collection",
            price: "90",
            id: 3

        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616166902711.jpeg",
            "title": "Sitha Silk Collection",
            price: "220",
            id: 4
        },
    ]
    const getComponents = (component) => {
        switch (component.ID) {
            case "featured-collection-header":
                return <div className="details-header">Featured Collections</div>

            case "featured-collection-list":
                return <div className="collection-row-one">
                    {collection.map((coll, key) => {
                        return (
                            <ItemComponent layout={component.Props.layout} item={coll} key={key} onClick={() => {
                                history.push(`/product/${coll.id}`)
                            }} />
                        )
                    })}

                </div>
            default:
                return <div />
        }

    }
    return (
        <div className="HomeComponentFour">
            {sortByOrderAscending(components).map((component) => {
                return getComponents(component)
            })}

        </div>
    )

}
export default HomeComponentFour