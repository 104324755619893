import React, { useState } from "react";
import "./EcommerceRegister.scss"
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";
import CodeInput from "../../components/CodeInput/CodeInput";
import globalValues from "../../global/globalValues";
import Modal from "../../components/Modal/Modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const EcommerceRegisterVerification = () => {
    const [code, setCode] = useState(Array(6).fill(""))
    const [isVerifiedOpen, setIsVerifiedOpen] = useState(false)
    const [isFailedOpen, setIsFailedOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()

    const companyDetails = useSelector((state) => state.companyData.company)

    const validateForm = () => {
        let valid = true;
        let codeValue = Number(code.join(''))
        if (codeValue === "") {
            valid = false;
        }
        return valid;
    }
    const decrypt = (data) => {
        var CryptoJS = require("crypto-js");
        var key = globalValues.keys.REACT_APP_encryptKey;
        var bytes = CryptoJS.AES.decrypt(data, key);
        var decText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decText;
    }
    const handleRegister = async () => {
        if (validateForm()) {
            setIsLoading(true)
            const initialValues = JSON.parse(decrypt(decodeURIComponent(sessionStorage.getItem('verificationtoken'))))
            if (Number(code.join('')) === initialValues) {
                sessionStorage.setItem('regeligible', true);
                setIsVerifiedOpen(true);
                setIsLoading(false)

            }
            else {
                setIsFailedOpen(true);
                setIsLoading(false)
            }
        }
    }
    return (
        <div className="Ecommerceregister">
            <div className="Ecommerceregister-container">
                <div className="logo-container">
                    <img alt="" className="logo"
                        onClick={() => {
                            history.push("/")
                        }}
                        src={companyDetails?.Logo} />
                </div>
                <div className="register-form">
                    <Header text={"Enter code"} fontSize={"26px"} fontWeight={"500"} />
                    <div className="register-sub-text">Verify your account</div>
                    <div className="input-container">
                        <CodeInput codes={code} setCodes={setCode} />

                    </div>
                    <div className="register-btn-container">
                        <Button text={"Verify Now"} onClick={handleRegister} width={"inherit"} isLoading={isLoading} />
                    </div>
                    <div className="sign-up-content">
                        We have sent an email with a verification to your email address. In order to complete the sign-up process, Please check your email and enter the verification code above. If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in our sign-up form. If you need assistance, please contact us.</div>

                </div>

            </div>
            {isVerifiedOpen && <Modal
                header={"Verified successfully"}
                subText={`One more step to complete your profile`}
                isSuccess
                onClick={() => {
                    history.push("/account/create")
                    setIsVerifiedOpen(false)
                }} />}
            {isFailedOpen && <Modal
                header={"Verification Failed"}
                subText={`Please check the OTP and try again`}
                isFailed
                onClick={() => {
                    setIsFailedOpen(false)
                }} />}
        </div>
    )
}
export default EcommerceRegisterVerification