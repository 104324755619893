import React from 'react';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import "bootstrap/dist/css/bootstrap.min.css";
import "../themes/default/css/default.css";
import "./home.css"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "../themes/default/header.component";
import Navbar from "../themes/default/navbar.component";
import Footer from "../themes/default/footer.component";
import Pages from "./pages";
import HomePage from "./home";
import HomeMenuPage from "./homeMenu.js";
import HomeVoucherPage from "./homeVouchers.js";


import Articles from './articles'


import ClothingHeader from "../themes/cloths/header.component";
import ClothingFooter from "../themes/cloths/footer.component";
import ClothingHomePage from "../themes/cloths/home";

import MartfuryHeader from "../themes/martfury/header.component";
import MartfuryFooter from "../themes/martfury/footer.component";
import MartfuryHomePage from "../themes/martfury/home";

import ApparelHeader from "../themes/apparel/header.component";
import ApparelFooter from "../themes/apparel/footer.component";
import ApparelHomePage from "../themes/apparel/home";

import ApparelcasiHeader from "../themes/apparel-casi/header.component";
import ApparelcasiFooter from "../themes/apparel-casi/footer.component";
import ApparelcasiHomePage from "../themes/apparel-casi/home";


import WitmegHeader from "../themes/witmeg/header.component";
import WitmegFooter from "../themes/witmeg/footer.component";

import SpicewayNavbar from "../themes/spiceway/navbar.component";
import SpicewayHeader from "../themes/spiceway/header.component";
import SpicewayFooter from "../themes/spiceway/footer.component";
import SpicewayHomePage from "../themes/spiceway/home";

import MeatShopHeader from "../themes/meatshop/header.component";
import MeatShopFooter from "../themes/meatshop/footer.component";
import MeatShopHomePage from "../themes/meatshop/home";

import StationaryHeader from "../themes/stationary/header.component";
import StationaryFooter from "../themes/stationary/footer.component";
import StationaryHomePage from "../themes/stationary/home";


import Products from "../shop/products";
import SearchItems from "../shop/products-search";
import ProductsByID from "../shop/productsbyid";
import ProductsByBrandID from "../shop/productsbybrandid";
import ProductsDetailsByID from "../shop/productdetails";
import Login from "../shop/login";
import MyCart from "../shop/cart.js";
import Checkout from "../shop/checkout";
import Payment from "../shop/payment";
import AccountLogin from "../shop/account-login";
import AccountForgot from "../shop/account-forgot";
import AccountMember from "../shop/account-member";
import AccountPayment from "../shop/account-payment";
import AccountRegister from "../shop/account-register";
import AccountVerify from "../shop/account-register-verify";
import AccountYourDetails from "../shop/account-register-yourdetails";
import OrderSuccess from "../shop/order-success";
import AccountMyInfo from "../shop/account-my-info";
import AccountAddresses from "../shop/account-addresses";
import AccountOrder from "../shop/account-order";
import AccountMyWishList from "../shop/account-my-wishlists";

import { Container } from 'react-bootstrap';
import config from 'react-global-configuration';
import "../config.js";
import axios from 'axios';
import { Spin } from 'witmeg-ui-system';
import PageUndefined from './default/404Page';
import OrderConfirmation from './default/WidgetCompoentens/common/OrderConfirmation';
import OrderSummaryTable from './default/WidgetCompoentens/ResturatnWidget/Common/OrderSummaryTable';
// import PaymentDetailsForm from './default/WidgetCompoentens/ResturatnWidget/Common/PaymentDetailsForm';
import PaymentDetails from './default/WidgetCompoentens/common/PaymentDetails';
import OrderSuccessForm from './default/WidgetCompoentens/ResturatnWidget/Common/OrderSuccessForm';
import { connect } from 'react-redux';
import { syncRestaurantMenuCartDetails } from '../redux/resturantWidgetSlice';
import { setCompany, setCompanyData, setPages, setSettingsData } from '../redux/companyDataSlice';
import SiteUnderMaintenance from '../components/SiteUnderMaintain';
import { getCompanyDetailsByDomain, getPageDetails, getSettingsByOrgId } from './default/utilities/apiRequests/witmegRetailPaxServerRequests';
import DefaultPageWrapper from './default/DefaultPageWrapper';
import ShankarPageWrapper from './sharkar/DefaultPageWrapper';
import AdeynPayment from '../shop/adeynPayment.js';
import SingleItemPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/SingleItemPage/SingleItemPage.js';
import OrderPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderPage.js';
import OrderSummaryPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderSummaryPage.js';
import OrderSuccessPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderSuccessPage.js';
import TimeoutPopup from './default/WidgetCompoentens/Shaakira/RestaurantWidget/components/popups/TimeoutPopup.js';
import PaymentSelectionPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/Payment/PaymentSelectionPage.js';
import OrderFailedPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderFailedPage.js';
import OrderTrackPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderTrackPage.js';
import OrderStatusPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderStatusPage.js';
import HospitalityPageWrapper from './default/WidgetCompoentens/Shaakira/HospitalityDefault/HospitalityPageWrapper.js';
import HospitalityDefaultSingleItemPage from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultSingleItemPage/HospitalityDefaultSingleItemPage.js';
import HospitalityDefaultOrderPage from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultOrderPage/HospitalityDefaultOrderPage.js';
import HospitalityDefaultOrderSummaryPage from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultOrderPage/HospitalityDefaultOrderSummaryPage.js';
import HospitalityDefaultOrderSuccessPage from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultOrderPage/HospitalityDefaultOrderSuccessPage.js';
import HospitalityDefaultOrderFailedPage from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultOrderPage/HospitalityDefaultOrderFailedPage.js';
import HospitalityDefaultOrderTrackPage from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultOrderPage/HospitalityDefaultOrderTrackPage.js';
import HospitalityDefaultOrderStatusPage from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultOrderPage/HospitalityDefaultOrderStatusPage.js';
import HospitalityDefaultOrderHistoryPage from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultOrderPage/HospitalityDefaultOrderHistoryPage.js';
import HospitalityLogin from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityAuth/HospitalityLogin.js';
import HospitalityRegister from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityAuth/HospitalityRegister.js';
import HospitalityAccountVerification from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityAuth/HospitalityAccountVerification.js';
import HospitalityCreateAccount from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityAuth/HospitalityCreateAccount.js';
import HospitalityDefaultSearchPage from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultSearchPage/HospitalityDefaultSearchPage.js';
import VoucherDefaultPageWrapper from './default/WidgetCompoentens/Shaakira/VoucherDefault/VoucherDefaultPageWrapper.js';
import VoucherDefaultSingleItemPage from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherDefaultSingleItemPage/VoucherDefaultSingleItemPage.js';
import VoucherDefaultSearchPage from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherDefaultSearchPage/VoucherDefaultSearchPage.js';
import VoucherDefaultOrderPage from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherDefaultOrderPage/VoucherDefaultOrderPage.js';
import VoucherDefaultOrderSummaryPage from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherDefaultOrderPage/VoucherDefaultOrderSummaryPage.js';
import VoucherDefaultOrderSuccessPage from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherDefaultOrderPage/VoucherDefaultOrderSuccessPage.js';
import VoucherDefaultOrderFailedPage from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherDefaultOrderPage/VoucherDefaultOrderFailedPage.js';
import VoucherDefaultOrderTrackPage from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherDefaultOrderPage/VoucherDefaultOrderTrackPage.js';
import VoucherDefaultOrderStatusPage from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherDefaultOrderPage/VoucherDefaultOrderStatusPage.js';
import VoucherDefaultOrderHistoryPage from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherDefaultOrderPage/VoucherDefaultOrderHistoryPage.js';
import VoucherLogin from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherAuth/VoucherLogin.js';
import VoucherRegister from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherAuth/VoucherRegister.js';
import VoucherAccountVerification from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherAuth/VoucherAccountVerification.js';
import VoucherCreateAccount from './default/WidgetCompoentens/Shaakira/VoucherDefault/pages/VoucherAuth/VoucherCreateAccount.js';
import VoucherMenuDefaultPageWrapper from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/VoucherMenuDefaultPageWrapper.js';
import VoucherMenuDefaultSearchPage from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefaultSearchPage/VoucherMenuDefaultSearchPage.js';
import VoucherMenuDefaultOrderPage from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefaultOrderPage/VoucherMenuDefaultOrderPage.js';
import VoucherMenuDefaultOrderSummaryPage from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefaultOrderPage/VoucherMenuDefaultOrderSummaryPage.js';
import VoucherMenuDefaultOrderSuccessPage from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefaultOrderPage/VoucherMenuDefaultOrderSuccessPage.js';
import VoucherMenuDefaultOrderFailedPage from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefaultOrderPage/VoucherMenuDefaultOrderFailedPage.js';
import VoucherMenuDefaultOrderTrackPage from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefaultOrderPage/VoucherMenuDefaultOrderTrackPage.js';
import VoucherMenuDefaultOrderStatusPage from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefaultOrderPage/VoucherMenuDefaultOrderStatusPage.js';
import VoucherMenuDefaultOrderHistoryPage from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefaultOrderPage/VoucherMenuDefaultOrderHistoryPage.js';
import VoucherMenuDefaultLogin from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefault/VoucherMenuDefaultLogin.js';
import VoucherMenuDefaultRegister from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefault/VoucherMenuDefaultRegister.js';
import VoucherMenuDefaultAccountVerification from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefault/VoucherMenuDefaultAccountVerification.js';
import VoucherMenuDefaultCreateAccount from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefault/VoucherMenuDefaultCreateAccount.js';
import HospitalityDefaultSingleItemPage2 from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultSingleItemPage/HospitalityDefaultSingleItemPage2.js';
import HospitalityDefaultSingleItemPage3 from './default/WidgetCompoentens/Shaakira/HospitalityDefault/pages/HospitalityDefaultSingleItemPage/HospitalityDefaultSingleItemPage3.js';
import { hexToCSSFilter } from 'hex-to-css-filter';
import VoucherMenuDefaultSingleItemPage3 from './default/WidgetCompoentens/Shaakira/VoucherMenuDefault/pages/VoucherMenuDefaultSingleItemPage/VoucherMenuDefaultSingleItemPage3.js';
import RestaurantThemeDefaultWrapper from './default/WidgetCompoentens/Shaakira/Hospitality/pages/RestaurantThemeDefaultWrapper.js';
import RestaurantHome from './default/WidgetCompoentens/Shaakira/Hospitality/pages/RestaurantHome/RestaurantHome.js';
import RestaurantContactus from './default/WidgetCompoentens/Shaakira/Hospitality/pages/RestaurantContactus/RestaurantContactus.js';
import RestaurantLookbook from './default/WidgetCompoentens/Shaakira/Hospitality/pages/RestaurantLookbook/RestaurantLookbook.js';
import RestaurantAboutus from './default/WidgetCompoentens/Shaakira/Hospitality/pages/RestaurantAboutus/RestaurantAboutus.js';
import RestaurantMenu from './default/WidgetCompoentens/Shaakira/Hospitality/pages/RestaurantMenu/RestaurantMenu.js';
import EcommerceDefaultWrapper from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceDefaultWrapper.js';
import EcommerceHome from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceHome/EcommerceHome.js';
import EcommerceContact from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceContact/EcommerceContact.js';
import EcommerceSinglePage from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceSinglePage/EcommerceSinglePage.js';
import EcommerceProduct from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceProduct/EcommerceProduct.js';
import EcommerceAboutus from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceAboutus/EcommerceAboutus.js';
import EcommerceWishlist from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceWishlist/EcommerceWishlist.js';
import EcommerceCheckout from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceCheckout/EcommerceCheckout.js';
import EcommerceLogin from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceLogin/EcommerceLogin.js';
import EcommerceRegister from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceRegister/EcommerceRegister.js';
import EcommerceRegisterVerification from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceRegister/EcommerceRegisterVerification.js';
import EcommerceRegisterAccount from './default/WidgetCompoentens/Shaakira/EcommerceDefault/pages/EcommerceRegister/EcommerceRegisterAccount.js';


class Application extends React.Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = { newCompanyResult: [], companyresult: [], loading: true, pageid: '', pageName: '', urlParams: '', localStorageValue: localStorage.getItem('date') || null, timeHasPassed: false, };
    }
    componentDidMount() {
        this.localStorageCheckInterval = setInterval(this.checkStoredTime, 1000);
    }
    fetchData = async () => {
        // const host = "" //window.location.host;
        // // const origin = "https://uat-retailpacxtest.rewardticks.com" // window.location.origin;
        // const origin = "https://www.throbox.com" // window.location.origin;

        const host = window.location.host;
        const origin = window.location.origin;
        const _response = await getCompanyDetailsByDomain({
            ID: '',
            OrganisationID: '',
            SubDomain: host,
            CustomerDomain: origin,
            Start: 0,
            Limit: 10,
            IsSearchApplicable: false,
            Search: {
                Key: '',
                Value: '',
            },
        });

        if (_response && _response.length > 0) {
            const _companyData = {
                organizationID: _response[0].OrganisationID,
                address: {
                    line1: _response[0].AddressLine1,
                    line2: _response[0].AddressLine2,
                    city: _response[0].City,
                    state: _response[0].StateProvinceRegion,
                    postcode: _response[0].PostZipCode,
                    country: _response[0].Country,
                    lat: _response[0].Latitude,
                    lng: _response[0].Longitude,
                },
                companyID: _response[0].ID,
                shopName: _response[0].Name,
                bigLogoURL: _response[0].Logo,
                smallLogoURL: _response[0].Logo,
                siteURL: _response[0].CustomerDomain,
                email: _response[0].Email,
                contactNumber: _response[0].Phone,
                websiteType: _response[0].WebSiteType,
                PaymentIntegration: _response[0].PaymentIntegration,
                settings: _response[0].Settings,
                openingHours: _response[0].OpeningHour,
            };
            this.props.initializeCompanyData(_companyData);



            const _settingsResponse = await (getSettingsByOrgId(_response[0].OrganisationID))
            if (_settingsResponse && _settingsResponse.length > 0) {
                this.props.initializeSettingsData(_settingsResponse[0])
            }

            console.log("_settingsResponse", _settingsResponse)
            console.log("_companyData", _companyData)
            this.props.initializeCompanyDetails(_response[0])
            let res = await getPageDetails(_response[0].ID)
            if (res) {
                this.props.initializePageData(res.Pages)
            }
            this.setState({ companyresult: _response[0], loading: false });
        }



    }

    componentDidMount() {
        this.props.syncRestaurantMenuCartStateData();
        this.fetchData()    // RMEOVE spiceway.throbox.com WHEN DONE TO WINDOW.LOCATION.hostname
        // axios.get(config.get('backendURL') + 'company/bydomain/' + window.location.hostname)
        //   .then(result => this.setState({ companyresult: result, loading: false }))
        //   .catch(function (error) {
        //     console.log(error);
        //   })
        // Listen for global click events on the entire document
        document.addEventListener('click', this.handleClick);

        // Check the stored time on initial mount
        this.checkStoredTime();
    }
    componentWillUnmount() {
        // Remove the click event listener when the component unmounts
        document.removeEventListener('click', this.handleClick);
    }

    handleClick = () => {
        this.checkStoredTime();
    };
    checkDate = () => {
        if (localStorage.getItem("date")) {
            let IsPassed = false
            let currentDate = new Date();
            let storedDate = new Date(localStorage.getItem("date"));
            if ((currentDate - storedDate) > 1800000) {
                IsPassed = true
            } else {
                IsPassed = false
            }
            return IsPassed
        } else {
            return false
        }
    }
    checkStoredTime = () => {
        const storedTime = localStorage.getItem('date');
        if (storedTime) {
            const timeHasPassed = this.checkDate()
            this.setState({ timeHasPassed });
        }
    };
    lightenColor = (hex, percent) => {
        hex = hex.replace(/^#/, "");
        if (hex.length === 3) {
            hex = hex.split("").map((char) => char + char).join("");
        }

        if (hex.length !== 6) return null;

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        const lighten = (value) => Math.min(255, Math.floor(value + (255 - value) * (percent / 100)));

        const newR = lighten(r);
        const newG = lighten(g);
        const newB = lighten(b);

        return `rgb(${newR}, ${newG}, ${newB})`;
    };
    darkenColor = (hex, percent) => {
        hex = hex.replace(/^#/, "");
        if (hex.length === 3) {
            hex = hex.split("").map((char) => char + char).join("");
        }

        if (hex.length !== 6) return null;

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        // Decrease brightness by the given percentage
        const darken = (value) => Math.max(0, Math.floor(value * (1 - percent / 100)));

        const newR = darken(r);
        const newG = darken(g);
        const newB = darken(b);

        return `rgb(${newR}, ${newG}, ${newB})`;
    };


    changeColor = (color) => {
        const rgb = this.lightenColor(color, 60);
        const op = this.lightenColor(color, 90);
        const darkrgb = this.darkenColor(color, 10);
        const cssFilterBlack = hexToCSSFilter('#000');

        const cssFilter = hexToCSSFilter(color);
        console.log(cssFilter.filter, "cssFilter", cssFilterBlack)
        if (!rgb) return;

        document.documentElement.style.setProperty("--primary-color", color);
        document.documentElement.style.setProperty("--light-primary-color", rgb);
        document.documentElement.style.setProperty("--dark-primary-color", darkrgb);
        document.documentElement.style.setProperty("--opacity-primary-color", op);
        document.documentElement.style.setProperty("--filter", cssFilter.filter);




    };

    //Navigation to Page Function
    handleNavbarPageClick = async (pageid) => {
        axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
            isLoginServrRequet: false,
            serviceName: "retailpacx",
        }).then(async response => {
            await axios.post(
                `${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/pagesinfo/id`,

                {
                    ID: pageid,
                    CompanyID: config.get('companyID')
                },
                {
                    headers: {
                        Authorization: `Bearer ${response.data.token}`,
                    },
                }).then(async result => {
                    this.setState({
                        pageid: result.data.Result[0].ID,
                        pageName: result.data.Result[0].Slug,
                        key: Math.random()
                    })

                    window.history.replaceState(null, null, `/pages/${result.data.Result[0].Slug}`)
                    // window.history.replaceState(null, null, '/pages/' + result.data.Result[0].Slug)
                    window.location.reload()
                }
                )
        })
    }

    setFavicon = (faviconUrl) => {
        let link = document.querySelector("link[rel~='icon']");

        if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.head.appendChild(link);
        }

        link.href = faviconUrl;
    };
    render() {
        if (this.state.companyresult.length !== 0) {
            this.setFavicon((this.state.companyresult)?.Favicon)
            if (this.state.companyresult.Style.ThemeColor !== "") {
                this.changeColor(this.state.companyresult.Style.ThemeColor)
            }
            config.set({
                companyID: (this.state.companyresult)?.ID,
                SenderEmail: (this.state.companyresult)?.Email,
                Theme: (this.state.companyresult)?.Theme,
                favicon: (this.state.companyresult)?.Favicon,
                OrganisationID: (this.state.companyresult)?.OrganisationID,
                companyLogo: (this.state.companyresult)?.Logo,
                companyLogoCDN: (this.state.companyresult)?.LogoCDN,
                companyPageTitle: (this.state.companyresult)?.Name,
                LocationID: (this.state.companyresult)?.LocationID,
                WorldPayServiceKey: (this.state.companyresult)?.WorldPayServiceKey,
                WorldPayClientKey: (this.state.companyresult)?.WorldPayClientKey,
                WishListEnabled: (this.state.companyresult)?.WishListEnabled,
                HelpPage: (this.state.companyresult)?.HelpPage,
                SocialMedia: (this.state.companyresult)?.SocialMedia,
                DeliveryMessage: (this.state.companyresult)?.DeliveryMessage,
                ShopNowItems: (this.state.companyresult)?.ShopNowItems,
                FBappid: (this.state.companyresult)?.FBappid,
                FBpageid: (this.state.companyresult)?.FBpageid,
                CDNPath: (this.state.companyresult)?.CDNPath,
                backendURL: config.get('backendURL'),
                API_URL_cust: config.get('API_URL_cust'),
                API_productregistry: config.get('API_productregistry'),
                rest_API_productregistry: config.get('rest_API_productregistry'),
                rest_API_productregistry_V2: config.get('rest_API_productregistry_V2'),
                rest_API: config.get('rest_API'),
                ProductjwtAud: config.get('ProductjwtAud'),
                ProductjwtIss: config.get('ProductjwtIss'),
                ProductjwtExp: config.get('ProductjwtExp'),
                ProductprivateKey: config.get('ProductprivateKey'),
                API_salesregistry: config.get('API_salesregistry'),
                SalesjwtAud: config.get('SalesjwtAud'),
                SalesjwtIss: config.get('SalesjwtIss'),
                SalesjwtExp: config.get('SalesjwtExp'),
                SalesprivateKey: config.get('SalesprivateKey'),
                LoginprivateKey: config.get('LoginprivateKey'),
                CustjwtAud: config.get('CustjwtAud'),
                CustjwtIss: config.get('CustjwtIss'),
                CustjwtExp: config.get('CustjwtExp'),

                LoginAuthorizationKey: config.get('LoginAuthorizationKey')
            }, { freeze: false });
            document.getElementById("root").setAttribute("class", config.get('Theme'))

        }

        const isHome = window.location.pathname === '/'
        console.log('isHome==>> ', isHome)
        console.log('pageId==>> ', this.state.pageid)
        return (

            // max-height: calc(100vh - 80px);
            // overflow-y: auto;

            <React.Fragment>
                {this.state.timeHasPassed ? <TimeoutPopup onOkConfirm={() => {
                    localStorage.clear()
                    window.location.href = '/';
                }} /> : ""}
                {this.state.loading
                    ?
                    <div className='Hospitality-loader-main'><div className='loader-spin ' /></div>

                    :

                    this.state.companyresult?.IsPublished
                        ?
                        (
                            <Router>
                                {/* <div style={{
                                    // maxHeight: "calc(100vh - 0px)",
                                    // overflowX: "hidden",
                                    // height: '100vh',
                                    // width: '100vw',
                                }}  > */}
                                {(() => {
                                    switch (config.get('Theme')) {
                                        case 'Cloths':
                                            return (
                                                <>
                                                    <ClothingHeader />
                                                    <Route exact path="/" component={ClothingHomePage} />
                                                </>
                                            )
                                        case 'Martfury':
                                            return (
                                                <>
                                                    <MartfuryHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={MartfuryHomePage} />
                                                </>
                                            )
                                        case 'Apparel':
                                            return (
                                                <>
                                                    <ApparelHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={ApparelHomePage} />
                                                </>
                                            )
                                        case 'Apparel-casi':
                                            return (
                                                <>
                                                    <ApparelcasiHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={ApparelcasiHomePage} />
                                                </>
                                            )
                                        case 'Spiceway':
                                            return (
                                                <>
                                                    <SpicewayNavbar parentPageCallback={this.handleNavbarPageClick} />
                                                    <SpicewayHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={SpicewayHomePage} />
                                                </>
                                            )
                                        case 'MeatShop':
                                            return (
                                                <>
                                                    <MeatShopHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={MeatShopHomePage} />
                                                </>
                                            )
                                        case 'Stationary':
                                            return (
                                                <>
                                                    <StationaryHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={StationaryHomePage} />
                                                </>
                                            )
                                        case 'Witmeg':
                                            return (
                                                <>
                                                    <WitmegHeader />
                                                    <Route exact path="/" component={ApparelHomePage} />
                                                </>
                                            )

                                        case 'Shankar':
                                            return (
                                                <>
                                                    <ShankarPageWrapper navbarPageClickCallback={this.handleNavbarPageClick}>
                                                        <Route exact path="/" render={(props) => <HomePage {...props} theme="Shankar" />} />
                                                        <Route path="/pages/:id" render={(props) => <HomePage {...props} theme="Shankar" />} />

                                                        <Route exact path="/checkout" component={OrderConfirmation} theme="Shankar" />
                                                        <Route exact path="/order-summary" component={OrderSummaryTable} theme="Shankar" />
                                                        <Route exact path="/payment-details" component={PaymentDetails} theme="Shankar" />
                                                        <Route exact path="/order-success/:id" component={OrderSuccessForm} theme="Shankar" />

                                                        {/* Restaurant Menu QR Code paths */}
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/table/:tableid" component={HomePage} />
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/delivery" component={HomePage} />
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/takeaway" component={HomePage} />

                                                        {/* E Commerce Widgets */}
                                                        <Route exact path="/products" render={(props) => <Products {...props} theme="Shankar" />} />
                                                        <Route exact path="/search" render={(props) => <SearchItems {...props} theme="Shankar" />} />
                                                        <Route exact path="/search/:name" render={(props) => <SearchItems {...props} theme="Shankar" />} />
                                                        <Route exact path="/search/:name/:categoryId" render={(props) => <SearchItems {...props} theme="Shankar" />} />
                                                        <Route exact path="/group/:id" render={(props) => <ProductsByID {...props} theme="Shankar" />} />
                                                        <Route exact path="/group/:id/:name" render={(props) => <ProductsByID {...props} theme="Shankar" />} />
                                                        <Route exact path="/product/:id" render={(props) => <ProductsDetailsByID {...props} theme="Shankar" />} />
                                                        {/* Product Landing Pagedgets */}

                                                        {/*User Profile Actions */}
                                                        <Route exact path="/cart" component={MyCart} />
                                                        <Route exact path="/login" component={Login} />
                                                        <Route exact path="/payment" component={Payment} />
                                                        <Route exact path="/account/login" component={AccountLogin} />
                                                        <Route exact path="/account/forgot" component={AccountForgot} />
                                                        <Route exact path="/account/" component={AccountMember} />
                                                        <Route exact path="/account/member" component={AccountMember} />
                                                        <Route exact path="/account/register" component={AccountRegister} />
                                                        <Route exact path="/account/register/verify/:id" component={AccountVerify} />
                                                        <Route exact path="/account/register/yourdetails" component={AccountYourDetails} />
                                                        <Route exact path="/account/payment" component={AccountPayment} />
                                                        <Route exact path="/account/my-info" component={AccountMyInfo} />
                                                        <Route exact path="/account/addresses" component={AccountAddresses} />
                                                        <Route exact path="/account/my-wishlists" component={AccountMyWishList} />
                                                        <Route exact path="/account/order-history" component={AccountOrder} />
                                                    </ShankarPageWrapper>

                                                </>
                                            )
                                        case 'Hospitality-Default':
                                            return (
                                                <>
                                                    <HospitalityPageWrapper >

                                                        {/* menu */}
                                                        <Route exact path="/" component={HomePage} />
                                                        <Route path="/pages/:id" component={HomePage} />
                                                        <Route path="/item/:id/:itemId" component={HospitalityDefaultSingleItemPage3} />
                                                        <Route path="/item/search" component={HospitalityDefaultSearchPage} />

                                                        {/* Restaurant Menu QR Code paths */}
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/:orderType/:applicationId" component={HomePage} />

                                                        {/* order */}
                                                        <Route exact path="/order/checkout" component={HospitalityDefaultOrderPage} />
                                                        <Route exact path="/order/summary" component={HospitalityDefaultOrderSummaryPage} />
                                                        <Route exact path="/order/success/:id" component={HospitalityDefaultOrderSuccessPage} />
                                                        <Route exact path="/order/failed" component={HospitalityDefaultOrderFailedPage} />
                                                        <Route exact path="/order/track" component={HospitalityDefaultOrderTrackPage} />
                                                        <Route exact path="/order/status" component={HospitalityDefaultOrderStatusPage} />
                                                        <Route exact path="/order/history" component={HospitalityDefaultOrderHistoryPage} />

                                                        {/* customer account */}
                                                        <Route exact path="/account/login" component={HospitalityLogin} />
                                                        <Route exact path="/account/register" component={HospitalityRegister} />
                                                        <Route exact path="/account/verify" component={HospitalityAccountVerification} />
                                                        <Route exact path="/account/create" component={HospitalityCreateAccount} />

                                                    </HospitalityPageWrapper>
                                                </>
                                            )
                                        case 'Voucher-Default':
                                            return (
                                                <>
                                                    <VoucherDefaultPageWrapper >

                                                        {/* menu */}
                                                        <Route exact path="/" component={HomePage} />
                                                        <Route path="/pages/:id" component={HomePage} />
                                                        <Route path="/item/:id/:itemId" component={VoucherDefaultSingleItemPage} />
                                                        <Route path="/item/search" component={VoucherDefaultSearchPage} />

                                                        {/* Restaurant Menu QR Code paths */}
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/:orderType/:applicationId" component={HomePage} />

                                                        {/* order */}
                                                        <Route exact path="/order/checkout" component={VoucherDefaultOrderPage} />
                                                        <Route exact path="/order/summary" component={VoucherDefaultOrderSummaryPage} />
                                                        <Route exact path="/order/success/:id" component={VoucherDefaultOrderSuccessPage} />
                                                        <Route exact path="/order/failed" component={VoucherDefaultOrderFailedPage} />
                                                        <Route exact path="/order/track" component={VoucherDefaultOrderTrackPage} />
                                                        <Route exact path="/order/status" component={VoucherDefaultOrderStatusPage} />
                                                        <Route exact path="/order/history" component={VoucherDefaultOrderHistoryPage} />

                                                        {/* customer account */}
                                                        <Route exact path="/account/login" component={VoucherLogin} />
                                                        <Route exact path="/account/register" component={VoucherRegister} />
                                                        <Route exact path="/account/verify" component={VoucherAccountVerification} />
                                                        <Route exact path="/account/create" component={VoucherCreateAccount} />

                                                    </VoucherDefaultPageWrapper>
                                                </>
                                            )
                                        case 'MenuPerks':
                                            return (
                                                <>
                                                    <VoucherMenuDefaultPageWrapper >

                                                        {/* menu */}
                                                        <Route exact path="/" component={HomeMenuPage} />
                                                        <Route path="/vouchers" component={HomeVoucherPage} />
                                                        <Route path="/item/:id/:itemId" component={VoucherMenuDefaultSingleItemPage3} />
                                                        <Route path="/item/search" component={VoucherMenuDefaultSearchPage} />

                                                        {/* Restaurant Menu QR Code paths */}
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/:orderType/:applicationId" component={HomePage} />

                                                        {/* order */}
                                                        <Route exact path="/order/checkout" component={VoucherMenuDefaultOrderPage} />
                                                        <Route exact path="/order/summary" component={VoucherMenuDefaultOrderSummaryPage} />
                                                        <Route exact path="/order/success/:id" component={VoucherMenuDefaultOrderSuccessPage} />
                                                        <Route exact path="/order/failed" component={VoucherMenuDefaultOrderFailedPage} />
                                                        <Route exact path="/order/track" component={VoucherMenuDefaultOrderTrackPage} />
                                                        <Route exact path="/order/status" component={VoucherMenuDefaultOrderStatusPage} />
                                                        <Route exact path="/order/history" component={VoucherMenuDefaultOrderHistoryPage} />

                                                        {/* customer account */}
                                                        <Route exact path="/account/login" component={VoucherMenuDefaultLogin} />
                                                        <Route exact path="/account/register" component={VoucherMenuDefaultRegister} />
                                                        <Route exact path="/account/verify" component={VoucherMenuDefaultAccountVerification} />
                                                        <Route exact path="/account/create" component={VoucherMenuDefaultCreateAccount} />

                                                    </VoucherMenuDefaultPageWrapper>
                                                </>
                                            )

                                        case 'JackAndJill':
                                            return (
                                                <RestaurantThemeDefaultWrapper>
                                                    <Route exact path="/" component={RestaurantHome} />
                                                    <Route exact path="/pages/contact" component={RestaurantContactus} />
                                                    <Route exact path="/pages/gallery" component={RestaurantLookbook} />
                                                    <Route exact path="/pages/about" component={RestaurantAboutus} />
                                                    <Route exact path="/pages/menu" component={RestaurantMenu} />

                                                </RestaurantThemeDefaultWrapper>
                                            )
                                        case 'Casipillai-Ecom':
                                            return (
                                                <>

                                                    <Route exact path="/" render={() => <EcommerceDefaultWrapper><EcommerceHome /></EcommerceDefaultWrapper>} />
                                                    <Route exact path="/pages/contact" render={() => <EcommerceDefaultWrapper><EcommerceContact /></EcommerceDefaultWrapper>} />
                                                    <Route exact path="/product/:id" render={() => <EcommerceDefaultWrapper><EcommerceSinglePage /></EcommerceDefaultWrapper>} />
                                                    <Route exact path="/products/:category" render={() => <EcommerceDefaultWrapper><EcommerceProduct /></EcommerceDefaultWrapper>} />
                                                    <Route exact path="/pages/about" render={() => <EcommerceDefaultWrapper><EcommerceAboutus /></EcommerceDefaultWrapper>} />
                                                    <Route exact path="/pages/wishlist" render={() => <EcommerceDefaultWrapper><EcommerceWishlist /></EcommerceDefaultWrapper>} />

                                                    <Route exact path="/pages/checkout" component={EcommerceCheckout} />
                                                    <Route exact path="/account/login" component={EcommerceLogin} />
                                                    <Route exact path="/account/register" component={EcommerceRegister} />
                                                    <Route exact path="/account/verification" component={EcommerceRegisterVerification} />
                                                    <Route exact path="/account/create" component={EcommerceRegisterAccount} />
                                                </>

                                            )
                                        default:
                                            return (
                                                <>
                                                    <DefaultPageWrapper navbarPageClickCallback={this.handleNavbarPageClick}>
                                                        <Route exact path="/adeynPayment" component={AdeynPayment} />
                                                        <Route exact path="/" component={HomePage} />
                                                        <Route path="/pages/:id" component={HomePage} />

                                                        {/* shaakira */}
                                                        <Route path="/item/:id/:itemId" component={SingleItemPage} />
                                                        <Route exact path="/order/checkout" component={OrderPage} />
                                                        <Route exact path="/order/summary" component={OrderSummaryPage} />
                                                        <Route exact path="/order/success/:id" component={OrderSuccessPage} />
                                                        <Route exact path="/order/payment/selection" component={PaymentSelectionPage} />
                                                        <Route exact path="/order/failed" component={OrderFailedPage} />
                                                        <Route exact path="/order/track" component={OrderTrackPage} />
                                                        <Route exact path="/order/status" component={OrderStatusPage} />




                                                        <Route exact path="/checkout" component={OrderConfirmation} />
                                                        <Route exact path="/order-summary" component={OrderSummaryTable} />
                                                        <Route exact path="/payment-details" component={PaymentDetails} />
                                                        <Route exact path="/order-success/:id" component={OrderSuccessForm} />


                                                        {/* Restaurant Menu QR Code paths */}
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/:orderType/:applicationId" component={HomePage} />
                                                        {/* <Route exact path="/:code/:organizationID/:cloudLocationID/delivery" component={HomePage} />
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/takeaway" component={HomePage} /> */}

                                                        {/* E Commerce Widgets */}
                                                        <Route exact path="/products" component={Products} />
                                                        <Route exact path="/search" component={SearchItems} />
                                                        <Route exact path="/search/:name" component={SearchItems} />
                                                        <Route exact path="/search/:name/:categoryId" component={SearchItems} />
                                                        <Route exact path="/group/:id" component={ProductsByID} />
                                                        <Route exact path="/group/:id/:name" component={ProductsByID} />
                                                        <Route exact path="/product/:id" component={ProductsDetailsByID} />
                                                        {/* Product Landing Pagedgets */}

                                                        {/*User Profile Actions */}
                                                        <Route exact path="/cart" component={MyCart} />
                                                        <Route exact path="/login" component={Login} />
                                                        <Route exact path="/payment" component={Payment} />
                                                        <Route exact path="/account/login" component={AccountLogin} />
                                                        <Route exact path="/account/forgot" component={AccountForgot} />
                                                        <Route exact path="/account/" component={AccountMember} />
                                                        <Route exact path="/account/member" component={AccountMember} />
                                                        <Route exact path="/account/register" component={AccountRegister} />
                                                        <Route exact path="/account/register/verify/:id" component={AccountVerify} />
                                                        <Route exact path="/account/register/yourdetails" component={AccountYourDetails} />
                                                        <Route exact path="/account/payment" component={AccountPayment} />
                                                        <Route exact path="/account/my-info" component={AccountMyInfo} />
                                                        <Route exact path="/account/addresses" component={AccountAddresses} />
                                                        <Route exact path="/account/my-wishlists" component={AccountMyWishList} />
                                                        <Route exact path="/account/order-history" component={AccountOrder} />
                                                    </DefaultPageWrapper>
                                                </>
                                            )
                                    }

                                })()}
                                <div className="ps-container">

                                    {/* {this.state.pageid === '' ? ( */}
                                    {/* // ) : ( */}
                                    {/* //    // <><Pages params={{ pageid: this.state.pageid }} key={this.state.key} /></> */}
                                    {/* // ) */}
                                    {/* } */}
                                    <Route exact path="/articles/:id" component={Articles} />
                                    {/* <Route exact path="/group/:id/:name" component={ProductsByID} /> */}
                                    {/* <Route exact path="/search/:id" component={searchItems} /> */}
                                    <Route exact path="/brand/:id/:name" component={ProductsByBrandID} />
                                    {/* <Route exact path="/product/:id/:id" component={ProductsDetailsByID} /> */}
                                    {/* <Route exact path="/cart" component={MyCart} />
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/payment" component={Payment} />
                                        <Route exact path="/account/login" component={AccountLogin} />
                                        <Route exact path="/account/forgot" component={AccountForgot} />
                                        <Route exact path="/account/" component={AccountMember} />
                                        <Route exact path="/account/member" component={AccountMember} />
                                        <Route exact path="/account/register" component={AccountRegister} />
                                        <Route exact path="/account/register/verify/:id" component={AccountVerify} />
                                        <Route exact path="/account/register/yourdetails" component={AccountYourDetails} />
                                        <Route exact path="/account/payment" component={AccountPayment} />
                                        <Route exact path="/account/my-info" component={AccountMyInfo} />
                                        <Route exact path="/account/addresses" component={AccountAddresses} />
                                        <Route exact path="/account/my-wishlists" component={AccountMyWishList} />
                                        <Route exact path="/account/order-history" component={AccountOrder} /> */}
                                </div>
                                {(() => {

                                    switch (config.get('Theme')) {
                                        case 'Cloths':
                                            return (
                                                <>
                                                    <ClothingFooter />
                                                </>
                                            )
                                        case 'Martfury':
                                            return (
                                                <>
                                                    <MartfuryFooter />
                                                </>
                                            )
                                        case 'Apparel':
                                            return (
                                                <>
                                                    <ApparelFooter />
                                                </>
                                            )
                                        case 'Apparel-casi':
                                            return (
                                                <>
                                                    <ApparelcasiFooter />
                                                </>
                                            )
                                        case 'ComingSoon':
                                            return (
                                                <>

                                                </>
                                            )
                                        case 'Witmeg':
                                            return (
                                                <>
                                                    <WitmegFooter />
                                                </>
                                            )
                                        case 'Spiceway':
                                            return (
                                                <>
                                                    <SpicewayFooter />
                                                </>
                                            )
                                        case 'Stationary':
                                            return (
                                                <>
                                                    <StationaryFooter />
                                                </>
                                            )
                                        case 'MeatShop':
                                            return (
                                                <>
                                                    <MeatShopFooter />
                                                </>
                                            )
                                        default:
                                            return (<>
                                                <Footer />
                                            </>
                                            )
                                    }

                                })()}
                                <div>
                                    {/* <MessengerCustomerChat
                                            pageId={config.get('FBpageid')}
                                            appId={config.get('FBappid')}
                                        /> */}
                                </div>
                                {/* </div> */}



                            </Router>
                        )
                        : <SiteUnderMaintenance />
                }
            </React.Fragment>

        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    syncRestaurantMenuCartStateData: () => dispatch(syncRestaurantMenuCartDetails()),
    initializeCompanyData: (data) => dispatch(setCompanyData(data)),
    initializeSettingsData: (data) => dispatch(setSettingsData(data)),
    initializePageData: (data) => dispatch(setPages(data)),
    initializeCompanyDetails: (data) => dispatch(setCompany(data)),



});

export default connect(null, mapDispatchToProps)(Application);