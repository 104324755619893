import React, { useState } from "react";
import "./VoucherMenuDefaultAuth.scss"
import { InputBox, Label } from 'witmeg-ui-system'
import tickIc from "../../assets/tick.svg"
import { useHistory } from 'react-router'
import config from 'react-global-configuration';
import jwt from 'jsonwebtoken';
import Modal from "../../components/Modal/Modal";

const VoucherMenuDefaultLogin = () => {
    let history = useHistory();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isChecked, setIsChecked] = useState(true)
    const [errors, setErrors] = useState({
        password: "",
        email: "",
    });
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)


    const validateEmail = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const validateForm = () => {
        let valid = true;
        const newErrors = {
            password: "",
            email: "",
        };
        if (password.trim() === "") {
            newErrors.password = "*required";
            valid = false;
        }
        if (email.trim() === "") {
            newErrors.email = "*required";
            valid = false;
        } else if (!validateEmail(email)) {
            newErrors.email = "*Invalid email format";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };
    const handleLogin = () => {

        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + config.get('LoginAuthorizationKey'),
            },
            body: JSON.stringify({ UserName: email, Password: password, OrganizationID: config.get('OrganisationID') })
        };
        fetch(config.get('API_URL_cust') + 'login', requestOptions)
            .then(response => response.json())
            .then(function (data) {
                if (data.Status === false) {
                    setIsLoading(false)
                    setIsError(true)
                }
                else {

                    localStorage.setItem("isLogged", "true")
                    const expireTime = new Date().getTime() + (config.get('CustjwtExp') * 1000)

                    var payload = {
                        "aud": config.get('CustjwtAud'),
                        "exp": expireTime,
                        "iss": config.get('CustjwtIss'),
                        "usertoken": data.Result.AccessToken
                    };

                    var signOptions = {
                        "algorithm": "RS256"
                    };

                    var privateKey = config.get('LoginprivateKey');
                    var token = jwt.sign(payload, privateKey, signOptions);

                    const requestUserDtlsOptions = {
                        method: 'POST',
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ` + token,
                        },
                        body: JSON.stringify({ "Email": email.toLowerCase(), "OrganizationID": config.get('OrganisationID') })
                    };

                    fetch(config.get('API_URL_cust') + 'fetch', requestUserDtlsOptions)
                        .then(response => response.json())
                        .then(function (data) {
                            setIsLoading(false)
                            localStorage.setItem('fullUserDetails', JSON.stringify(data.Result));
                            window.location.href = "/"
                        });


                }
            })

    };
    const handleChange = (name) => {
        setErrors({
            ...errors,
            [name]: "",
        });
    };
    const handleClear = () => {
        setEmail("")
        setPassword("")
        setErrors({
            password: "",
            email: "",
        })
        setIsError(false)
    }
    return (
        <div className="VoucherMenuDefaultAuth">
            <div className="VoucherMenuDefaultAuth-inner">
                <div className="header-text-cont">Account login</div>
                <div className="auth-container-wrapper">
                    <div className="auth-container-wrapper-left">
                        <div className="auth-container-left-inner">
                            <div className="input-containers ">
                                <div className="form-label-wrapper"><span>*</span><Label className="label-text">Email Address</Label></div>
                                <InputBox onChange={(e) => {
                                    setEmail(e.target.value);
                                    handleChange("email")
                                }} type="text" value={email} error={errors.email} />
                            </div>

                            <div className="input-containers ">
                                <div className="form-label-wrapper"><span>*</span><Label className="label-text">Password</Label></div>
                                <InputBox onChange={(e) => {
                                    setPassword(e.target.value);
                                    handleChange("password")
                                }} type="password" value={password} error={errors.password} />
                            </div>
                            <div className="remember-me-cont">
                                <div className="remember-me">
                                    {isChecked ? <div className="check-box" onClick={() => setIsChecked(false)}><img alt="" src={tickIc} /></div> :
                                        <div className="empty-check" />}
                                    Remember me</div>
                                <div className="forget-password">Forget Password</div>
                            </div>
                            <div className="button-conatiner" onClick={() => {
                                if (validateForm() && !isLoading) {
                                    setIsLoading(true)
                                    handleLogin()
                                }
                            }}>{isLoading && <div className="loader-btn" />}Login</div>
                            <div className="reg-link">
                                <div className="reg-text">Don’t have an account?</div>
                                <div className="reg-link-text" onClick={() => {
                                    history.push("/account/register")
                                }}>Create one new</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal visibility={isError} type="failed" headerText="Invalid Login" subText="Invalid username or password"
                onPopupClose={() => { handleClear() }}
            />
        </div>
    )
}
export default VoucherMenuDefaultLogin