import React from "react";
import "./Footer.scss"
import { useSelector } from "react-redux"
import x from "../../assets/x.svg"
import fb from "../../assets/facebook.svg"
import insta from "../../assets/instagram.svg"
import tt from "../../assets/tiktok.svg"
const Footer = () => {
    const companyDetails = useSelector((state) => state.companyData.company)


    return (
        <div className="Hospitality-Footer">
            <div className="Hospitality-Footer-Container">
                <div className="Hospitality-Footer-content">
                    <img alt="" className="logo-container"
                        onClick={() => {
                        }}
                        src={companyDetails?.Logo} />
                    <div className="footer-text">
                        Dear guests, you are welcomed to dine with us at Jack & Jill. Have a pleasant dining experience.
                    </div>
                </div>
                <div className="Hospitality-Footer-content">
                    <div className="footer-text-header">
                        Address
                    </div>
                    <div className="footer-text">
                        {companyDetails?.Phone}
                    </div>
                    <div className="footer-text-2">
                        {companyDetails?.Email}
                    </div>
                    <div className="footer-text">
                        {companyDetails?.AddressLine1},
                        {companyDetails?.AddressLine2 !== "" && <>{companyDetails?.AddressLine2},</>}
                        {companyDetails?.City},
                        {companyDetails?.PostZipCode},
                        {companyDetails?.Country}.
                    </div>
                </div>
                <div className="Hospitality-Footer-content">
                    <div className="footer-text-header">
                        Hours of opening
                    </div>
                    <>
                        {companyDetails?.OpeningHour?.map((open, key) => {
                            return (<div key={key} className="opening-hours-text">
                                <div>{open.Day}</div><div className="dotted-line"></div><div>{open?.Closed ? "Closed" : open.OpenHours?.map((date) => {
                                    return (
                                        <div>{date.StartTime + " - " + date.EndTime}</div>
                                    )
                                })}</div>
                            </div>)
                        })}
                    </>
                </div>
                {companyDetails?.SocialMedias &&
                    <div className="Hospitality-Footer-content">
                        <div className="footer-text-header">
                            Social Media
                        </div>

                        {companyDetails?.SocialMedias.instagram && companyDetails?.SocialMedias.instagram !== "" && <div className="social">
                            <img alt="" src={insta} className="social-icon" />
                            <a href={companyDetails?.SocialMedias.instagram} target="_blank">Instagram</a>
                        </div>}
                        {companyDetails?.SocialMedias?.facebook && companyDetails?.SocialMedias.facebook !== "" && <div className="social">
                            <img alt="" src={fb} className="social-icon" />
                            <a target="_blank" href={companyDetails?.SocialMedias?.facebook}>Facebook</a>
                        </div>}
                        {companyDetails?.SocialMedias?.x && companyDetails?.SocialMedias.x !== "" && <div className="social">
                            <img alt="" src={x} className="social-icon" />
                            <a target="_blank" href={companyDetails?.SocialMedias?.x}>X</a>
                        </div>}   {companyDetails?.SocialMedias?.tiktok && companyDetails?.SocialMedias.tiktok !== "" && <div className="social">
                            <img alt="" src={tt} className="social-icon" />
                            <a target="_blank" href={companyDetails?.SocialMedias?.tiktok}>TikTok</a>
                        </div>}
                    </div>}

            </div>
            <div className="Hospitality-Footer-Container">
                <div className="copy-text">
                    © 2019 Witmeg. All rights reserved.
                </div>
            </div>
        </div>
    )
}
export default Footer