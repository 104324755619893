import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import "./VoucherMenuDefaultOrderPage.scss"

import "@fontsource/inter";
import OrderConfirmationPage from './VoucherMenuDefaultOrderConfirmationPage';
import { LOCAL_STORAGE_ITEMS_NAMES } from '../../../RestaurantWidget/utilities/helper/helper';

const VoucherMenuDefaultOrderPage = () => {

    let history = useHistory();
    const orderID = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) ? localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) : ""


    useEffect(() => {
        if (orderID === "") {
            history.push("/")
        }
    }, [])


    return (
        <div className='VoucherMenuDefaultOrderPage'>  <OrderConfirmationPage />
        </div>
    )
}

export default VoucherMenuDefaultOrderPage