import React from "react";
import "./RestaurantLookbook.scss";
import { sortByOrderAscending } from "../../helper/helper";
import { useSelector } from "react-redux";

const RestaurantLookbook = () => {

    const pages = useSelector((state) => state.companyData.pages)
    const page = pages.find((page) => page.PageId === "lookbook")
    const Components = page?.Components || []
    const getImageContainer = (items) => {
        return (<>
            {items.map((img, index) => (
                <img src={img.Props.src} key={index} className="galler-image" alt="" />
            ))}
        </>)
    }
    const getComponents = (component) => {
        switch (component.ID) {

            case "ImageRow":
                return getImageContainer(component.Components)

            default:
                return <div />
        }

    }
    return (
        <div className="RestaurantLookbook">

            {sortByOrderAscending(Components).map((component, key) => {
                return (<div key={key}>
                    {getComponents(component)}
                </div>)
            })}
        </div>
    );
};

export default RestaurantLookbook;
