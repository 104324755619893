import React, { useState, useEffect } from "react";

import "./EcommerceCheckout.scss"
import CheckoutItem from "./CheckoutItem/CheckoutItem";
import Header from "../../components/Header/Header";
import InputComponent from "../../components/InputComponent/InputComponent";
import Checkbox from "../../components/Checkbox/Checkbox";
import PaymentComponent from "../../components/PaymentComponent/PaymentComponent";
import RadioButton from "../../components/RadioButton/RadioButton";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
const EcommerceCheckout = () => {
    const history = useHistory()
    const companyDetails = useSelector((state) => state.companyData.company)

    let parsedUserDetails = localStorage.getItem('fullUserDetails') && JSON.parse(localStorage.getItem('fullUserDetails'))
    const address = parsedUserDetails ? parsedUserDetails.Addresses.find((address) => address.AddressType === "Billing") : null
    const addressDelivery = parsedUserDetails ? parsedUserDetails.Addresses.find((address) => address.AddressType === "Delivery") : null

    const [isSame, setIsSame] = useState(false);
    const [contactDetails, setContactDetails] = useState({
        firstName: parsedUserDetails ? parsedUserDetails.FirstName : "",
        lastName: parsedUserDetails ? parsedUserDetails.LastName : "",
        email: parsedUserDetails ? parsedUserDetails.Email : "",
        phoneNumber: address ? address.Phone : ""
    })
    const [deliveryDetails, setDeliveryDetails] = useState({
        address1: addressDelivery?.AddressLine1 || "",
        address2: addressDelivery?.AddressLine2 || "",
        city: addressDelivery?.City || "",
        state: addressDelivery?.StateProvinceRegion || "",
        country: addressDelivery?.Country || 'United Kingdom',
        postal: addressDelivery?.PostZipCode || ""
    })
    const [billingDetails, setBillingDetails] = useState({
        address1: address ? address?.AddressLine1 : "",
        address2: address ? address?.AddressLine2 : "",
        city: address ? address?.City : "",
        state: address ? address?.StateProvinceRegion : "",
        country: address ? address?.Country : 'United Kingdom',
        postal: address ? address?.PostZipCode : ""
    })
    const [selectedValue, setSelectedValue] = useState("CARD");

    const handleChange = (e) => {
        setSelectedValue(e.target.value);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const products = [
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616161936148.jpeg",
            "title": "Mangalya Silk ",
            "sub": "Mangalya Silk collection ",
            qty: 1,

            price: "100.00",
            id: 1,
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616162869639.jpeg",
            "title": "Thirumana Silk ",
            "sub": "Thirumana Silk collection ",
            price: "120.00",
            id: 2,
            qty: 2
        },
    ]
    const onContactDetailsChange = (e, field) => {
        setContactDetails({ ...contactDetails, [field]: e.target.value })
    }
    const onDeliveryDetailsChange = (e, field) => {
        setDeliveryDetails({ ...deliveryDetails, [field]: e.target.value })
    }
    const onBillingDetailsChange = (e, field) => {
        setBillingDetails({ ...billingDetails, [field]: e.target.value })
    }
    const handleCheckboxChange = () => {
        setIsSame(!isSame);
        if (!isSame) {
            setBillingDetails(deliveryDetails)
        } else {
            setBillingDetails({
                address1: address ? address?.AddressLine1 : "",
                address2: address ? address?.AddressLine2 : "",
                city: address ? address?.City : "",
                state: address ? address?.StateProvinceRegion : "",
                country: 'United Kingdom',
                postal: address ? address?.PostZipCode : ""
            })
        }
    };
    const handleLogout = () => {
        sessionStorage.clear()
        localStorage.clear()
        history.push("/")
    };
    return (
        <div className="EcommerceCheckout">
            <div className="EcommerceCheckout-Navbar" >
                <img alt="" className="logo-container" src={companyDetails?.Logo}
                    onClick={() => {
                        history.push("/")
                    }}
                />

            </div>
            <div className="EcommerceCheckout-Container">
                <div className="EcommerceCheckout-Container-left">
                    <div className="EcommerceCheckout-container-content">
                        <div className="Eccomerce-form-container">
                            <div className="header-container">
                                <Header text={"Contact"} fontSize={"24px"} />
                                {localStorage.getItem('isLogged') === 'true' ?
                                    <div className="login-text" onClick={() => {
                                        handleLogout()
                                    }}>Logout</div> :
                                    <div className="login-text" onClick={() => {
                                        history.push("/account/login")
                                    }}>Login</div>}
                            </div>
                            <div className="input-grid-container">
                                <InputComponent layout="layout2"
                                    placeholder="First Name"
                                    value={contactDetails.firstName}
                                    onChange={e => onContactDetailsChange(e, "firstName")} />
                                <InputComponent layout="layout2"
                                    placeholder="Last Name"
                                    value={contactDetails.lastName}
                                    onChange={e => onContactDetailsChange(e, "lastName")} />
                            </div>
                            <div className="input-container">
                                <InputComponent layout="layout2" placeholder="Email" value={contactDetails.email} onChange={e => onContactDetailsChange(e, "email")} />
                            </div>
                            <div className="input-container">
                                <InputComponent layout="layout2" placeholder="Phone Number" value={contactDetails.phoneNumber} onChange={e => onContactDetailsChange(e, "phoneNumber")} type="number" />
                            </div>


                        </div>
                        <div className="Eccomerce-form-container">
                            <div className="header-container">
                                <Header text={"Delivery"} fontSize={"24px"} />
                            </div>
                            <div className="input-container">
                                <InputComponent
                                    layout="layout2"
                                    placeholder="Address 1"
                                    value={deliveryDetails.address1}
                                    onChange={e => onDeliveryDetailsChange(e, "address1")} />
                            </div>
                            <div className="input-container">
                                <InputComponent
                                    layout="layout2"
                                    placeholder="Address 2"
                                    value={deliveryDetails.address2}
                                    onChange={e => onDeliveryDetailsChange(e, "address2")} />
                            </div>
                            <div className="input-grid-container">
                                <InputComponent layout="layout2"
                                    placeholder="City"
                                    value={deliveryDetails.city}
                                    onChange={e => onDeliveryDetailsChange(e, "city")} />
                                <InputComponent layout="layout2"
                                    placeholder="State"
                                    value={deliveryDetails.state}
                                    onChange={e => onDeliveryDetailsChange(e, "state")} />
                            </div>
                            <div className="input-grid-container">
                                <InputComponent layout="layout2"
                                    placeholder="Postal"
                                    value={deliveryDetails.postal}
                                    onChange={e => onDeliveryDetailsChange(e, "postal")} />
                                <InputComponent layout="layout2"
                                    placeholder="Country"
                                    value={deliveryDetails.country}
                                    onChange={e => onDeliveryDetailsChange(e, "country")} />
                            </div>
                            <div className="input-container">
                                <Checkbox
                                    label="Same as shipping address"
                                    checked={isSame}
                                    onChange={handleCheckboxChange}
                                />
                            </div>

                        </div>
                        {!isSame && <div className="Eccomerce-form-container">
                            <div className="header-container">
                                <Header text={"Billing Address"} fontSize={"24px"} />
                            </div>
                            <div className="input-container">
                                <InputComponent
                                    layout="layout2"
                                    placeholder="Address 1"
                                    value={billingDetails.address1}
                                    onChange={e => onBillingDetailsChange(e, "address1")} />
                            </div>
                            <div className="input-container">
                                <InputComponent
                                    layout="layout2"
                                    placeholder="Address 2"
                                    value={billingDetails.address2}
                                    onChange={e => onBillingDetailsChange(e, "address2")} />
                            </div>
                            <div className="input-grid-container">
                                <InputComponent layout="layout2"
                                    placeholder="City"
                                    value={billingDetails.city}
                                    onChange={e => onBillingDetailsChange(e, "city")} />
                                <InputComponent layout="layout2"
                                    placeholder="State"
                                    value={billingDetails.state}
                                    onChange={e => onBillingDetailsChange(e, "state")} />
                            </div>
                            <div className="input-grid-container">
                                <InputComponent layout="layout2"
                                    placeholder="Postal"
                                    value={billingDetails.postal}
                                    onChange={e => onBillingDetailsChange(e, "postal")} />
                                <InputComponent layout="layout2"
                                    placeholder="Country"
                                    value={billingDetails.country}
                                    onChange={e => onBillingDetailsChange(e, "country")} />
                            </div>

                        </div>}
                        <div className="Eccomerce-form-container">
                            <div className="header-container">
                                <Header text={"Payment"} fontSize={"24px"} />
                            </div>
                            <div className="input-container-accrodion">
                                <div className="accrodion-top">
                                    <RadioButton
                                        name="options"
                                        value="CARD"
                                        checked={selectedValue === "CARD"}
                                        onChange={handleChange}
                                        label="Card"
                                    />
                                    {selectedValue === "CARD" && <PaymentComponent />}
                                </div>
                                <div className="accrodion-bottom ">
                                    <RadioButton
                                        name="options"
                                        value="CASH"
                                        checked={selectedValue === "CASH"}
                                        onChange={handleChange}
                                        label="Cash on Delivery (COD)"
                                    />
                                </div>
                            </div>
                        </div>
                        {selectedValue !== "CARD" && <div className="Eccomerce-btn-container">
                            <Button text={"Complete Order"} width={"inherit"} />
                        </div>}
                    </div>
                </div>
                <div className="EcommerceCheckout-Container-right">
                    <div className="EcommerceCheckout-container-content">
                        <div className="header-container">
                            <Header text={"Order Summary"} fontSize={"24px"} />
                        </div>
                        {products.map((product, key) => {
                            return (
                                <CheckoutItem item={product} key={key} />
                            )
                        })}
                        <div className="EcommerceCheckout-container-total">
                            <div className="total-container">
                                <div>Subtotal</div>
                                <div>£ 220.00</div>
                            </div>
                            <div className="total-container">
                                <div>Shipping</div>
                                <div>£ 20.00</div>
                            </div>
                            <div className="value-container">
                                <div>Total</div>
                                <div>£ 220.00</div>
                            </div>
                            {selectedValue !== "CARD" && <div className="Eccomerce-btn-container">
                                <Button text={"Complete Order"} width={"inherit"} />
                            </div>}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}
export default EcommerceCheckout