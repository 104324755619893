import React from "react";
import "./HomeComponentOne.scss"
import CustomList from "./CustomList";
const HomeComponentOne = ({ components }) => {
    const getComponents = (component) => {
        switch (component.ID) {
            case "hero-custom-list":
                return <CustomList customContent={component.Components} />


            default:
                return <div />
        }

    }
    return (
        <div className="HomeComponentOne">
            {components.map((component) => {
                return getComponents(component)
            })}
        </div>
    )

}
export default HomeComponentOne