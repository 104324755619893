import React from "react";
import "./EcommerceHome.scss"

import ContactUs from "../../components/ContactUs/ContactUs";
import HomeComponentOne from "../../components/HomeComponentOne/HomeComponentOne";
import HomeHeroComponent from "../../components/HomeHeroComponent/HomeHeroComponent";
import HomeComponentTwo from "../../components/HomeComponentTwo/HomeComponentTwo";
import HomeComponentThree from "../../components/HomeComponentThree/HomeComponentThree";
import HomeComponentFour from "../../components/HomeComponentFour/HomeComponentFour";
import HomeHeroVideo from "../../components/HomeHeroVideo/HomeHeroVideo";
import { sortByOrderAscending } from "../../helper/helper";
import { useSelector } from "react-redux";

const EcommerceHome = () => {
    const pages = useSelector((state) => state.companyData.pages)
    const page = pages.find((page) => page.PageId === "home")
    const Components = page?.Components || []

    const getComponents = (component) => {
        switch (component.ID) {
            case "HomeHeroVideo":
                return <HomeHeroVideo components={component.Components} />
            case "HomeHeroComponentText":
                return <HomeHeroComponent components={component.Components} />
            case "HomeComponentOne":
                return <HomeComponentOne components={component.Components} />

            case "HomeComponentTwo":
                return <HomeComponentTwo components={component.Components} />

            case "HomeComponentThree":
                return <HomeComponentThree components={component.Components} />

            case "HomeComponentFour":
                return <HomeComponentFour components={component.Components} />
            case "ContactUs":
                return <ContactUs components={component.Components} />
            default:
                return <div />
        }

    }
    return (
        <div className="EcommerceHome">
            {sortByOrderAscending(Components).map((component, key) => {
                return (<div className="EcommerceHome-container" key={key}>
                    {getComponents(component)}
                </div>)
            })}

        </div >
    )

}
export default EcommerceHome