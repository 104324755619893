import React from "react"
import "./Footer.scss"
import Button from "../Button/Button"
import visa from "../../assets/images/pay_visa.png"
import amex from "../../assets/images/pay_amex.png"
import master from "../../assets/images/pay_master.png"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"

const Footer = () => {
    const companyDetails = useSelector((state) => state.companyData.company)
    const pages = useSelector((state) => state.companyData.pages)
    const history = useHistory()
    return (
        <div className="Ecommerce-Footer ">
            <div className="Ecommerce-Footer-top">
                <div className="Ecommerce-Footer-left">
                    <div className="Footer-header">Contact Info</div>
                    <div className="Footer-sub-text">{companyDetails?.Name}<br />
                        {companyDetails?.AddressLine1},<br />
                        {companyDetails?.AddressLine2 !== "" && <>{companyDetails?.AddressLine2},<br /></>}
                        {companyDetails?.City}<br />
                        {companyDetails?.PostZipCode}<br />
                        {companyDetails?.Country}.</div>
                    {companyDetails?.Email !== "" && <div className="Footer-sub-text"><b>Email</b>:  {companyDetails?.Email}</div>}
                    {companyDetails?.Phone !== "" && <div className="Footer-sub-text"><b>Phone</b>:  {companyDetails?.Phone}</div>}

                </div>
                <div className="Ecommerce-Footer-center">
                    <div className="Footer-header">Our Store</div>
                    <div className="Footer-sub-text">Read Our Story</div>
                    <div className="Footer-sub-text">Bespoke Bridal Service</div>
                    <div className="Footer-sub-text">The Weaving Process</div>
                    <div className="Footer-sub-text">Bride Gallery</div>
                </div>
                {pages && pages.length > 0 && <div className="Ecommerce-Footer-center">
                    <div className="Footer-header">Useful Links</div>
                    {pages.map((page, key) => {
                        return (
                            <div key={key} className="Footer-sub-text"
                                onClick={() => {
                                    history.push(page.Slug)
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                    });
                                }}
                            >        {page.Title}</div>

                        )
                    })}
                </div>}
                <div className="Ecommerce-Footer-right">
                    <div className="Footer-header">Sign Up for Email</div>
                    <div className="Footer-sub-text">Subscribe to our newsletter to receive news on update.</div>
                    <div className="Footer-button">
                        <Button text={"SUBSCRIBE"} />
                    </div>
                </div>

            </div>

            <div className="Ecommerce-Footer-bottom">
                <div className="Ecommerce-Footer-bottom-left">
                    Copyright © {companyDetails?.Name}. All Rights Reserved.

                </div>
                <div className="Ecommerce-Footer-bottom-right">
                    <div className="text-content">
                        We Using Safe Payment For
                    </div>
                    <div className="card-content">
                        <img alt="" className="card-details" src={visa} />
                        <img alt="" className="card-details" src={amex} />
                        <img alt="" className="card-details" src={master} />

                    </div>

                </div>
            </div>
        </div>
    )
}
export default Footer