import React, { useEffect } from "react";
import "./VoucherMenuDefaultOrderPage.scss"
import { useDispatch, useSelector } from "react-redux";
import locationIC from "../../assets/location.svg"
import moment from 'moment';
import Footer from "../../components/Footer/Footer";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import download from "../../assets/download-svgrepo-com.svg"
import { setOrderTrackingData } from "../../../../../../../redux/resturantWidgetSlice";
import { LOCAL_STORAGE_ITEMS_NAMES } from "../../../RestaurantWidget/utilities/helper/helper";
import { getOrderDetails } from "../../../RestaurantWidget/utilities/api/restaurantsService";
const VoucherMenuDefaultOrderStatusPage = () => {
    const { orderTrackingData } = useSelector((state) => state.resturantData);
    const storeOrderLocalData = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)) : null
    const customerDetails = orderTrackingData?.Customers[0]
    const dispatch = useDispatch()

    const isCartDataAvailable = orderTrackingData?.Carts?.Items?.length > 0 ? true : false
    const steps = [{
        head: "Order Confirmation",
        sub: "Your order has confirmed",
        status: 'CREATED'
    }, {
        head: "Order Preparing",
        sub: "Restaurant is preparing your food",
        status: 'PREPARING'

    }, {
        head: "Delivery in Progress",
        sub: "Your order will be delivered soon",
        status: 'SCHEDULED'

    }, {
        head: "Order Completed",
        sub: `Thank You for ordering at ${orderTrackingData?.Store?.Name}`,
        status: 'FINISHED'

    }
    ]
    const getSteps = () => {
        if (orderTrackingData.FulfillmentType !== "DELIVERY_BY_REWARDCONSUMER") {
            steps.splice(2, 1)
            return steps
        } else {
            return steps
        }
    }
    const getPrice = (
        basePrice
    ) => {
        let priceValue;
        switch (orderTrackingData.FulfillmentType) {
            case "DELIVERY_BY_REWARDCONSUMER":
                priceValue = basePrice.Delivery.FormattedText;
                break;
            case "DINE_IN":
                priceValue = basePrice.DineIn.FormattedText;
                break;
            case "TAKE_AWAY":
                priceValue = basePrice.TakeAway.FormattedText;
                break;
            default:
                priceValue = basePrice.Delivery.FormattedText;
                break;
        }
        return priceValue
    }

    const getEstimation = () => {
        let esitimation;
        switch (orderTrackingData.StateVal) {
            case "CREATED":
                esitimation = "Order confirmation time : " + moment(new Date(orderTrackingData.CreatedTime)).format('D MMM, YYYY HH:mm A');
                break;
            case "PREPARING":
                esitimation = "Preparation completion time : " + moment(new Date(orderTrackingData.PreparationTime.ReadyForPickupTime)).format('D MMM, YYYY HH:mm A');
                break;
            case "SCHEDULED":
                esitimation = "Expected delivery time : " + moment(new Date(orderTrackingData.ScheduledOrderTargetDeliveryTimeRange.EndTime)).format('D MMM, YYYY HH:mm A');
                break;
            case "FINISHED":
                esitimation = "Order completion time : " + moment(new Date(orderTrackingData.CompletedTime)).format('D MMM, YYYY HH:mm A');
                break;
            default:
                esitimation = "Order confirmation time : " + moment(new Date(orderTrackingData.CreatedTime)).format('D MMM, YYYY HH:mm A');
                break;
        }
        return esitimation
    }
    const updateState = (order) => {
        if (order.State === "CREATED" && order.PreparationStatus === "") {
            order.StateVal = "CREATED"
        }
        if (order.State === "CREATED" && order.PreparationStatus === "PREPARING") {
            order.StateVal = "PREPARING"
        }
        if (order.State === "CREATED" && order.PreparationStatus !== "" && order.Deliveries.Status === "SCHEDULED"
        ) {
            order.StateVal = "SCHEDULED"
        }
        if (order.State === "FINISHED") {
            order.StateVal = "FINISHED"
        }
        return order
    }
    useEffect(() => {
        if (orderTrackingData) {
            getOrderDetails(orderTrackingData.OrderID, storeOrderLocalData.cloudLocationID).then((response) => {
                if (response) {
                    dispatch(setOrderTrackingData(updateState(response)))

                }
            })
        }
    }, [])

    const renderModifierGroups = (selectedGroup, item) => {
        return (
            <div>
                {selectedGroup.SelectedItems.map((selectedItem, index) => (
                    <span key={index}>
                        <span className="addon-title">
                            {selectedItem.Title.replace(/\.+$/, '')} <span className="qty-box">{Math.floor(selectedItem.Quantity.Amount / item.Quantity.Amount)}</span>
                        </span>
                        {selectedItem.SelectedModifierGroups && selectedItem.SelectedModifierGroups.length > 0 && (
                            <span>
                                {selectedItem.SelectedModifierGroups.map((nestedGroup, nestedIndex) => (
                                    <span key={nestedIndex}> {renderModifierGroups(nestedGroup, item)}</span>
                                ))}
                            </span>
                        )}
                        {index < selectedGroup.SelectedItems.length - 1 && <span>, </span>}
                    </span>
                ))}
            </div>
        );
    };
    const exportToPDF = () => {
        const input = document.getElementById("orderCart");

        html2canvas(input, {
            scale: 2, // Optional for better quality
            useCORS: true, // Ensure proper CORS handling for images
        }).then((canvas) => {
            const pdf = new jsPDF();

            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 10, 10, 100, 0);

            // Save the PDF
            pdf.save("order_summary.pdf");
        });
    };

    return (
        <div className='VoucherMenuDefaultOrderPage'>
            <div className="Order-confirmation">
                <div className="Order-confirmation-header">
                    <div className="header-text-cont">Track your order</div>

                </div>
                <div className="Order-confirmation-inner">
                    <div className="eco-mb-16 Order-confirmation-inner-left ">
                        <div className="order-form-summary  eco-mb-36">
                            <div className="order-form-container">
                                <div className="form-header-text">{orderTrackingData.FulfillmentType === "DELIVERY_BY_REWARDCONSUMER" ? "Delivery" : "Billing"} Address</div>
                                <div className="address-conatinar">
                                    <div className="address-conatinar-left">
                                        <img className="loc-cont" alt="" src={locationIC} />
                                        <div className="customer-details-cont">
                                            <div className="customer-name-cont">
                                                <div className="name-text">{customerDetails?.Name?.FirstName} {customerDetails?.Name?.LastName}</div>
                                                <div className="address-tag">Main Address</div>
                                            </div>
                                            <div className="phone-number">{customerDetails.Contact.PinCode} {customerDetails.Contact.Number}</div>
                                            {orderTrackingData.FulfillmentType === "DELIVERY_BY_REWARDCONSUMER" ?
                                                <div className="address">{customerDetails?.DeliveryAddress?.AddressLine1} {customerDetails?.DeliveryAddress?.StateProvinceRegion}, {customerDetails?.DeliveryAddress?.Country} {customerDetails?.DeliveryAddress?.PostZipCode} </div>
                                                : <div className="address">{customerDetails?.BillingAddress?.AddressLine1} {customerDetails?.BillingAddress?.StateProvinceRegion}, {customerDetails?.BillingAddress?.Country} {customerDetails?.BillingAddress?.PostZipCode} </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="order-form-container">
                                <div className="form-header-text">{getEstimation()} </div>
                                <div className="track-steps-container">
                                    {getSteps().map((step, index) => {
                                        let stepClass = '';
                                        if (orderTrackingData.StateVal === step.status) {
                                            stepClass = '-current-step';
                                        }

                                        else if (index < steps.findIndex(s => s.status === orderTrackingData.StateVal)) {
                                            stepClass = '-completed-step';
                                        }

                                        else {
                                            stepClass = '-upcoming-step';
                                        }

                                        return (
                                            <div className={`steps-container${stepClass}`} key={index}>
                                                <div className="loc-container"><div className="inner-loc" /></div>
                                                {index + 1 !== steps.length && <div className="left-border-step" />}
                                                <div>
                                                    <div className="content-head">{step.head}</div>
                                                    <div className="content-track">{step.sub}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="Order-confirmation-inner-right">
                        {isCartDataAvailable ?
                            <div className="order-form-summary" id="orderCart">
                                <div className="order-form-container">
                                    <div className="form-header-text">Order Summary
                                        <div onClick={() => {
                                            exportToPDF()
                                        }}><img alt="" src={download} /></div></div>
                                    {isCartDataAvailable && orderTrackingData.Carts.Items.map((item, index) => {
                                        const _hasAddons = item.SelectedModifierGroups.length > 0;
                                        return (
                                            <div className="product-summary" key={index}>
                                                <div>
                                                    <div className='truncate-text'>{item.Title}
                                                    </div>
                                                    <div className="modifiers-text-sum">
                                                        {_hasAddons && item.SelectedModifierGroups.map((selectedGroup, index) => (
                                                            <span key={index}> {renderModifierGroups(selectedGroup, item)} </span>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div>  {getPrice(item.PriceInfo.TotalPrice)}</div>
                                            </div>
                                        )
                                    })}
                                    <div className="price-list">
                                        <div className="form-header-text">Order Total</div>
                                        <div className="price-list-inner">
                                            <div className="price-list-inner-left">Sub total</div>
                                            <div className="price-list-inner-right">     {orderTrackingData.Payment.
                                                PaymentDetail.ItemCharges.Net.Formatted}</div>

                                        </div>
                                        {orderTrackingData?.Payment.
                                            PaymentDetail?.Fee?.Details?.map((fee, key) => {
                                                return (
                                                    <div className="price-list-inner" key={key}>
                                                        <div className="price-list-inner-left">{fee.Description}</div>
                                                        <div className="price-list-inner-right">
                                                            {fee.Amount.DisplayAmount}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                    <div className="price-list">
                                        <div className="price-list-inner-total" >
                                            <div className="total-value-text ">Total Price</div>
                                            <div className="total-value-text ">
                                                {orderTrackingData?.Payment.
                                                    PaymentDetail.OrderTotal.Gross.Formatted}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}
                    </div>
                </div>
                <Footer />
            </div>
        </div>

    )
}
export default VoucherMenuDefaultOrderStatusPage