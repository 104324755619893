import React from "react";
import Layout from "../../components/Menu/layouts/Layout";
import "./RestaurantMenu.scss"
import { sortByOrderAscending } from "../../helper/helper";
const RestaurantMenu = () => {
    const Components = [
        {
            ID: "Header",
            Order: 1,
            Type: "Image-Text",
            Props: {
                content: "MENU",
                src: "https://w0.peakpx.com/wallpaper/467/393/HD-wallpaper-food-dessert-still-life.jpg"
            }
        }, {
            ID: "menu-content-section",
            Order: 2,
            Type: "section",
            Props: {
                "layout": "layout2",
                showHeader: true
            },
        },]
    const getComponents = (component) => {
        switch (component.ID) {
            case "Header":
                return <div className="header-menu" ><div
                    className={`image-back `}

                    style={{ backgroundImage: `url(${component.Props.src})` }}>
                    <div className="text">{component.Props.content}</div>
                </div>
                </div>
            case "menu-content-section":
                return <div className="menu-container">
                    <Layout layout={component.Props.layout} showHeader={component.Props.showHeader} />
                </div>
            default:
                return <div />
        }

    }
    return (
        <div className="RestaurantMenu">

            {sortByOrderAscending(Components).map((component, key) => {
                return (<div key={key}>
                    {getComponents(component)}
                </div>)
            })}

        </div>
    )
}
export default RestaurantMenu