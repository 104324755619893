import React, { useState, useEffect } from "react";
import "./Navbar.scss"
import { useHistory } from 'react-router-dom'
import bellIC from "../../assets/hotel-bell.png"
import phoneIC from "../../assets/phone-call.png"
import menuIC from "../../assets/menu.png"
import { useSelector } from "react-redux"
import { sortByOrderAscending } from "../../helper/helper";

const Navbar = ({ }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const companyDetails = useSelector((state) => state.companyData.company)
    const pages = useSelector((state) => state.companyData.pages)


    const history = useHistory()

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);


    return (
        <div className="Hospitality-navbar-jack">
            <div className={`Hospitality-navbar-fixed-header  ${isScrolled ? "scrolled" : ""}`}
                style={{ borderBottom: isOpened ? "none" : "" }}>

                <div className="Hospitality-navbarLeft">
                    <div className="Hospitality-navbar-left">

                        <img alt="" className="logo-container"
                            onClick={() => {
                                history.push("/")
                            }}
                            src={companyDetails?.Logo} />
                    </div>
                    <div className="Hospitality-navbar-center">
                        {sortByOrderAscending(pages).map((page, key) => {
                            return (
                                page.ShowInNavigation && <div key={key} className="header-title" onClick={() => {
                                    history.push(page.Slug)
                                    window.scrollTo({
                                        top: 0,
                                        behavior: "smooth",
                                    });
                                }}>
                                    {page.Title}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="Hospitality-navbar-mobile-right">
                    <div className="menu-icon" onClick={() => {
                        setIsOpened(!isOpened)
                    }}>
                        <img alt="" src={menuIC} className="menu-container" />
                    </div>
                </div>
                <div className="Hospitality-navbar-right">
                    {/* <div className="order-online-tab">
                        <img alt="" src={bellIC} className="bell-container" />
                    </div> */}
                    <div className="order-hotline-tab">
                        <img alt="" src={phoneIC} className="phone-container" />
                        {companyDetails?.Phone}
                    </div>
                </div>
            </div>
            {isOpened && <div className={"Hospitality-mobile-nav-conrainer"}>
                {sortByOrderAscending(pages).map((page, key) => {
                    return (
                        page.ShowInNavigation && <div className="navbar-links" key={key}
                            onClick={() => {
                                history.push(page.Slug)
                                setIsOpened(false)
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }}>{page.Title}</div>
                    )
                })}
                {/* <div className="navbar-links">
                    <img alt="" src={bellIC} className="bell-container" /></div> */}
                <div className="navbar-links">
                    <img alt="" src={phoneIC} className="phone-container" />
                    <div className="navbar-links">{companyDetails?.Phone}</div></div>
            </div>}
        </div>
    )
}
export default Navbar