import React, { useEffect, useState } from "react";

import { Popup } from 'witmeg-ui-system';
import "./LocationFinder.scss"
import loc from "../../../assets/location-pin.png"
import closeIC from "../../../assets/close.svg"
import { Col, InputBox, Label, Row, } from 'witmeg-ui-system'
import { useDispatch, useSelector } from "react-redux";

import DataValidation, { DATA_VALIDATION_TYPES } from "../../../../RestaurantWidget/utilities/helper/dataValidation";
import { setOrderData, setSelectedOrderType } from "../../../../../../../../redux/resturantWidgetSlice";
import { LOCAL_STORAGE_ITEMS_NAMES, setLocalStorageData } from "../../../../RestaurantWidget/utilities/helper/helper";
import { checklocation, updateOrderTypeDetails } from "../../../../RestaurantWidget/utilities/api/restaurantsService";
const LocationFinder = ({ visibility, onPopupClose, setAddDeliveryLoc,
    setDeliverySuccess,
    setDeliveryFailed
}) => {
    const storeOrderLocalData = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)) : null
    const companyDetails = useSelector((state) => state.companyData.companyDetails);
    const userDetails = localStorage.getItem('fullUserDetails')
    let parsedUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'))
    const addressDelivery = userDetails ? parsedUserDetails.Addresses.find((address) => address.AddressType === "Delivery") : null
    const dispatch = useDispatch()
    let customerDetailsInitialState = {
        deliveryAddress: {
            line1: {
                value: addressDelivery ? addressDelivery?.AddressLine1 !== "" ? addressDelivery?.AddressLine1 : "" : "",
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD],
            },
            line2: {
                value: addressDelivery ? addressDelivery?.AddressLine2 !== "" ? addressDelivery?.AddressLine2 : "" : "",
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [],
            },
            state: {
                value: addressDelivery ? addressDelivery?.StateProvinceRegion !== "" ? addressDelivery?.StateProvinceRegion : "" : "",
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [],
            },
            city: {
                value: addressDelivery ? addressDelivery?.City !== "" ? addressDelivery?.City : "" : "",
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD],
            },
            postcode: {
                value: addressDelivery ? addressDelivery?.PostZipCode !== "" ? addressDelivery.PostZipCode : "" : "",
                hasError: false,
                errorType: null,
                errorMessage: null,
                validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD, DATA_VALIDATION_TYPES.IS_POSTCODE],
            },
            country: {
                value: 'United Kingdom',
                hasError: false,
                errorType: null,
                errorMessage: null,
                // validations: [DATA_VALIDATION_TYPES.REQUIRED_FIELD],
                validations: [],
            },
        },
    };
    const [customerDetails, setCustomerDetails] = useState(customerDetailsInitialState);
    useEffect(() => {
        setCustomerDetails(customerDetailsInitialState)
    }, [visibility])
    const [isLoading, setIsLoading] = useState(false)
    const onFormDataChanged = (property, value) => {
        setCustomerDetails((prevState) => {
            const [key, subKey] = property.split('.');

            if (subKey) {
                return {
                    ...prevState,
                    [key]: {
                        ...prevState[key],

                        [subKey]: {
                            ...prevState[key][subKey],
                            value,
                            hasError: false
                        },
                    },
                };
            }

            return {
                ...prevState,
                [property]: {
                    ...prevState[property],
                    value,
                    hasError: false
                },
            };
        });
    };
    const validateFieldSet = (fields) => {
        let pageHasError = false;
        const newFields = { ...fields };

        for (const key in fields) {
            if (!fields.hasOwnProperty(key)) continue;

            let validationRes = { hasError: false, errorType: null };

            for (const validation of fields[key].validations) {
                if (validationRes.hasError) break;

                validationRes = DataValidation(validation, fields[key].value, fields[key].additional ?? null);
            }

            if (validationRes.hasError) pageHasError = true;

            newFields[key] = {
                ...newFields[key],
                ...validationRes,
            };
        }

        return {
            fields: newFields,
            hasError: pageHasError,
        };
    };
    const updateOrderType = (type) => {
        if (localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID)) {
            let body = {
                "OrderID": localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID),
                "DeliveryType": type,
                "CountryCode": "GB"
            }
            updateOrderTypeDetails(body).then((res) => {
                dispatch(setOrderData(res))
            })
        }

    }
    const hanldeConfirm = (data, customerFormData) => {
        setIsLoading(true)
        let req = {
            "CloudLocationID": storeOrderLocalData?.cloudLocationID,
            "Store": {
                "AddressLine1": companyDetails.address.line1,
                "AddressLine2": companyDetails.address.line2,
                "City": companyDetails.address.city,
                "StateProvinceRegion": companyDetails.address.state,
                "PostZipCode": companyDetails.address.postcode,
                "Country": companyDetails.address.country,
                "CountryCode": "GB",
            },
            "Customer": {
                "AddressLine1": customerDetails.deliveryAddress.line1.value,
                "AddressLine2": customerDetails.deliveryAddress.line2.value,
                "City": customerDetails.deliveryAddress.city.value,
                "StateProvinceRegion": customerDetails.deliveryAddress.state.value,
                "PostZipCode": customerDetails.deliveryAddress.postcode.value,
                "Country": customerDetails.deliveryAddress.country.value,
                "CountryCode": "GB",
            }
        }
        checklocation(req).then((response) => {
            if (response !== "") {
                localStorage.setItem("fullUserDetails", JSON.stringify(data))
                setLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS, customerFormData);
                updateOrderType("DELIVERY_BY_REWARDCONSUMER")
                dispatch(setSelectedOrderType("delivery"))
                localStorage.setItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_LOC_ID, response)
                setCustomerDetails(customerDetailsInitialState)
                setIsLoading(false)
                setAddDeliveryLoc(false)
                setDeliveryFailed(false)
                setDeliverySuccess(true)
            } else {
                localStorage.removeItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_LOC_ID)
                setCustomerDetails(customerDetailsInitialState)
                setIsLoading(false)
                setAddDeliveryLoc(false)
                setDeliverySuccess(false)
                setDeliveryFailed(true)



            }

        })
    }
    return (
        <Popup
            className='finder'
            modalVisible={visibility}
            modalTitle=""
            mask={true}
            centered
            onCancel={onPopupClose}
            closable={false}>
            <div className="finder-inner">
                <div className='inner-container'>
                </div>
                <div className="finder-header-text">
                    <div className="header-text">                    <img alt="" src={loc} />
                        Check if we deliver to your location</div>
                    <div className="close-icon"
                        onClick={onPopupClose}><img alt="" src={closeIC} /></div>

                </div>
                <div className="finder-sub-text">Enter your address to check if delivery is available in your area.</div>
                <div className="formfinder">

                    <Row className="eco-mb-24" gutter={[16, 16]}>
                        <Col span={24}>
                            <Row className="form-row">
                                <Col span={24}>
                                    <div className="form-lbl-wrapper"><Label required>Address Line 1</Label></div>
                                </Col>
                                <Col span={24}>
                                    <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.line1', e.target.value)} type="text" value={customerDetails.deliveryAddress.line1.value} error={customerDetails.deliveryAddress.line1.hasError} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="eco-mb-24">
                        <Col span={24}>
                            <Row className="form-row">
                                <Col span={24}>
                                    <div className="form-lbl-wrapper"><Label>Address Line 2</Label></div>
                                </Col>
                                <Col span={24}>
                                    <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.line2', e.target.value)} type="text" value={customerDetails.deliveryAddress.line2.value} error={customerDetails.deliveryAddress.line2.hasError} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="eco-mb-24" gutter={[16, 16]}>

                        <Col lg={12} sm={24} xs={24}>
                            <Row className="form-row">
                                <Col span={24}>
                                    <div className="form-lbl-wrapper"><Label required>Postcode</Label></div>
                                </Col>
                                <Col span={24}>
                                    <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.postcode', e.target.value)} type="text" value={customerDetails.deliveryAddress.postcode.value} error={customerDetails.deliveryAddress.postcode.hasError} />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12} sm={24} xs={24}>
                            <Row className="form-row">
                                <Col span={24}>
                                    <div className="form-lbl-wrapper"><Label required>City</Label></div>
                                </Col>
                                <Col span={24}>
                                    <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.city', e.target.value)} type="text" value={customerDetails.deliveryAddress.city.value} error={customerDetails.deliveryAddress.city.hasError} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="eco-mb-24" gutter={[16, 16]}>
                        <Col lg={12} sm={24} xs={24}>
                            <Row className="form-row">
                                <Col span={24}>
                                    <div className="form-lbl-wrapper"><Label >State</Label></div>
                                </Col>
                                <Col span={24}>
                                    <InputBox onChange={(e) => onFormDataChanged('deliveryAddress.state', e.target.value)} type="text" value={customerDetails.deliveryAddress.state.value} error={customerDetails.deliveryAddress.state.hasError} />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12} sm={24} xs={24}>
                            <Row className="form-row">
                                <Col span={24}>
                                    <div className="form-lbl-wrapper"><Label required>Country</Label></div>
                                </Col>
                                <Col span={24}>
                                    <InputBox type="text" value={customerDetails.deliveryAddress.country.value} disabled />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                <div className="btn-container" onClick={() => {


                    const newDeliveryAddress =
                        validateFieldSet(customerDetails.deliveryAddress);
                    const pageHasError = newDeliveryAddress.hasError;
                    setCustomerDetails({ deliveryAddress: newDeliveryAddress.fields })
                    if (!pageHasError) {
                        const customerFormData = {
                            ID: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID),
                            Name: {
                                DisplayName: "",
                                FirstName: "",
                                LastName: "",
                            },
                            Email: "",
                            Contact: {
                                Number: "",
                                PinCode: "+44",
                                CountryISO2: "GB"
                            },
                            BillingAddress: {
                                Id: "",
                                AddressType: "",
                                AddressLine1: "",
                                AddressLine2: "",
                                StateProvinceRegion: "",
                                PostZipCode: "",
                                Country: "",
                                CountryCode: "",
                                AddressLabel: "",
                                Longitude: 0,
                                Latitude: 0,
                                Additional: {
                                    Instruction: "",
                                    InteractionType: ""
                                }
                            },
                            DeliveryAddress: {
                                Id: "",
                                AddressType: "",
                                "AddressLine1": customerDetails.deliveryAddress.line1.value,
                                "AddressLine2": customerDetails.deliveryAddress.line2.value,
                                "City": customerDetails.deliveryAddress.city.value,
                                "StateProvinceRegion": customerDetails.deliveryAddress.state.value,
                                "PostZipCode": customerDetails.deliveryAddress.postcode.value,
                                "Country": customerDetails.deliveryAddress.country.value,
                                CountryCode: "",
                                AddressLabel: "",
                                Longitude: 0,
                                Latitude: 0,
                                Additional: {
                                    Instruction: "",
                                    InteractionType: ""
                                }
                            },
                        };
                        let data = {
                            "UserID": localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID),
                            "CompanyName": "",
                            "UserName": "",
                            "FirstName": "",
                            "LastName": "",
                            "Addresses": [
                                {
                                    "AddressType": "Billing",
                                    "HouseNo": "",
                                    "AddressLine1": "",
                                    "AddressLine2": "",
                                    "City": "",
                                    "StateProvinceRegion": "",
                                    "PostZipCode": "",
                                    "Country": "",
                                    "Phone": "",
                                    "Fax": "",
                                    "Email": "",
                                    "CreatedDate": "",
                                    "LastModifiedDate": "",
                                    "IsDefault": false,
                                    "IsActive": false,
                                    "TelephoneCode": "+44",
                                    "AddressLabel": ""
                                },
                                {
                                    "AddressType": "Delivery",
                                    "HouseNo": "",
                                    "AddressLine1": customerDetails.deliveryAddress.line1.value,
                                    "AddressLine2": customerDetails.deliveryAddress.line2.value,
                                    "City": customerDetails.deliveryAddress.city.value,
                                    "StateProvinceRegion": customerDetails.deliveryAddress.state.value,
                                    "PostZipCode": customerDetails.deliveryAddress.postcode.value,
                                    "Country": customerDetails.deliveryAddress.country.value,
                                    "Phone": "",
                                    "Fax": "",
                                    "Email": "",
                                    "CreatedDate": "",
                                    "LastModifiedDate": "",
                                    "IsDefault": false,
                                    "IsActive": false,
                                    "TelephoneCode": "+44",
                                    "AddressLabel": ""
                                }
                            ],
                            "Email": "",
                        }

                        hanldeConfirm(data, customerFormData)

                    }
                }}>
                    <div className="btn-cony">{isLoading ? <div className="loader-btn " /> :
                        "Confirm & Continue"}</div></div>
            </div>
        </Popup>
    )
}
export default LocationFinder