import React from "react";
import "./HomeComponents.scss"
import Layout from "../Menu/layouts/Layout";
import { useHistory } from "react-router";
const HomeHeroFour = () => {
    const history = useHistory()
    const categories = [
        "breakfast",
        "lunch",
        "dessert"
        , "drinks"
    ]
    return (
        <div className="HomeHeroFour">
            <div className="header-text-jack">Menu</div>
            <div className="menu-component-header">Jack & Jill Menu</div>
            <div className="menu-comp-sub-header">Indulge in our diverse menu, featuring fresh ingredients and unique flavors, while enjoying expertly crafted cocktails and refreshing drinks at the bar.</div>
            <div className="menu-category">
                {categories.map((cat, index) => {
                    return (
                        <div key={index} className="menu-cat-item">{cat}</div>
                    )
                })}
            </div>
            <div className="menu-layout">
                <Layout layout={"layout1"} limit={5} navigate={() => {
                    history.push('/pages/menu')
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                }} showHeader={false} />
            </div>

        </div>
    )
}
export default HomeHeroFour