import React, { useState } from "react";
import "./ContactForm.scss"
import Input from "../../Input/Input";
import Textarea from "../../Textarea/Textarea";
import Button from "../../Button/Button";
import { contactEnquiry } from "../../../../../../utilities/apiRequests/witmegRetailPaxServerRequests";
import { useSelector } from "react-redux";
const ContactForm = () => {
    const company = useSelector((state) => state.companyData.company)
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")
    const [errors, setErrors] = useState({
        fullName: "",
        email: "",
        message: ""
    })
    const [loading, setLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)


    const validateEmail = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const validateForm = () => {
        let valid = true;
        let newErrors = {
            fullName: "",
            email: "",
            message: ""
        }
        if (fullName.trim() === "") {
            newErrors.fullName = "*Required";
            valid = false;
        }
        if (message.trim() === "") {
            newErrors.message = "*Required";
            valid = false;
        }
        if (email.trim() === "") {
            newErrors.email = "*Required";
            valid = false;
        }
        else if (!validateEmail(email)) {
            newErrors.email = "*Invalid email format";
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    };
    const handleContact = async () => {
        const data = {
            "WebsiteID": company?.ID || "",
            "Name": fullName,
            "Email": email,
            "PhoneNo": phone,
            "Message": message,
            "Subject": subject
        }
        if (validateForm() && !loading) {
            setLoading(true)
            let res = await contactEnquiry(data)
            if (res) {
                setEmail("")
                setFullName("")
                setMessage("")
                setSubject("")
                setPhone("")
                setLoading(false)
                setShowSuccess(true)
                setTimeout(() => {
                    document.querySelector('.succes-alert').classList.add('fade-out');
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 1000);
                }, 2000);
            } else {
                setLoading(false)
            }
        }
    }


    return (
        <div className="ContactForm">

            <div className="input-container-flex">
                <Input
                    label={"Your name"}
                    required
                    placeholder="Your full name"
                    value={fullName}
                    onChange={(e) => {
                        setFullName(e.target.value)
                        setErrors({ ...errors, fullName: false })
                    }}
                    errorMessage={errors.fullName}
                />      <Input
                    label={"Your email"}
                    required
                    placeholder="Your email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                        setErrors({ ...errors, email: false })

                    }}
                    errorMessage={errors.email}

                />
            </div>
            <div className="input-container-flex">
                <Input
                    label={"Your subject"}
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => {
                        setSubject(e.target.value)
                    }}
                />
                <Input
                    label={"Your phone"}
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => {
                        setPhone(e.target.value)
                    }}
                />
            </div>
            <div className="input-container-flex">
                <Textarea
                    label={"Your message"}
                    placeholder="Type your message"
                    value={message}
                    required
                    onChange={(e) => {
                        setMessage(e.target.value)
                        setErrors({ ...errors, message: false })

                    }}
                    errorMessage={errors.message}
                />
            </div>
            {showSuccess && <div className="succes-alert">
                ✔ Thank you for contacting us! We appreciate your message and will be in touch shortly .
            </div>}
            <div className="btn-container"><Button text={"SEND MESSAGE"} Secondary
                onClick={handleContact} isLoading={loading} /></div>
        </div>
    )
}
export default ContactForm