import Axios from "axios";
import globalValues from "../../../../../utilities/apiRequests/globalValues";

export const generateTokenForRetailPaxLoyality = async () => {
    try {
        const apiObject = {
            isLoginServerRequest: false,
            serviceName: globalValues.tokenValues.REACT_APP_REGISTRY_VALUE,
        };
        const tokenResponse = await Axios.post(
            globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
            apiObject
        );

        return tokenResponse.data.token;
    } catch (error) {
        console.error("Error In Generating Token", error);
    }
}
export const generateTokenForRetailPaxNuetriPos = async () => {
    try {
        const apiObject = {
            isLoginServerRequest: false,
            serviceName: '',
        };
        const tokenResponse = await Axios.post(
            globalValues.baseURLS.REACT_APP_TOKENSERVICE_URL,
            apiObject
        );

        return tokenResponse.data.token;
    } catch (error) {
        console.error("Error In Generating Token", error);
    }
} 