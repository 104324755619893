import React from "react";
import "./EcommerceAboutus.scss"
import Header from "../../components/Header/Header";
import HomeHeroVideo from "../../components/HomeHeroVideo/HomeHeroVideo";
import { sortByOrderAscending } from "../../helper/helper";
import AboutusContent from "./AboutusContent";
import { useSelector } from "react-redux";

const EcommerceAboutus = () => {
    const pages = useSelector((state) => state.companyData.pages)
    const page = pages.find((page) => page.PageId === "aboutus")
    const Components = page?.Components || []
    const getComponents = (component) => {
        switch (component.ID) {
            case "Header":
                return <div className="EcommerceAboutus-header"><Header text={component.Props.content} textAlign="center" /></div>
            case "HomeHeroVideo":
                return <HomeHeroVideo components={component.Components} />
            case "AboutusContent":
                return <AboutusContent components={component.Components} />
            default:
                return <div />
        }

    }
    return (
        <div className="EcommerceAboutus">
            {sortByOrderAscending(Components).map((component, key) => {
                return (<div className="EcommerceHome-container" key={key}>
                    {getComponents(component)}
                </div>)
            })}

        </div>
    )
}
export default EcommerceAboutus