import React from "react";
import "./HomeHeroComponent.scss"
import { sortByOrderAscending } from "../../helper/helper";
const HomeHeroComponent = ({ components }) => {

    const getComponents = (component) => {
        switch (component.ID) {
            case "hero-details-header":
                return <div className="details-header">Welcome to Casipillai Designer Collection</div>

            case "hero-details-content":
                return <div className="details-content">
                    We are a globally recognized family owned business based in the UK and renowned for creating unique and memorable designs that is guaranteed to last for many generations, both in quality and style.with many years’ experience of designing sarees from Kanchipuram, our team ranging from front customer service and designers to specially chosen weavers will ensure that your saree is tailored to your character. Each saree is made with tender care and love from the first thread to the last stitch. We ensure that we materialise your dreams into a visual reality keeping mind to the quality and distinctive wishes of all our clients. Whether you are looking for specific Wedding wear service or something for other special events, Casipillai Designer Collections will provide you with all you need to look divine!
                </div>


            default:
                return <div />
        }

    }
    return (
        <div className="HomeHeroComponent">
            {sortByOrderAscending(components).map((component) => {
                return getComponents(component)
            })}

        </div>
    )
}
export default HomeHeroComponent