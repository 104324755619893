import React from "react";
import "./HomeComponents.scss"
import JumbledImageContainer from "../JumbledImageContainer/JumbledImageContainer";
import { sortByOrderAscending } from "../../helper/helper";


const HomeHeroTwo = ({ component }) => {
    const getTextComponents = (component) => {
        switch (component.ID) {
            case "header":
                return <div className="header-text-jack">{component.Props.content}</div>
            case "content":
                return <div className="des-header">
                    {component.Props.content}
                </div>
            case "sub-content":
                return <div className="description-text">
                    {component.Props.content} </div>

            case "avatar-content":
                return <div className="avatar-container">
                    <div className="avatar-cont"></div>
                    <div className="avatar-description">
                        <div className="avatar-name">{component.Props.name}</div>
                        <div className="avatar-description">{component.Props.title}</div>
                    </div>
                </div>
            default:
                return <div />
        }

    }
    const getTextContainer = (components) => {
        return (
            <div className="HomeHerotwo-left">
                {sortByOrderAscending(components).map((component, key) => {
                    return (<div key={key}>
                        {getTextComponents(component)}
                    </div>)
                })}
            </div>
        )
    }

    const getComponents = (component) => {
        switch (component.ID) {
            case "HomeHeroTwo-Content-Container":
                return getTextContainer(component.Components)
            case "HomeHeroTwo-Image-Container":
                return <div className="HomeHerotwo-right">
                    <JumbledImageContainer components={component.Props} />
                </div>
            default:
                return <div />
        }

    }
    return (
        <div className="HomeHeroTwo">
            {sortByOrderAscending(component).map((component, key) => {
                return (getComponents(component))
            })}
        </div>
    )
}
export default HomeHeroTwo