import React from 'react'
import "./TextareaComponent.scss"
const TextareaComponent = ({ placeholder, value, onChange, isError = false, errorMessage = "", }) => {

    return (
        <div className={`TextareaComponent  ${isError ? "TextareaComponent-error" : ""}`}>
            <textarea placeholder={placeholder} value={value} onChange={onChange}
            />
            {isError && <div className='TextareaComponent-error'>{errorMessage}</div>}

        </div>
    )
}
export default TextareaComponent