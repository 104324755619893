import React from "react";
import "./TextContent.scss"

const TextContent = ({ text, fontSize, textAlign, fontWeight }) => {
    return (
        <div className="TextContentComponent" style={{ fontSize, textAlign, fontWeight }}>
            {text}
        </div>
    )
}
export default TextContent