import React from "react";
import "./HomeComponentThree.scss"
import BackgroundImageComponent from "./BackgroundImageComponent";
import { sortByOrderAscending } from "../../helper/helper";

const HomeComponentThree = ({ components }) => {
    const getComponents = (component) => {
        switch (component.ID) {
            case "image-detail-header":
                return <div className="details-header">Bridal World</div>
            case "image-detail-background":
                return <BackgroundImageComponent content={component} />

            default:
                return <div />
        }

    }
    return (
        <div className="HomeComponentThree">
            {sortByOrderAscending(components).map((component) => {
                return getComponents(component)
            })}
        </div>
    )

}
export default HomeComponentThree