import React, { useEffect, useState } from "react";
import "./EcommerceSinglePage.scss"
import { useParams } from 'react-router';
import plus from "../../assets/plus.svg"
import minus from "../../assets/minus.svg"
import Button from "../../components/Button/Button";
import visa from "../../assets/images/pay_visa.png"
import amex from "../../assets/images/pay_amex.png"
import master from "../../assets/images/pay_master.png"
import Accordion from "../../components/Accordion/Accordion";
const EcommerceSinglePage = () => {
    const params = useParams();
    const [item, setItem] = useState(null)
    const collection = [
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616161936148.jpeg",
            "title": "Mangalya Silk Collection",
            price: "100.00",
            id: 1
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616162869639.jpeg",
            "title": "Thirumana Silk Collection",
            price: "120.00",
            id: 2
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616166519893.jpeg",
            "title": "Thozhi Silk Collection",
            price: "90.00",
            id: 3

        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616166902711.jpeg",
            "title": "Sitha Silk Collection",
            price: "220.00",
            id: 4
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1625041966373.jpg",
            "title": "Sitha Silk ",
            price: "180.00",
            id: 5
        }, {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1625038454247.JPG",
            "title": "Sitha Silk ",
            price: "130.00",
            id: 6
        }, {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1625038374020.JPG",
            "title": "Sitha Silk ",
            price: "320.00",
            id: 7
        },
    ]
    const items = [
        { title: "Details", content: "This Floral Pattern Colorful Summer Tee is a must-have for any summer wardrobe. The colorful floral pattern adds a touch of fun to any outfit, while the lightweight fabric keeps you cool and comfortable on hot days. Expertly crafted with high-quality materials, this tee is both stylish and durable. Experience the perfect blend of fashion and function with this versatile piece." },
    ];
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (params.id) {
            let product = collection.filter((value) => value.id === Number(params.id))[0]
            setItem(product)
        }
    }, [params.id])
    return (
        <div className="EcommerceSinglePage">
            <div className="EcommerceSinglePage-left">
                <img alt="" src={item?.image} />
            </div>
            <div className="EcommerceSinglePage-right">
                <div className="item-header">{item?.title}</div>
                <div className="item-ref" >PRODUCT REF NO: TEST01</div>
                <div className="item-price"><div className="mrp">M.R.P
                </div> £{item?.price}</div>
                <div className="item-vendor"><b>Vendor:</b>Casipillai Designer Collection</div>
                <div className="item-container">
                    <div className="qty-container">
                        <div className="qty-cont-action "><img alt="" src={minus} /></div>
                        <div className="qty-cont-item">1</div>
                        <div className="qty-cont-action "><img alt="" src={plus} /></div>
                    </div>
                    <div className="btn-container">
                        <div className="btn-item">
                            <Button text={"Add To Wishlist"} invert width={"inherit"} />
                        </div>
                        <div className="btn-item">
                            <Button text={"Add To Cart"} width={"inherit"} />
                        </div>
                    </div>


                </div>
                <div className="buy-btn">
                    <Button text={"Buy It Now"} width={"100%"} />

                </div>
                <div className="payment-container">
                    <div className="payment-text" >Granteed safe checkout</div>
                    <div className="payment-cards ">
                        <img alt="" src={visa} />
                        <img alt="" src={amex} />
                        <img alt="" src={master} />
                    </div>
                </div>
                <div className="details-container">
                    <Accordion items={items} icon isOpen />
                </div>


            </div>

        </div>
    )

}
export default EcommerceSinglePage