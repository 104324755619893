import React, { useState, useEffect, } from "react";
import "./EcommerceProduct.scss"
import { useParams } from 'react-router';
import Header from "../../components/Header/Header";
import ProductsContent from "./ProductsContent";
import { sortByOrderAscending } from "../../helper/helper";
import FiltersComponent from "./FiltersComponent";
import { useSelector } from "react-redux";


const EcommerceProduct = () => {
    const params = useParams();

    const [header, setHeader] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (params.category) {
            setHeader(params.category)
        }
    }, [params.category])

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };
    const pages = useSelector((state) => state.companyData.pages)
    const page = pages.find((page) => page.PageId === "productCategory")
    const Components = page?.Components || []
    const getProductsComponents = (component) => {
        switch (component.ID) {
            case "Web-Filter-Component":
                return <FiltersComponent isWeb={true} isOpen={isOpen} />
            case "Web-Products-content":
                return <ProductsContent toggleDrawer={toggleDrawer} components={component.Components} />

            default:
                return <div />
        }

    }
    const getProductsSection = (components) => {
        return (
            <div className="EcommerceContact-content">
                {sortByOrderAscending(components).map((component, key) => {
                    return getProductsComponents(component)

                })}
            </div>
        )
    }
    const getComponents = (component) => {
        switch (component.ID) {
            case "Mobile-Filter-Component":
                return <FiltersComponent isWeb={false} isOpen={isOpen} />
            case "Header":
                return <div className="EcommerceProduct-header ">
                    <Header text={header} textAlign="center" />
                </div>
            case "Products-Content":
                return getProductsSection(component.Components)
            default:
                return <div />
        }

    }
    return (
        <div className="EcommerceProduct">
            {sortByOrderAscending(Components).map((component, key) => {
                return getComponents(component)

            })}
        </div>
    )
}
export default EcommerceProduct