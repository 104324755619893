import React, { useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import Cart from "../components/Cart/Cart";
import "./EcommerceDefaultWrapper.scss"
import SearchComponent from "../components/SearchComponent/SearchComponent";
const EcommerceDefaultWrapper = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);


    return (
        <div className="EcommerceDefaultWrapper" >
            <Cart isOpen={isOpen} setIsOpen={setIsOpen} />
            <Navbar setIsOpen={setIsOpen} setIsSearchOpen={setIsSearchOpen} />
            {children}
            <Footer />
            <SearchComponent isOpen={isSearchOpen} setIsOpen={setIsSearchOpen} />
        </div>

    )

}
export default EcommerceDefaultWrapper;