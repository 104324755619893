import React from "react";
import "./ContactHero.scss"
import loc from "../../assets/location.png"
import phone from "../../assets/call.png"
import fax from "../../assets/paper.png"
import mail from "../../assets/mail.png"
import Card from "../Card/Card";
import ContactFormContainer from "./ContactFromContainer";
import { sortByOrderAscending } from "../../helper/helper";



const ContactHero = ({ Components }) => {
    const getHeaderContent = (component) => {
        return (
            <div className="Contact-Hero-cop" style={{ backgroundImage: `url(${component.Props.src})` }}>
                <div className="text">
                    <div>
                        <div className="header-text-jack">{component.Props.content}</div>
                        <div className="sub-text-content">
                            {component.Props.subContent}
                        </div>
                    </div>
                </div>
                <div className="curve" />
            </div>
        )
    }
    const getIconType = (type) => {
        switch (type) {
            case 'location':
                return loc
            case 'email':
                return mail
            case 'fax':
                return fax
            case 'phone':
                return phone
            default:
                return mail
        }
    }

    const getComponents = (component) => {
        switch (component.ID) {
            case "Header":
                return getHeaderContent(component)
            case "contact-card":
                return <div className="card-details-container">
                    {component.Components.map((card, key) => {
                        return (
                            <div className="card-contact-wrapper">
                                <Card key={key} icon={getIconType(card.Props.iconType)} header={card.Props.content} description={card.Props.subContent} />
                            </div>
                        )
                    })}
                </div>
            case "contact-form":
                return <ContactFormContainer Components={component.Components} />
            default:
                return <div />
        }

    }

    return (
        <div className="ContactHero" >

            {sortByOrderAscending(Components).map((component, key) => {
                return (<div key={key}>
                    {getComponents(component)}
                </div>)
            })}

        </div>
    )
}
export default ContactHero