import React from "react";
import "./Footer.scss"
import { useSelector } from "react-redux";
import email from "../../assets/email.png"
import phone from "../../assets/phone-call.png"
import location from "../../assets/location-pin.png"

const Footer = () => {
    const companyDetails = useSelector((state) => state.companyData.companyDetails);
    return (
        <div className="Footer-Voucher">
            <div className="footer-innwr">
                <div className="footer-innwr-right">
                    <div className="logo-container">
                        <img alt="" className="logo-conr" src={companyDetails.bigLogoURL} />
                    </div>
                    <div className="menu-header-inner-header">
                        <div className="name">{companyDetails.shopName}</div>
                        <div className="address">
                            {companyDetails.address.city}<br />{companyDetails.address.state} .
                        </div>
                    </div>
                </div>

                <div className="menu-header-inner">
                    <div className="name">Contact Us</div>
                    <div className="address">
                        <div className="cont"><img alt="" src={email} />{companyDetails.email}</div>  <div className="cont">
                            <img alt="" src={phone} /> +44 {companyDetails.contactNumber}    </div>
                        <div className="cont"><img alt="" src={location} /><div>{companyDetails.address.line1},<br />
                            {companyDetails.address.city}, {companyDetails.address.postcode},<br /> {companyDetails.address.state}, {companyDetails.address.country}
                        </div> </div></div>
                </div>
            </div>

        </div>
    )

}
export default Footer