import { createSlice } from "@reduxjs/toolkit";

export const companyDataSlice = createSlice({
  name: "Company Details",
  initialState: {
    companyDetails: {
      organizationID: '',
      companyID: '',
      shopName: '',
      bigLogoURL: '',
      smallLogoURL: '',
      siteURL: '',
      address: '',
      email: '',
      contactNumber: '',
    },
    company: null,
    settings: {},
    pages: []
  },

  reducers: {
    setCompanyData: (state, action) => {
      state.companyDetails = { ...action.payload };
    },
    setSettingsData: (state, action) => {
      state.settings = { ...action.payload };
    },
    setPages(state, action) {
      state.pages = action.payload
    },
    setCompany(state, action) {
      state.company = action.payload
    },
  },
});

export const { setCompanyData, setSettingsData, setPages, setCompany } = companyDataSlice.actions;

export default companyDataSlice.reducer;