import React, { useState } from "react";
import "./EcommerceRegister.scss"
import Header from "../../components/Header/Header";
import InputComponent from "../../components/InputComponent/InputComponent";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import _ from "lodash";
import apiEndpointPaths from "../../helper/apiEndpointPaths";
import { renderEmail } from 'postonents';
import Modal from "../../components/Modal/Modal";
import VerificationEmail from "../../email/VerificationEmail";
import globalValues from "../../global/globalValues";
import { useHistory } from "react-router";

const EcommerceRegister = () => {
    const [email, setEmail] = useState("")
    const [errors, setErrors] = useState({ email: "" })
    const [accountAvailable, setAccountAvailable] = useState(false)
    const [isVerifyOpen, setIsVerifyOpen] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const companyDetails = useSelector((state) => state.companyData.company)

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const validateForm = () => {
        let valid = true;
        const newErrors = {
            email: "",
        };
        if (email.trim() === "") {
            newErrors.email = "*required";
            valid = false;
        } else if (!validateEmail(email)) {
            newErrors.email = "*Invalid email format";
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    }
    const encrypt = (data) => {
        var CryptoJS = require("crypto-js");
        var key = globalValues.keys.REACT_APP_encryptKey;
        var cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
        return cipherText;
    }
    const handleRegister = async () => {
        if (validateForm() && !isLoading) {
            setIsLoading(true)
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ` + globalValues.keys.REACT_APP_LoginAuthorizationKey,
                },
                body: JSON.stringify({ "Email": email, "OrganizationID": companyDetails?.OrganisationID })
            };
            fetch(apiEndpointPaths.orgCustomerRegistry.findUser, requestOptions)
                .then(response => response.json())
                .then(function (data) {
                    if (data.Status === false) {
                        sessionStorage.setItem('tmpEmail', email);


                        let DigitCode = _.random(100000, 999999);
                        let token = encrypt(JSON.stringify(DigitCode));

                        const data = {
                            verificationToken: token,
                            companyPageTitle: companyDetails?.Name,
                            SenderEmail: companyDetails?.Email,
                            companyLogoCDN: companyDetails?.LogoCDN,
                            DigitCode: DigitCode,
                            email: email
                        }
                        const emailBody = renderEmail(VerificationEmail, { lang: 'en', data });

                        sessionStorage.setItem('verificationtoken', token);
                        sessionStorage.setItem('tmpEmail', email);

                        async function postData() {
                            const ecoEmail = {
                                "to": email,
                                "from": companyDetails?.Name + "<" + companyDetails?.Email + ">",
                                "subject": "Your verification code",
                                "html": emailBody
                            };
                            const response = await fetch(globalValues.baseURLS.REACT_APP_backendURL + 'mail/send', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(ecoEmail)
                            })
                            return response.json();
                        }

                        postData()
                            .then(data => {
                                if (data === "SUCCESS") {
                                    setIsLoading(false)
                                    setIsVerifyOpen(true)
                                }
                                else {
                                    setIsLoading(false)
                                }
                            });
                    } else {
                        setIsLoading(false)
                        setAccountAvailable(true)
                    }
                })
        }
    }
    return (
        <div className="Ecommerceregister">
            <div className="Ecommerceregister-container">
                <div className="logo-container">
                    <img
                        onClick={() => {
                            history.push("/")
                        }}
                        alt="" className="logo"
                        src={companyDetails?.Logo} />
                </div>
                <div className="register-form">
                    <Header text={"Register"} fontSize={"26px"} fontWeight={"500"} />
                    <div className="register-sub-text">Creat an account to get started</div>
                    <div className="input-container">
                        <InputComponent
                            isError={errors.email !== ""}
                            errorMessage={errors.email}
                            layout="layout2" placeholder="Email*" value={email} onChange={e => setEmail(e.target.value)} />

                    </div>
                    <div className="register-btn-container">
                        <Button text={"Register"} onClick={handleRegister} width={"inherit"} isLoading={isLoading} />
                    </div>
                    <div className="sign-up-content">
                        Already have an account?  <span
                            onClick={() => { history.push("/account/login") }}
                        >Sign in </span> </div>

                </div>

            </div>
            {isVerifyOpen && <Modal
                header={"Please verify your account"}
                subText={`Verification email has been sent to ${email}`}
                isAlert
                onClick={() => {
                    history.push("/account/verification")
                    setIsVerifyOpen(false)
                }} />}
            {accountAvailable && <Modal
                header={"You have already an account with us"}
                subText={`Please login to continue the shopping`}
                isSuccess
                onClick={() => {
                    history.push("/account/login")
                    setAccountAvailable(false)
                }} />}
        </div>
    )
}
export default EcommerceRegister