import React from "react";
import Button from "../Button/Button";

const BackgroundImageComponent = ({ content }) => {
    const getComponents = (component) => {
        switch (component.ID) {
            case "image-bg-header":
                return <div className="header-casi">
                    {component.Props.content}
                </div>
            case "image-bg-sub-header":
                return <div className="sub-text">
                    {component.Props.content}
                </div>
            case "image-bg-btn":
                return <div className="button-containetr">
                    <Button text={component.Props.content} />
                </div>

            default:
                return <div />
        }

    }
    return (
        <div className="bridal-contet"
            style={{
                backgroundImage:
                    ` url(${content.Props.src})`
            }}>
            <div className="biradal-content-text">

                {content.Components.map((component) => {
                    return getComponents(component)
                })}


            </div>
        </div>
    )
}
export default BackgroundImageComponent