import React from "react";
import "./RestaurantHome.scss"
import HomeHeroOne from "../../components/HomeComponents/HomeHeroOne";
import HomeHeroTwo from "../../components/HomeComponents/HomeHeroTwo";
import HomeHeroThree from "../../components/HomeComponents/HomeHeroThree";
import HomeHeroFour from "../../components/HomeComponents/HomeHeroFour";
import HomeHeroFive from "../../components/HomeComponents/HomeHeroFive";
import HomeHeroSix from "../../components/HomeComponents/HomeHeroSix";
import { sortByOrderAscending } from "../../helper/helper";
import { useSelector } from "react-redux";

const RestaurantHome = () => {

    const pages = useSelector((state) => state.companyData.pages)
    const page = pages.find((page) => page.PageId === "home")
    const Components = page?.Components || []
    const getComponents = (component) => {
        switch (component.ID) {
            case "HomeHeroOne":
                return <HomeHeroOne component={component.Components} />
            case "HomeHeroTwo":
                return <HomeHeroTwo component={component.Components} />

            case "HomeHeroThree":
                return <HomeHeroThree component={component.Components} />

            case "HomeHeroFour":
                return <HomeHeroFour />

            case "HomeHeroFive":
                return <HomeHeroFive component={component.Components} />
            case "HomeHeroSix":
                return <HomeHeroSix component={component.Components} />
            default:
                return <div />
        }

    }
    return (
        <div className="RestaurantHome">
            {Components?.length > 0 && sortByOrderAscending(Components).map((component, key) => {
                return (<div key={key}>
                    {getComponents(component)}
                </div>)
            })}
        </div >
    )
}
export default RestaurantHome