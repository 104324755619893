import React from "react";
import "./HomeComponents.scss"
import Card from "../Card/Card";
import quality from "../../assets/number-one.png"
import open from "../../assets/open-sign.png"
import parking from "../../assets/parking-brake.png"
import chef from "../../assets/chef.png"
import { sortByOrderAscending } from "../../helper/helper";

const HomeHeroSix = ({ component }) => {

    const getIconType = (type) => {
        switch (type) {
            case 'quality':
                return quality
            case 'open':
                return open
            case 'parking':
                return parking
            case 'chef':
                return chef
            default:
                return parking
        }
    }
    const getComponents = (component) => {
        switch (component.ID) {
            case "header":
                return <div className="header-text-jack">{component.Props.content}</div>
            case "content":
                return <div className="menu-component-header">{component.Props.content}</div>
            case "sub-content":
                return <div className="menu-comp-sub-header">{component.Props.content}</div>
            case "service-card":
                return <div className="services-card">
                    {component.Components.map((card, key) => {
                        return (
                            <Card key={key} icon={getIconType(card.Props.iconType)} header={card.Props.content} description={card.Props.subContent} />

                        )
                    })}
                </div>
            case "seperator":
                return <div className="seperator" />
            case "comp-sub-content":
                return <div className="menu-comp-sub-header-2">
                    {component.Props.content}
                </div>
            default:
                return <div />
        }

    }
    return (
        <div className="HomeHeroSix">
            {sortByOrderAscending(component).map((component, key) => {
                return (
                    getComponents(component)
                )
            })}
        </div>
    )
}
export default HomeHeroSix