import React from "react";
import "./Card.scss"
const Card = ({ header, description, icon }) => {
    return (
        <div className="Card">
            <div className="card-container">
                <div className="icon-conte"> {icon && <img alt="" src={icon} className="icon-conatiner" />}</div>
                <div className="card-header-text">{header && header}</div>
                <div className="card-sub-text">{description && description}</div>
            </div>


        </div>
    )
}
export default Card