import React from "react";
import "./Header.scss"

const Header = ({ text, fontSize, textAlign, fontWeight }) => {
    return (
        <div className="HeaderComponent" style={{ fontSize, textAlign, fontWeight }}>
            {text}
        </div>
    )
}
export default Header