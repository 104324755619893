import React, { useState, useEffect } from "react";
import "./Navbar.scss"
import search from "../../assets/search.svg"
import cart from "../../assets/cart.svg"
import wishlist from "../../assets/wishlist.svg"
import menu from "../../assets/menu.svg"
import close from "../../assets/close.svg"
import { useSelector } from "react-redux";
import { useHistory } from "react-router";



const Navbar = ({ setIsOpen, setIsSearchOpen }) => {
    const companyDetails = useSelector((state) => state.companyData.company)
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)

    const history = useHistory()
    const pages = useSelector((state) => state.companyData.pages)
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };
    return (
        <div className="Ecommerce-navbar ">
            <div className={`Ecommerce-navbar-fixed-header  ${isScrolled ? "scrolled" : ""}`}>
                <div className="Ecommerce-navbar-left">
                    <div className="action-icon" onClick={toggleDrawer}>
                        <img alt="" className="icon" src={menu} />
                    </div>
                    <img alt="" className="logo-container"
                        onClick={() => {
                            history.push("/")
                            window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                            });
                        }}
                        src={companyDetails?.Logo} />
                </div>
                <div className={`Ecommerce-mobile-navbar-drawer  ${isDrawerOpen ? 'open' : ''}`}>
                    <div className="Ecommerce-mobile-navbar-drawer-header">
                        <img alt="" className="logo-container"
                            onClick={() => {
                                toggleDrawer()
                                history.push("/")
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }}
                            src={companyDetails?.Logo} />
                        <div className="action-icon" onClick={toggleDrawer}>
                            <img alt="" className="icon" src={close} />
                        </div>
                    </div>
                    {pages.map((page, key) => {
                        return (
                            <div key={key} className="drawer-menu" onClick={() => {
                                toggleDrawer()
                                history.push(page.Slug)
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }}>
                                {page.Title}
                            </div>
                        )
                    })}
                </div>
                <div className="Ecommerce-navbar-center">
                    {pages.map((page, key) => {
                        return (
                            <div key={key} className="header-title" onClick={() => {
                                history.push(page.Slug)
                                window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            }}>
                                {page.Title}
                            </div>
                        )
                    })}
                </div>  <div className="Ecommerce-navbar-right">
                    <div className="action-icon" onClick={() => {
                        setIsSearchOpen(true)
                    }}>
                        <img alt="" className="icon" src={search} />
                    </div>
                    <div className="action-icon"
                        onClick={() => {
                            history.push("/pages/wishlist")
                        }}>
                        <img alt="" className="icon" src={wishlist} />
                        <div className="count-cont">2</div>
                    </div>
                    <div className="action-icon" onClick={() => {
                        setIsOpen(true)
                    }}>
                        <img alt="" className="icon" src={cart} />
                        <div className="count-cont">2</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Navbar