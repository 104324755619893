import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedRestaurantMainMenu, fetchAllRestaurantMenuData } from "../../../../../../../redux/resturantWidgetSlice";
import "./MenuTabs.scss"
import moment from 'moment-timezone';

const MenuTabs = ({ children, isPopupWidget, onSelect, selectedItem, tabRefs }) => {
    const dispatch = useDispatch();

    const { menuData: { storage: { mainMenus, data } }, defualtSelectedLocation, organizationID } = useSelector((state) => state.resturantData);

    const handleActiveTab = (menuID) => {
        dispatch(changeSelectedRestaurantMainMenu({ mainMenuID: menuID }));
        dispatch(fetchAllRestaurantMenuData({
            mainMenuID: menuID,
            CloudLocationID: defualtSelectedLocation.CloudLocationID,
            OrganizationID: organizationID,
        }));

    }
    const isCurrentTimeInRange = (timeData) => {
        const now = moment.utc();
        const currentDay = now.format('dddd');

        const dayData = timeData[currentDay];
        if (!dayData) return false;

        const startTime = moment.utc(dayData.StartTime, 'HH:mm');
        const endTime = moment.utc(dayData.EndTime, 'HH:mm');
        return now.isBetween(startTime, endTime, null, '[]');
    };
    return (
        <div className="Hospitality-navbar-MenuTabs-Menu">
            <div className='tab-headers'>
                {mainMenus && mainMenus.length > 0 && mainMenus.map((item, index) => {
                    const found = data.Menu.find((menu) => menu.MenuID === item.menuID).MenuAvailability

                    return (
                        isCurrentTimeInRange(found) && <div
                            key={item.menuID}
                            onClick={() => {
                                onSelect(item.menuID);
                                handleActiveTab(item.menuID)

                            }}
                            ref={(el) => (tabRefs.current[item.menuID] = el)}
                            className={selectedItem === item.menuID ? 'tab-item selected-tab-item' : 'tab-item'}>
                            {item.menuName.toCapitalFirst()}
                        </div>
                    )
                })}
            </div>
            <div className={isPopupWidget ? 'tab-content-container tab-popup' : 'tab-content-container'}>
                {children}
            </div>
        </div >
    )
}
export default MenuTabs