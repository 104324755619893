import React, { useState } from "react";
import "./EcommerceLogin.scss"
import Header from "../../components/Header/Header";
import InputComponent from "../../components/InputComponent/InputComponent";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import globalValues from "../../global/globalValues";
import jwt from 'jsonwebtoken';
import Modal from "../../components/Modal/Modal";
import { useHistory } from "react-router";
import apiEndpointPaths from "../../helper/apiEndpointPaths";


const EcommerceLogin = () => {
    const companyDetails = useSelector((state) => state.companyData.company)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({ email: "", password: "" })
    const [isError, setIsError] = useState(false)

    const history = useHistory()
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const validateForm = () => {
        let valid = true;
        const newErrors = {
            password: "",
            email: "",
        };

        if (password.trim() === "") {
            newErrors.password = "*required";
            valid = false;
        }
        if (email.trim() === "") {
            newErrors.email = "*required";
            valid = false;
        } else if (!validateEmail(email)) {
            newErrors.email = "*Invalid email format";
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    }
    const handleClear = () => {
        setEmail("")
        setPassword("")
        setErrors({
            password: "",
            email: "",
        })
        setIsError(false)
    }
    const handleLogin = async () => {
        if (validateForm() && !isLoading) {
            setIsLoading(true)
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ` + globalValues.keys.REACT_APP_LoginAuthorizationKey,
                },
                body: JSON.stringify({ UserName: email, Password: password, OrganizationID: companyDetails?.OrganisationID })
            };
            fetch(apiEndpointPaths.orgCustomerRegistry.loginUser, requestOptions)
                .then(response => response.json())
                .then(function (data) {
                    if (data.Status === false) {
                        setIsLoading(false)
                        setIsError(true)

                    }
                    else {

                        localStorage.setItem("isLogged", "true")
                        const expireTime = new Date().getTime() + (globalValues.jwtKeys.REACT_APP_CustjwtExp * 1000)

                        var payload = {
                            "aud": globalValues.jwtKeys.REACT_APP_CustjwtAud,
                            "exp": expireTime,
                            "iss": globalValues.jwtKeys.REACT_APP_CustjwtIss,
                            "usertoken": data.Result.AccessToken
                        };

                        var signOptions = {
                            "algorithm": "RS256"
                        };

                        var privateKey = globalValues.keys.REACT_APP_LoginprivateKey;
                        var token = jwt.sign(payload, privateKey, signOptions);

                        const requestUserDtlsOptions = {
                            method: 'POST',
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ` + token,
                            },
                            body: JSON.stringify({ "Email": email.toLowerCase(), "OrganizationID": companyDetails?.OrganisationID })
                        };

                        fetch(apiEndpointPaths.orgCustomerRegistry.getUser, requestUserDtlsOptions)
                            .then(response => response.json())
                            .then(function (data) {
                                setIsLoading(false)
                                localStorage.setItem('fullUserDetails', JSON.stringify(data.Result));
                                history.push("/pages/checkout")
                            });


                    }
                })
        }
    }
    return (
        <div className="EcommerceLogin">
            <div className="EcommerceLogin-container">
                <div className="logo-container">
                    <img
                        onClick={() => {
                            history.push("/")
                        }}
                        alt="" className="logo"
                        src={companyDetails?.Logo} />
                </div>
                <div className="login-form">
                    <Header text={"Log in"} fontSize={"26px"} fontWeight={"500"} />
                    <div className="login-sub-text">Log in to your account to get started</div>
                    <div className="input-container">
                        <InputComponent
                            isError={errors.email !== ""}
                            errorMessage={errors.email}
                            layout="layout2" placeholder="Email*" value={email} onChange={e => setEmail(e.target.value)} />

                    </div>
                    <div className="input-container">
                        <InputComponent
                            isError={errors.password !== ""}
                            errorMessage={errors.password}
                            type="password" layout="layout2" placeholder="Password*" value={password} onChange={e => setPassword(e.target.value)} />

                    </div>
                    <div></div>
                    <div className="login-btn-container">
                        <Button text={"Log in"} onClick={handleLogin} width={"inherit"} isLoading={isLoading} />
                    </div>
                    <div className="sign-up-content">
                        Don’t have an account?  <span onClick={() => {
                            history.push("/account/register")
                        }} >Sign up </span> </div>

                </div>

            </div>
            {isError && <Modal header={"Invalid Login"} subText={"Invalid username or password"}
                isFailed
                onClick={() => {
                    handleClear()
                }} />}
        </div>
    )
}
export default EcommerceLogin