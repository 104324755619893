import React from "react";
import "./CartItem.scss"
import plus from "../../../assets/plus.svg"
import minus from "../../../assets/minus.svg"
const CartItem = ({ item, onDelete }) => {
    return (
        <div className="CartItem">
            <div className="CartItem-Image">
                <img alt="" src={item.image} />
            </div>
            <div className="CartItem-details">
                <div className="CartItem-title">{item.title}</div>
                <div className="CartItem-price"> £{item.price}</div>
                <div className="item-container">
                    <div className="qty-container">
                        <div className="qty-cont-action "><img alt="" src={minus} /></div>
                        <div className="qty-cont-item">1</div>
                        <div className="qty-cont-action "><img alt="" src={plus} /></div>
                    </div>
                    <div className="delete" onClick={onDelete}>Remove</div>
                </div>
            </div>
        </div>
    )
}
export default CartItem