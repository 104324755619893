import React, { useState } from "react";
import "./VoucherMenuDefaultAuth.scss"
import { InputBox, Label } from 'witmeg-ui-system'
import { useHistory } from 'react-router'
import Modal from "../../components/Modal/Modal";
import { renderEmail } from 'postonents';
import config from 'react-global-configuration';
import _ from "lodash";
import VerificationEmail from "../../../../../../../email/VerificationEmail";

const VoucherMenuDefaultRegister = () => {
    let history = useHistory();

    const [email, setEmail] = useState("")
    const [accountAvailable, setAccountAvailable] = useState(false)
    const [isVerifyOpen, setIsVerifyOpen] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isEmailError, setIsEmailError] = useState("")
    const validateEmail = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const validateForm = () => {
        let valid = true;
        let newErrors = ""
        if (email.trim() === "") {
            newErrors = "*required";
            valid = false;
        } else if (!validateEmail(email)) {
            newErrors = "*Invalid email format";
            valid = false;
        }
        console.log(newErrors, "newErrors")
        setIsEmailError(newErrors);
        return valid;
    };
    const encrypt = (data) => {
        var CryptoJS = require("crypto-js");
        var key = 'U2FsdGVkX1y3pF1AfoHaMGdUJpg6nOMru98BUt';
        var cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
        return cipherText;
    }
    const handleRegister = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ` + config.get('LoginAuthorizationKey'),
            },
            body: JSON.stringify({ "Email": email, "OrganizationID": config.get('OrganisationID') })
        };
        fetch(config.get('API_URL_cust') + 'find', requestOptions)
            .then(response => response.json())
            .then(function (data) {
                if (data.Status === false) {
                    sessionStorage.setItem('tmpEmail', email);


                    let DigitCode = _.random(100000, 999999);
                    let token = encrypt(JSON.stringify(DigitCode));

                    const data = {
                        verificationToken: token,
                        companyPageTitle: config.get('companyPageTitle'),
                        SenderEmail: config.get('SenderEmail'),
                        companyLogoCDN: config.get('companyLogoCDN'),
                        DigitCode: DigitCode,
                        email: email
                    }
                    const emailBody = renderEmail(VerificationEmail, { lang: 'en', data });

                    sessionStorage.setItem('verificationtoken', token);
                    sessionStorage.setItem('tmpEmail', email);

                    async function postData() {
                        const ecoEmail = {
                            "to": email,
                            "from": config.get('companyPageTitle') + "<" + config.get('SenderEmail') + ">",
                            "subject": "Your verification code",
                            "html": emailBody
                        };
                        const response = await fetch(config.get('backendURL') + 'mail/send', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(ecoEmail)
                        })
                        return response.json();
                    }

                    postData()
                        .then(data => {
                            if (data === "SUCCESS") {
                                setIsLoading(false)
                                setIsVerifyOpen(true)
                            }
                            else {
                                setIsLoading(false)
                                setIsError(true)
                            }
                        });
                } else {
                    setIsLoading(false)
                    setAccountAvailable(true)
                }
            })
    };
    return (
        <div className="VoucherMenuDefaultAuth">
            <div className="VoucherMenuDefaultAuth-inner">
                <div className="header-text-cont">Register for online shopping</div>
                <div className="auth-container-wrapper">
                    <div className="auth-container-wrapper-left">
                        <div className="auth-container-left-inner">
                            <div className="input-containers ">
                                <div className="form-label-wrapper"><span>*</span><Label className="label-text">Email Address</Label></div>
                                <InputBox onChange={(e) => {
                                    setEmail(e.target.value);
                                    setIsEmailError("")
                                }} type="text" value={email} error={isEmailError} />

                            </div>
                            <div className="button-conatiner" onClick={() => {
                                if (!isLoading && validateForm()) {
                                    setIsLoading(true)
                                    handleRegister()
                                }
                            }}>{isLoading && <div className="loader-btn" />}Register</div>
                            <div className="reg-link">
                                <div className="reg-text">Already have an account?</div>
                                <div className="reg-link-text" onClick={() => {
                                    history.push("/account/login")
                                }}>Sign in</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Modal visibility={accountAvailable} type="success" headerText="You have already an account with us" subText="Please login to continue the shopping" onPopupClose={() => {
                setAccountAvailable(false);
                setEmail("")
            }} />
            <Modal visibility={isVerifyOpen} type="failed" headerText="Please verify your account" subText={`Verification email has been sent to ${email}`} onPopupClose={() => {
                setIsVerifyOpen(false);
                history.push("/account/verify")
            }} />
            <Modal visibility={isError} type="failed" headerText="Oops!! Something wrong" subText={`We are sorry, Something wrong with the registration process. Please try again later.'`} onPopupClose={() => { setIsError(false) }} />

        </div>
    )
}
export default VoucherMenuDefaultRegister