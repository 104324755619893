import React from "react";
import "./HomeComponents.scss"
import dish from "../../assets/serving-dish.png"
import Button from "../Button/Button";
import { useSelector } from "react-redux"
import { sortByOrderAscending } from "../../helper/helper";

const HomeHeroThree = ({ component }) => {
    const companyDetails = useSelector((state) => state.companyData.company)


    const OpeningHours = () => {
        return (

            companyDetails?.OpeningHour?.map((open, key) => {
                return (<div key={key} className="opening-hours-text">
                    <div>{open.Day}</div><div>{open?.Closed ? "Closed" : open.OpenHours?.map((date) => {
                        return (
                            <div>{date.StartTime + " - " + date.EndTime}</div>
                        )
                    })}</div>
                </div>)
            })

        )
    }
    const getTextComponents = (component) => {
        switch (component.ID) {
            case "header-text-jack":
                return <div className="header-text-jack">{component.Props.content}</div>
            case "opening-hours-container":
                return OpeningHours()

            case "Button":
                return <div className="btn-container">
                    <Button text={component.Props.text} />
                </div>
            default:
                return <div />
        }

    }
    const getLeftContent = (components) => {
        return (
            <div className="HomeHerothree-left">
                <div className="homethreee-container">
                    <div className="homethree-left-contnet">
                        <div className="image-icon" >
                            <img alt="" src={dish} />

                        </div>
                        {sortByOrderAscending(components).map((component, key) => {
                            return (<div key={key}>
                                {getTextComponents(component)}
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        )
    }
    const getComponents = (component) => {
        switch (component.ID) {
            case "res-conrainer":
                return getLeftContent(component.Components)
            case "bg-image":
                return <div className="HomeHerothree-right">
                    <img alt="" src={component.Props.src} />
                </div>

            default:
                return <div />
        }

    }
    return (
        <div className="HomeHeroThree">

            {sortByOrderAscending(component).map((component, key) => {
                return (getComponents(component))

            })}
        </div>
    )
}
export default HomeHeroThree