import React, { useState } from 'react';
import './Cart.scss';
import Header from '../Header/Header';
import close from "../../assets/close.svg"
import Button from '../Button/Button';
import CartItem from './CartItem/CartItem';
import { useHistory } from 'react-router';

const Cart = ({ isOpen, setIsOpen }) => {
    const history = useHistory()
    const products = [
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616161936148.jpeg",
            "title": "Mangalya Silk ",
            price: "100.00",
            id: 1,
        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616162869639.jpeg",
            "title": "Thirumana Silk ",
            price: "120.00",
            id: 2,
        },
    ]
    const [items, setItems] = useState(products)
    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };
    const handleDelete = (item) => {
        const updated = items.filter((product) => product.id !== item.id)
        setItems(updated)
    }

    return (
        <div className="sliding-drawer-container">
            <div className={`drawer ${isOpen ? 'open' : ''}`}>
                <div className="drawer-content">
                    <div className='header-conatiner'><Header text={"Item added to your cart"} fontSize={"18px"} />
                        <div className='close-cont' onClick={toggleDrawer} ><img alt='' src={close} /></div></div>
                    {items.length === 0 && <div className='empty-cart'>
                        <Header text={"Your cart is empty"} fontSize={"22px"} />
                        <Button text={"Continue Shopping"} invert width={"100%"}
                            onClick={() => {
                                toggleDrawer()
                            }} />

                    </div>}
                    <div className='items-container'>
                        {items.map((product, key) => {
                            return (
                                <CartItem item={product} key={key} onDelete={() => {
                                    handleDelete(product)
                                }} />

                            )
                        })}
                    </div>
                </div>
                {items.length > 0 && <div className='bottom-container-div'>
                    <div className='price-container'>
                        <Header text="Subtotal" fontSize={"26px"} />
                        <div className='price-cont'> £200.00</div>
                    </div>
                    <div className='button-container'>
                        <Button text={"Back"} invert width={"100%"}
                            onClick={() => {
                                toggleDrawer()
                            }} />
                        <Button text={"Check Out"} width={"100%"} onClick={() => {
                            history.push("/pages/checkout")
                        }} />
                    </div>

                </div>}
            </div>
        </div>
    );
};

export default Cart;
