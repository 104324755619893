import React from "react";
import Button from "../Button/Button";
import "./HomeComponents.scss"
import { sortByOrderAscending } from "../../helper/helper";
import { useHistory } from "react-router";

const HomeHeroFive = ({ component }) => {
    const history = useHistory()
    const getComponents = (component) => {
        switch (component.ID) {
            case "grid-image":
                return <div className="gallery-container">
                    {component.Props.images.map((image, key) => {
                        return (<div key={key} className="image-cont">
                            <img alt="" src={image} />
                        </div>)
                    })}
                </div>
            case "Button":
                return <div className="btn-container">
                    <Button text={component.Props.text} onClick={() => {
                        history.push("/pages/gallery")
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                    }} />
                </div>
            default:
                return <div />
        }

    }
    return (
        <div className="HomeHeroFive">
            <div className="HomeHeroFive-Content">
                {sortByOrderAscending(component).map((component, key) => {
                    return (<div key={key}>
                        {getComponents(component)}
                    </div>)
                })}

            </div>
        </div>
    )
}
export default HomeHeroFive