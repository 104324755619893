import React, { useState } from "react";
import "./Accordion.scss";
import plus from "../../assets/plus.svg"
import minus from "../../assets/minus.svg"

import eye from "../../assets/eye.svg"



const Accordion = ({ items, icon, isOpen, isSmall }) => {
    const [activeIndex, setActiveIndex] = useState(isOpen ? 0 : null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="accordion">
            {items.map((item, index) => (
                <div className="accordion-item" key={index}>
                    <div
                        className={`accordion-header ${activeIndex === index ? "active" : ""
                            }`}
                        onClick={() => toggleAccordion(index)}
                    >
                        <div className={isSmall ? "header-content-small" : "header-content"}>
                            {icon && <img alt="" src={eye} />}
                            {item.title}
                        </div>
                        <div className="accordion-icon">
                            <img alt="" src={activeIndex === index ? minus : plus} />
                        </div>
                    </div>
                    <div
                        className={`accordion-content ${activeIndex === index ? "open" : ""
                            }`}
                    >
                        {item.content}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Accordion;
