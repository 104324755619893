import React, { useState } from 'react';
import "./Layout.scss"
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Button from "../../Button/Button"
import imagePlaceholder from "../../../assets/image-placeholder.png"
const Layout = ({ layout, limit, navigate, showHeader }) => {
    const history = useHistory();



    const Categories = {
        "Categories": [

            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "ID": "67c7f02f9ba8a88fba2d2f97",
                "MenuCategoryID": "67c1c0cb37102c0b0d9885e5-PIZZA",
                "MenuEntities": [
                    {
                        "ID": "67c1c0fb37102c0b0d9886e5-MARGHERITHA PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0fc37102c0b0d9886e7-VEGETARIAN PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0fc37102c0b0d9886e9-VEGETARIAN HOT PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c15437102c0b0d98891e-MASALA HOT PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0fd37102c0b0d9886eb-DESI STYLE PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0fd37102c0b0d9886ed-VEGETARIAN SUPREME PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0fd37102c0b0d9886ef-PUNJABI STYLE GARLIC PIZZA BREAD",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0fe37102c0b0d9886f1-PUNJABI SPICY PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10237102c0b0d98870b-PANEER PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10337102c0b0d988713-CHILLI PANEER PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c15537102c0b0d988924-VEGGIE CHILLI TURMERIC PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0fe37102c0b0d9886f3-HAWAIIAN PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0fe37102c0b0d9886f5-PEPPERONI LOVER PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0ff37102c0b0d9886f7-PEPPERONI SPECIAL PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0ff37102c0b0d9886f9-HOT HOUSE SPECIAL PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c0ff37102c0b0d9886fb-MEAT FEAST PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10037102c0b0d9886fd-BBQ MEAT FEAST PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10037102c0b0d9886ff-BBQ CHICKEN PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10337102c0b0d98870f-BBQ MEAT LOVER PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10037102c0b0d988701-CHICKEN SPECIAL PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10137102c0b0d988703-TANDOORI CHICKEN HOT PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10137102c0b0d988705-AMERICAN HOT PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10137102c0b0d988707-KEBAB SPECIAL PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10337102c0b0d988711-GARLIC SPICIAL PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10237102c0b0d988709-KING CHICKEN PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10237102c0b0d98870d-DESI CHICKEN PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c15637102c0b0d98892a-MASALA CHICKEN PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10437102c0b0d988715-BUFFALO CHICKEN PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c15737102c0b0d988930-CHILLI CHICKEN PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10437102c0b0d988717-PERI PERI PANEER PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c10537102c0b0d988719-PANEER TIKKA MASALA PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "ID": "67c1c16037102c0b0d988966-HOT HONEY PEPPERONI",
                        "Type": "ITEM"
                    }
                ],
                "MenuID": "67c1c0c537102c0b0d9885b9-PIZZA",
                "MetaData": {
                    "Name": "PIZZA",
                    "RefNo": "67c1c0cb37102c0b0d9885e5"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": {
                    "en": ""
                },
                "Title": {
                    "en": "PIZZA"
                }
            },
        ],
        "Items": [
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "GARLIC BREAD(4 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d304e",
                "ImageURL": "",
                "MenuItemID": "67c1c0ce37102c0b0d988606-GARLIC BREAD(4 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0ce37102c0b0d988606",
                    "ProductName": "GARLIC BREAD(4 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 250,
                        "PickupPrice": 250,
                        "TablePrice": 250
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GARLIC BREAD(4 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "GARLIC BREAD WITH CHEESE(4 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d304f",
                "ImageURL": "",
                "MenuItemID": "67c1c0cf37102c0b0d98860a-GARLIC BREAD WITH CHEESE(4 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0cf37102c0b0d98860a",
                    "ProductName": "GARLIC BREAD WITH CHEESE(4 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 300,
                        "PickupPrice": 300,
                        "TablePrice": 300
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GARLIC BREAD WITH CHEESE(4 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "GARLIC BREAD WITH CHEESE & GREEN CHILLI(4 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3050",
                "ImageURL": "",
                "MenuItemID": "67c1c0cf37102c0b0d98860c-GARLIC BREAD WITH CHEESE & GREEN CHILLI(4 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0cf37102c0b0d98860c",
                    "ProductName": "GARLIC BREAD WITH CHEESE & GREEN CHILLI(4 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 350,
                        "PickupPrice": 350,
                        "TablePrice": 350
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GARLIC BREAD WITH CHEESE & GREEN CHILLI(4 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "GARLIC BREAD WITH CHEESE SHAREBOX(20 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3051",
                "ImageURL": "",
                "MenuItemID": "67c1c0cf37102c0b0d98860e-GARLIC BREAD WITH CHEESE SHAREBOX(20 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0cf37102c0b0d98860e",
                    "ProductName": "GARLIC BREAD WITH CHEESE SHAREBOX(20 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1299,
                        "PickupPrice": 1299,
                        "TablePrice": 1299
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GARLIC BREAD WITH CHEESE SHAREBOX(20 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "GARLIC BREAD WITH CHEESE WITH 1 TOPPING"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3052",
                "ImageURL": "",
                "MenuItemID": "67c1c0d037102c0b0d988610-GARLIC BREAD WITH CHEESE WITH 1 TOPPING",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d037102c0b0d988610",
                    "ProductName": "GARLIC BREAD WITH CHEESE WITH 1 TOPPING"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GARLIC BREAD WITH CHEESE WITH 1 TOPPING"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "POTATO WEDGES"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3053",
                "ImageURL": "",
                "MenuItemID": "67c1c0d037102c0b0d988612-POTATO WEDGES",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d037102c0b0d988612",
                    "ProductName": "POTATO WEDGES"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 450,
                        "PickupPrice": 450,
                        "TablePrice": 450
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "POTATO WEDGES"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "ONION RINGS (10 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3054",
                "ImageURL": "",
                "MenuItemID": "67c1c0d037102c0b0d988614-ONION RINGS (10 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d037102c0b0d988614",
                    "ProductName": "ONION RINGS (10 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 350,
                        "PickupPrice": 350,
                        "TablePrice": 350
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "ONION RINGS (10 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "JALAPENO CREAM CHEESE (6 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3055",
                "ImageURL": "",
                "MenuItemID": "67c1c0d137102c0b0d988616-JALAPENO CREAM CHEESE (6 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d137102c0b0d988616",
                    "ProductName": "JALAPENO CREAM CHEESE (6 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 449,
                        "PickupPrice": 449,
                        "TablePrice": 449
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "JALAPENO CREAM CHEESE (6 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "MOZZARELLA STICKS(6 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3056",
                "ImageURL": "",
                "MenuItemID": "67c1c0d137102c0b0d988618-MOZZARELLA STICKS(6 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d137102c0b0d988618",
                    "ProductName": "MOZZARELLA STICKS(6 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 449,
                        "PickupPrice": 449,
                        "TablePrice": 449
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MOZZARELLA STICKS(6 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "CHEESY GARLIC STICKS (12 INCH)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3057",
                "ImageURL": "",
                "MenuItemID": "67c1c0d137102c0b0d98861a-CHEESY GARLIC STICKS (12 INCH)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d137102c0b0d98861a",
                    "ProductName": "CHEESY GARLIC STICKS (12 INCH)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 849,
                        "PickupPrice": 849,
                        "TablePrice": 849
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHEESY GARLIC STICKS (12 INCH)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "CHILLI CHEESE GARLIC STICKS (12 INCH)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3058",
                "ImageURL": "",
                "MenuItemID": "67c1c0d237102c0b0d98861c-CHILLI CHEESE GARLIC STICKS (12 INCH)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d237102c0b0d98861c",
                    "ProductName": "CHILLI CHEESE GARLIC STICKS (12 INCH)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI CHEESE GARLIC STICKS (12 INCH)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "CHILLI CHEESE BITES (6 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3059",
                "ImageURL": "",
                "MenuItemID": "67c1c0d237102c0b0d98861e-CHILLI CHEESE BITES (6 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d237102c0b0d98861e",
                    "ProductName": "CHILLI CHEESE BITES (6 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 349,
                        "PickupPrice": 349,
                        "TablePrice": 349
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI CHEESE BITES (6 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "CHIPS "
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d305a",
                "ImageURL": "",
                "MenuItemID": "67c1c0d237102c0b0d988621-CHIPS ",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d237102c0b0d988621",
                    "ProductName": "CHIPS "
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 350,
                        "PickupPrice": 350,
                        "TablePrice": 350
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHIPS "
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "CURLY FRIES"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d305b",
                "ImageURL": "",
                "MenuItemID": "67c1c0d337102c0b0d988623-CURLY FRIES",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d337102c0b0d988623",
                    "ProductName": "CURLY FRIES"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 450,
                        "PickupPrice": 450,
                        "TablePrice": 450
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CURLY FRIES"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "CHILLI CHIPS"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d305c",
                "ImageURL": "",
                "MenuItemID": "67c1c0d337102c0b0d988625-CHILLI CHIPS",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d337102c0b0d988625",
                    "ProductName": "CHILLI CHIPS"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 499,
                        "PickupPrice": 499,
                        "TablePrice": 499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI CHIPS"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "CHICKEN NUGGETS (6 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d305d",
                "ImageURL": "",
                "MenuItemID": "67c1c0d337102c0b0d988627-CHICKEN NUGGETS (6 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d337102c0b0d988627",
                    "ProductName": "CHICKEN NUGGETS (6 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 350,
                        "PickupPrice": 350,
                        "TablePrice": 350
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN NUGGETS (6 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "CHICKEN DIPPERS (6 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d305e",
                "ImageURL": "",
                "MenuItemID": "67c1c0d437102c0b0d988629-CHICKEN DIPPERS (6 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d437102c0b0d988629",
                    "ProductName": "CHICKEN DIPPERS (6 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 550,
                        "PickupPrice": 550,
                        "TablePrice": 550
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN DIPPERS (6 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "SPICY CHICKEN DIPPERS (6 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d305f",
                "ImageURL": "",
                "MenuItemID": "67c1c0d437102c0b0d98862b-SPICY CHICKEN DIPPERS (6 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d437102c0b0d98862b",
                    "ProductName": "SPICY CHICKEN DIPPERS (6 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 550,
                        "PickupPrice": 550,
                        "TablePrice": 550
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SPICY CHICKEN DIPPERS (6 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "CHICKEN STRIPS(6 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3060",
                "ImageURL": "",
                "MenuItemID": "67c1c0d437102c0b0d98862d-CHICKEN STRIPS(6 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d437102c0b0d98862d",
                    "ProductName": "CHICKEN STRIPS(6 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN STRIPS(6 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "WINGS (7 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3061",
                "ImageURL": "",
                "MenuItemID": "67c1c0d637102c0b0d988638-WINGS (7 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d637102c0b0d988638",
                    "ProductName": "WINGS (7 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "WINGS (7 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "WINGS (10 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3062",
                "ImageURL": "",
                "MenuItemID": "67c1c0d637102c0b0d98863a-WINGS (10 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d637102c0b0d98863a",
                    "ProductName": "WINGS (10 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "WINGS (10 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "WINGS (15 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3063",
                "ImageURL": "",
                "MenuItemID": "67c1c0d737102c0b0d98863d-WINGS (15 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d737102c0b0d98863d",
                    "ProductName": "WINGS (15 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 999,
                        "PickupPrice": 999,
                        "TablePrice": 999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "WINGS (15 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "WINGS (20 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3064",
                "ImageURL": "",
                "MenuItemID": "67c1c0d737102c0b0d98863f-WINGS (20 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0d737102c0b0d98863f",
                    "ProductName": "WINGS (20 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1299,
                        "PickupPrice": 1299,
                        "TablePrice": 1299
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "WINGS (20 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "WINGS (25 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3065",
                "ImageURL": "",
                "MenuItemID": "67c1c0e737102c0b0d988658-WINGS (25 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0e737102c0b0d988658",
                    "ProductName": "WINGS (25 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1499,
                        "PickupPrice": 1499,
                        "TablePrice": 1499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "WINGS (25 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "WINGS (30 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3066",
                "ImageURL": "",
                "MenuItemID": "67c1c0e837102c0b0d98865a-WINGS (30 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0e837102c0b0d98865a",
                    "ProductName": "WINGS (30 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1798,
                        "PickupPrice": 1798,
                        "TablePrice": 1798
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "WINGS (30 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "WINGS (40 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3067",
                "ImageURL": "",
                "MenuItemID": "67c1c0e837102c0b0d98865c-WINGS (40 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0e837102c0b0d98865c",
                    "ProductName": "WINGS (40 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 2399,
                        "PickupPrice": 2399,
                        "TablePrice": 2399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "WINGS (40 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "WINGS (50 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3068",
                "ImageURL": "",
                "MenuItemID": "67c1c0e837102c0b0d98865f-WINGS (50 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0e837102c0b0d98865f",
                    "ProductName": "WINGS (50 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 2999,
                        "PickupPrice": 2999,
                        "TablePrice": 2999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "WINGS (50 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": " BUFFALO WINGS (7 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3069",
                "ImageURL": "",
                "MenuItemID": "67c1c15937102c0b0d988938- BUFFALO WINGS (7 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c15937102c0b0d988938",
                    "ProductName": " BUFFALO WINGS (7 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": " BUFFALO WINGS (7 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "BUFFALO WINGS ( 10 PCS)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d306a",
                "ImageURL": "",
                "MenuItemID": "67c1c15937102c0b0d98893a-BUFFALO WINGS ( 10 PCS)",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c15937102c0b0d98893a",
                    "ProductName": "BUFFALO WINGS ( 10 PCS)"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 799,
                        "PickupPrice": 799,
                        "TablePrice": 799
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BUFFALO WINGS ( 10 PCS)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1305473Z",
                "Description": {
                    "en": "CHICKEN FILLET BURGER"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d306b",
                "ImageURL": "",
                "MenuItemID": "67c1c0e937102c0b0d988665-CHICKEN FILLET BURGER",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0e937102c0b0d988665",
                    "ProductName": "CHICKEN FILLET BURGER"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1305473Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 400,
                        "PickupPrice": 400,
                        "TablePrice": 400
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN FILLET BURGER"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "1/4 POUNDER WITH CHEESE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d306c",
                "ImageURL": "",
                "MenuItemID": "67c1c0ea37102c0b0d988667-1-4 POUNDER WITH CHEESE",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0ea37102c0b0d988667",
                    "ProductName": "1/4 POUNDER WITH CHEESE"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 400,
                        "PickupPrice": 400,
                        "TablePrice": 400
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "1/4 POUNDER WITH CHEESE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "1/2 POUNDER WITH CHEESE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d306d",
                "ImageURL": "",
                "MenuItemID": "67c1c0ea37102c0b0d988669-1-2 POUNDER WITH CHEESE",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0ea37102c0b0d988669",
                    "ProductName": "1/2 POUNDER WITH CHEESE"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 500,
                        "PickupPrice": 500,
                        "TablePrice": 500
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "1/2 POUNDER WITH CHEESE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "VEGETARIAN BURGER"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d306e",
                "ImageURL": "",
                "MenuItemID": "67c1c0ea37102c0b0d98866b-VEGETARIAN BURGER",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0ea37102c0b0d98866b",
                    "ProductName": "VEGETARIAN BURGER"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 350,
                        "PickupPrice": 350,
                        "TablePrice": 350
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "VEGETARIAN BURGER"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CHICKEN STEAK BURGER"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d306f",
                "ImageURL": "",
                "MenuItemID": "67c1c0eb37102c0b0d98866d-CHICKEN STEAK BURGER",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0eb37102c0b0d98866d",
                    "ProductName": "CHICKEN STEAK BURGER"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 250,
                        "PickupPrice": 250,
                        "TablePrice": 250
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN STEAK BURGER"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CHICKEN FILLET WITH CHEESE & BACON"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3070",
                "ImageURL": "",
                "MenuItemID": "67c1c0eb37102c0b0d98866f-CHICKEN FILLET WITH CHEESE & BACON",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0eb37102c0b0d98866f",
                    "ProductName": "CHICKEN FILLET WITH CHEESE & BACON"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 499,
                        "PickupPrice": 499,
                        "TablePrice": 499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN FILLET WITH CHEESE & BACON"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CHILLI CHEESE CHICKEN BURGER"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3071",
                "ImageURL": "",
                "MenuItemID": "67c1c0eb37102c0b0d988672-CHILLI CHEESE CHICKEN BURGER",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0eb37102c0b0d988672",
                    "ProductName": "CHILLI CHEESE CHICKEN BURGER"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 450,
                        "PickupPrice": 450,
                        "TablePrice": 450
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI CHEESE CHICKEN BURGER"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CHICKEN FILLET BUGER MEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3072",
                "ImageURL": "",
                "MenuItemID": "67c1c15d37102c0b0d988952-CHICKEN FILLET BUGER MEAL",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c15d37102c0b0d988952",
                    "ProductName": "CHICKEN FILLET BUGER MEAL"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 550,
                        "PickupPrice": 550,
                        "TablePrice": 550
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN FILLET BUGER MEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "1/4 POUNDER WITH CHEESE MEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3073",
                "ImageURL": "",
                "MenuItemID": "67c1c15d37102c0b0d988954-1-4 POUNDER WITH CHEESE MEAL",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c15d37102c0b0d988954",
                    "ProductName": "1/4 POUNDER WITH CHEESE MEAL"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 550,
                        "PickupPrice": 550,
                        "TablePrice": 550
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "1/4 POUNDER WITH CHEESE MEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "1/2 POUNDER WITH CHEESE MEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3074",
                "ImageURL": "",
                "MenuItemID": "67c1c15d37102c0b0d988956-1-2 POUNDER WITH CHEESE MEAL",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c15d37102c0b0d988956",
                    "ProductName": "1/2 POUNDER WITH CHEESE MEAL"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 675,
                        "PickupPrice": 675,
                        "TablePrice": 675
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "1/2 POUNDER WITH CHEESE MEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "VEGETARIAN BUGER MEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3075",
                "ImageURL": "",
                "MenuItemID": "67c1c15e37102c0b0d988958-VEGETARIAN BUGER MEAL",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c15e37102c0b0d988958",
                    "ProductName": "VEGETARIAN BUGER MEAL"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 500,
                        "PickupPrice": 500,
                        "TablePrice": 500
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "VEGETARIAN BUGER MEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CHICKEN STEAK BUGER MEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3076",
                "ImageURL": "",
                "MenuItemID": "67c1c15e37102c0b0d98895a-CHICKEN STEAK BUGER MEAL",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c15e37102c0b0d98895a",
                    "ProductName": "CHICKEN STEAK BUGER MEAL"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 450,
                        "PickupPrice": 450,
                        "TablePrice": 450
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN STEAK BUGER MEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CHICKEN FILLET WITH CHEESE & BACON MEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3077",
                "ImageURL": "",
                "MenuItemID": "67c1c15e37102c0b0d98895c-CHICKEN FILLET WITH CHEESE & BACON MEAL",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c15e37102c0b0d98895c",
                    "ProductName": "CHICKEN FILLET WITH CHEESE & BACON MEAL"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 650,
                        "PickupPrice": 650,
                        "TablePrice": 650
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN FILLET WITH CHEESE & BACON MEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CHILLI CHEESE CHICKEN BUGER MEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3078",
                "ImageURL": "",
                "MenuItemID": "67c1c15f37102c0b0d98895e-CHILLI CHEESE CHICKEN BUGER MEAL",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c15f37102c0b0d98895e",
                    "ProductName": "CHILLI CHEESE CHICKEN BUGER MEAL"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 600,
                        "PickupPrice": 600,
                        "TablePrice": 600
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI CHEESE CHICKEN BUGER MEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "1/4 POUNDER BUGER WITH CHEESE & BACON MEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3079",
                "ImageURL": "",
                "MenuItemID": "67c1c15f37102c0b0d988960-1-4 POUNDER BUGER WITH CHEESE & BACON MEAL",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c15f37102c0b0d988960",
                    "ProductName": "1/4 POUNDER BUGER WITH CHEESE & BACON MEAL"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988553-EXTRA PATTY",
                        "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                        "67c1c0b937102c0b0d988553-SALAD ",
                        "67c1c0b937102c0b0d988553-SAUCE "
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 630,
                        "PickupPrice": 630,
                        "TablePrice": 630
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "1/4 POUNDER BUGER WITH CHEESE & BACON MEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "BACON FRIES"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d307a",
                "ImageURL": "",
                "MenuItemID": "67c1c0ee37102c0b0d988686-BACON FRIES",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0ee37102c0b0d988686",
                    "ProductName": "BACON FRIES"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BACON FRIES"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "FULLY LOADED FRIES"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d307b",
                "ImageURL": "",
                "MenuItemID": "67c1c0ee37102c0b0d988688-FULLY LOADED FRIES",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0ee37102c0b0d988688",
                    "ProductName": "FULLY LOADED FRIES"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FULLY LOADED FRIES"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "FULLY LOADED FRIES VEGETARIAN"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d307c",
                "ImageURL": "",
                "MenuItemID": "67c1c0ee37102c0b0d98868a-FULLY LOADED FRIES VEGETARIAN",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0ee37102c0b0d98868a",
                    "ProductName": "FULLY LOADED FRIES VEGETARIAN"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FULLY LOADED FRIES VEGETARIAN"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "FULLY LOADED FRIES ALL THE MEAT"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d307d",
                "ImageURL": "",
                "MenuItemID": "67c1c0ef37102c0b0d98868e-FULLY LOADED FRIES ALL THE MEAT",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0ef37102c0b0d98868e",
                    "ProductName": "FULLY LOADED FRIES ALL THE MEAT"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FULLY LOADED FRIES ALL THE MEAT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "LOADED POTATO WEDGES"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d307e",
                "ImageURL": "",
                "MenuItemID": "67c1c0ef37102c0b0d988690-LOADED POTATO WEDGES",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0ef37102c0b0d988690",
                    "ProductName": "LOADED POTATO WEDGES"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 799,
                        "PickupPrice": 799,
                        "TablePrice": 799
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LOADED POTATO WEDGES"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CURLY FRIES."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d307f",
                "ImageURL": "",
                "MenuItemID": "67c1c0ef37102c0b0d988692-CURLY FRIES.",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0ef37102c0b0d988692",
                    "ProductName": "CURLY FRIES."
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 799,
                        "PickupPrice": 799,
                        "TablePrice": 799
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CURLY FRIES."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CURLY FRIES VEGETARIAN"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3080",
                "ImageURL": "",
                "MenuItemID": "67c1c0f037102c0b0d988694-CURLY FRIES VEGETARIAN",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f037102c0b0d988694",
                    "ProductName": "CURLY FRIES VEGETARIAN"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 799,
                        "PickupPrice": 799,
                        "TablePrice": 799
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CURLY FRIES VEGETARIAN"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "HOT HONEY."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3081",
                "ImageURL": "",
                "MenuItemID": "67c1c0f037102c0b0d988696-HOT HONEY.",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f037102c0b0d988696",
                    "ProductName": "HOT HONEY."
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HOT HONEY."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "GARLIC MAYO."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3082",
                "ImageURL": "",
                "MenuItemID": "67c1c0f037102c0b0d988698-GARLIC MAYO.",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f037102c0b0d988698",
                    "ProductName": "GARLIC MAYO."
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GARLIC MAYO."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CHILLI SAUCE."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3083",
                "ImageURL": "",
                "MenuItemID": "67c1c0f137102c0b0d98869a-CHILLI SAUCE.",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f137102c0b0d98869a",
                    "ProductName": "CHILLI SAUCE."
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI SAUCE."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "COKE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3084",
                "ImageURL": "",
                "MenuItemID": "67c1c0f137102c0b0d98869c-COKE",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f137102c0b0d98869c",
                    "ProductName": "COKE"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "COKE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "COKE ZERO"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3085",
                "ImageURL": "",
                "MenuItemID": "67c1c0f337102c0b0d9886a7-COKE ZERO",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f337102c0b0d9886a7",
                    "ProductName": "COKE ZERO"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "COKE ZERO"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "PEPSI"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3086",
                "ImageURL": "",
                "MenuItemID": "67c1c0f137102c0b0d98869e-PEPSI",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f137102c0b0d98869e",
                    "ProductName": "PEPSI"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPSI"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "7UP"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3087",
                "ImageURL": "",
                "MenuItemID": "67c1c0f237102c0b0d9886a0-7UP",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f237102c0b0d9886a0",
                    "ProductName": "7UP"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "7UP"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "TANGO"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3088",
                "ImageURL": "",
                "MenuItemID": "67c1c0f237102c0b0d9886a3-TANGO",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f237102c0b0d9886a3",
                    "ProductName": "TANGO"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TANGO"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "FANTA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3089",
                "ImageURL": "",
                "MenuItemID": "67c1c0f237102c0b0d9886a5-FANTA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f237102c0b0d9886a5",
                    "ProductName": "FANTA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FANTA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "DR.PEPPER"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d308a",
                "ImageURL": "",
                "MenuItemID": "67c1c0f337102c0b0d9886a9-DR.PEPPER",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f337102c0b0d9886a9",
                    "ProductName": "DR.PEPPER"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "DR.PEPPER"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "7UP BOTTLE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d308b",
                "ImageURL": "",
                "MenuItemID": "67c1c0f437102c0b0d9886ab-7UP BOTTLE",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f437102c0b0d9886ab",
                    "ProductName": "7UP BOTTLE"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 350,
                        "PickupPrice": 350,
                        "TablePrice": 350
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "7UP BOTTLE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "PEPSI BOTTLE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d308c",
                "ImageURL": "",
                "MenuItemID": "67c1c0f437102c0b0d9886ad-PEPSI BOTTLE",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f437102c0b0d9886ad",
                    "ProductName": "PEPSI BOTTLE"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 350,
                        "PickupPrice": 350,
                        "TablePrice": 350
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPSI BOTTLE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "PEPSI MAX BOTTLE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d308d",
                "ImageURL": "",
                "MenuItemID": "67c1c0f437102c0b0d9886b0-PEPSI MAX BOTTLE",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f437102c0b0d9886b0",
                    "ProductName": "PEPSI MAX BOTTLE"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 350,
                        "PickupPrice": 350,
                        "TablePrice": 350
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPSI MAX BOTTLE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "TANGO BOTTLE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d308e",
                "ImageURL": "",
                "MenuItemID": "67c1c0f537102c0b0d9886b3-TANGO BOTTLE",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f537102c0b0d9886b3",
                    "ProductName": "TANGO BOTTLE"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 350,
                        "PickupPrice": 350,
                        "TablePrice": 350
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TANGO BOTTLE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "B&J - CHOCOLATE FUDGE CAKE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d308f",
                "ImageURL": "",
                "MenuItemID": "67c1c0f537102c0b0d9886b5-B&J - CHOCOLATE FUDGE CAKE",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f537102c0b0d9886b5",
                    "ProductName": "B&J - CHOCOLATE FUDGE CAKE"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "B&J - CHOCOLATE FUDGE CAKE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "B&J - COOKIE DOUGH"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3090",
                "ImageURL": "",
                "MenuItemID": "67c1c0f537102c0b0d9886b7-B&J - COOKIE DOUGH",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductID": "67c1c0f537102c0b0d9886b7",
                    "ProductName": "B&J - COOKIE DOUGH"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "B&J - COOKIE DOUGH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3093",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-1-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 1,
                    "ProductID": "67c1c0fb37102c0b0d9886e5",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3094",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-2-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 2,
                    "ProductID": "67c1c0fb37102c0b0d9886e5",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3095",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-3-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 3,
                    "ProductID": "67c1c0fb37102c0b0d9886e5",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3096",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-4-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 4,
                    "ProductID": "67c1c0fb37102c0b0d9886e5",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3097",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-5-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 5,
                    "ProductID": "67c1c0fb37102c0b0d9886e5",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3098",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-6-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 6,
                    "ProductID": "67c1c0fb37102c0b0d9886e5",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3099",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-187-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 187,
                    "ProductID": "67c1c0fb37102c0b0d9886e5",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d309a",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-188-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 188,
                    "ProductID": "67c1c0fb37102c0b0d9886e5",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 4.8,
                "Allergens": [
                    "Gluten",
                    "Milk"
                ],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "Classic Italian pizza with fresh tomato sauce, mozzarella cheese, and basil leaves drizzled with extra virgin olive oil on our homemade dough."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": true,
                        "DietaryLabelInfo": {
                            "Labels": [
                                "Vegetarian",
                                "Contains Gluten",
                                "Contains Dairy"
                            ]
                        },
                        "FoolBusinessOperator": {
                            "Address": "123 Main Street, Food City",
                            "Name": "Authentic Pizza Co."
                        },
                        "Ingredients": [
                            "Wheat Flour",
                            "Tomatoes",
                            "Mozzarella Cheese",
                            "Fresh Basil Leaves",
                            "Extra Virgin Olive Oil",
                            "Water",
                            "Yeast",
                            "Salt",
                            "Olive Oil",
                            "Basil"
                        ],
                        "InstructionsForUse": "Best served hot. Reheat in oven at 180°C for 5 minutes if needed.",
                        "IsHalal": true,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": true,
                        "PreparationType": "Wood-fired oven baked",
                        "SpiceLevel": 1
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3091",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740999333.jpeg",
                "MenuItemID": "67c1c0fb37102c0b0d9886e5-MARGHERITHA PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0fb37102c0b0d9886e5",
                    "ProductModifierUnitChartID": "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE",
                    "ProductName": "MARGHERITHA PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 1,
                        "EnergyInterval": {
                            "Lower": 850,
                            "Upper": 950
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 1,
                        "EnergyInterval": {
                            "Lower": 106,
                            "Upper": 119
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 90,
                                "Upper": 100
                            },
                            "Weight": {
                                "UnitType": "g"
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 12,
                                "Upper": 14
                            },
                            "Weight": {
                                "UnitType": "g"
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 1,
                        "EnergyInterval": {
                            "Lower": 3556,
                            "Upper": 3975
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 1,
                        "EnergyInterval": {
                            "Lower": 444,
                            "Upper": 497
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": "slices"
                            },
                            "Interval": {
                                "Lower": 8,
                                "Upper": 8
                            }
                        },
                        "MeasurementType": "Weight",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 1200,
                                "Upper": 1500
                            },
                            "Weight": {
                                "UnitType": "g"
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 6,
                        "Upper": 8
                    },
                    "NumberofServings": 8,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 32,
                                "Upper": 35
                            },
                            "Weight": {
                                "UnitType": "g"
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 2.5,
                                "Upper": 3
                            },
                            "Weight": {
                                "UnitType": "g"
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 5,
                                "Upper": 6
                            },
                            "Weight": {
                                "UnitType": "g"
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": "slice"
                            },
                            "Interval": {
                                "Lower": 1,
                                "Upper": 1
                            }
                        },
                        "MeasurementType": "Weight",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 300,
                                "Upper": 300
                            },
                            "Weight": {
                                "UnitType": "g"
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 6,
                                "Upper": 8
                            },
                            "Weight": {
                                "UnitType": "g"
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 1299,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": [
                        "Italy"
                    ],
                    "Gtin": "5901234123457",
                    "MerchantID": "MRG-PIZ-01",
                    "Plu": "M001",
                    "ProductTraits": [
                        "Handmade",
                        "Traditional",
                        "Italian"
                    ],
                    "ProductType": "Pizza",
                    "TargetMarket": 1
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 10,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0.09,
                    "VATRateInPercentage": 9
                },
                "Title": {
                    "en": "MARGHERITA PIZZA"
                },
                "TotalReviews": 235,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d309d",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-7-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 7,
                    "ProductID": "67c1c0fc37102c0b0d9886e7",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d309e",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-8-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 8,
                    "ProductID": "67c1c0fc37102c0b0d9886e7",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d309f",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-9-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 9,
                    "ProductID": "67c1c0fc37102c0b0d9886e7",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30a0",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-10-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 10,
                    "ProductID": "67c1c0fc37102c0b0d9886e7",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30a1",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-11-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 11,
                    "ProductID": "67c1c0fc37102c0b0d9886e7",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30a2",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-12-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 12,
                    "ProductID": "67c1c0fc37102c0b0d9886e7",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30a3",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-189-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 189,
                    "ProductID": "67c1c0fc37102c0b0d9886e7",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30a4",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-190-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 190,
                    "ProductID": "67c1c0fc37102c0b0d9886e7",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "VEGETARIAN PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d309b",
                "ImageURL": "",
                "MenuItemID": "67c1c0fc37102c0b0d9886e7-VEGETARIAN PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0fc37102c0b0d9886e7",
                    "ProductModifierUnitChartID": "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE",
                    "ProductName": "VEGETARIAN PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "VEGETARIAN PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30a7",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-13-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 13,
                    "ProductID": "67c1c0fc37102c0b0d9886e9",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30a8",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-14-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 14,
                    "ProductID": "67c1c0fc37102c0b0d9886e9",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30a9",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-15-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 15,
                    "ProductID": "67c1c0fc37102c0b0d9886e9",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30aa",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-16-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 16,
                    "ProductID": "67c1c0fc37102c0b0d9886e9",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30ab",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-17-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 17,
                    "ProductID": "67c1c0fc37102c0b0d9886e9",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30ac",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-18-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 18,
                    "ProductID": "67c1c0fc37102c0b0d9886e9",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30ad",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-191-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 191,
                    "ProductID": "67c1c0fc37102c0b0d9886e9",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30ae",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-192-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 192,
                    "ProductID": "67c1c0fc37102c0b0d9886e9",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "VEGETARIAN HOT PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30a5",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740996360.jpeg",
                "MenuItemID": "67c1c0fc37102c0b0d9886e9-VEGETARIAN HOT PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0fc37102c0b0d9886e9",
                    "ProductModifierUnitChartID": "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE",
                    "ProductName": "VEGETARIAN HOT PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "VEGETARIAN HOT PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30b1",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-163-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 163,
                    "ProductID": "67c1c15437102c0b0d98891e",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15437102c0b0d98891e-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30b2",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-164-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 164,
                    "ProductID": "67c1c15437102c0b0d98891e",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15437102c0b0d98891e-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30b3",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-165-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 165,
                    "ProductID": "67c1c15437102c0b0d98891e",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15437102c0b0d98891e-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30b4",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-166-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 166,
                    "ProductID": "67c1c15437102c0b0d98891e",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15437102c0b0d98891e-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30b5",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-167-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 167,
                    "ProductID": "67c1c15437102c0b0d98891e",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15437102c0b0d98891e-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30b6",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-168-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 168,
                    "ProductID": "67c1c15437102c0b0d98891e",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15437102c0b0d98891e-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30b7",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-241-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 241,
                    "ProductID": "67c1c15437102c0b0d98891e",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15437102c0b0d98891e-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30b8",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-242-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 242,
                    "ProductID": "67c1c15437102c0b0d98891e",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15437102c0b0d98891e-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "MASALA HOT PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30af",
                "ImageURL": "",
                "MenuItemID": "67c1c15437102c0b0d98891e-MASALA HOT PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c15437102c0b0d98891e",
                    "ProductModifierUnitChartID": "67c1c15437102c0b0d98891e-CHOOSE SIZE",
                    "ProductName": "MASALA HOT PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c15437102c0b0d98891e-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MASALA HOT PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30bb",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-19-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 19,
                    "ProductID": "67c1c0fd37102c0b0d9886eb",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30bc",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-20-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 20,
                    "ProductID": "67c1c0fd37102c0b0d9886eb",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30bd",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-21-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 21,
                    "ProductID": "67c1c0fd37102c0b0d9886eb",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30be",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-22-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 22,
                    "ProductID": "67c1c0fd37102c0b0d9886eb",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30bf",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-23-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 23,
                    "ProductID": "67c1c0fd37102c0b0d9886eb",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30c0",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-24-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 24,
                    "ProductID": "67c1c0fd37102c0b0d9886eb",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30c1",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-193-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 193,
                    "ProductID": "67c1c0fd37102c0b0d9886eb",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30c2",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-194-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 194,
                    "ProductID": "67c1c0fd37102c0b0d9886eb",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "DESI STYLE PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30b9",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740997739.jpeg",
                "MenuItemID": "67c1c0fd37102c0b0d9886eb-DESI STYLE PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0fd37102c0b0d9886eb",
                    "ProductModifierUnitChartID": "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE",
                    "ProductName": "DESI STYLE PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "DESI STYLE PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30c5",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-25-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 25,
                    "ProductID": "67c1c0fd37102c0b0d9886ed",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30c6",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-26-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 26,
                    "ProductID": "67c1c0fd37102c0b0d9886ed",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30c7",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-27-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 27,
                    "ProductID": "67c1c0fd37102c0b0d9886ed",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30c8",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-28-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 28,
                    "ProductID": "67c1c0fd37102c0b0d9886ed",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30c9",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-29-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 29,
                    "ProductID": "67c1c0fd37102c0b0d9886ed",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30ca",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-30-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 30,
                    "ProductID": "67c1c0fd37102c0b0d9886ed",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30cb",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-195-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 195,
                    "ProductID": "67c1c0fd37102c0b0d9886ed",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30cc",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-196-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 196,
                    "ProductID": "67c1c0fd37102c0b0d9886ed",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "VEGETARIAN SUPREME PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30c3",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740994806.jpeg",
                "MenuItemID": "67c1c0fd37102c0b0d9886ed-VEGETARIAN SUPREME PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0fd37102c0b0d9886ed",
                    "ProductModifierUnitChartID": "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE",
                    "ProductName": "VEGETARIAN SUPREME PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "VEGETARIAN SUPREME PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30cf",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-31-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 31,
                    "ProductID": "67c1c0fd37102c0b0d9886ef",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30d0",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-32-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 32,
                    "ProductID": "67c1c0fd37102c0b0d9886ef",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30d1",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-33-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 33,
                    "ProductID": "67c1c0fd37102c0b0d9886ef",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30d2",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-34-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 34,
                    "ProductID": "67c1c0fd37102c0b0d9886ef",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30d3",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-35-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 35,
                    "ProductID": "67c1c0fd37102c0b0d9886ef",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30d4",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-36-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 36,
                    "ProductID": "67c1c0fd37102c0b0d9886ef",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30d5",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-197-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 197,
                    "ProductID": "67c1c0fd37102c0b0d9886ef",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30d6",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-198-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 198,
                    "ProductID": "67c1c0fd37102c0b0d9886ef",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "PUNJABI STYLE GARLIC PIZZA BREAD"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30cd",
                "ImageURL": "",
                "MenuItemID": "67c1c0fd37102c0b0d9886ef-PUNJABI STYLE GARLIC PIZZA BREAD",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0fd37102c0b0d9886ef",
                    "ProductModifierUnitChartID": "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE",
                    "ProductName": "PUNJABI STYLE GARLIC PIZZA BREAD"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PUNJABI STYLE GARLIC PIZZA BREAD"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30d9",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-37-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 37,
                    "ProductID": "67c1c0fe37102c0b0d9886f1",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30da",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-38-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 38,
                    "ProductID": "67c1c0fe37102c0b0d9886f1",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30db",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-39-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 39,
                    "ProductID": "67c1c0fe37102c0b0d9886f1",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30dc",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-40-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 40,
                    "ProductID": "67c1c0fe37102c0b0d9886f1",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30dd",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-41-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 41,
                    "ProductID": "67c1c0fe37102c0b0d9886f1",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30de",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-42-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 42,
                    "ProductID": "67c1c0fe37102c0b0d9886f1",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30df",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-199-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 199,
                    "ProductID": "67c1c0fe37102c0b0d9886f1",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30e0",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-200-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 200,
                    "ProductID": "67c1c0fe37102c0b0d9886f1",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "PUNJABI SPICY PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30d7",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740993989.jpeg",
                "MenuItemID": "67c1c0fe37102c0b0d9886f1-PUNJABI SPICY PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0fe37102c0b0d9886f1",
                    "ProductModifierUnitChartID": "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE",
                    "ProductName": "PUNJABI SPICY PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PUNJABI SPICY PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30e3",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-115-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 115,
                    "ProductID": "67c1c10237102c0b0d98870b",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870b-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30e4",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-116-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 116,
                    "ProductID": "67c1c10237102c0b0d98870b",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870b-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 499,
                                "PickupPrice": 499,
                                "TablePrice": 499
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 499,
                        "PickupPrice": 499,
                        "TablePrice": 499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30e5",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-117-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 117,
                    "ProductID": "67c1c10237102c0b0d98870b",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870b-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 699,
                                "PickupPrice": 699,
                                "TablePrice": 699
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30e6",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-118-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 118,
                    "ProductID": "67c1c10237102c0b0d98870b",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870b-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 999,
                                "PickupPrice": 999,
                                "TablePrice": 999
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 999,
                        "PickupPrice": 999,
                        "TablePrice": 999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30e7",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-119-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 119,
                    "ProductID": "67c1c10237102c0b0d98870b",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870b-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1299,
                                "PickupPrice": 1299,
                                "TablePrice": 1299
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1299,
                        "PickupPrice": 1299,
                        "TablePrice": 1299
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30e8",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-120-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 120,
                    "ProductID": "67c1c10237102c0b0d98870b",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870b-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1499,
                                "PickupPrice": 1499,
                                "TablePrice": 1499
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1499,
                        "PickupPrice": 1499,
                        "TablePrice": 1499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30e9",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-225-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 225,
                    "ProductID": "67c1c10237102c0b0d98870b",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870b-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30ea",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-226-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 226,
                    "ProductID": "67c1c10237102c0b0d98870b",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870b-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "PANEER PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30e1",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740996940.jpeg",
                "MenuItemID": "67c1c10237102c0b0d98870b-PANEER PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10237102c0b0d98870b",
                    "ProductModifierUnitChartID": "67c1c10237102c0b0d98870b-CHOOSE SIZE",
                    "ProductName": "PANEER PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10237102c0b0d98870b-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PANEER PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30ed",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-139-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 139,
                    "ProductID": "67c1c10337102c0b0d988713",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988713-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30ee",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-140-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 140,
                    "ProductID": "67c1c10337102c0b0d988713",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988713-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30ef",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-141-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 141,
                    "ProductID": "67c1c10337102c0b0d988713",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988713-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 799,
                                "PickupPrice": 799,
                                "TablePrice": 799
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 799,
                        "PickupPrice": 799,
                        "TablePrice": 799
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30f0",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-142-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 142,
                    "ProductID": "67c1c10337102c0b0d988713",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988713-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1099,
                                "PickupPrice": 1099,
                                "TablePrice": 1099
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1099,
                        "PickupPrice": 1099,
                        "TablePrice": 1099
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30f1",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-143-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 143,
                    "ProductID": "67c1c10337102c0b0d988713",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988713-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30f2",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-144-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 144,
                    "ProductID": "67c1c10337102c0b0d988713",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988713-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1599,
                                "PickupPrice": 1599,
                                "TablePrice": 1599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1599,
                        "PickupPrice": 1599,
                        "TablePrice": 1599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30f3",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-233-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 233,
                    "ProductID": "67c1c10337102c0b0d988713",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988713-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30f4",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-234-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 234,
                    "ProductID": "67c1c10337102c0b0d988713",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988713-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": "CHILLI PANEER PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30eb",
                "ImageURL": "",
                "MenuItemID": "67c1c10337102c0b0d988713-CHILLI PANEER PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10337102c0b0d988713",
                    "ProductModifierUnitChartID": "67c1c10337102c0b0d988713-CHOOSE SIZE",
                    "ProductName": "CHILLI PANEER PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10337102c0b0d988713-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI PANEER PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30f7",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-169-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 169,
                    "ProductID": "67c1c15537102c0b0d988924",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15537102c0b0d988924-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30f8",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-170-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 170,
                    "ProductID": "67c1c15537102c0b0d988924",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15537102c0b0d988924-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30f9",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-171-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 171,
                    "ProductID": "67c1c15537102c0b0d988924",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15537102c0b0d988924-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30fa",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-172-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 172,
                    "ProductID": "67c1c15537102c0b0d988924",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15537102c0b0d988924-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30fb",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-173-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 173,
                    "ProductID": "67c1c15537102c0b0d988924",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15537102c0b0d988924-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30fc",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-174-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 174,
                    "ProductID": "67c1c15537102c0b0d988924",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15537102c0b0d988924-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30fd",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-243-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 243,
                    "ProductID": "67c1c15537102c0b0d988924",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15537102c0b0d988924-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30fe",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-251-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 251,
                    "ProductID": "67c1c15537102c0b0d988924",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15537102c0b0d988924-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": "VEGGIE CHILLI TURMERIC PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30f5",
                "ImageURL": "",
                "MenuItemID": "67c1c15537102c0b0d988924-VEGGIE CHILLI TURMERIC PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c15537102c0b0d988924",
                    "ProductModifierUnitChartID": "67c1c15537102c0b0d988924-CHOOSE SIZE",
                    "ProductName": "VEGGIE CHILLI TURMERIC PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c15537102c0b0d988924-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "VEGGIE CHILLI TURMERIC PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3101",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-43-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 43,
                    "ProductID": "67c1c0fe37102c0b0d9886f3",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3102",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-44-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 44,
                    "ProductID": "67c1c0fe37102c0b0d9886f3",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3103",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-45-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 45,
                    "ProductID": "67c1c0fe37102c0b0d9886f3",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3104",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-46-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 46,
                    "ProductID": "67c1c0fe37102c0b0d9886f3",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3105",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-47-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 47,
                    "ProductID": "67c1c0fe37102c0b0d9886f3",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3106",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-48-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 48,
                    "ProductID": "67c1c0fe37102c0b0d9886f3",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3107",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-201-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 201,
                    "ProductID": "67c1c0fe37102c0b0d9886f3",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3108",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-202-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 202,
                    "ProductID": "67c1c0fe37102c0b0d9886f3",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": "HAWAIIAN PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d30ff",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1741003520.jpeg",
                "MenuItemID": "67c1c0fe37102c0b0d9886f3-HAWAIIAN PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0fe37102c0b0d9886f3",
                    "ProductModifierUnitChartID": "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE",
                    "ProductName": "HAWAIIAN PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HAWAIIAN PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d310b",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-49-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 49,
                    "ProductID": "67c1c0fe37102c0b0d9886f5",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d310c",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-50-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 50,
                    "ProductID": "67c1c0fe37102c0b0d9886f5",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d310d",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-51-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 51,
                    "ProductID": "67c1c0fe37102c0b0d9886f5",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d310e",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-52-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 52,
                    "ProductID": "67c1c0fe37102c0b0d9886f5",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d310f",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-53-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 53,
                    "ProductID": "67c1c0fe37102c0b0d9886f5",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3110",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-54-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 54,
                    "ProductID": "67c1c0fe37102c0b0d9886f5",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3111",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-203-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 203,
                    "ProductID": "67c1c0fe37102c0b0d9886f5",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3112",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-204-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 204,
                    "ProductID": "67c1c0fe37102c0b0d9886f5",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": "PEPPERONI LOVER PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3109",
                "ImageURL": "",
                "MenuItemID": "67c1c0fe37102c0b0d9886f5-PEPPERONI LOVER PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0fe37102c0b0d9886f5",
                    "ProductModifierUnitChartID": "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE",
                    "ProductName": "PEPPERONI LOVER PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPPERONI LOVER PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3115",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-55-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 55,
                    "ProductID": "67c1c0ff37102c0b0d9886f7",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3116",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-56-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 56,
                    "ProductID": "67c1c0ff37102c0b0d9886f7",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3117",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-57-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 57,
                    "ProductID": "67c1c0ff37102c0b0d9886f7",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3118",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-58-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 58,
                    "ProductID": "67c1c0ff37102c0b0d9886f7",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3119",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-59-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 59,
                    "ProductID": "67c1c0ff37102c0b0d9886f7",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d311a",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-60-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 60,
                    "ProductID": "67c1c0ff37102c0b0d9886f7",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d311b",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-205-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 205,
                    "ProductID": "67c1c0ff37102c0b0d9886f7",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d311c",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-206-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 206,
                    "ProductID": "67c1c0ff37102c0b0d9886f7",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": "PEPPERONI SPECIAL PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3113",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1741002472.jpeg",
                "MenuItemID": "67c1c0ff37102c0b0d9886f7-PEPPERONI SPECIAL PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0ff37102c0b0d9886f7",
                    "ProductModifierUnitChartID": "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE",
                    "ProductName": "PEPPERONI SPECIAL PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPPERONI SPECIAL PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d311f",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-61-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 61,
                    "ProductID": "67c1c0ff37102c0b0d9886f9",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3120",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-62-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 62,
                    "ProductID": "67c1c0ff37102c0b0d9886f9",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3121",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-63-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 63,
                    "ProductID": "67c1c0ff37102c0b0d9886f9",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3122",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-64-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 64,
                    "ProductID": "67c1c0ff37102c0b0d9886f9",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3123",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-65-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 65,
                    "ProductID": "67c1c0ff37102c0b0d9886f9",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3124",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-66-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 66,
                    "ProductID": "67c1c0ff37102c0b0d9886f9",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3125",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-207-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 207,
                    "ProductID": "67c1c0ff37102c0b0d9886f9",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3126",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-208-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 208,
                    "ProductID": "67c1c0ff37102c0b0d9886f9",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": "HOT HOUSE SPECIAL PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d311d",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740999449.jpeg",
                "MenuItemID": "67c1c0ff37102c0b0d9886f9-HOT HOUSE SPECIAL PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0ff37102c0b0d9886f9",
                    "ProductModifierUnitChartID": "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE",
                    "ProductName": "HOT HOUSE SPECIAL PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HOT HOUSE SPECIAL PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3129",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-67-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 67,
                    "ProductID": "67c1c0ff37102c0b0d9886fb",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d312a",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-68-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 68,
                    "ProductID": "67c1c0ff37102c0b0d9886fb",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d312b",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-69-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 69,
                    "ProductID": "67c1c0ff37102c0b0d9886fb",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d312c",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-70-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 70,
                    "ProductID": "67c1c0ff37102c0b0d9886fb",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d312d",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-71-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 71,
                    "ProductID": "67c1c0ff37102c0b0d9886fb",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d312e",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-72-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 72,
                    "ProductID": "67c1c0ff37102c0b0d9886fb",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d312f",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-209-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 209,
                    "ProductID": "67c1c0ff37102c0b0d9886fb",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3130",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-210-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 210,
                    "ProductID": "67c1c0ff37102c0b0d9886fb",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": "MEAT FEAST PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3127",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740996393.jpeg",
                "MenuItemID": "67c1c0ff37102c0b0d9886fb-MEAT FEAST PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c0ff37102c0b0d9886fb",
                    "ProductModifierUnitChartID": "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE",
                    "ProductName": "MEAT FEAST PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MEAT FEAST PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3133",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-73-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 73,
                    "ProductID": "67c1c10037102c0b0d9886fd",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886fd-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3134",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-74-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 74,
                    "ProductID": "67c1c10037102c0b0d9886fd",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886fd-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3135",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-75-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 75,
                    "ProductID": "67c1c10037102c0b0d9886fd",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886fd-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3136",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-76-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 76,
                    "ProductID": "67c1c10037102c0b0d9886fd",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886fd-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3137",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-77-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 77,
                    "ProductID": "67c1c10037102c0b0d9886fd",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886fd-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3138",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-78-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 78,
                    "ProductID": "67c1c10037102c0b0d9886fd",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886fd-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3139",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-211-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 211,
                    "ProductID": "67c1c10037102c0b0d9886fd",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886fd-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d313a",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-212-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 212,
                    "ProductID": "67c1c10037102c0b0d9886fd",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886fd-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": "BBQ MEAT FEAST PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3131",
                "ImageURL": "",
                "MenuItemID": "67c1c10037102c0b0d9886fd-BBQ MEAT FEAST PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10037102c0b0d9886fd",
                    "ProductModifierUnitChartID": "67c1c10037102c0b0d9886fd-CHOOSE SIZE",
                    "ProductName": "BBQ MEAT FEAST PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10037102c0b0d9886fd-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ MEAT FEAST PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d313d",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-79-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 79,
                    "ProductID": "67c1c10037102c0b0d9886ff",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886ff-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d313e",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-80-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 80,
                    "ProductID": "67c1c10037102c0b0d9886ff",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886ff-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d313f",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-81-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 81,
                    "ProductID": "67c1c10037102c0b0d9886ff",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886ff-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3140",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-82-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 82,
                    "ProductID": "67c1c10037102c0b0d9886ff",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886ff-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3141",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-83-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 83,
                    "ProductID": "67c1c10037102c0b0d9886ff",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886ff-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3142",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-84-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 84,
                    "ProductID": "67c1c10037102c0b0d9886ff",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886ff-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3143",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-213-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 213,
                    "ProductID": "67c1c10037102c0b0d9886ff",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886ff-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3144",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-214-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 214,
                    "ProductID": "67c1c10037102c0b0d9886ff",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d9886ff-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": "BBQ CHICKEN PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d313b",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740996208.jpeg",
                "MenuItemID": "67c1c10037102c0b0d9886ff-BBQ CHICKEN PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10037102c0b0d9886ff",
                    "ProductModifierUnitChartID": "67c1c10037102c0b0d9886ff-CHOOSE SIZE",
                    "ProductName": "BBQ CHICKEN PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10037102c0b0d9886ff-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ CHICKEN PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3147",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-127-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 127,
                    "ProductID": "67c1c10337102c0b0d98870f",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d98870f-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3148",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-128-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 128,
                    "ProductID": "67c1c10337102c0b0d98870f",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d98870f-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 499,
                                "PickupPrice": 499,
                                "TablePrice": 499
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 499,
                        "PickupPrice": 499,
                        "TablePrice": 499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3149",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-129-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 129,
                    "ProductID": "67c1c10337102c0b0d98870f",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d98870f-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 699,
                                "PickupPrice": 699,
                                "TablePrice": 699
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d314a",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-130-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 130,
                    "ProductID": "67c1c10337102c0b0d98870f",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d98870f-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 999,
                                "PickupPrice": 999,
                                "TablePrice": 999
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 999,
                        "PickupPrice": 999,
                        "TablePrice": 999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d314b",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-131-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 131,
                    "ProductID": "67c1c10337102c0b0d98870f",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d98870f-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1299,
                                "PickupPrice": 1299,
                                "TablePrice": 1299
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1299,
                        "PickupPrice": 1299,
                        "TablePrice": 1299
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d314c",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-132-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 132,
                    "ProductID": "67c1c10337102c0b0d98870f",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d98870f-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1499,
                                "PickupPrice": 1499,
                                "TablePrice": 1499
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1499,
                        "PickupPrice": 1499,
                        "TablePrice": 1499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d314d",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-229-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 229,
                    "ProductID": "67c1c10337102c0b0d98870f",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d98870f-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d314e",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-230-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 230,
                    "ProductID": "67c1c10337102c0b0d98870f",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d98870f-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": "BBQ MEAT LOVER PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3145",
                "ImageURL": "",
                "MenuItemID": "67c1c10337102c0b0d98870f-BBQ MEAT LOVER PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10337102c0b0d98870f",
                    "ProductModifierUnitChartID": "67c1c10337102c0b0d98870f-CHOOSE SIZE",
                    "ProductName": "BBQ MEAT LOVER PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10337102c0b0d98870f-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ MEAT LOVER PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3151",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-85-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 85,
                    "ProductID": "67c1c10037102c0b0d988701",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d988701-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3152",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-86-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 86,
                    "ProductID": "67c1c10037102c0b0d988701",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d988701-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3153",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-87-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 87,
                    "ProductID": "67c1c10037102c0b0d988701",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d988701-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3154",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-88-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 88,
                    "ProductID": "67c1c10037102c0b0d988701",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d988701-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3155",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-89-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 89,
                    "ProductID": "67c1c10037102c0b0d988701",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d988701-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3156",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-90-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 90,
                    "ProductID": "67c1c10037102c0b0d988701",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d988701-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3157",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-215-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 215,
                    "ProductID": "67c1c10037102c0b0d988701",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d988701-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3158",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-216-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 216,
                    "ProductID": "67c1c10037102c0b0d988701",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10037102c0b0d988701-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": "CHICKEN SPECIAL PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d314f",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1741001079.jpeg",
                "MenuItemID": "67c1c10037102c0b0d988701-CHICKEN SPECIAL PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10037102c0b0d988701",
                    "ProductModifierUnitChartID": "67c1c10037102c0b0d988701-CHOOSE SIZE",
                    "ProductName": "CHICKEN SPECIAL PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10037102c0b0d988701-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN SPECIAL PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d315b",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-91-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 91,
                    "ProductID": "67c1c10137102c0b0d988703",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988703-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d315c",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-92-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 92,
                    "ProductID": "67c1c10137102c0b0d988703",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988703-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d315d",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-93-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 93,
                    "ProductID": "67c1c10137102c0b0d988703",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988703-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d315e",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-94-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 94,
                    "ProductID": "67c1c10137102c0b0d988703",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988703-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d315f",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-95-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 95,
                    "ProductID": "67c1c10137102c0b0d988703",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988703-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3160",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-96-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 96,
                    "ProductID": "67c1c10137102c0b0d988703",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988703-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3161",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-217-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 217,
                    "ProductID": "67c1c10137102c0b0d988703",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988703-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3162",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-218-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 218,
                    "ProductID": "67c1c10137102c0b0d988703",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988703-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": "TANDOORI CHICKEN HOT PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3159",
                "ImageURL": "",
                "MenuItemID": "67c1c10137102c0b0d988703-TANDOORI CHICKEN HOT PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10137102c0b0d988703",
                    "ProductModifierUnitChartID": "67c1c10137102c0b0d988703-CHOOSE SIZE",
                    "ProductName": "TANDOORI CHICKEN HOT PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10137102c0b0d988703-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TANDOORI CHICKEN HOT PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3165",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-97-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 97,
                    "ProductID": "67c1c10137102c0b0d988705",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988705-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3166",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-98-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 98,
                    "ProductID": "67c1c10137102c0b0d988705",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988705-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3167",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-99-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 99,
                    "ProductID": "67c1c10137102c0b0d988705",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988705-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3168",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-100-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 100,
                    "ProductID": "67c1c10137102c0b0d988705",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988705-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3169",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-101-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 101,
                    "ProductID": "67c1c10137102c0b0d988705",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988705-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d316a",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-102-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 102,
                    "ProductID": "67c1c10137102c0b0d988705",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988705-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d316b",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-219-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 219,
                    "ProductID": "67c1c10137102c0b0d988705",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988705-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d316c",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-220-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 220,
                    "ProductID": "67c1c10137102c0b0d988705",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988705-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": "AMERICAN HOT PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3163",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740993246.jpeg",
                "MenuItemID": "67c1c10137102c0b0d988705-AMERICAN HOT PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10137102c0b0d988705",
                    "ProductModifierUnitChartID": "67c1c10137102c0b0d988705-CHOOSE SIZE",
                    "ProductName": "AMERICAN HOT PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10137102c0b0d988705-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "AMERICAN HOT PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d316f",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-103-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 103,
                    "ProductID": "67c1c10137102c0b0d988707",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988707-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3170",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-104-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 104,
                    "ProductID": "67c1c10137102c0b0d988707",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988707-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3171",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-105-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 105,
                    "ProductID": "67c1c10137102c0b0d988707",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988707-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3172",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-106-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 106,
                    "ProductID": "67c1c10137102c0b0d988707",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988707-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3173",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-107-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 107,
                    "ProductID": "67c1c10137102c0b0d988707",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988707-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3174",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-108-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 108,
                    "ProductID": "67c1c10137102c0b0d988707",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988707-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3175",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-221-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 221,
                    "ProductID": "67c1c10137102c0b0d988707",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988707-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3176",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-222-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 222,
                    "ProductID": "67c1c10137102c0b0d988707",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10137102c0b0d988707-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": "KEBAB SPECIAL PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d316d",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740996551.jpeg",
                "MenuItemID": "67c1c10137102c0b0d988707-KEBAB SPECIAL PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10137102c0b0d988707",
                    "ProductModifierUnitChartID": "67c1c10137102c0b0d988707-CHOOSE SIZE",
                    "ProductName": "KEBAB SPECIAL PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10137102c0b0d988707-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "KEBAB SPECIAL PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3179",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-133-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 133,
                    "ProductID": "67c1c10337102c0b0d988711",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988711-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d317a",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-134-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 134,
                    "ProductID": "67c1c10337102c0b0d988711",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988711-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 499,
                                "PickupPrice": 499,
                                "TablePrice": 499
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 499,
                        "PickupPrice": 499,
                        "TablePrice": 499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d317b",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-135-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 135,
                    "ProductID": "67c1c10337102c0b0d988711",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988711-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 699,
                                "PickupPrice": 699,
                                "TablePrice": 699
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d317c",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-136-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 136,
                    "ProductID": "67c1c10337102c0b0d988711",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988711-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 999,
                                "PickupPrice": 999,
                                "TablePrice": 999
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 999,
                        "PickupPrice": 999,
                        "TablePrice": 999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d317d",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-137-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 137,
                    "ProductID": "67c1c10337102c0b0d988711",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988711-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1299,
                                "PickupPrice": 1299,
                                "TablePrice": 1299
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1299,
                        "PickupPrice": 1299,
                        "TablePrice": 1299
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d317e",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-138-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 138,
                    "ProductID": "67c1c10337102c0b0d988711",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988711-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1499,
                                "PickupPrice": 1499,
                                "TablePrice": 1499
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1499,
                        "PickupPrice": 1499,
                        "TablePrice": 1499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d317f",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-231-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 231,
                    "ProductID": "67c1c10337102c0b0d988711",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988711-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3180",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-232-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 232,
                    "ProductID": "67c1c10337102c0b0d988711",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10337102c0b0d988711-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": "GARLIC SPICIAL PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3177",
                "ImageURL": "",
                "MenuItemID": "67c1c10337102c0b0d988711-GARLIC SPICIAL PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10337102c0b0d988711",
                    "ProductModifierUnitChartID": "67c1c10337102c0b0d988711-CHOOSE SIZE",
                    "ProductName": "GARLIC SPICIAL PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10337102c0b0d988711-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GARLIC SPICIAL PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3183",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-109-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 109,
                    "ProductID": "67c1c10237102c0b0d988709",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d988709-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3184",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-110-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 110,
                    "ProductID": "67c1c10237102c0b0d988709",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d988709-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3185",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-111-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 111,
                    "ProductID": "67c1c10237102c0b0d988709",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d988709-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3186",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-112-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 112,
                    "ProductID": "67c1c10237102c0b0d988709",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d988709-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3187",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-113-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 113,
                    "ProductID": "67c1c10237102c0b0d988709",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d988709-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3188",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-114-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 114,
                    "ProductID": "67c1c10237102c0b0d988709",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d988709-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3189",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-223-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 223,
                    "ProductID": "67c1c10237102c0b0d988709",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d988709-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d318a",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-224-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 224,
                    "ProductID": "67c1c10237102c0b0d988709",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d988709-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": "KING CHICKEN PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3181",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740999096.jpeg",
                "MenuItemID": "67c1c10237102c0b0d988709-KING CHICKEN PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10237102c0b0d988709",
                    "ProductModifierUnitChartID": "67c1c10237102c0b0d988709-CHOOSE SIZE",
                    "ProductName": "KING CHICKEN PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10237102c0b0d988709-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "KING CHICKEN PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d318d",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-121-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 121,
                    "ProductID": "67c1c10237102c0b0d98870d",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870d-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d318e",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-122-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 122,
                    "ProductID": "67c1c10237102c0b0d98870d",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870d-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 499,
                                "PickupPrice": 499,
                                "TablePrice": 499
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 499,
                        "PickupPrice": 499,
                        "TablePrice": 499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d318f",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-123-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 123,
                    "ProductID": "67c1c10237102c0b0d98870d",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870d-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 699,
                                "PickupPrice": 699,
                                "TablePrice": 699
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 699,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3190",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-124-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 124,
                    "ProductID": "67c1c10237102c0b0d98870d",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870d-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 999,
                                "PickupPrice": 999,
                                "TablePrice": 999
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 999,
                        "PickupPrice": 999,
                        "TablePrice": 999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3191",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-125-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 125,
                    "ProductID": "67c1c10237102c0b0d98870d",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870d-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1299,
                                "PickupPrice": 1299,
                                "TablePrice": 1299
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1299,
                        "PickupPrice": 1299,
                        "TablePrice": 1299
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3192",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-126-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 126,
                    "ProductID": "67c1c10237102c0b0d98870d",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870d-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1499,
                                "PickupPrice": 1499,
                                "TablePrice": 1499
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1499,
                        "PickupPrice": 1499,
                        "TablePrice": 1499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3193",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-227-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 227,
                    "ProductID": "67c1c10237102c0b0d98870d",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870d-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3194",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-228-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 228,
                    "ProductID": "67c1c10237102c0b0d98870d",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10237102c0b0d98870d-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": "DESI CHICKEN PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d318b",
                "ImageURL": "",
                "MenuItemID": "67c1c10237102c0b0d98870d-DESI CHICKEN PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10237102c0b0d98870d",
                    "ProductModifierUnitChartID": "67c1c10237102c0b0d98870d-CHOOSE SIZE",
                    "ProductName": "DESI CHICKEN PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10237102c0b0d98870d-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "DESI CHICKEN PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3197",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-175-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 175,
                    "ProductID": "67c1c15637102c0b0d98892a",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15637102c0b0d98892a-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3198",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-176-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 176,
                    "ProductID": "67c1c15637102c0b0d98892a",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15637102c0b0d98892a-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3199",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-177-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 177,
                    "ProductID": "67c1c15637102c0b0d98892a",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15637102c0b0d98892a-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d319a",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-178-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 178,
                    "ProductID": "67c1c15637102c0b0d98892a",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15637102c0b0d98892a-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d319b",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-179-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 179,
                    "ProductID": "67c1c15637102c0b0d98892a",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15637102c0b0d98892a-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d319c",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-180-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 180,
                    "ProductID": "67c1c15637102c0b0d98892a",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15637102c0b0d98892a-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d319d",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-247-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 247,
                    "ProductID": "67c1c15637102c0b0d98892a",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15637102c0b0d98892a-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d319e",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-248-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 248,
                    "ProductID": "67c1c15637102c0b0d98892a",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15637102c0b0d98892a-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": "MASALA CHICKEN PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d3195",
                "ImageURL": "",
                "MenuItemID": "67c1c15637102c0b0d98892a-MASALA CHICKEN PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c15637102c0b0d98892a",
                    "ProductModifierUnitChartID": "67c1c15637102c0b0d98892a-CHOOSE SIZE",
                    "ProductName": "MASALA CHICKEN PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c15637102c0b0d98892a-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MASALA CHICKEN PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31a1",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-145-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 145,
                    "ProductID": "67c1c10437102c0b0d988715",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988715-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31a2",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-146-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 146,
                    "ProductID": "67c1c10437102c0b0d988715",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988715-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31a3",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-147-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 147,
                    "ProductID": "67c1c10437102c0b0d988715",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988715-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 799,
                                "PickupPrice": 799,
                                "TablePrice": 799
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 799,
                        "PickupPrice": 799,
                        "TablePrice": 799
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31a4",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-148-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 148,
                    "ProductID": "67c1c10437102c0b0d988715",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988715-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1099,
                                "PickupPrice": 1099,
                                "TablePrice": 1099
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1099,
                        "PickupPrice": 1099,
                        "TablePrice": 1099
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31a5",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-149-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 149,
                    "ProductID": "67c1c10437102c0b0d988715",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988715-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31a6",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-150-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 150,
                    "ProductID": "67c1c10437102c0b0d988715",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988715-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1599,
                                "PickupPrice": 1599,
                                "TablePrice": 1599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1599,
                        "PickupPrice": 1599,
                        "TablePrice": 1599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31a7",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-235-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 235,
                    "ProductID": "67c1c10437102c0b0d988715",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988715-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31a8",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-236-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 236,
                    "ProductID": "67c1c10437102c0b0d988715",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988715-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": "BUFFALO CHICKEN PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d319f",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740999092.jpeg",
                "MenuItemID": "67c1c10437102c0b0d988715-BUFFALO CHICKEN PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10437102c0b0d988715",
                    "ProductModifierUnitChartID": "67c1c10437102c0b0d988715-CHOOSE SIZE",
                    "ProductName": "BUFFALO CHICKEN PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10437102c0b0d988715-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BUFFALO CHICKEN PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31ab",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-181-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 181,
                    "ProductID": "67c1c15737102c0b0d988930",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15737102c0b0d988930-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31ac",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-182-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 182,
                    "ProductID": "67c1c15737102c0b0d988930",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15737102c0b0d988930-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 399,
                                "PickupPrice": 399,
                                "TablePrice": 399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 399,
                        "PickupPrice": 399,
                        "TablePrice": 399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31ad",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-183-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 183,
                    "ProductID": "67c1c15737102c0b0d988930",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15737102c0b0d988930-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31ae",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-184-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 184,
                    "ProductID": "67c1c15737102c0b0d988930",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15737102c0b0d988930-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 899,
                                "PickupPrice": 899,
                                "TablePrice": 899
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 899,
                        "PickupPrice": 899,
                        "TablePrice": 899
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31af",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-185-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 185,
                    "ProductID": "67c1c15737102c0b0d988930",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15737102c0b0d988930-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1199,
                                "PickupPrice": 1199,
                                "TablePrice": 1199
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31b0",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-186-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 186,
                    "ProductID": "67c1c15737102c0b0d988930",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15737102c0b0d988930-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31b1",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-249-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 249,
                    "ProductID": "67c1c15737102c0b0d988930",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15737102c0b0d988930-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31b2",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-250-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 250,
                    "ProductID": "67c1c15737102c0b0d988930",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c15737102c0b0d988930-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": "CHILLI CHICKEN PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31a9",
                "ImageURL": "",
                "MenuItemID": "67c1c15737102c0b0d988930-CHILLI CHICKEN PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c15737102c0b0d988930",
                    "ProductModifierUnitChartID": "67c1c15737102c0b0d988930-CHOOSE SIZE",
                    "ProductName": "CHILLI CHICKEN PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c15737102c0b0d988930-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI CHICKEN PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31b5",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-151-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 151,
                    "ProductID": "67c1c10437102c0b0d988717",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988717-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31b6",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-152-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 152,
                    "ProductID": "67c1c10437102c0b0d988717",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988717-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 599,
                                "PickupPrice": 599,
                                "TablePrice": 599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 599,
                        "PickupPrice": 599,
                        "TablePrice": 599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31b7",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-153-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 153,
                    "ProductID": "67c1c10437102c0b0d988717",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988717-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 799,
                                "PickupPrice": 799,
                                "TablePrice": 799
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 799,
                        "PickupPrice": 799,
                        "TablePrice": 799
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31b8",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-154-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 154,
                    "ProductID": "67c1c10437102c0b0d988717",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988717-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1099,
                                "PickupPrice": 1099,
                                "TablePrice": 1099
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1099,
                        "PickupPrice": 1099,
                        "TablePrice": 1099
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31b9",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-155-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 155,
                    "ProductID": "67c1c10437102c0b0d988717",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988717-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1399,
                                "TablePrice": 1399
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31ba",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-156-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 156,
                    "ProductID": "67c1c10437102c0b0d988717",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988717-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1599,
                                "PickupPrice": 1599,
                                "TablePrice": 1599
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1599,
                        "PickupPrice": 1599,
                        "TablePrice": 1599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31bb",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-237-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 237,
                    "ProductID": "67c1c10437102c0b0d988717",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988717-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31bc",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-238-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 238,
                    "ProductID": "67c1c10437102c0b0d988717",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10437102c0b0d988717-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": "PERI PERI PANEER PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31b3",
                "ImageURL": "",
                "MenuItemID": "67c1c10437102c0b0d988717-PERI PERI PANEER PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10437102c0b0d988717",
                    "ProductModifierUnitChartID": "67c1c10437102c0b0d988717-CHOOSE SIZE",
                    "ProductName": "PERI PERI PANEER PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10437102c0b0d988717-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PERI PERI PANEER PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31bf",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-157-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 157,
                    "ProductID": "67c1c10537102c0b0d988719",
                    "UnitType": "UNIT",
                    "UnitTypeID": "67c1c0a337102c0b0d9884e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10537102c0b0d988719-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 1,
                                "TablePrice": 1
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 1,
                        "TablePrice": 1
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31c0",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-158-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 158,
                    "ProductID": "67c1c10537102c0b0d988719",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b6"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988546-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10537102c0b0d988719-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 749,
                                "PickupPrice": 749,
                                "TablePrice": 749
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 749,
                        "PickupPrice": 749,
                        "TablePrice": 749
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31c1",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-159-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 159,
                    "ProductID": "67c1c10537102c0b0d988719",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988512"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d988548-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10537102c0b0d988719-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 849,
                                "PickupPrice": 849,
                                "TablePrice": 849
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 849,
                        "PickupPrice": 849,
                        "TablePrice": 849
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31c2",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-160-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 160,
                    "ProductID": "67c1c10537102c0b0d988719",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988514"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                        "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10537102c0b0d988719-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1149,
                                "PickupPrice": 1149,
                                "TablePrice": 1149
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1149,
                        "PickupPrice": 1149,
                        "TablePrice": 1149
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31c3",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-161-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 161,
                    "ProductID": "67c1c10537102c0b0d988719",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988516"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10537102c0b0d988719-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1449,
                                "PickupPrice": 1449,
                                "TablePrice": 1449
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1449,
                        "PickupPrice": 1449,
                        "TablePrice": 1449
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31c4",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-162-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 162,
                    "ProductID": "67c1c10537102c0b0d988719",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": "67c1c0a837102c0b0d988518"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10537102c0b0d988719-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1648,
                                "PickupPrice": 1648,
                                "TablePrice": 1648
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1648,
                        "PickupPrice": 1648,
                        "TablePrice": 1648
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31c5",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-239-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 239,
                    "ProductID": "67c1c10537102c0b0d988719",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4b8"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                        "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                        "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                        "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10537102c0b0d988719-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1000,
                                "PickupPrice": 1000,
                                "TablePrice": 1000
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1000,
                        "PickupPrice": 1000,
                        "TablePrice": 1000
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31c6",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-240-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 240,
                    "ProductID": "67c1c10537102c0b0d988719",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": "67c1ea0f37102c0b0d98b4ba"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                        "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                        "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                        "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c10537102c0b0d988719-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1200,
                                "PickupPrice": 1200,
                                "TablePrice": 1200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1200,
                        "PickupPrice": 1200,
                        "TablePrice": 1200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": "PANEER TIKKA MASALA PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31bd",
                "ImageURL": "https://cdn.neurolage.com/production/web-neutripos/product-images/P_1740998765.jpeg",
                "MenuItemID": "67c1c10537102c0b0d988719-PANEER TIKKA MASALA PIZZA",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c10537102c0b0d988719",
                    "ProductModifierUnitChartID": "67c1c10537102c0b0d988719-CHOOSE SIZE",
                    "ProductName": "PANEER TIKKA MASALA PIZZA"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c10537102c0b0d988719-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PANEER TIKKA MASALA PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31c9",
                "ImageURL": "",
                "MenuItemID": "67c1c0a337102c0b0d9884e5-252-UNIT",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 252,
                    "ProductID": "67c1c16037102c0b0d988966",
                    "UnitType": "UNIT",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c16037102c0b0d988966-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "UNIT"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31ca",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b6-253-SML 7INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 253,
                    "ProductID": "67c1c16037102c0b0d988966",
                    "UnitType": "SML 7INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c16037102c0b0d988966-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 799,
                                "PickupPrice": 499,
                                "TablePrice": 499
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 799,
                        "PickupPrice": 499,
                        "TablePrice": 499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SML 7INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31cb",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988512-254-MED 9INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 254,
                    "ProductID": "67c1c16037102c0b0d988966",
                    "UnitType": "MED 9INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c16037102c0b0d988966-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 999,
                                "PickupPrice": 699,
                                "TablePrice": 699
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 999,
                        "PickupPrice": 699,
                        "TablePrice": 699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MED 9INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31cc",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988514-255-LRG 12INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 255,
                    "ProductID": "67c1c16037102c0b0d988966",
                    "UnitType": "LRG 12INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c16037102c0b0d988966-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1299,
                                "PickupPrice": 999,
                                "TablePrice": 999
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1299,
                        "PickupPrice": 999,
                        "TablePrice": 999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LRG 12INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31cd",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988516-256-X-LRG 15INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 256,
                    "ProductID": "67c1c16037102c0b0d988966",
                    "UnitType": "X-LRG 15INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c16037102c0b0d988966-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1599,
                                "PickupPrice": 1299,
                                "TablePrice": 1299
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1599,
                        "PickupPrice": 1299,
                        "TablePrice": 1299
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31ce",
                "ImageURL": "",
                "MenuItemID": "67c1c0a837102c0b0d988518-257-XXLRG 18INCH",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 257,
                    "ProductID": "67c1c16037102c0b0d988966",
                    "UnitType": "XXLRG 18INCH",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c16037102c0b0d988966-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1798,
                                "PickupPrice": 1499,
                                "TablePrice": 1499
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1798,
                        "PickupPrice": 1499,
                        "TablePrice": 1499
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31cf",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4b8-258-X-LRG 15INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 258,
                    "ProductID": "67c1c16037102c0b0d988966",
                    "UnitType": "X-LRG 15INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c16037102c0b0d988966-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1399,
                                "PickupPrice": 1099,
                                "TablePrice": 1099
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1099,
                        "TablePrice": 1099
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "X-LRG 15INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": null,
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31d0",
                "ImageURL": "",
                "MenuItemID": "67c1ea0f37102c0b0d98b4ba-259-XXLRG 18INCH(COLL)",
                "MetaData": {
                    "IsUnitChartItem": true,
                    "LocalUnitChartID": 259,
                    "ProductID": "67c1c16037102c0b0d988966",
                    "UnitType": "XXLRG 18INCH(COLL)",
                    "UnitTypeID": ""
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c16037102c0b0d988966-CHOOSE SIZE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1599,
                                "PickupPrice": 1299,
                                "TablePrice": 1299
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1599,
                        "PickupPrice": 1299,
                        "TablePrice": 1299
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "XXLRG 18INCH(COLL)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": "HOT HONEY PEPPERONI"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0379ba8a88fba2d31c7",
                "ImageURL": "",
                "MenuItemID": "67c1c16037102c0b0d988966-HOT HONEY PEPPERONI",
                "MetaData": {
                    "IsAddonProduct": false,
                    "ProductHasUnitChart": true,
                    "ProductID": "67c1c16037102c0b0d988966",
                    "ProductModifierUnitChartID": "67c1c16037102c0b0d988966-CHOOSE SIZE",
                    "ProductName": "HOT HONEY PEPPERONI"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "67c1c16037102c0b0d988966-CHOOSE SIZE"
                    ],
                    "Overrides": []
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HOT HONEY PEPPERONI"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3934812Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2f9a",
                "ImageURL": "",
                "MenuItemID": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0f937102c0b0d9886d6",
                    "ProductName": "DEEP PAN"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3934812Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT CRUST ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT CRUST ",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "DEEP PAN"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4264043Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fc1",
                "ImageURL": "",
                "MenuItemID": "67c1c11537102c0b0d988783-PERI PERI CHICKEN.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11537102c0b0d988783",
                    "ProductName": "PERI PERI CHICKEN."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4264043Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PERI PERI CHICKEN."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4342814Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fd2",
                "ImageURL": "",
                "MenuItemID": "67c1c11a37102c0b0d9887a3-FRESH GARLIC BLACK OLIVES.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11a37102c0b0d9887a3",
                    "ProductName": "FRESH GARLIC BLACK OLIVES."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4342814Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GARLIC BLACK OLIVES."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4512807Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3029",
                "ImageURL": "",
                "MenuItemID": "67c1d22b37102c0b0d989ee0-MANCHURIAN BASE..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d22b37102c0b0d989ee0",
                    "ProductName": "MANCHURIAN BASE.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4512807Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MANCHURIAN BASE.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4257734Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fbd",
                "ImageURL": "",
                "MenuItemID": "67c1c0fa37102c0b0d9886dd-CHEESE CRUST.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0fa37102c0b0d9886dd",
                    "ProductName": "CHEESE CRUST."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4257734Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 150,
                        "PickupPrice": 150,
                        "TablePrice": 150
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHEESE CRUST."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4389563Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fed",
                "ImageURL": "",
                "MenuItemID": "67c1d45537102c0b0d98a0d7-TIKKA MASALA BASE...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d45537102c0b0d98a0d7",
                    "ProductName": "TIKKA MASALA BASE..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4389563Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TIKKA MASALA BASE..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4406355Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ff6",
                "ImageURL": "",
                "MenuItemID": "67c1c14237102c0b0d98889b-PERI PERI CHICKEN...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14237102c0b0d98889b",
                    "ProductName": "PERI PERI CHICKEN..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4406355Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PERI PERI CHICKEN..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4350059Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fd3",
                "ImageURL": "",
                "MenuItemID": "67c1c11a37102c0b0d9887a6-PINEAPPLE.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11a37102c0b0d9887a6",
                    "ProductName": "PINEAPPLE."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4350059Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PINEAPPLE."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4497398Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d301d",
                "ImageURL": "",
                "MenuItemID": "67c1c11f37102c0b0d9887c4-MUSHROOM..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11f37102c0b0d9887c4",
                    "ProductName": "MUSHROOM.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4497398Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MUSHROOM.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4512807Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d302a",
                "ImageURL": "",
                "MenuItemID": "67c1d16d37102c0b0d989e4c-PERI PERI..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d16d37102c0b0d989e4c",
                    "ProductName": "PERI PERI.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4512807Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PERI PERI.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4378926Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fe3",
                "ImageURL": "",
                "MenuItemID": "67c1c14637102c0b0d9888bb-FRESH GINGER...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14637102c0b0d9888bb",
                    "ProductName": "FRESH GINGER..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4378926Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GINGER..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4454476Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d300b",
                "ImageURL": "",
                "MenuItemID": "67c1c15c37102c0b0d98894e-BBQ",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c15c37102c0b0d98894e",
                    "ProductName": "BBQ"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4454476Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4497398Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d301e",
                "ImageURL": "",
                "MenuItemID": "67c1c13f37102c0b0d988885-SWEETCORN..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c13f37102c0b0d988885",
                    "ProductName": "SWEETCORN.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4497398Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SWEETCORN.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4199857Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fac",
                "ImageURL": "",
                "MenuItemID": "67c1c11237102c0b0d98876f-GREEN PEPPER",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11237102c0b0d98876f",
                    "ProductName": "GREEN PEPPER"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4199857Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GREEN PEPPER"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.422327Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fb5",
                "ImageURL": "",
                "MenuItemID": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0f737102c0b0d9886c5",
                    "ProductName": "GARLIC BUTTER"
                },
                "ModifiedDate": "2025-03-05T06:33:22.422327Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GARLIC BUTTER"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4423742Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fff",
                "ImageURL": "",
                "MenuItemID": "67c1c15f37102c0b0d988962-EXTRA PATTY",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c15f37102c0b0d988962",
                    "ProductName": "EXTRA PATTY"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4423742Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-EXTRA PATTY",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 150,
                        "PickupPrice": 150,
                        "TablePrice": 150
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-EXTRA PATTY",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "EXTRA PATTY"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4512807Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3026",
                "ImageURL": "",
                "MenuItemID": "67c1c14137102c0b0d988895-PANEER..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14137102c0b0d988895",
                    "ProductName": "PANEER.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4512807Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PANEER.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4174712Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fa9",
                "ImageURL": "",
                "MenuItemID": "67c1c11137102c0b0d988769-ONION",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11137102c0b0d988769",
                    "ProductName": "ONION"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4174712Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "ONION"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4400844Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ff2",
                "ImageURL": "",
                "MenuItemID": "67c1c0fb37102c0b0d9886e3-CHEESE CRUST....",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0fb37102c0b0d9886e3",
                    "ProductName": "CHEESE CRUST...."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4400844Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 300,
                                "PickupPrice": 300,
                                "TablePrice": 300
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 300,
                        "PickupPrice": 300,
                        "TablePrice": 300
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHEESE CRUST...."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4429738Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3003",
                "ImageURL": "",
                "MenuItemID": "67c1c15a37102c0b0d98893e-LETTUCE",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c15a37102c0b0d98893e",
                    "ProductName": "LETTUCE"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4429738Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SALAD ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SALAD ",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LETTUCE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4477804Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3013",
                "ImageURL": "",
                "MenuItemID": "67c1c11c37102c0b0d9887b1-PERI PERI CHICKEN..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11c37102c0b0d9887b1",
                    "ProductName": "PERI PERI CHICKEN.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4477804Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PERI PERI CHICKEN.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4528778Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d302b",
                "ImageURL": "",
                "MenuItemID": "67c1d42137102c0b0d98a0a0-TIKKA MASALA BASE..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d42137102c0b0d98a0a0",
                    "ProductName": "TIKKA MASALA BASE.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4528778Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TIKKA MASALA BASE.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4193424Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2faa",
                "ImageURL": "",
                "MenuItemID": "67c1c11137102c0b0d98876b-MUSHROOM",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11137102c0b0d98876b",
                    "ProductName": "MUSHROOM"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4193424Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MUSHROOM"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4406355Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ff7",
                "ImageURL": "",
                "MenuItemID": "67c1c14237102c0b0d98889e-BBQ CHICKEN...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14237102c0b0d98889e",
                    "ProductName": "BBQ CHICKEN..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4406355Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ CHICKEN..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4471494Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3012",
                "ImageURL": "",
                "MenuItemID": "67c1c11b37102c0b0d9887ae-TANDOORI CHICKEN..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11b37102c0b0d9887ae",
                    "ProductName": "TANDOORI CHICKEN.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4471494Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TANDOORI CHICKEN.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4314238Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fce",
                "ImageURL": "",
                "MenuItemID": "67c1c11937102c0b0d98879b-JALAPENO.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11937102c0b0d98879b",
                    "ProductName": "JALAPENO."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4314238Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "JALAPENO."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4357752Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fd8",
                "ImageURL": "",
                "MenuItemID": "67c1d0dd37102c0b0d989ddd-PERI PERI.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d0dd37102c0b0d989ddd",
                    "ProductName": "PERI PERI."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4357752Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PERI PERI."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4477804Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3016",
                "ImageURL": "",
                "MenuItemID": "67c1c11d37102c0b0d9887b8-HAM..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11d37102c0b0d9887b8",
                    "ProductName": "HAM.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4477804Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HAM.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4502725Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3020",
                "ImageURL": "",
                "MenuItemID": "67c1c13f37102c0b0d988889-JALAPENO..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c13f37102c0b0d988889",
                    "ProductName": "JALAPENO.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4502725Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "JALAPENO.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4211786Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fb0",
                "ImageURL": "",
                "MenuItemID": "67c1c11337102c0b0d988777-GREEN CHILLI",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11337102c0b0d988777",
                    "ProductName": "GREEN CHILLI"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4211786Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GREEN CHILLI"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4373666Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fe2",
                "ImageURL": "",
                "MenuItemID": "67c1c14637102c0b0d9888b9-JALAPENO...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14637102c0b0d9888b9",
                    "ProductName": "JALAPENO..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4373666Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "JALAPENO..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4234124Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fb6",
                "ImageURL": "",
                "MenuItemID": "67c1d1d737102c0b0d989e9d-MANCHURIAN BASE",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d1d737102c0b0d989e9d",
                    "ProductName": "MANCHURIAN BASE"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4234124Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MANCHURIAN BASE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4394882Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fee",
                "ImageURL": "",
                "MenuItemID": "67c1cfc537102c0b0d989bb4-BBQ BASE...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1cfc537102c0b0d989bb4",
                    "ProductName": "BBQ BASE..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4394882Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ BASE..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4502725Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d301f",
                "ImageURL": "",
                "MenuItemID": "67c1c13f37102c0b0d988887-GREEN PEPPER..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c13f37102c0b0d988887",
                    "ProductName": "GREEN PEPPER.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4502725Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GREEN PEPPER.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4373666Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fe1",
                "ImageURL": "",
                "MenuItemID": "67c1c14537102c0b0d9888b7-GREEN PEPPER...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14537102c0b0d9888b7",
                    "ProductName": "GREEN PEPPER..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4373666Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GREEN PEPPER..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4411537Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ff8",
                "ImageURL": "",
                "MenuItemID": "67c1c14337102c0b0d9888a3-LAMB DONNER...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14337102c0b0d9888a3",
                    "ProductName": "LAMB DONNER..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4411537Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LAMB DONNER..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3977745Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fa5",
                "ImageURL": "",
                "MenuItemID": "67c1c11037102c0b0d988763-BACON",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11037102c0b0d988763",
                    "ProductName": "BACON"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3977745Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BACON"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4264043Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fc0",
                "ImageURL": "",
                "MenuItemID": "67c1c11437102c0b0d988781-TANDOORI CHICKEN.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11437102c0b0d988781",
                    "ProductName": "TANDOORI CHICKEN."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4264043Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TANDOORI CHICKEN."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4296839Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fca",
                "ImageURL": "",
                "MenuItemID": "67c1c11737102c0b0d988793-ONION.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11737102c0b0d988793",
                    "ProductName": "ONION."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4296839Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "ONION."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4502725Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3023",
                "ImageURL": "",
                "MenuItemID": "67c1c14037102c0b0d98888f-GREEN CHILLI..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14037102c0b0d98888f",
                    "ProductName": "GREEN CHILLI.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4502725Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GREEN CHILLI.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4512807Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3024",
                "ImageURL": "",
                "MenuItemID": "67c1c14037102c0b0d988891-FRESH GARLIC BLACK OLIVES..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14037102c0b0d988891",
                    "ProductName": "FRESH GARLIC BLACK OLIVES.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4512807Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GARLIC BLACK OLIVES.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4342814Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fd1",
                "ImageURL": "",
                "MenuItemID": "67c1c11a37102c0b0d9887a1-GREEN CHILLI.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11a37102c0b0d9887a1",
                    "ProductName": "GREEN CHILLI."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4342814Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GREEN CHILLI."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4384279Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fe6",
                "ImageURL": "",
                "MenuItemID": "67c1c14737102c0b0d9888c2-FRESH GARLIC BLACK OLIVES...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14737102c0b0d9888c2",
                    "ProductName": "FRESH GARLIC BLACK OLIVES..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4384279Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GARLIC BLACK OLIVES..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4211786Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fb1",
                "ImageURL": "",
                "MenuItemID": "67c1c11337102c0b0d988779-FRESH GARLIC BLACK OLIVES",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11337102c0b0d988779",
                    "ProductName": "FRESH GARLIC BLACK OLIVES"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4211786Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GARLIC BLACK OLIVES"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4280026Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fc8",
                "ImageURL": "",
                "MenuItemID": "67c1c11737102c0b0d988791-SPICY BEEF.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11737102c0b0d988791",
                    "ProductName": "SPICY BEEF."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4280026Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SPICY BEEF."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4302058Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fcc",
                "ImageURL": "",
                "MenuItemID": "67c1c11837102c0b0d988797-SWEETCORN.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11837102c0b0d988797",
                    "ProductName": "SWEETCORN."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4302058Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SWEETCORN."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4406355Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ff5",
                "ImageURL": "",
                "MenuItemID": "67c1c14237102c0b0d988899-TANDOORI CHICKEN...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14237102c0b0d988899",
                    "ProductName": "TANDOORI CHICKEN..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4406355Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TANDOORI CHICKEN..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3952356Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2f9f",
                "ImageURL": "",
                "MenuItemID": "67c1c10e37102c0b0d988756-TANDOORI CHICKEN",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c10e37102c0b0d988756",
                    "ProductName": "TANDOORI CHICKEN"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3952356Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TANDOORI CHICKEN"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4152221Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fa6",
                "ImageURL": "",
                "MenuItemID": "67c1c11037102c0b0d988765-PEPPERONI",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11037102c0b0d988765",
                    "ProductName": "PEPPERONI"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4152221Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPPERONI"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4307244Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fcd",
                "ImageURL": "",
                "MenuItemID": "67c1c11837102c0b0d988799-GREEN PEPPER.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11837102c0b0d988799",
                    "ProductName": "GREEN PEPPER."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4307244Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GREEN PEPPER."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4448335Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3009",
                "ImageURL": "",
                "MenuItemID": "67c1c15b37102c0b0d98894a-KETCHUP",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c15b37102c0b0d98894a",
                    "ProductName": "KETCHUP"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4448335Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "KETCHUP"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4217865Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fb2",
                "ImageURL": "",
                "MenuItemID": "67c1c11437102c0b0d98877b-PINEAPPLE",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11437102c0b0d98877b",
                    "ProductName": "PINEAPPLE"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4217865Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PINEAPPLE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4217865Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fb3",
                "ImageURL": "",
                "MenuItemID": "67c1c11437102c0b0d98877d-PANEER",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11437102c0b0d98877d",
                    "ProductName": "PANEER"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4217865Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PANEER"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4240429Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fb8",
                "ImageURL": "",
                "MenuItemID": "67c1d3ab37102c0b0d98a01c-TIKKA MASALA BASE",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d3ab37102c0b0d98a01c",
                    "ProductName": "TIKKA MASALA BASE"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4240429Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TIKKA MASALA BASE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3972002Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fa3",
                "ImageURL": "",
                "MenuItemID": "67c1c10f37102c0b0d98875f-HAM",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c10f37102c0b0d98875f",
                    "ProductName": "HAM"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3972002Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HAM"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4240429Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fb7",
                "ImageURL": "",
                "MenuItemID": "67c1c0f837102c0b0d9886ca-PERI PERI",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0f837102c0b0d9886ca",
                    "ProductName": "PERI PERI"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4240429Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 130,
                                "PickupPrice": 130,
                                "TablePrice": 130
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 130,
                                "PickupPrice": 130,
                                "TablePrice": 130
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 130,
                                "PickupPrice": 130,
                                "TablePrice": 130
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 130,
                                "PickupPrice": 130,
                                "TablePrice": 130
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 130,
                                "PickupPrice": 130,
                                "TablePrice": 130
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PERI PERI"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4246209Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fb9",
                "ImageURL": "",
                "MenuItemID": "67c1c0f937102c0b0d9886d4-BBQ BASE",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0f937102c0b0d9886d4",
                    "ProductName": "BBQ BASE"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4246209Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ BASE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4342814Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fd0",
                "ImageURL": "",
                "MenuItemID": "67c1c11937102c0b0d98879f-FRESH TOMATO.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11937102c0b0d98879f",
                    "ProductName": "FRESH TOMATO."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4342814Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH TOMATO."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4389563Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2feb",
                "ImageURL": "",
                "MenuItemID": "67c1d26737102c0b0d989f08-MANCHURIAN BASE...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d26737102c0b0d989f08",
                    "ProductName": "MANCHURIAN BASE..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4389563Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MANCHURIAN BASE..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4389563Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fec",
                "ImageURL": "",
                "MenuItemID": "67c1d19f37102c0b0d989e7f-PERI PERI...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d19f37102c0b0d989e7f",
                    "ProductName": "PERI PERI..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4389563Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PERI PERI..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3952356Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2f9e",
                "ImageURL": "",
                "MenuItemID": "67c1c10d37102c0b0d988754-PLAIN CHICKEN",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c10d37102c0b0d988754",
                    "ProductName": "PLAIN CHICKEN"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3952356Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PLAIN CHICKEN"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4205772Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fad",
                "ImageURL": "",
                "MenuItemID": "67c1c11237102c0b0d988771-JALAPENO",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11237102c0b0d988771",
                    "ProductName": "JALAPENO"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4205772Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "JALAPENO"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4362959Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fdc",
                "ImageURL": "",
                "MenuItemID": "67c1c0ed37102c0b0d988684-DOUBLE UP",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0ed37102c0b0d988684",
                    "ProductName": "DOUBLE UP"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4362959Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988544-SELECT FOR DOUBLE UP",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 150,
                        "PickupPrice": 150,
                        "TablePrice": 150
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988544-SELECT FOR DOUBLE UP",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "DOUBLE UP"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4491937Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d301a",
                "ImageURL": "",
                "MenuItemID": "67c1c11e37102c0b0d9887c0-SPICY BEEF..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11e37102c0b0d9887c0",
                    "ProductName": "SPICY BEEF.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4491937Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SPICY BEEF.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4502725Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3022",
                "ImageURL": "",
                "MenuItemID": "67c1c14037102c0b0d98888d-FRESH TOMATO..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14037102c0b0d98888d",
                    "ProductName": "FRESH TOMATO.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4502725Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH TOMATO.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3940678Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2f9b",
                "ImageURL": "",
                "MenuItemID": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0f937102c0b0d9886d8",
                    "ProductName": "THIN CRUST"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3940678Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT CRUST ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT CRUST ",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "THIN CRUST"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3977745Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fa4",
                "ImageURL": "",
                "MenuItemID": "67c1c10f37102c0b0d988761-SPICY PORK",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c10f37102c0b0d988761",
                    "ProductName": "SPICY PORK"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3977745Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SPICY PORK"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4384279Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fe7",
                "ImageURL": "",
                "MenuItemID": "67c1c14737102c0b0d9888c4-PINEAPPLE...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14737102c0b0d9888c4",
                    "ProductName": "PINEAPPLE..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4384279Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PINEAPPLE..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4477804Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3015",
                "ImageURL": "",
                "MenuItemID": "67c1c11c37102c0b0d9887b6-LAMB DONNER..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11c37102c0b0d9887b6",
                    "ProductName": "LAMB DONNER.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4477804Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LAMB DONNER.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4491937Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3019",
                "ImageURL": "",
                "MenuItemID": "67c1c11e37102c0b0d9887be-PEPPERONI..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11e37102c0b0d9887be",
                    "ProductName": "PEPPERONI.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4491937Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPPERONI.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3958266Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fa0",
                "ImageURL": "",
                "MenuItemID": "67c1c10e37102c0b0d988758-PERI PERI CHICKEN",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c10e37102c0b0d988758",
                    "ProductName": "PERI PERI CHICKEN"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3958266Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PERI PERI CHICKEN"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4269341Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fc5",
                "ImageURL": "",
                "MenuItemID": "67c1c11637102c0b0d98878b-SPICY PORK.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11637102c0b0d98878b",
                    "ProductName": "SPICY PORK."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4269341Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SPICY PORK."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4417271Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ffd",
                "ImageURL": "",
                "MenuItemID": "67c1c14437102c0b0d9888af-SPICY BEEF...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14437102c0b0d9888af",
                    "ProductName": "SPICY BEEF..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4417271Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SPICY BEEF..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4423742Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3001",
                "ImageURL": "",
                "MenuItemID": "67c1c15f37102c0b0d988964-SAUCE ON CHIPS",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c15f37102c0b0d988964",
                    "ProductName": "SAUCE ON CHIPS"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4423742Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SAUCE ON CHIPS"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4357752Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fd7",
                "ImageURL": "",
                "MenuItemID": "67c1d1fb37102c0b0d989ebf-MANCHURIAN BASE.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d1fb37102c0b0d989ebf",
                    "ProductName": "MANCHURIAN BASE."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4357752Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MANCHURIAN BASE."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4357752Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fd9",
                "ImageURL": "",
                "MenuItemID": "67c1d3ff37102c0b0d98a08c-TIKKA MASALA BASE.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1d3ff37102c0b0d98a08c",
                    "ProductName": "TIKKA MASALA BASE."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4357752Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TIKKA MASALA BASE."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4357752Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fda",
                "ImageURL": "",
                "MenuItemID": "67c1cf6b37102c0b0d989b3e-BBQ BASE.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1cf6b37102c0b0d989b3e",
                    "ProductName": "BBQ BASE."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4357752Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ BASE."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4411537Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ffa",
                "ImageURL": "",
                "MenuItemID": "67c1c14337102c0b0d9888a9-SPICY PORK...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14337102c0b0d9888a9",
                    "ProductName": "SPICY PORK..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4411537Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SPICY PORK..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4411537Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ffb",
                "ImageURL": "",
                "MenuItemID": "67c1c14337102c0b0d9888ab-BACON...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14337102c0b0d9888ab",
                    "ProductName": "BACON..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4411537Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BACON..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4378926Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fe4",
                "ImageURL": "",
                "MenuItemID": "67c1c14637102c0b0d9888bd-FRESH TOMATO...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14637102c0b0d9888bd",
                    "ProductName": "FRESH TOMATO..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4378926Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH TOMATO..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4448335Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3008",
                "ImageURL": "",
                "MenuItemID": "67c1c15b37102c0b0d988948-CHILLI",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c15b37102c0b0d988948",
                    "ProductName": "CHILLI"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4448335Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4205772Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fae",
                "ImageURL": "",
                "MenuItemID": "67c1c11237102c0b0d988773-FRESH GINGER",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11237102c0b0d988773",
                    "ProductName": "FRESH GINGER"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4205772Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GINGER"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4417271Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ffc",
                "ImageURL": "",
                "MenuItemID": "67c1c14437102c0b0d9888ad-PEPPERONI...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14437102c0b0d9888ad",
                    "ProductName": "PEPPERONI..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4417271Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPPERONI..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4442333Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3007",
                "ImageURL": "",
                "MenuItemID": "67c1c15b37102c0b0d988946-GARLIC MAYO..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c15b37102c0b0d988946",
                    "ProductName": "GARLIC MAYO.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4442333Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GARLIC MAYO.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4528778Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d302c",
                "ImageURL": "",
                "MenuItemID": "67c1cf9937102c0b0d989b61-BBQ BASE..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1cf9937102c0b0d989b61",
                    "ProductName": "BBQ BASE.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4528778Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ BASE.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3940678Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2f9c",
                "ImageURL": "",
                "MenuItemID": "67c1c0fa37102c0b0d9886da-CHEESE CRUST",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0fa37102c0b0d9886da",
                    "ProductName": "CHEESE CRUST"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3940678Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT CRUST ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 150,
                                "PickupPrice": 150,
                                "TablePrice": 150
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 175,
                                "PickupPrice": 175,
                                "TablePrice": 175
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT CRUST",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 250,
                                "PickupPrice": 250,
                                "TablePrice": 250
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT CRUST ",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT CRUST",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHEESE CRUST"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4466199Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d300f",
                "ImageURL": "",
                "MenuItemID": "67c1c0fa37102c0b0d9886df-CHEESE CRUST..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0fa37102c0b0d9886df",
                    "ProductName": "CHEESE CRUST.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4466199Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 200,
                        "PickupPrice": 200,
                        "TablePrice": 200
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHEESE CRUST.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4491937Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3018",
                "ImageURL": "",
                "MenuItemID": "67c1c11e37102c0b0d9887bc-BACON..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11e37102c0b0d9887bc",
                    "ProductName": "BACON.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4491937Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BACON.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4497398Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d301c",
                "ImageURL": "",
                "MenuItemID": "67c1c11f37102c0b0d9887c2-ONION..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11f37102c0b0d9887c2",
                    "ProductName": "ONION.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4497398Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "ONION.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4302058Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fcb",
                "ImageURL": "",
                "MenuItemID": "67c1c11837102c0b0d988795-MUSHROOM.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11837102c0b0d988795",
                    "ProductName": "MUSHROOM."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4302058Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MUSHROOM."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4368279Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fdf",
                "ImageURL": "",
                "MenuItemID": "67c1c14537102c0b0d9888b3-MUSHROOM...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14537102c0b0d9888b3",
                    "ProductName": "MUSHROOM..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4368279Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MUSHROOM..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4378926Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fe5",
                "ImageURL": "",
                "MenuItemID": "67c1c14737102c0b0d9888c0-GREEN CHILLI...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14737102c0b0d9888c0",
                    "ProductName": "GREEN CHILLI..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4378926Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GREEN CHILLI..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4411537Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ff9",
                "ImageURL": "",
                "MenuItemID": "67c1c14337102c0b0d9888a7-HAM...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14337102c0b0d9888a7",
                    "ProductName": "HAM..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4411537Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HAM..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4471494Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3011",
                "ImageURL": "",
                "MenuItemID": "67c1c11b37102c0b0d9887ac-PLAIN CHICKEN..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11b37102c0b0d9887ac",
                    "ProductName": "PLAIN CHICKEN.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4471494Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PLAIN CHICKEN.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4257734Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fbf",
                "ImageURL": "",
                "MenuItemID": "67c1c11437102c0b0d98877f-PLAIN CHICKEN.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11437102c0b0d98877f",
                    "ProductName": "PLAIN CHICKEN."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4257734Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PLAIN CHICKEN."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4350059Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fd4",
                "ImageURL": "",
                "MenuItemID": "67c1c11b37102c0b0d9887aa-PANEER.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11b37102c0b0d9887aa",
                    "ProductName": "PANEER."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4350059Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PANEER."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4548858Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3036",
                "ImageURL": "",
                "MenuItemID": "67c1c0fb37102c0b0d9886e1-CHEESE CRUST...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c0fb37102c0b0d9886e1",
                    "ProductName": "CHEESE CRUST..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4548858Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 225,
                                "PickupPrice": 225,
                                "TablePrice": 225
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 225,
                        "PickupPrice": 225,
                        "TablePrice": 225
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHEESE CRUST..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3963904Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fa1",
                "ImageURL": "",
                "MenuItemID": "67c1c10e37102c0b0d98875a-BBQ CHICKEN",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c10e37102c0b0d98875a",
                    "ProductName": "BBQ CHICKEN"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3963904Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ CHICKEN"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4211786Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2faf",
                "ImageURL": "",
                "MenuItemID": "67c1c11337102c0b0d988775-FRESH TOMATO",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11337102c0b0d988775",
                    "ProductName": "FRESH TOMATO"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4211786Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH TOMATO"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4269341Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fc4",
                "ImageURL": "",
                "MenuItemID": "67c1c11637102c0b0d988789-HAM.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11637102c0b0d988789",
                    "ProductName": "HAM."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4269341Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HAM."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.427474Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fc7",
                "ImageURL": "",
                "MenuItemID": "67c1c11737102c0b0d98878f-PEPPERONI.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11737102c0b0d98878f",
                    "ProductName": "PEPPERONI."
                },
                "ModifiedDate": "2025-03-05T06:33:22.427474Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPPERONI."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4368279Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fde",
                "ImageURL": "",
                "MenuItemID": "67c1c14437102c0b0d9888b1-ONION...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14437102c0b0d9888b1",
                    "ProductName": "ONION..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4368279Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "ONION..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4384279Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fe8",
                "ImageURL": "",
                "MenuItemID": "67c1c14837102c0b0d9888c6-PANEER...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14837102c0b0d9888c6",
                    "ProductName": "PANEER..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4384279Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PANEER..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4429738Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3004",
                "ImageURL": "",
                "MenuItemID": "67c1c15a37102c0b0d988940-ONION....",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c15a37102c0b0d988940",
                    "ProductName": "ONION...."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4429738Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SALAD ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SALAD ",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "ONION...."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4169101Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fa7",
                "ImageURL": "",
                "MenuItemID": "67c1c11037102c0b0d988767-SPICY BEEF",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11037102c0b0d988767",
                    "ProductName": "SPICY BEEF"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4169101Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SPICY BEEF"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4269341Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fc3",
                "ImageURL": "",
                "MenuItemID": "67c1c11537102c0b0d988787-LAMB DONNER.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11537102c0b0d988787",
                    "ProductName": "LAMB DONNER."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4269341Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LAMB DONNER."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.427474Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fc6",
                "ImageURL": "",
                "MenuItemID": "67c1c11637102c0b0d98878d-BACON.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11637102c0b0d98878d",
                    "ProductName": "BACON."
                },
                "ModifiedDate": "2025-03-05T06:33:22.427474Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BACON."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4373666Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fe0",
                "ImageURL": "",
                "MenuItemID": "67c1c14537102c0b0d9888b5-SWEETCORN...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14537102c0b0d9888b5",
                    "ProductName": "SWEETCORN..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4373666Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SWEETCORN..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4477804Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3014",
                "ImageURL": "",
                "MenuItemID": "67c1c11c37102c0b0d9887b3-BBQ CHICKEN..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11c37102c0b0d9887b3",
                    "ProductName": "BBQ CHICKEN.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4477804Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ CHICKEN.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4269341Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fc2",
                "ImageURL": "",
                "MenuItemID": "67c1c11537102c0b0d988785-BBQ CHICKEN.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11537102c0b0d988785",
                    "ProductName": "BBQ CHICKEN."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4269341Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ CHICKEN."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4442333Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3006",
                "ImageURL": "",
                "MenuItemID": "67c1c15a37102c0b0d988944-MAYO",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c15a37102c0b0d988944",
                    "ProductName": "MAYO"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4442333Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MAYO"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4486715Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3017",
                "ImageURL": "",
                "MenuItemID": "67c1c11d37102c0b0d9887ba-SPICY PORK..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11d37102c0b0d9887ba",
                    "ProductName": "SPICY PORK.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4486715Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SPICY PORK.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4512807Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3025",
                "ImageURL": "",
                "MenuItemID": "67c1c14137102c0b0d988893-PINEAPPLE..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14137102c0b0d988893",
                    "ProductName": "PINEAPPLE.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4512807Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PINEAPPLE.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.3972002Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fa2",
                "ImageURL": "",
                "MenuItemID": "67c1c10f37102c0b0d98875d-LAMB DONNER",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c10f37102c0b0d98875d",
                    "ProductName": "LAMB DONNER"
                },
                "ModifiedDate": "2025-03-05T06:33:22.3972002Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LAMB DONNER"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4199857Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fab",
                "ImageURL": "",
                "MenuItemID": "67c1c11137102c0b0d98876d-SWEETCORN",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11137102c0b0d98876d",
                    "ProductName": "SWEETCORN"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4199857Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 30,
                                "PickupPrice": 30,
                                "TablePrice": 30
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 30,
                        "PickupPrice": 30,
                        "TablePrice": 30
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "SWEETCORN"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4342814Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2fcf",
                "ImageURL": "",
                "MenuItemID": "67c1c11937102c0b0d98879d-FRESH GINGER.",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c11937102c0b0d98879d",
                    "ProductName": "FRESH GINGER."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4342814Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 50,
                                "PickupPrice": 50,
                                "TablePrice": 50
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 50,
                        "PickupPrice": 50,
                        "TablePrice": 50
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GINGER."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4406355Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d2ff4",
                "ImageURL": "",
                "MenuItemID": "67c1c14137102c0b0d988897-PLAIN CHICKEN...",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c14137102c0b0d988897",
                    "ProductName": "PLAIN CHICKEN..."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4406355Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 140,
                                "PickupPrice": 140,
                                "TablePrice": 140
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 140,
                        "PickupPrice": 140,
                        "TablePrice": 140
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PLAIN CHICKEN..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4454476Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d300a",
                "ImageURL": "",
                "MenuItemID": "67c1c15c37102c0b0d98894c-BURGER SAUCE",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c15c37102c0b0d98894c",
                    "ProductName": "BURGER SAUCE"
                },
                "ModifiedDate": "2025-03-05T06:33:22.4454476Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 1,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 1,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b937102c0b0d988553-SAUCE ",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BURGER SAUCE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "2025-03-05T06:33:22.4502725Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0329ba8a88fba2d3021",
                "ImageURL": "",
                "MenuItemID": "67c1c13f37102c0b0d98888b-FRESH GINGER..",
                "MetaData": {
                    "IsAddonProduct": true,
                    "ProductID": "67c1c13f37102c0b0d98888b",
                    "ProductName": "FRESH GINGER.."
                },
                "ModifiedDate": "2025-03-05T06:33:22.4502725Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 100,
                        "PickupPrice": 100,
                        "TablePrice": 100
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                            "Quantity": {
                                "ChargeAbove": 0,
                                "IsMinPermittedOptional": false,
                                "MaxPermitted": 20,
                                "MaxPermittedUnique": 0,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GINGER.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "PUNJABI STYLE GARLIC PIZZA BREAD.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d3220",
                "ImageURL": "",
                "MenuItemID": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                "MetaData": {
                    "ProductID": "67c1c14d37102c0b0d9888ee",
                    "ProductName": "PUNJABI STYLE GARLIC PIZZA BREAD..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PUNJABI STYLE GARLIC PIZZA BREAD.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "MASALA CHICKEN PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03e9ba8a88fba2d3221",
                "ImageURL": "",
                "MenuItemID": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15737102c0b0d98892e",
                    "ProductName": "MASALA CHICKEN PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MASALA CHICKEN PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "TWO CANS OF DRINKS"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03e9ba8a88fba2d3222",
                "ImageURL": "",
                "MenuItemID": "67c1c14b37102c0b0d9888dd-TWO CANS OF DRINKS",
                "MetaData": {
                    "ProductID": "67c1c14b37102c0b0d9888dd",
                    "ProductName": "TWO CANS OF DRINKS",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TWO CANS OF DRINKS"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "FRESH GARLIC..."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03e9ba8a88fba2d3223",
                "ImageURL": "",
                "MenuItemID": "67c5e4fcc672a66e93ae9c01-FRESH GARLIC...",
                "MetaData": {
                    "ProductID": "67c5e4fcc672a66e93ae9c01",
                    "ProductName": "FRESH GARLIC...",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GARLIC..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "DESI MEAT FEAST PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03e9ba8a88fba2d3224",
                "ImageURL": "",
                "MenuItemID": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15237102c0b0d98890e",
                    "ProductName": "DESI MEAT FEAST PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "DESI MEAT FEAST PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "NO EXTRA TOPPINGS"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03e9ba8a88fba2d3225",
                "ImageURL": "",
                "MenuItemID": "67c1c14b37102c0b0d9888e2-NO EXTRA TOPPINGS",
                "MetaData": {
                    "ProductID": "67c1c14b37102c0b0d9888e2",
                    "ProductName": "NO EXTRA TOPPINGS",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "NO EXTRA TOPPINGS"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "PANEER TIKKA MASALA  PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03f9ba8a88fba2d3226",
                "ImageURL": "",
                "MenuItemID": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15337102c0b0d988918",
                    "ProductName": "PANEER TIKKA MASALA  PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PANEER TIKKA MASALA  PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "HAWAIIAN PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03f9ba8a88fba2d3227",
                "ImageURL": "",
                "MenuItemID": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14e37102c0b0d9888f2",
                    "ProductName": "HAWAIIAN PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HAWAIIAN PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "BUFFALO CHICKEN  PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03f9ba8a88fba2d3228",
                "ImageURL": "",
                "MenuItemID": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15337102c0b0d988914",
                    "ProductName": "BUFFALO CHICKEN  PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BUFFALO CHICKEN  PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "GARLIC BREAD WITH CHEESE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03f9ba8a88fba2d3229",
                "ImageURL": "",
                "MenuItemID": "67c1c15437102c0b0d98891c-GARLIC BREAD WITH CHEESE",
                "MetaData": {
                    "ProductID": "67c1c15437102c0b0d98891c",
                    "ProductName": "GARLIC BREAD WITH CHEESE",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "GARLIC BREAD WITH CHEESE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "MARGHERITHA PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03f9ba8a88fba2d322a",
                "ImageURL": "",
                "MenuItemID": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14c37102c0b0d9888e4",
                    "ProductName": "MARGHERITHA PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MARGHERITHA PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "MEAT FEAST PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0409ba8a88fba2d322b",
                "ImageURL": "",
                "MenuItemID": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14f37102c0b0d9888fa",
                    "ProductName": "MEAT FEAST PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MEAT FEAST PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "AMERICAN HOT PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0409ba8a88fba2d322c",
                "ImageURL": "",
                "MenuItemID": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15037102c0b0d988904",
                    "ProductName": "AMERICAN HOT PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "AMERICAN HOT PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "PANEER PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0409ba8a88fba2d322d",
                "ImageURL": "",
                "MenuItemID": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15137102c0b0d98890a",
                    "ProductName": "PANEER PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PANEER PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "BBQ MEAT FEAST PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0409ba8a88fba2d322e",
                "ImageURL": "",
                "MenuItemID": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14f37102c0b0d9888fc",
                    "ProductName": "BBQ MEAT FEAST PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ MEAT FEAST PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "CHILLI CHICKEN PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0409ba8a88fba2d322f",
                "ImageURL": "",
                "MenuItemID": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15837102c0b0d988934",
                    "ProductName": "CHILLI CHICKEN PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI CHICKEN PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "PUNJABI SPICY PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0419ba8a88fba2d3230",
                "ImageURL": "",
                "MenuItemID": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14d37102c0b0d9888f0",
                    "ProductName": "PUNJABI SPICY PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PUNJABI SPICY PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "KEBAB SPECIAL PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0419ba8a88fba2d3231",
                "ImageURL": "",
                "MenuItemID": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15137102c0b0d988906",
                    "ProductName": "KEBAB SPECIAL PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "KEBAB SPECIAL PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "8 PCS GARLIC BREAD WITH CHEESE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0419ba8a88fba2d3232",
                "ImageURL": "",
                "MenuItemID": "67c1c15437102c0b0d98891a-8 PCS GARLIC BREAD WITH CHEESE",
                "MetaData": {
                    "ProductID": "67c1c15437102c0b0d98891a",
                    "ProductName": "8 PCS GARLIC BREAD WITH CHEESE",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "8 PCS GARLIC BREAD WITH CHEESE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "BLACK OLIVE.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0419ba8a88fba2d3233",
                "ImageURL": "",
                "MenuItemID": "67c5fa66c672a66e93aeb089-BLACK OLIVE..",
                "MetaData": {
                    "ProductID": "67c5fa66c672a66e93aeb089",
                    "ProductName": "BLACK OLIVE..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BLACK OLIVE.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "VEGGIE CHILLI TURMERIC PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0419ba8a88fba2d3234",
                "ImageURL": "",
                "MenuItemID": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15637102c0b0d988928",
                    "ProductName": "VEGGIE CHILLI TURMERIC PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "VEGGIE CHILLI TURMERIC PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "12 PCS GARLIC BREAD WITH CHEESE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0429ba8a88fba2d3235",
                "ImageURL": "",
                "MenuItemID": "67c1c14837102c0b0d9888c8-12 PCS GARLIC BREAD WITH CHEESE",
                "MetaData": {
                    "ProductID": "67c1c14837102c0b0d9888c8",
                    "ProductName": "12 PCS GARLIC BREAD WITH CHEESE",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "12 PCS GARLIC BREAD WITH CHEESE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "8 PCS GARLIC BREAD"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0429ba8a88fba2d3236",
                "ImageURL": "",
                "MenuItemID": "67c1c14937102c0b0d9888d4-8 PCS GARLIC BREAD",
                "MetaData": {
                    "ProductID": "67c1c14937102c0b0d9888d4",
                    "ProductName": "8 PCS GARLIC BREAD",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "8 PCS GARLIC BREAD"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "POTATO WEDGES"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0429ba8a88fba2d3237",
                "ImageURL": "",
                "MenuItemID": "67c1c14937102c0b0d9888d7-POTATO WEDGES",
                "MetaData": {
                    "ProductID": "67c1c14937102c0b0d9888d7",
                    "ProductName": "POTATO WEDGES",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "POTATO WEDGES"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "NO EXTRA TOPPINGS..."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0429ba8a88fba2d3238",
                "ImageURL": "",
                "MenuItemID": "67c5e42cc672a66e93ae9b78-NO EXTRA TOPPINGS...",
                "MetaData": {
                    "ProductID": "67c5e42cc672a66e93ae9b78",
                    "ProductName": "NO EXTRA TOPPINGS...",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "NO EXTRA TOPPINGS..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "VEGETARIAN HOT PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0429ba8a88fba2d3239",
                "ImageURL": "",
                "MenuItemID": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14c37102c0b0d9888e8",
                    "ProductName": "VEGETARIAN HOT PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "VEGETARIAN HOT PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "FRESH GARLIC"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0439ba8a88fba2d323a",
                "ImageURL": "",
                "MenuItemID": "67c5e452c672a66e93ae9b92-FRESH GARLIC",
                "MetaData": {
                    "ProductID": "67c5e452c672a66e93ae9b92",
                    "ProductName": "FRESH GARLIC",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GARLIC"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "FRESH GARLIC.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0439ba8a88fba2d323b",
                "ImageURL": "",
                "MenuItemID": "67c5e4dac672a66e93ae9be8-FRESH GARLIC..",
                "MetaData": {
                    "ProductID": "67c5e4dac672a66e93ae9be8",
                    "ProductName": "FRESH GARLIC..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GARLIC.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "PEPPERONI LOVER PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0439ba8a88fba2d323c",
                "ImageURL": "",
                "MenuItemID": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14e37102c0b0d9888f4",
                    "ProductName": "PEPPERONI LOVER PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPPERONI LOVER PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "MASALA HOT PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0439ba8a88fba2d323d",
                "ImageURL": "",
                "MenuItemID": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15537102c0b0d988922",
                    "ProductName": "MASALA HOT PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MASALA HOT PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "DESI STYLE PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0449ba8a88fba2d323e",
                "ImageURL": "",
                "MenuItemID": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14c37102c0b0d9888ea",
                    "ProductName": "DESI STYLE PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "DESI STYLE PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "CHICKEN SPECIAL PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0449ba8a88fba2d323f",
                "ImageURL": "",
                "MenuItemID": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15037102c0b0d988900",
                    "ProductName": "CHICKEN SPECIAL PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHICKEN SPECIAL PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "TWO 1.5 LTR DRINKS"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0449ba8a88fba2d3240",
                "ImageURL": "",
                "MenuItemID": "67c1c14937102c0b0d9888cc-TWO 1.5 LTR DRINKS",
                "MetaData": {
                    "ProductID": "67c1c14937102c0b0d9888cc",
                    "ProductName": "TWO 1.5 LTR DRINKS",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TWO 1.5 LTR DRINKS"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "BLACK OLIVE."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0449ba8a88fba2d3241",
                "ImageURL": "",
                "MenuItemID": "67c5fa3ec672a66e93aeb063-BLACK OLIVE.",
                "MetaData": {
                    "ProductID": "67c5fa3ec672a66e93aeb063",
                    "ProductName": "BLACK OLIVE.",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BLACK OLIVE."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "CHILLI PANEER PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0449ba8a88fba2d3242",
                "ImageURL": "",
                "MenuItemID": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15337102c0b0d988912",
                    "ProductName": "CHILLI PANEER PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CHILLI PANEER PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "PERI PERI PANEER PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0459ba8a88fba2d3243",
                "ImageURL": "",
                "MenuItemID": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15337102c0b0d988916",
                    "ProductName": "PERI PERI PANEER PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 200,
                                "PickupPrice": 200,
                                "TablePrice": 200
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PERI PERI PANEER PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "TANDOORI CHICKEN HOT PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0459ba8a88fba2d3244",
                "ImageURL": "",
                "MenuItemID": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15037102c0b0d988902",
                    "ProductName": "TANDOORI CHICKEN HOT PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "TANDOORI CHICKEN HOT PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "1.5 LTR DRINKS"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0459ba8a88fba2d3245",
                "ImageURL": "",
                "MenuItemID": "67c1c14a37102c0b0d9888d9-1.5 LTR DRINKS",
                "MetaData": {
                    "ProductID": "67c1c14a37102c0b0d9888d9",
                    "ProductName": "1.5 LTR DRINKS",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "1.5 LTR DRINKS"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "HOT HOUSE SPECIAL PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0459ba8a88fba2d3246",
                "ImageURL": "",
                "MenuItemID": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14f37102c0b0d9888f8",
                    "ProductName": "HOT HOUSE SPECIAL PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HOT HOUSE SPECIAL PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "PEPPERONI SPECIAL PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0459ba8a88fba2d3247",
                "ImageURL": "",
                "MenuItemID": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14e37102c0b0d9888f6",
                    "ProductName": "PEPPERONI SPECIAL PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "PEPPERONI SPECIAL PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "ONE LARGE CURRY FRIES"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0469ba8a88fba2d3248",
                "ImageURL": "",
                "MenuItemID": "67c1c14b37102c0b0d9888e0-ONE LARGE CURRY FRIES",
                "MetaData": {
                    "ProductID": "67c1c14b37102c0b0d9888e0",
                    "ProductName": "ONE LARGE CURRY FRIES",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "ONE LARGE CURRY FRIES"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "VEGETARIAN PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0469ba8a88fba2d3249",
                "ImageURL": "",
                "MenuItemID": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14c37102c0b0d9888e6",
                    "ProductName": "VEGETARIAN PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "VEGETARIAN PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "LARGE CHIPS"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0469ba8a88fba2d324a",
                "ImageURL": "",
                "MenuItemID": "67c1c14a37102c0b0d9888db-LARGE CHIPS",
                "MetaData": {
                    "ProductID": "67c1c14a37102c0b0d9888db",
                    "ProductName": "LARGE CHIPS",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "LARGE CHIPS"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "NO EXTRA TOPPINGS."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0469ba8a88fba2d324b",
                "ImageURL": "",
                "MenuItemID": "67c5e3eec672a66e93ae9b41-NO EXTRA TOPPINGS.",
                "MetaData": {
                    "ProductID": "67c5e3eec672a66e93ae9b41",
                    "ProductName": "NO EXTRA TOPPINGS.",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "NO EXTRA TOPPINGS."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "KING CHICKEN PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0469ba8a88fba2d324c",
                "ImageURL": "",
                "MenuItemID": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15137102c0b0d988908",
                    "ProductName": "KING CHICKEN PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "KING CHICKEN PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "6 REG FRIES"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0479ba8a88fba2d324d",
                "ImageURL": "",
                "MenuItemID": "67c1c14937102c0b0d9888cf-6 REG FRIES",
                "MetaData": {
                    "ProductID": "67c1c14937102c0b0d9888cf",
                    "ProductName": "6 REG FRIES",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "6 REG FRIES"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "FRESH GARLIC."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0479ba8a88fba2d324e",
                "ImageURL": "",
                "MenuItemID": "67c5e4bac672a66e93ae9bd3-FRESH GARLIC.",
                "MetaData": {
                    "ProductID": "67c5e4bac672a66e93ae9bd3",
                    "ProductName": "FRESH GARLIC.",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FRESH GARLIC."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "NO EXTRA TOPPINGS.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0479ba8a88fba2d324f",
                "ImageURL": "",
                "MenuItemID": "67c5e40ec672a66e93ae9b56-NO EXTRA TOPPINGS..",
                "MetaData": {
                    "ProductID": "67c5e40ec672a66e93ae9b56",
                    "ProductName": "NO EXTRA TOPPINGS..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "NO EXTRA TOPPINGS.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "DESI CHICKEN PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0479ba8a88fba2d3250",
                "ImageURL": "",
                "MenuItemID": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15237102c0b0d98890c",
                    "ProductName": "DESI CHICKEN PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "DESI CHICKEN PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "HOT HONEY PEPPERONI PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0479ba8a88fba2d3251",
                "ImageURL": "",
                "MenuItemID": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c15237102c0b0d988910",
                    "ProductName": "HOT HONEY PEPPERONI PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 100,
                                "PickupPrice": 100,
                                "TablePrice": 100
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HOT HONEY PEPPERONI PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "BBQ CHICKEN PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0489ba8a88fba2d3252",
                "ImageURL": "",
                "MenuItemID": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14f37102c0b0d9888fe",
                    "ProductName": "BBQ CHICKEN PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BBQ CHICKEN PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "2 REGULAR CHIPS"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0489ba8a88fba2d3253",
                "ImageURL": "",
                "MenuItemID": "67c1c14837102c0b0d9888ca-2 REGULAR CHIPS",
                "MetaData": {
                    "ProductID": "67c1c14837102c0b0d9888ca",
                    "ProductName": "2 REGULAR CHIPS",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SIDES",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SIDES",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "2 REGULAR CHIPS"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "BLACK OLIVE"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0489ba8a88fba2d3254",
                "ImageURL": "",
                "MenuItemID": "67c5e822c672a66e93ae9f08-BLACK OLIVE",
                "MetaData": {
                    "ProductID": "67c5e822c672a66e93ae9f08",
                    "ProductName": "BLACK OLIVE",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BLACK OLIVE"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "BLACK OLIVE..."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0489ba8a88fba2d3255",
                "ImageURL": "",
                "MenuItemID": "67c60226c672a66e93aeb919-BLACK OLIVE...",
                "MetaData": {
                    "ProductID": "67c60226c672a66e93aeb919",
                    "ProductName": "BLACK OLIVE...",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "BLACK OLIVE..."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": [],
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "VEGETARIAN SUPREME PIZZA.."
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": [],
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": [],
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": [],
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f0499ba8a88fba2d3256",
                "ImageURL": "",
                "MenuItemID": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                "MetaData": {
                    "ProductID": "67c1c14d37102c0b0d9888ec",
                    "ProductName": "VEGETARIAN SUPREME PIZZA..",
                    "UnitChartID": "67c1c0a337102c0b0d9884e5",
                    "UnitChartName": "UNIT"
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": null,
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": [
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        },
                        {
                            "Context": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "CorePrice": 0,
                            "Price": {
                                "DeliveryPrice": 0,
                                "PickupPrice": 0,
                                "TablePrice": 0
                            }
                        }
                    ],
                    "Price": {
                        "DeliveryPrice": 0,
                        "PickupPrice": 0,
                        "TablePrice": 0
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": [
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 4,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 4,
                                "MaxPermittedUnique": 4,
                                "MinPermitted": 4,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 1,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 1,
                                "MaxPermittedUnique": 1,
                                "MinPermitted": 1,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 3,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 3,
                                "MaxPermittedUnique": 3,
                                "MinPermitted": 3,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        },
                        {
                            "ContextType": "MODIFIERGROUP",
                            "ContextValue": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                            "Quantity": {
                                "ChargeAbove": 2,
                                "IsMinPermittedOptional": true,
                                "MaxPermitted": 2,
                                "MaxPermittedUnique": 2,
                                "MinPermitted": 2,
                                "MinPermittedUnique": 0,
                                "RefundUnder": 0
                            }
                        }
                    ],
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 0,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 0,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "VEGETARIAN SUPREME PIZZA.."
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "HOT HOUSE DEAL 2 (XXL 18 INCH)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31d1",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)",
                "MetaData": {
                    "DealProductID": "67c1c17637102c0b0d9889ad",
                    "IsDealProduct": true,
                    "ProductID": "67c1c17637102c0b0d9889ad",
                    "ProductName": "HOT HOUSE DEAL 2 (XXL 18 INCH)",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                        "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SIDES"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 7198,
                        "PickupPrice": 699,
                        "TablePrice": 6998
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HOT HOUSE DEAL 2 (XXL 18 INCH)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "HOT HOUSE DEAL 1 (XL 15 INCH)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31d4",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)",
                "MetaData": {
                    "DealProductID": "67c1c16237102c0b0d98896e",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16237102c0b0d98896e",
                    "ProductName": "HOT HOUSE DEAL 1 (XL 15 INCH)",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-",
                        "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-",
                        "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                        "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 5199,
                        "PickupPrice": 4999,
                        "TablePrice": 4999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "HOT HOUSE DEAL 1 (XL 15 INCH)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "MEGA DEAL (L 12 INCH)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31d9",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)",
                "MetaData": {
                    "DealProductID": "67c1c16437102c0b0d988977",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16437102c0b0d988977",
                    "ProductName": "MEGA DEAL (L 12 INCH)",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                        "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 4499,
                        "PickupPrice": 4299,
                        "TablePrice": 4299
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "MEGA DEAL (L 12 INCH)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "FAMILY DEAL-1(L-12 INCH)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31dc",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)",
                "MetaData": {
                    "DealProductID": "67c1c16537102c0b0d988979",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16537102c0b0d988979",
                    "ProductName": "FAMILY DEAL-1(L-12 INCH)",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                        "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 2799,
                        "PickupPrice": 2599,
                        "TablePrice": 2599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FAMILY DEAL-1(L-12 INCH)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "FAMILY DEAL-2(XL-15 INCH)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31df",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)",
                "MetaData": {
                    "DealProductID": "67c1c16737102c0b0d98897b",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16737102c0b0d98897b",
                    "ProductName": "FAMILY DEAL-2(XL-15 INCH)",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                        "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SIDES"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 3499,
                        "PickupPrice": 3299,
                        "TablePrice": 3299
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FAMILY DEAL-2(XL-15 INCH)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "THE BIG EATER (XL 15 INCH)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31e2",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)",
                "MetaData": {
                    "DealProductID": "67c1c16837102c0b0d98897d",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16837102c0b0d98897d",
                    "ProductName": "THE BIG EATER (XL 15 INCH)",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                        "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SIDES"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1998,
                        "PickupPrice": 1798,
                        "TablePrice": 1798
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "THE BIG EATER (XL 15 INCH)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "CURLY DEAL (XL 15 INCH)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31e5",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)",
                "MetaData": {
                    "DealProductID": "67c1c16937102c0b0d988982",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16937102c0b0d988982",
                    "ProductName": "CURLY DEAL (XL 15 INCH)",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                        "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SIDES"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 2199,
                        "PickupPrice": 1998,
                        "TablePrice": 1998
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "CURLY DEAL (XL 15 INCH)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "FEED 8 DEAL (XXL 18 INCH)"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31e8",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)",
                "MetaData": {
                    "DealProductID": "67c1c16a37102c0b0d988986",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16a37102c0b0d988986",
                    "ProductName": "FEED 8 DEAL (XXL 18 INCH)",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                        "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SIDES"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 3999,
                        "PickupPrice": 3799,
                        "TablePrice": 3799
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "FEED 8 DEAL (XXL 18 INCH)"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "(X3) M 9 INCH PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31eb",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA",
                "MetaData": {
                    "DealProductID": "67c1c16c37102c0b0d98898a",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16c37102c0b0d98898a",
                    "ProductName": "(X3) M 9 INCH PIZZA",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 2199,
                        "PickupPrice": 1998,
                        "TablePrice": 1998
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "(X3) M 9 INCH PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "(X3) L 12 INCH PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31ed",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA",
                "MetaData": {
                    "DealProductID": "67c1c16d37102c0b0d98898e",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16d37102c0b0d98898e",
                    "ProductName": "(X3) L 12 INCH PIZZA",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 2999,
                        "PickupPrice": 2799,
                        "TablePrice": 2799
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "(X3) L 12 INCH PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "(X3) XL 15 INCH PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31ef",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA",
                "MetaData": {
                    "DealProductID": "67c1c16e37102c0b0d988991",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16e37102c0b0d988991",
                    "ProductName": "(X3) XL 15 INCH PIZZA",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 3899,
                        "PickupPrice": 3699,
                        "TablePrice": 3699
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "(X3) XL 15 INCH PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "(X3) XXL 18 INCH PIZZA"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31f1",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA",
                "MetaData": {
                    "DealProductID": "67c1c16f37102c0b0d988996",
                    "IsDealProduct": true,
                    "ProductID": "67c1c16f37102c0b0d988996",
                    "ProductName": "(X3) XXL 18 INCH PIZZA",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 4199,
                        "PickupPrice": 3999,
                        "TablePrice": 3999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "(X3) XXL 18 INCH PIZZA"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e9-WICKED PIZZA DEAL"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "(X2) 7 INCH WICKED  PIZZA DEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31f3",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL",
                "MetaData": {
                    "DealProductID": "67c1c17037102c0b0d988999",
                    "IsDealProduct": true,
                    "ProductID": "67c1c17037102c0b0d988999",
                    "ProductName": "(X2) 7 INCH WICKED  PIZZA DEAL",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "(X2) 7 INCH WICKED  PIZZA DEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e9-WICKED PIZZA DEAL"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "(X2) 9 INCH WICKED PIZZA DEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31f5",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL",
                "MetaData": {
                    "DealProductID": "67c1c17137102c0b0d98899c",
                    "IsDealProduct": true,
                    "ProductID": "67c1c17137102c0b0d98899c",
                    "ProductName": "(X2) 9 INCH WICKED PIZZA DEAL",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1599,
                        "PickupPrice": 1599,
                        "TablePrice": 1599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "(X2) 9 INCH WICKED PIZZA DEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e9-WICKED PIZZA DEAL"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "(X2) 12 INCH WICKED PIZZA DEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31f7",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL",
                "MetaData": {
                    "DealProductID": "67c1c17237102c0b0d9889a1",
                    "IsDealProduct": true,
                    "ProductID": "67c1c17237102c0b0d9889a1",
                    "ProductName": "(X2) 12 INCH WICKED PIZZA DEAL",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1998,
                        "PickupPrice": 1998,
                        "TablePrice": 1998
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "(X2) 12 INCH WICKED PIZZA DEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e9-WICKED PIZZA DEAL"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "(X2) 15 INCH WICKED PIZZA DEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31f9",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL",
                "MetaData": {
                    "DealProductID": "67c1c17337102c0b0d9889a3",
                    "IsDealProduct": true,
                    "ProductID": "67c1c17337102c0b0d9889a3",
                    "ProductName": "(X2) 15 INCH WICKED PIZZA DEAL",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 2599,
                        "PickupPrice": 2599,
                        "TablePrice": 2599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "(X2) 15 INCH WICKED PIZZA DEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": [
                    "67c1c0cb37102c0b0d9885e9-WICKED PIZZA DEAL"
                ],
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "(X2) 18 INCH WICKED PIZZA DEAL"
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31fb",
                "ImageURL": "",
                "MenuItemID": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL",
                "MetaData": {
                    "DealProductID": "67c1c17437102c0b0d9889a6",
                    "IsDealProduct": true,
                    "ProductID": "67c1c17437102c0b0d9889a6",
                    "ProductName": "(X2) 18 INCH WICKED PIZZA DEAL",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 2999,
                        "PickupPrice": 2999,
                        "TablePrice": 2999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": "(X2) 18 INCH WICKED PIZZA DEAL"
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d31fd",
                "ImageURL": "",
                "MenuItemID": "Deal-67c5cb44c672a66e93ae8afe-",
                "MetaData": {
                    "DealProductID": "67c5cb44c672a66e93ae8afe",
                    "IsDealProduct": true,
                    "ProductID": "67c5cb44c672a66e93ae8afe",
                    "ProductName": "",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c5cb44c672a66e93ae8afe--SELECT CRUST",
                        "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                        "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                        "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                        "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                        "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 799,
                        "PickupPrice": 799,
                        "TablePrice": 799
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": ""
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d3204",
                "ImageURL": "",
                "MenuItemID": "Deal-67c60b40c672a66e93aec346-",
                "MetaData": {
                    "DealProductID": "67c60b40c672a66e93aec346",
                    "IsDealProduct": true,
                    "ProductID": "67c60b40c672a66e93aec346",
                    "ProductName": "",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c60b40c672a66e93aec346--SELECT CRUST ",
                        "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                        "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                        "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                        "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                        "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 999,
                        "PickupPrice": 999,
                        "TablePrice": 999
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": ""
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d320b",
                "ImageURL": "",
                "MenuItemID": "Deal-67c630c0c672a66e93aedbac-",
                "MetaData": {
                    "DealProductID": "67c630c0c672a66e93aedbac",
                    "IsDealProduct": true,
                    "ProductID": "67c630c0c672a66e93aedbac",
                    "ProductName": "",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c630c0c672a66e93aedbac--SELECT CRUST",
                        "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                        "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                        "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                        "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                        "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1199,
                        "PickupPrice": 1199,
                        "TablePrice": 1199
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": ""
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d3212",
                "ImageURL": "",
                "MenuItemID": "Deal-67c71008c672a66e93af0eeb-",
                "MetaData": {
                    "DealProductID": "67c71008c672a66e93af0eeb",
                    "IsDealProduct": true,
                    "ProductID": "67c71008c672a66e93af0eeb",
                    "ProductName": "",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c71008c672a66e93af0eeb--SELECT CRUST",
                        "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                        "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                        "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                        "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                        "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1399,
                        "PickupPrice": 1399,
                        "TablePrice": 1399
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": ""
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            },
            {
                "AggregatedProductRating": 0,
                "Allergens": null,
                "BeverageInfo": {
                    "AlcoholbyVolume": 0,
                    "CaffeineAmount": 0,
                    "CoffeeInfo": {
                        "CoffeeBeanOrigin": null
                    }
                },
                "BundleItems": null,
                "CategoryIDs": null,
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": ""
                },
                "DishInfo": {
                    "Classifications": {
                        "Additives": null,
                        "AlcoholicItem": 0,
                        "CanServeAlone": false,
                        "DietaryLabelInfo": null,
                        "FoolBusinessOperator": {
                            "Address": "",
                            "Name": ""
                        },
                        "Ingredients": null,
                        "InstructionsForUse": "",
                        "IsHalal": false,
                        "IsHighFatSaltSugar": false,
                        "IsVegetarian": false,
                        "PreparationType": "",
                        "SpiceLevel": 0
                    }
                },
                "ExternalData": "",
                "ID": "67c7f03d9ba8a88fba2d3219",
                "ImageURL": "",
                "MenuItemID": "Deal-67c715c4c672a66e93af1221-",
                "MetaData": {
                    "DealProductID": "67c715c4c672a66e93af1221",
                    "IsDealProduct": true,
                    "ProductID": "67c715c4c672a66e93af1221",
                    "ProductName": "",
                    "UnitChartID": "",
                    "UnitChartName": ""
                },
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupRules": {
                    "IDs": [
                        "Deal-67c715c4c672a66e93af1221--SELECT CRUST",
                        "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                        "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                        "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                        "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                        "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING"
                    ],
                    "Overrides": null
                },
                "NutrientData": {
                    "Calories": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "CaloriesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "Carbohydrates": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Fat": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Kilojules": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "KilojulesperServing": {
                        "DisplayType": 0,
                        "EnergyInterval": {
                            "Lower": 0,
                            "Upper": 0
                        }
                    },
                    "NetQuantity": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "NumberofServingIntervals": {
                        "Lower": 0,
                        "Upper": 0
                    },
                    "NumberofServings": 0,
                    "Protein": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Salt": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "SaturatedFattyAcids": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "ServingSize": {
                        "CountInterval": {
                            "Count": {
                                "UnitType": ""
                            },
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            }
                        },
                        "MeasurementType": "",
                        "VolumenInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Volume": {
                                "UnitType": ""
                            }
                        },
                        "WeightedInterval": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    },
                    "Sugar": {
                        "Amount": {
                            "Interval": {
                                "Lower": 0,
                                "Upper": 0
                            },
                            "Weight": {
                                "UnitType": ""
                            }
                        }
                    }
                },
                "PriceInfo": {
                    "ContainerDeposit": 0,
                    "CorePrice": 0,
                    "Overrides": null,
                    "Price": {
                        "DeliveryPrice": 1599,
                        "PickupPrice": 1599,
                        "TablePrice": 1599
                    },
                    "PricebyUnit": ""
                },
                "ProductInfo": {
                    "CountriesofOrigin": null,
                    "Gtin": "",
                    "MerchantID": "",
                    "Plu": "",
                    "ProductTraits": null,
                    "ProductType": "",
                    "TargetMarket": 0
                },
                "QuantityInfo": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "RewardGroupRules": {
                    "Overrides": null,
                    "Reward": {
                        "CustomValue": 0,
                        "DefaultValue": 0,
                        "IsMutiplierRequired": false,
                        "MultiplierValue": 0,
                        "Type": ""
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "SuspensionRules": {
                    "Suspension": {
                        "IsSuspended": false,
                        "Reason": "",
                        "SuspendedUntil": 0
                    },
                    "SuspensionOverride": null
                },
                "TaxInfo": {
                    "Taxrate": 0,
                    "VATRateInPercentage": 0
                },
                "Title": {
                    "en": ""
                },
                "TotalReviews": 0,
                "VisibilityInfo": {
                    "VisibilityHours": {
                        "EndDate": "",
                        "HoursofWeek": null,
                        "StartDate": ""
                    }
                }
            }
        ],
        "Menu": [
            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "Description": null,
                "ID": "67c7f02f9ba8a88fba2d2f98",
                "MenuAvailability": {
                    "Friday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Monday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Saturday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Sunday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Thursday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Tuesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Wednesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    }
                },
                "MenuCategoryIDs": [
                    "67c1c0c837102c0b0d9885d4-STARTERS",
                    "67c1c0c837102c0b0d9885d6-CHICKEN STARTERS"
                ],
                "MenuID": "67c1c0c437102c0b0d9885ab-STARTERS",
                "MetaData": {
                    "Name": "STARTERS",
                    "RefNo": "67c1c0c437102c0b0d9885ab"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": null,
                "Title": {
                    "en": "STARTERS"
                },
                "VerticalID": "632983961250b0105020d561"
            },
            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "Description": null,
                "ID": "67c7f02f9ba8a88fba2d2f98",
                "MenuAvailability": {
                    "Friday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Monday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Saturday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Sunday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Thursday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Tuesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Wednesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    }
                },
                "MenuCategoryIDs": [
                    "67c1c0c937102c0b0d9885d8-GRILLED CHICKEN WINGS"
                ],
                "MenuID": "67c1c0c437102c0b0d9885ad-GRILLED CHICKEN WINGS",
                "MetaData": {
                    "Name": "GRILLED CHICKEN WINGS",
                    "RefNo": "67c1c0c437102c0b0d9885ad"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": null,
                "Title": {
                    "en": "GRILLED CHICKEN WINGS"
                },
                "VerticalID": "632983961250b0105020d561"
            },
            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "Description": null,
                "ID": "67c7f02f9ba8a88fba2d2f98",
                "MenuAvailability": {
                    "Friday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Monday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Saturday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Sunday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Thursday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Tuesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Wednesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    }
                },
                "MenuCategoryIDs": [
                    "67c1c0c937102c0b0d9885da-BURGERS"
                ],
                "MenuID": "67c1c0c437102c0b0d9885af-BURGERS",
                "MetaData": {
                    "Name": "BURGERS",
                    "RefNo": "67c1c0c437102c0b0d9885af"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": null,
                "Title": {
                    "en": "BURGERS"
                },
                "VerticalID": "632983961250b0105020d561"
            },
            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "Description": null,
                "ID": "67c7f02f9ba8a88fba2d2f98",
                "MenuAvailability": {
                    "Friday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Monday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Saturday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Sunday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Thursday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Tuesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Wednesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    }
                },
                "MenuCategoryIDs": [
                    "67c1c0c937102c0b0d9885dc-FULLY LOADED OPTIONS"
                ],
                "MenuID": "67c1c0c537102c0b0d9885b1-FULLY LOADED OPTIONS",
                "MetaData": {
                    "Name": "FULLY LOADED OPTIONS",
                    "RefNo": "67c1c0c537102c0b0d9885b1"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": null,
                "Title": {
                    "en": "FULLY LOADED OPTIONS"
                },
                "VerticalID": "632983961250b0105020d561"
            },
            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "Description": null,
                "ID": "67c7f02f9ba8a88fba2d2f98",
                "MenuAvailability": {
                    "Friday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Monday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Saturday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Sunday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Thursday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Tuesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Wednesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    }
                },
                "MenuCategoryIDs": [
                    "67c1c0c937102c0b0d9885df-DIPS"
                ],
                "MenuID": "67c1c0c537102c0b0d9885b3-DIPS",
                "MetaData": {
                    "Name": "DIPS",
                    "RefNo": "67c1c0c537102c0b0d9885b3"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": null,
                "Title": {
                    "en": "DIPS"
                },
                "VerticalID": "632983961250b0105020d561"
            },
            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "Description": null,
                "ID": "67c7f02f9ba8a88fba2d2f98",
                "MenuAvailability": {
                    "Friday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Monday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Saturday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Sunday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Thursday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Tuesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Wednesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    }
                },
                "MenuCategoryIDs": [
                    "67c1c0ca37102c0b0d9885e1-DRINKS"
                ],
                "MenuID": "67c1c0c537102c0b0d9885b5-DRINKS",
                "MetaData": {
                    "Name": "DRINKS",
                    "RefNo": "67c1c0c537102c0b0d9885b5"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": null,
                "Title": {
                    "en": "DRINKS"
                },
                "VerticalID": "632983961250b0105020d561"
            },
            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "Description": null,
                "ID": "67c7f02f9ba8a88fba2d2f98",
                "MenuAvailability": {
                    "Friday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Monday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Saturday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Sunday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Thursday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Tuesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Wednesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    }
                },
                "MenuCategoryIDs": [
                    "67c1c0ca37102c0b0d9885e3-DESSERTS"
                ],
                "MenuID": "67c1c0c537102c0b0d9885b7-DESSERTS",
                "MetaData": {
                    "Name": "DESSERTS",
                    "RefNo": "67c1c0c537102c0b0d9885b7"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": null,
                "Title": {
                    "en": "DESSERTS"
                },
                "VerticalID": "632983961250b0105020d561"
            },
            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "Description": null,
                "ID": "67c7f02f9ba8a88fba2d2f98",
                "MenuAvailability": {
                    "Friday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Monday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Saturday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Sunday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Thursday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Tuesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Wednesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    }
                },
                "MenuCategoryIDs": [
                    "67c1c0cb37102c0b0d9885e5-PIZZA"
                ],
                "MenuID": "67c1c0c537102c0b0d9885b9-PIZZA",
                "MetaData": {
                    "Name": "PIZZA",
                    "RefNo": "67c1c0c537102c0b0d9885b9"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": null,
                "Title": {
                    "en": "PIZZA"
                },
                "VerticalID": "632983961250b0105020d561"
            },
            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "Description": null,
                "ID": "67c7f02f9ba8a88fba2d2f98",
                "MenuAvailability": {
                    "Friday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Monday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Saturday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Sunday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Thursday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Tuesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Wednesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    }
                },
                "MenuCategoryIDs": [
                    "67c1c0cb37102c0b0d9885e7-PIZZA DEALS"
                ],
                "MenuID": "67c1c0c637102c0b0d9885bb-PIZZA DEALS",
                "MetaData": {
                    "Name": "PIZZA DEALS",
                    "RefNo": "67c1c0c637102c0b0d9885bb"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": null,
                "Title": {
                    "en": "PIZZA DEALS"
                },
                "VerticalID": "632983961250b0105020d561"
            },
            {
                "CreatedBy": "",
                "CreatedDate": "2025-03-05T06:33:19.7600083Z",
                "Description": null,
                "ID": "67c7f02f9ba8a88fba2d2f98",
                "MenuAvailability": {
                    "Friday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Monday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Saturday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Sunday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Thursday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Tuesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    },
                    "Wednesday": {
                        "EndTime": "23:59:59",
                        "StartTime": "00:00:00"
                    }
                },
                "MenuCategoryIDs": [
                    "67c1c0cb37102c0b0d9885e9-WICKED PIZZA DEAL"
                ],
                "MenuID": "67c1c0c637102c0b0d9885bd-WICKED PIZZA DEAL",
                "MetaData": {
                    "Name": "WICKED PIZZA DEAL",
                    "RefNo": "67c1c0c637102c0b0d9885bd"
                },
                "ModifiedBy": "",
                "ModifiedDate": "2025-03-05T06:33:19.7600083Z",
                "StoreID": "67c14076877eae2d49f80772",
                "SubTitle": null,
                "Title": {
                    "en": "WICKED PIZZA DEAL"
                },
                "VerticalID": "632983961250b0105020d561"
            }
        ],
        "ModifierGroups": [
            {
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3092",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0fb37102c0b0d9886e5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupID": "67c1c0fb37102c0b0d9886e5-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-1-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-2-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-3-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-4-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-5-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-6-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-187-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-188-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d309c",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0fc37102c0b0d9886e7"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupID": "67c1c0fc37102c0b0d9886e7-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-7-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-8-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-9-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-10-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-11-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-12-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-189-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-190-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d30a6",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0fc37102c0b0d9886e9"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupID": "67c1c0fc37102c0b0d9886e9-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-13-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-14-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-15-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-16-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-17-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-18-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-191-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-192-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d30b0",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c15437102c0b0d98891e"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupID": "67c1c15437102c0b0d98891e-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-163-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-164-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-165-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-166-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-167-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-168-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-241-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-242-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d30ba",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0fd37102c0b0d9886eb"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupID": "67c1c0fd37102c0b0d9886eb-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-19-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-20-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-21-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-22-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-23-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-24-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-193-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-194-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d30c4",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0fd37102c0b0d9886ed"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupID": "67c1c0fd37102c0b0d9886ed-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-25-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-26-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-27-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-28-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-29-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-30-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-195-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-196-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d30ce",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0fd37102c0b0d9886ef"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupID": "67c1c0fd37102c0b0d9886ef-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-31-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-32-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-33-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-34-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-35-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-36-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-197-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-198-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d30d8",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0fe37102c0b0d9886f1"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupID": "67c1c0fe37102c0b0d9886f1-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-37-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-38-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-39-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-40-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-41-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-42-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-199-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-200-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d30e2",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10237102c0b0d98870b"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupID": "67c1c10237102c0b0d98870b-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-115-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-116-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-117-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-118-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-119-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-120-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-225-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-226-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1311292Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d30ec",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10337102c0b0d988713"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1311292Z",
                "ModifierGroupID": "67c1c10337102c0b0d988713-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-139-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-140-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-141-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-142-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-143-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-144-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-233-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-234-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d30f6",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c15537102c0b0d988924"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupID": "67c1c15537102c0b0d988924-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-169-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-170-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-171-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-172-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-173-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-174-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-243-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-251-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3100",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0fe37102c0b0d9886f3"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupID": "67c1c0fe37102c0b0d9886f3-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-43-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-44-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-45-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-46-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-47-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-48-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-201-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-202-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d310a",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0fe37102c0b0d9886f5"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupID": "67c1c0fe37102c0b0d9886f5-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-49-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-50-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-51-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-52-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-53-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-54-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-203-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-204-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3114",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0ff37102c0b0d9886f7"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupID": "67c1c0ff37102c0b0d9886f7-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-55-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-56-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-57-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-58-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-59-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-60-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-205-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-206-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d311e",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0ff37102c0b0d9886f9"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupID": "67c1c0ff37102c0b0d9886f9-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-61-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-62-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-63-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-64-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-65-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-66-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-207-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-208-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.131922Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3128",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c0ff37102c0b0d9886fb"
                },
                "ModifiedDate": "2025-03-05T06:33:27.131922Z",
                "ModifierGroupID": "67c1c0ff37102c0b0d9886fb-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-67-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-68-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-69-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-70-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-71-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-72-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-209-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-210-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3132",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10037102c0b0d9886fd"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupID": "67c1c10037102c0b0d9886fd-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-73-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-74-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-75-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-76-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-77-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-78-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-211-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-212-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d313c",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10037102c0b0d9886ff"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupID": "67c1c10037102c0b0d9886ff-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-79-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-80-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-81-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-82-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-83-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-84-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-213-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-214-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3146",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10337102c0b0d98870f"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupID": "67c1c10337102c0b0d98870f-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-127-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-128-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-129-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-130-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-131-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-132-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-229-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-230-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3150",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10037102c0b0d988701"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupID": "67c1c10037102c0b0d988701-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-85-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-86-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-87-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-88-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-89-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-90-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-215-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-216-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d315a",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10137102c0b0d988703"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupID": "67c1c10137102c0b0d988703-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-91-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-92-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-93-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-94-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-95-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-96-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-217-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-218-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.132449Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3164",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10137102c0b0d988705"
                },
                "ModifiedDate": "2025-03-05T06:33:27.132449Z",
                "ModifierGroupID": "67c1c10137102c0b0d988705-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-97-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-98-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-99-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-100-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-101-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-102-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-219-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-220-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d316e",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10137102c0b0d988707"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupID": "67c1c10137102c0b0d988707-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-103-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-104-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-105-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-106-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-107-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-108-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-221-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-222-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3178",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10337102c0b0d988711"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupID": "67c1c10337102c0b0d988711-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-133-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-134-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-135-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-136-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-137-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-138-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-231-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-232-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3182",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10237102c0b0d988709"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupID": "67c1c10237102c0b0d988709-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-109-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-110-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-111-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-112-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-113-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-114-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-223-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-224-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d318c",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10237102c0b0d98870d"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupID": "67c1c10237102c0b0d98870d-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-121-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-122-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-123-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-124-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-125-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-126-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-227-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-228-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d3196",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c15637102c0b0d98892a"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupID": "67c1c15637102c0b0d98892a-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-175-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-176-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-177-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-178-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-179-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-180-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-247-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-248-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d31a0",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10437102c0b0d988715"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupID": "67c1c10437102c0b0d988715-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-145-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-146-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-147-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-148-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-149-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-150-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-235-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-236-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d31aa",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c15737102c0b0d988930"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupID": "67c1c15737102c0b0d988930-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-181-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-182-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-183-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-184-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-185-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-186-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-249-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-250-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d31b4",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10437102c0b0d988717"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupID": "67c1c10437102c0b0d988717-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-151-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-152-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-153-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-154-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-155-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-156-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-237-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-238-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d31be",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c10537102c0b0d988719"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupID": "67c1c10537102c0b0d988719-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-157-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-158-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-159-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-160-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-161-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-162-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-239-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-240-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:27.1329528Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f0379ba8a88fba2d31c8",
                "MetaData": {
                    "IsAddOn": false,
                    "IsUnitChart": true,
                    "ProductID": "67c1c16037102c0b0d988966"
                },
                "ModifiedDate": "2025-03-05T06:33:27.1329528Z",
                "ModifierGroupID": "67c1c16037102c0b0d988966-CHOOSE SIZE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0a337102c0b0d9884e5-252-UNIT",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b6-253-SML 7INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988512-254-MED 9INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988514-255-LRG 12INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988516-256-X-LRG 15INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1c0a837102c0b0d988518-257-XXLRG 18INCH",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4b8-258-X-LRG 15INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    },
                    {
                        "Id": "67c1ea0f37102c0b0d98b4ba-259-XXLRG 18INCH(COLL)",
                        "Type": "MODIFIERGROUP"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "CHOOSE SIZE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.3892294Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2f99",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d988546",
                    "AddonGroupName": "SELECT YOUR BASE",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 6
                },
                "ModifiedDate": "2025-03-05T06:33:22.3892294Z",
                "ModifierGroupID": "67c1c0b837102c0b0d988546-SELECT YOUR BASE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0fa37102c0b0d9886da-CHEESE CRUST",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT YOUR BASE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.3946524Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2f9d",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d988546",
                    "AddonGroupName": "SELECT MEAT TOPPING",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 6
                },
                "ModifiedDate": "2025-03-05T06:33:22.3946524Z",
                "ModifierGroupID": "67c1c0b837102c0b0d988546-SELECT MEAT TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c10d37102c0b0d988754-PLAIN CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988756-TANDOORI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988758-PERI PERI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d98875a-BBQ CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875d-LAMB DONNER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875f-HAM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d988761-SPICY PORK",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988763-BACON",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988765-PEPPERONI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988767-SPICY BEEF",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT MEAT TOPPING"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4169101Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2fa8",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d988546",
                    "AddonGroupName": "SELECT VEG TOPPING",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 6
                },
                "ModifiedDate": "2025-03-05T06:33:22.4169101Z",
                "ModifierGroupID": "67c1c0b837102c0b0d988546-SELECT VEG TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c11137102c0b0d988769-ONION",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876b-MUSHROOM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876d-SWEETCORN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d98876f-GREEN PEPPER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988771-JALAPENO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988773-FRESH GINGER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988775-FRESH TOMATO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988777-GREEN CHILLI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988779-FRESH GARLIC BLACK OLIVES",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877b-PINEAPPLE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877d-PANEER",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT VEG TOPPING"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4217865Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2fb4",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d988546",
                    "AddonGroupName": "SELECT BASE SAUCE",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 6
                },
                "ModifiedDate": "2025-03-05T06:33:22.4217865Z",
                "ModifierGroupID": "67c1c0b837102c0b0d988546-SELECT BASE SAUCE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d1d737102c0b0d989e9d-MANCHURIAN BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f837102c0b0d9886ca-PERI PERI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d3ab37102c0b0d98a01c-TIKKA MASALA BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d4-BBQ BASE",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT BASE SAUCE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.425226Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2fba",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d988548",
                    "AddonGroupName": "SELECT YOUR BASE",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 7
                },
                "ModifiedDate": "2025-03-05T06:33:22.425226Z",
                "ModifierGroupID": "67c1c0b837102c0b0d988548-SELECT YOUR BASE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0fa37102c0b0d9886dd-CHEESE CRUST.",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "ChargeAbove": 0,
                    "IsMinPermittedOptional": false,
                    "MaxPermitted": 1,
                    "MaxPermittedUnique": 0,
                    "MinPermitted": 1,
                    "MinPermittedUnique": 0,
                    "RefundUnder": 0
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT YOUR BASE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4257734Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2fbe",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d988548",
                    "AddonGroupName": "SELECT MEAT TOPPING",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 7
                },
                "ModifiedDate": "2025-03-05T06:33:22.4257734Z",
                "ModifierGroupID": "67c1c0b837102c0b0d988548-SELECT MEAT TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c11437102c0b0d98877f-PLAIN CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d988781-TANDOORI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988783-PERI PERI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988785-BBQ CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988787-LAMB DONNER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d988789-HAM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878b-SPICY PORK.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878d-BACON.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d98878f-PEPPERONI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988791-SPICY BEEF.",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT MEAT TOPPING"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4285481Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2fc9",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d988548",
                    "AddonGroupName": "SELECT VEG TOPPING",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 7
                },
                "ModifiedDate": "2025-03-05T06:33:22.4285481Z",
                "ModifierGroupID": "67c1c0b837102c0b0d988548-SELECT VEG TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c11737102c0b0d988793-ONION.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988795-MUSHROOM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988797-SWEETCORN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988799-GREEN PEPPER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879b-JALAPENO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879d-FRESH GINGER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879f-FRESH TOMATO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a1-GREEN CHILLI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a3-FRESH GARLIC BLACK OLIVES.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a6-PINEAPPLE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887aa-PANEER.",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT VEG TOPPING"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4350059Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2fd5",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d988548",
                    "AddonGroupName": "SELECT BASE SAUCE",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 7
                },
                "ModifiedDate": "2025-03-05T06:33:22.4350059Z",
                "ModifierGroupID": "67c1c0b837102c0b0d988548-SELECT BASE SAUCE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d1fb37102c0b0d989ebf-MANCHURIAN BASE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d0dd37102c0b0d989ddd-PERI PERI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d3ff37102c0b0d98a08c-TIKKA MASALA BASE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1cf6b37102c0b0d989b3e-BBQ BASE.",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT BASE SAUCE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4362959Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2fdb",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d988544",
                    "AddonGroupName": "SELECT FOR DOUBLE UP",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 5
                },
                "ModifiedDate": "2025-03-05T06:33:22.4362959Z",
                "ModifierGroupID": "67c1c0b837102c0b0d988544-SELECT FOR DOUBLE UP",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0ed37102c0b0d988684-DOUBLE UP",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT FOR DOUBLE UP"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4368279Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2fdd",
                "MetaData": {
                    "AddonGroupID": "67c1c0b937102c0b0d988551",
                    "AddonGroupName": "SELECT VEG TOPPING",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 10
                },
                "ModifiedDate": "2025-03-05T06:33:22.4368279Z",
                "ModifierGroupID": "67c1c0b937102c0b0d988551-SELECT VEG TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14437102c0b0d9888b1-ONION...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b3-MUSHROOM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b5-SWEETCORN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b7-GREEN PEPPER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888b9-JALAPENO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bb-FRESH GINGER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bd-FRESH TOMATO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c0-GREEN CHILLI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c2-FRESH GARLIC BLACK OLIVES...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c4-PINEAPPLE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14837102c0b0d9888c6-PANEER...",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT VEG TOPPING"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4384279Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2fe9",
                "MetaData": {
                    "AddonGroupID": "67c1c0b937102c0b0d988551",
                    "AddonGroupName": "SELECT BASE SAUCE",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 10
                },
                "ModifiedDate": "2025-03-05T06:33:22.4384279Z",
                "ModifierGroupID": "67c1c0b937102c0b0d988551-SELECT BASE SAUCE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d26737102c0b0d989f08-MANCHURIAN BASE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d19f37102c0b0d989e7f-PERI PERI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d45537102c0b0d98a0d7-TIKKA MASALA BASE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1cfc537102c0b0d989bb4-BBQ BASE...",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT BASE SAUCE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4394882Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2fef",
                "MetaData": {
                    "AddonGroupID": "67c1c0b937102c0b0d988551",
                    "AddonGroupName": "SELECT YOUR BASE",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 10
                },
                "ModifiedDate": "2025-03-05T06:33:22.4394882Z",
                "ModifierGroupID": "67c1c0b937102c0b0d988551-SELECT YOUR BASE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0fb37102c0b0d9886e3-CHEESE CRUST....",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT YOUR BASE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4400844Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2ff3",
                "MetaData": {
                    "AddonGroupID": "67c1c0b937102c0b0d988551",
                    "AddonGroupName": "SELECT MEAT TOPPING",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 10
                },
                "ModifiedDate": "2025-03-05T06:33:22.4400844Z",
                "ModifierGroupID": "67c1c0b937102c0b0d988551-SELECT MEAT TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14137102c0b0d988897-PLAIN CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d988899-TANDOORI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889b-PERI PERI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889e-BBQ CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a3-LAMB DONNER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a7-HAM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a9-SPICY PORK...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888ab-BACON...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888ad-PEPPERONI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888af-SPICY BEEF...",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT MEAT TOPPING"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4417271Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d2ffe",
                "MetaData": {
                    "AddonGroupID": "67c1c0b937102c0b0d988553",
                    "AddonGroupName": "EXTRA PATTY",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 11
                },
                "ModifiedDate": "2025-03-05T06:33:22.4417271Z",
                "ModifierGroupID": "67c1c0b937102c0b0d988553-EXTRA PATTY",
                "ModifierOptions": [
                    {
                        "Id": "67c1c15f37102c0b0d988962-EXTRA PATTY",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "EXTRA PATTY"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4423742Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d3000",
                "MetaData": {
                    "AddonGroupID": "67c1c0b937102c0b0d988553",
                    "AddonGroupName": "SAUCE ON CHIPS",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 11
                },
                "ModifiedDate": "2025-03-05T06:33:22.4423742Z",
                "ModifierGroupID": "67c1c0b937102c0b0d988553-SAUCE ON CHIPS",
                "ModifierOptions": [
                    {
                        "Id": "67c1c15f37102c0b0d988964-SAUCE ON CHIPS",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SAUCE ON CHIPS"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4429738Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d3002",
                "MetaData": {
                    "AddonGroupID": "67c1c0b937102c0b0d988553",
                    "AddonGroupName": "SALAD ",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 11
                },
                "ModifiedDate": "2025-03-05T06:33:22.4429738Z",
                "ModifierGroupID": "67c1c0b937102c0b0d988553-SALAD ",
                "ModifierOptions": [
                    {
                        "Id": "67c1c15a37102c0b0d98893e-LETTUCE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15a37102c0b0d988940-ONION....",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SALAD "
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4429738Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d3005",
                "MetaData": {
                    "AddonGroupID": "67c1c0b937102c0b0d988553",
                    "AddonGroupName": "SAUCE ",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 11
                },
                "ModifiedDate": "2025-03-05T06:33:22.4429738Z",
                "ModifierGroupID": "67c1c0b937102c0b0d988553-SAUCE ",
                "ModifierOptions": [
                    {
                        "Id": "67c1c15a37102c0b0d988944-MAYO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15b37102c0b0d988946-GARLIC MAYO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15b37102c0b0d988948-CHILLI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15b37102c0b0d98894a-KETCHUP",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15c37102c0b0d98894c-BURGER SAUCE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15c37102c0b0d98894e-BBQ",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SAUCE "
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4460123Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d300c",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d98854b",
                    "AddonGroupName": "SELECT YOUR BASE",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 8
                },
                "ModifiedDate": "2025-03-05T06:33:22.4460123Z",
                "ModifierGroupID": "67c1c0b837102c0b0d98854b-SELECT YOUR BASE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0fa37102c0b0d9886df-CHEESE CRUST..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT YOUR BASE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4466199Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d3010",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d98854b",
                    "AddonGroupName": "SELECT MEAT TOPPING",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 8
                },
                "ModifiedDate": "2025-03-05T06:33:22.4466199Z",
                "ModifierGroupID": "67c1c0b837102c0b0d98854b-SELECT MEAT TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c11b37102c0b0d9887ac-PLAIN CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ae-TANDOORI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b1-PERI PERI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b3-BBQ CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b6-LAMB DONNER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887b8-HAM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887ba-SPICY PORK..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887bc-BACON..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887be-PEPPERONI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887c0-SPICY BEEF..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT MEAT TOPPING"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4497398Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d301b",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d98854b",
                    "AddonGroupName": "SELECT VEG TOPPING",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 8
                },
                "ModifiedDate": "2025-03-05T06:33:22.4497398Z",
                "ModifierGroupID": "67c1c0b837102c0b0d98854b-SELECT VEG TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c11f37102c0b0d9887c2-ONION..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c4-MUSHROOM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988885-SWEETCORN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988887-GREEN PEPPER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988889-JALAPENO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d98888b-FRESH GINGER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888d-FRESH TOMATO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888f-GREEN CHILLI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d988891-FRESH GARLIC BLACK OLIVES..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988893-PINEAPPLE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988895-PANEER..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT VEG TOPPING"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4512807Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d3027",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d98854b",
                    "AddonGroupName": "SELECT BASE SAUCE",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 8
                },
                "ModifiedDate": "2025-03-05T06:33:22.4512807Z",
                "ModifierGroupID": "67c1c0b837102c0b0d98854b-SELECT BASE SAUCE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d22b37102c0b0d989ee0-MANCHURIAN BASE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d16d37102c0b0d989e4c-PERI PERI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d42137102c0b0d98a0a0-TIKKA MASALA BASE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1cf9937102c0b0d989b61-BBQ BASE..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT BASE SAUCE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4528778Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d302d",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d98854e",
                    "AddonGroupName": "SELECT BASE SAUCE",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 9
                },
                "ModifiedDate": "2025-03-05T06:33:22.4528778Z",
                "ModifierGroupID": "67c1c0b837102c0b0d98854e-SELECT BASE SAUCE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d26737102c0b0d989f08-MANCHURIAN BASE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d19f37102c0b0d989e7f-PERI PERI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d45537102c0b0d98a0d7-TIKKA MASALA BASE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1cfc537102c0b0d989bb4-BBQ BASE...",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT BASE SAUCE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4538839Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d3033",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d98854e",
                    "AddonGroupName": "SELECT YOUR BASE",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 9
                },
                "ModifiedDate": "2025-03-05T06:33:22.4538839Z",
                "ModifierGroupID": "67c1c0b837102c0b0d98854e-SELECT YOUR BASE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0fb37102c0b0d9886e1-CHEESE CRUST...",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT YOUR BASE"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4548858Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d3037",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d98854e",
                    "AddonGroupName": "SELECT MEAT TOPPING",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 9
                },
                "ModifiedDate": "2025-03-05T06:33:22.4548858Z",
                "ModifierGroupID": "67c1c0b837102c0b0d98854e-SELECT MEAT TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c11b37102c0b0d9887ac-PLAIN CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ae-TANDOORI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b1-PERI PERI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b3-BBQ CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b6-LAMB DONNER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887b8-HAM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887ba-SPICY PORK..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887bc-BACON..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887be-PEPPERONI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887c0-SPICY BEEF..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT MEAT TOPPING"
                }
            },
            {
                "CreatedDate": "2025-03-05T06:33:22.4575699Z",
                "Description": null,
                "DisplayType": "EXPANDED",
                "ID": "67c7f0329ba8a88fba2d3042",
                "MetaData": {
                    "AddonGroupID": "67c1c0b837102c0b0d98854e",
                    "AddonGroupName": "SELECT VEG TOPPING",
                    "IsAddOn": true,
                    "IsUnitChart": false,
                    "LocalAddonGroupID": 9
                },
                "ModifiedDate": "2025-03-05T06:33:22.4575699Z",
                "ModifierGroupID": "67c1c0b837102c0b0d98854e-SELECT VEG TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c11f37102c0b0d9887c2-ONION..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c4-MUSHROOM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988885-SWEETCORN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988887-GREEN PEPPER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988889-JALAPENO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d98888b-FRESH GINGER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888d-FRESH TOMATO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888f-GREEN CHILLI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d988891-FRESH GARLIC BLACK OLIVES..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988893-PINEAPPLE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988895-PANEER..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 0,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 20,
                        "MaxPermittedUnique": 0,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT VEG TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 4 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31d2",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SELECT 4 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10337102c0b0d98870f-BBQ MEAT LOVER PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10337102c0b0d988711-GARLIC SPICIAL PIZZA",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 4,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 4,
                        "MaxPermittedUnique": 4,
                        "MinPermitted": 4,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 4 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SIDES"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31d3",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c17637102c0b0d9889ad-HOT HOUSE DEAL 2 (XXL 18 INCH)-SIDES",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14937102c0b0d9888cf-6 REG FRIES",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14937102c0b0d9888cc-TWO 1.5 LTR DRINKS",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 4,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 4,
                        "MaxPermittedUnique": 4,
                        "MinPermitted": 4,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SIDES"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31d5",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-",
                "ModifierOptions": null,
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": ""
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": ""
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31d6",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-",
                "ModifierOptions": null,
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": ""
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT X3 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31d7",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SELECT X3 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 3,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 3,
                        "MaxPermittedUnique": 3,
                        "MinPermitted": 3,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT X3 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SIDES"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31d8",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16237102c0b0d98896e-HOT HOUSE DEAL 1 (XL 15 INCH)-SIDES",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14837102c0b0d9888c8-12 PCS GARLIC BREAD WITH CHEESE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14837102c0b0d9888ca-2 REGULAR CHIPS",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14937102c0b0d9888cc-TWO 1.5 LTR DRINKS",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 3,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 3,
                        "MaxPermittedUnique": 3,
                        "MinPermitted": 3,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SIDES"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 4 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31da",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SELECT 4 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 4,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 4,
                        "MaxPermittedUnique": 4,
                        "MinPermitted": 4,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 4 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SIDES"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31db",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16437102c0b0d988977-MEGA DEAL (L 12 INCH)-SIDES",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14937102c0b0d9888d4-8 PCS GARLIC BREAD",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14937102c0b0d9888d7-POTATO WEDGES",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14a37102c0b0d9888d9-1.5 LTR DRINKS",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 3,
                        "IsMinPermittedOptional": false,
                        "MaxPermitted": 3,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 3,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SIDES"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31dd",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SELECT 2 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SIDES"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31de",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16537102c0b0d988979-FAMILY DEAL-1(L-12 INCH)-SIDES",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14937102c0b0d9888d4-8 PCS GARLIC BREAD",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14a37102c0b0d9888db-LARGE CHIPS",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14a37102c0b0d9888d9-1.5 LTR DRINKS",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 3,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 3,
                        "MaxPermittedUnique": 3,
                        "MinPermitted": 3,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SIDES"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31e0",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SELECT 2 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SIDES"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31e1",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16737102c0b0d98897b-FAMILY DEAL-2(XL-15 INCH)-SIDES",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0d037102c0b0d988612-POTATO WEDGES",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14a37102c0b0d9888d9-1.5 LTR DRINKS",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15437102c0b0d98891a-8 PCS GARLIC BREAD WITH CHEESE",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 3,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 3,
                        "MaxPermittedUnique": 3,
                        "MinPermitted": 3,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SIDES"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT ANY PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31e3",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SELECT ANY PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT ANY PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SIDES"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31e4",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16837102c0b0d98897d-THE BIG EATER (XL 15 INCH)-SIDES",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14b37102c0b0d9888dd-TWO CANS OF DRINKS",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15437102c0b0d98891c-GARLIC BREAD WITH CHEESE",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SIDES"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31e6",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SELECT PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SIDES"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31e7",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16937102c0b0d988982-CURLY DEAL (XL 15 INCH)-SIDES",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14b37102c0b0d9888e0-ONE LARGE CURRY FRIES",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14b37102c0b0d9888dd-TWO CANS OF DRINKS",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SIDES"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31e9",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SELECT 2 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SIDES"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31ea",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16a37102c0b0d988986-FEED 8 DEAL (XXL 18 INCH)-SIDES",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14837102c0b0d9888ca-2 REGULAR CHIPS",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14937102c0b0d9888cc-TWO 1.5 LTR DRINKS",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SIDES"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT X3 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31ec",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16c37102c0b0d98898a-(X3) M 9 INCH PIZZA-SELECT X3 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 3,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 3,
                        "MaxPermittedUnique": 3,
                        "MinPermitted": 3,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT X3 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT X3 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31ee",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16d37102c0b0d98898e-(X3) L 12 INCH PIZZA-SELECT X3 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 3,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 3,
                        "MaxPermittedUnique": 3,
                        "MinPermitted": 3,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT X3 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 3 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31f0",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16e37102c0b0d988991-(X3) XL 15 INCH PIZZA-SELECT 3 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 3,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 3,
                        "MaxPermittedUnique": 3,
                        "MinPermitted": 3,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 3 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 3 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31f2",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c16f37102c0b0d988996-(X3) XXL 18 INCH PIZZA-SELECT 3 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d98890a-PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890c-DESI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d988910-HOT HONEY PEPPERONI PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988912-CHILLI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988914-BUFFALO CHICKEN  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988918-PANEER TIKKA MASALA  PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15537102c0b0d988922-MASALA HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15637102c0b0d988928-VEGGIE CHILLI TURMERIC PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15737102c0b0d98892e-MASALA CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15837102c0b0d988934-CHILLI CHICKEN PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 3,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 3,
                        "MaxPermittedUnique": 3,
                        "MinPermitted": 3,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 3 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31f4",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c17037102c0b0d988999-(X2) 7 INCH WICKED  PIZZA DEAL-SELECT PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31f6",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c17137102c0b0d98899c-(X2) 9 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31f8",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c17237102c0b0d9889a1-(X2) 12 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31fa",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c17337102c0b0d9889a3-(X2) 15 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2 PIZZA"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31fc",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c1c17437102c0b0d9889a6-(X2) 18 INCH WICKED PIZZA DEAL-SELECT 2 PIZZA",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14c37102c0b0d9888e4-MARGHERITHA PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e6-VEGETARIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888e8-VEGETARIAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14c37102c0b0d9888ea-DESI STYLE PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ec-VEGETARIAN SUPREME PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888ee-PUNJABI STYLE GARLIC PIZZA BREAD..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14d37102c0b0d9888f0-PUNJABI SPICY PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f2-HAWAIIAN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f4-PEPPERONI LOVER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14e37102c0b0d9888f6-PEPPERONI SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888f8-HOT HOUSE SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fa-MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fc-BBQ MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14f37102c0b0d9888fe-BBQ CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988900-CHICKEN SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988902-TANDOORI CHICKEN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15037102c0b0d988904-AMERICAN HOT PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988906-KEBAB SPECIAL PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15137102c0b0d988908-KING CHICKEN PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15237102c0b0d98890e-DESI MEAT FEAST PIZZA..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 2,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 2,
                        "MaxPermittedUnique": 2,
                        "MinPermitted": 2,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2 PIZZA"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT CRUST"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31fe",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c5cb44c672a66e93ae8afe--SELECT CRUST",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0fa37102c0b0d9886da-CHEESE CRUST",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT CRUST"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT BASE SAUCE "
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d31ff",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c5cb44c672a66e93ae8afe--SELECT BASE SAUCE ",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d4-BBQ BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f837102c0b0d9886ca-PERI PERI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d3ab37102c0b0d98a01c-TIKKA MASALA BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d1d737102c0b0d989e9d-MANCHURIAN BASE",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT BASE SAUCE "
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 1ST TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3200",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c5cb44c672a66e93ae8afe--SELECT 1ST TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c10d37102c0b0d988754-PLAIN CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988756-TANDOORI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988758-PERI PERI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d98875a-BBQ CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875d-LAMB DONNER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875f-HAM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d988761-SPICY PORK",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988763-BACON",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988765-PEPPERONI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988767-SPICY BEEF",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d988769-ONION",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876b-MUSHROOM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876d-SWEETCORN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d98876f-GREEN PEPPER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988771-JALAPENO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988773-FRESH GINGER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988775-FRESH TOMATO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988777-GREEN CHILLI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877b-PINEAPPLE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877d-PANEER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e452c672a66e93ae9b92-FRESH GARLIC",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e822c672a66e93ae9f08-BLACK OLIVE",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 1ST TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2ND TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3201",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c5cb44c672a66e93ae8afe--SELECT 2ND TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14b37102c0b0d9888e2-NO EXTRA TOPPINGS",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d988781-TANDOORI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d0dd37102c0b0d989ddd-PERI PERI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988785-BBQ CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988787-LAMB DONNER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d988789-HAM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878b-SPICY PORK.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878d-BACON.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d98878f-PEPPERONI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988791-SPICY BEEF.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988793-ONION.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988795-MUSHROOM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988797-SWEETCORN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988799-GREEN PEPPER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879b-JALAPENO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879d-FRESH GINGER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879f-FRESH TOMATO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a1-GREEN CHILLI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a6-PINEAPPLE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887aa-PANEER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877f-PLAIN CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4bac672a66e93ae9bd3-FRESH GARLIC.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5fa3ec672a66e93aeb063-BLACK OLIVE.",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2ND TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 3RD TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3202",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c5cb44c672a66e93ae8afe--SELECT 3RD TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e40ec672a66e93ae9b56-NO EXTRA TOPPINGS..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ae-TANDOORI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b1-PERI PERI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b3-BBQ CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b6-LAMB DONNER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887b8-HAM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887ba-SPICY PORK..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c2-ONION..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c4-MUSHROOM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988885-SWEETCORN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988887-GREEN PEPPER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988889-JALAPENO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d98888b-FRESH GINGER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888d-FRESH TOMATO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c0-GREEN CHILLI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4dac672a66e93ae9be8-FRESH GARLIC..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5fa66c672a66e93aeb089-BLACK OLIVE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988893-PINEAPPLE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988895-PANEER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ac-PLAIN CHICKEN..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 3RD TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 4TH TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3203",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c5cb44c672a66e93ae8afe--SELECT 4TH TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e42cc672a66e93ae9b78-NO EXTRA TOPPINGS...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d988899-TANDOORI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889b-PERI PERI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889e-BBQ CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a3-LAMB DONNER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a7-HAM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a9-SPICY PORK...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888b1-ONION...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b3-MUSHROOM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b5-SWEETCORN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b7-GREEN PEPPER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888b9-JALAPENO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bb-FRESH GINGER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bd-FRESH TOMATO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888f-GREEN CHILLI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4fcc672a66e93ae9c01-FRESH GARLIC...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c60226c672a66e93aeb919-BLACK OLIVE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c4-PINEAPPLE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14837102c0b0d9888c6-PANEER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988897-PLAIN CHICKEN...",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 4TH TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT CRUST "
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3205",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c60b40c672a66e93aec346--SELECT CRUST ",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0fa37102c0b0d9886da-CHEESE CRUST",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT CRUST "
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT BASE SAUCE"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3206",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c60b40c672a66e93aec346--SELECT BASE SAUCE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d4-BBQ BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f837102c0b0d9886ca-PERI PERI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d3ab37102c0b0d98a01c-TIKKA MASALA BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d1d737102c0b0d989e9d-MANCHURIAN BASE",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT BASE SAUCE"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 1ST TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3207",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c60b40c672a66e93aec346--SELECT 1ST TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c10d37102c0b0d988754-PLAIN CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988756-TANDOORI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988758-PERI PERI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d98875a-BBQ CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875d-LAMB DONNER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875f-HAM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d988761-SPICY PORK",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988763-BACON",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988765-PEPPERONI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988767-SPICY BEEF",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d988769-ONION",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876b-MUSHROOM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876d-SWEETCORN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d98876f-GREEN PEPPER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988771-JALAPENO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988773-FRESH GINGER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988775-FRESH TOMATO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988777-GREEN CHILLI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e452c672a66e93ae9b92-FRESH GARLIC",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e822c672a66e93ae9f08-BLACK OLIVE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877b-PINEAPPLE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877d-PANEER",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 1ST TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2ND TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3208",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c60b40c672a66e93aec346--SELECT 2ND TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e3eec672a66e93ae9b41-NO EXTRA TOPPINGS.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d988781-TANDOORI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988783-PERI PERI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988785-BBQ CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988787-LAMB DONNER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878b-SPICY PORK.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878d-BACON.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d98878f-PEPPERONI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988791-SPICY BEEF.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988793-ONION.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988795-MUSHROOM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988797-SWEETCORN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988799-GREEN PEPPER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879b-JALAPENO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879d-FRESH GINGER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879f-FRESH TOMATO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a1-GREEN CHILLI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4bac672a66e93ae9bd3-FRESH GARLIC.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5fa3ec672a66e93aeb063-BLACK OLIVE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a6-PINEAPPLE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887aa-PANEER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877f-PLAIN CHICKEN.",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2ND TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 3RD TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3209",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c60b40c672a66e93aec346--SELECT 3RD TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e40ec672a66e93ae9b56-NO EXTRA TOPPINGS..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ae-TANDOORI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b3-BBQ CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b1-PERI PERI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887b8-HAM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887ba-SPICY PORK..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888b1-ONION...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c4-MUSHROOM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988885-SWEETCORN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887bc-BACON..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887be-PEPPERONI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887c0-SPICY BEEF..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988887-GREEN PEPPER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988889-JALAPENO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d98888b-FRESH GINGER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888d-FRESH TOMATO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888f-GREEN CHILLI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4dac672a66e93ae9be8-FRESH GARLIC..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5fa66c672a66e93aeb089-BLACK OLIVE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988893-PINEAPPLE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988895-PANEER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ac-PLAIN CHICKEN..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 3RD TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 4TH TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d320a",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c60b40c672a66e93aec346--SELECT 4TH TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c14237102c0b0d988899-TANDOORI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15337102c0b0d988916-PERI PERI PANEER PIZZA..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889e-BBQ CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a3-LAMB DONNER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a7-HAM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a9-SPICY PORK...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888ab-BACON...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888ad-PEPPERONI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888af-SPICY BEEF...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15a37102c0b0d988940-ONION....",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b3-MUSHROOM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b5-SWEETCORN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b7-GREEN PEPPER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888b9-JALAPENO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bb-FRESH GINGER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bd-FRESH TOMATO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c0-GREEN CHILLI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4fcc672a66e93ae9c01-FRESH GARLIC...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c60226c672a66e93aeb919-BLACK OLIVE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c4-PINEAPPLE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14837102c0b0d9888c6-PANEER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988897-PLAIN CHICKEN...",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 4TH TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT CRUST"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d320c",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c630c0c672a66e93aedbac--SELECT CRUST",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0fa37102c0b0d9886da-CHEESE CRUST",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT CRUST"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT BASE SAUCE"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d320d",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c630c0c672a66e93aedbac--SELECT BASE SAUCE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d4-BBQ BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f837102c0b0d9886ca-PERI PERI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d3ab37102c0b0d98a01c-TIKKA MASALA BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d1d737102c0b0d989e9d-MANCHURIAN BASE",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT BASE SAUCE"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 1ST TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d320e",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c630c0c672a66e93aedbac--SELECT 1ST TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c10d37102c0b0d988754-PLAIN CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988756-TANDOORI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988758-PERI PERI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d98875a-BBQ CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875d-LAMB DONNER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875f-HAM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d988761-SPICY PORK",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988763-BACON",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988765-PEPPERONI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988767-SPICY BEEF",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d988769-ONION",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876b-MUSHROOM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876d-SWEETCORN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d98876f-GREEN PEPPER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988771-JALAPENO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988773-FRESH GINGER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988775-FRESH TOMATO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988777-GREEN CHILLI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e452c672a66e93ae9b92-FRESH GARLIC",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e822c672a66e93ae9f08-BLACK OLIVE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877b-PINEAPPLE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877d-PANEER",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 1ST TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2ND TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d320f",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c630c0c672a66e93aedbac--SELECT 2ND TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e3eec672a66e93ae9b41-NO EXTRA TOPPINGS.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d988781-TANDOORI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988783-PERI PERI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988785-BBQ CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988787-LAMB DONNER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d988789-HAM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878b-SPICY PORK.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878d-BACON.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d98878f-PEPPERONI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988791-SPICY BEEF.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988793-ONION.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988795-MUSHROOM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988797-SWEETCORN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988799-GREEN PEPPER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879b-JALAPENO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879d-FRESH GINGER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879f-FRESH TOMATO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a1-GREEN CHILLI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4bac672a66e93ae9bd3-FRESH GARLIC.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5fa3ec672a66e93aeb063-BLACK OLIVE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a6-PINEAPPLE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887aa-PANEER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877f-PLAIN CHICKEN.",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2ND TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 3RD TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3210",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c630c0c672a66e93aedbac--SELECT 3RD TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e40ec672a66e93ae9b56-NO EXTRA TOPPINGS..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ae-TANDOORI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d16d37102c0b0d989e4c-PERI PERI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b3-BBQ CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b6-LAMB DONNER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887b8-HAM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887ba-SPICY PORK..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887bc-BACON..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887be-PEPPERONI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887c0-SPICY BEEF..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c2-ONION..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c4-MUSHROOM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988885-SWEETCORN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988887-GREEN PEPPER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988889-JALAPENO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d98888b-FRESH GINGER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888d-FRESH TOMATO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888f-GREEN CHILLI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4dac672a66e93ae9be8-FRESH GARLIC..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5fa66c672a66e93aeb089-BLACK OLIVE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988893-PINEAPPLE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988895-PANEER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ac-PLAIN CHICKEN..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 3RD TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 4TH TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3211",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c630c0c672a66e93aedbac--SELECT 4TH TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e42cc672a66e93ae9b78-NO EXTRA TOPPINGS...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d988899-TANDOORI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889b-PERI PERI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889e-BBQ CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a3-LAMB DONNER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a7-HAM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a9-SPICY PORK...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888ab-BACON...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888ad-PEPPERONI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888af-SPICY BEEF...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15a37102c0b0d988940-ONION....",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b3-MUSHROOM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b5-SWEETCORN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b7-GREEN PEPPER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888b9-JALAPENO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bb-FRESH GINGER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bd-FRESH TOMATO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c0-GREEN CHILLI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4fcc672a66e93ae9c01-FRESH GARLIC...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c60226c672a66e93aeb919-BLACK OLIVE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c4-PINEAPPLE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14837102c0b0d9888c6-PANEER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988897-PLAIN CHICKEN...",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 4TH TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT CRUST"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3213",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c71008c672a66e93af0eeb--SELECT CRUST",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0fa37102c0b0d9886da-CHEESE CRUST",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT CRUST"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT BASE SAUCE"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3214",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c71008c672a66e93af0eeb--SELECT BASE SAUCE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d4-BBQ BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f837102c0b0d9886ca-PERI PERI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d3ab37102c0b0d98a01c-TIKKA MASALA BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d1d737102c0b0d989e9d-MANCHURIAN BASE",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT BASE SAUCE"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 1ST TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3215",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c71008c672a66e93af0eeb--SELECT 1ST TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c10d37102c0b0d988754-PLAIN CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988756-TANDOORI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d98875a-BBQ CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875d-LAMB DONNER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875f-HAM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d988761-SPICY PORK",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988763-BACON",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988765-PEPPERONI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988767-SPICY BEEF",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d988769-ONION",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876b-MUSHROOM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876d-SWEETCORN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d98876f-GREEN PEPPER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988771-JALAPENO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988773-FRESH GINGER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988775-FRESH TOMATO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988777-GREEN CHILLI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e452c672a66e93ae9b92-FRESH GARLIC",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e822c672a66e93ae9f08-BLACK OLIVE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877b-PINEAPPLE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877d-PANEER",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 1ST TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2ND TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3216",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c71008c672a66e93af0eeb--SELECT 2ND TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e3eec672a66e93ae9b41-NO EXTRA TOPPINGS.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d988781-TANDOORI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988783-PERI PERI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988787-LAMB DONNER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d988789-HAM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878b-SPICY PORK.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878d-BACON.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d98878f-PEPPERONI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988791-SPICY BEEF.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988793-ONION.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988795-MUSHROOM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988797-SWEETCORN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988799-GREEN PEPPER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879b-JALAPENO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879d-FRESH GINGER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879f-FRESH TOMATO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a1-GREEN CHILLI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4bac672a66e93ae9bd3-FRESH GARLIC.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5fa3ec672a66e93aeb063-BLACK OLIVE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a6-PINEAPPLE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887aa-PANEER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877f-PLAIN CHICKEN.",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2ND TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 3RD TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3217",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c71008c672a66e93af0eeb--SELECT 3RD TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e40ec672a66e93ae9b56-NO EXTRA TOPPINGS..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ae-TANDOORI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b1-PERI PERI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b3-BBQ CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b6-LAMB DONNER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887b8-HAM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887ba-SPICY PORK..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887bc-BACON..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c2-ONION..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c4-MUSHROOM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988885-SWEETCORN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988887-GREEN PEPPER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988889-JALAPENO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d98888b-FRESH GINGER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888d-FRESH TOMATO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888f-GREEN CHILLI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4dac672a66e93ae9be8-FRESH GARLIC..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5fa66c672a66e93aeb089-BLACK OLIVE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988893-PINEAPPLE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988895-PANEER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ac-PLAIN CHICKEN..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 3RD TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 4TH TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d3218",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c71008c672a66e93af0eeb--SELECT 4TH TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e42cc672a66e93ae9b78-NO EXTRA TOPPINGS...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d988899-TANDOORI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889b-PERI PERI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889e-BBQ CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a3-LAMB DONNER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a7-HAM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a9-SPICY PORK...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888ab-BACON...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888ad-PEPPERONI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888af-SPICY BEEF...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888b1-ONION...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b3-MUSHROOM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b5-SWEETCORN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b7-GREEN PEPPER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888b9-JALAPENO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bb-FRESH GINGER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bd-FRESH TOMATO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c0-GREEN CHILLI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4fcc672a66e93ae9c01-FRESH GARLIC...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c60226c672a66e93aeb919-BLACK OLIVE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c4-PINEAPPLE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14837102c0b0d9888c6-PANEER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988897-PLAIN CHICKEN...",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 4TH TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT CRUST"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d321a",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c715c4c672a66e93af1221--SELECT CRUST",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f937102c0b0d9886d6-DEEP PAN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d8-THIN CRUST",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0fa37102c0b0d9886da-CHEESE CRUST",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT CRUST"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT BASE SAUCE"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d321b",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c715c4c672a66e93af1221--SELECT BASE SAUCE",
                "ModifierOptions": [
                    {
                        "Id": "67c1c0f737102c0b0d9886c5-GARLIC BUTTER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f937102c0b0d9886d4-BBQ BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c0f837102c0b0d9886ca-PERI PERI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d3ab37102c0b0d98a01c-TIKKA MASALA BASE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1d1d737102c0b0d989e9d-MANCHURIAN BASE",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT BASE SAUCE"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 1ST TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d321c",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c715c4c672a66e93af1221--SELECT 1ST TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c1c10d37102c0b0d988754-PLAIN CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988756-TANDOORI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d988758-PERI PERI CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10e37102c0b0d98875a-BBQ CHICKEN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875d-LAMB DONNER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d98875f-HAM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c10f37102c0b0d988761-SPICY PORK",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988763-BACON",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988765-PEPPERONI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11037102c0b0d988767-SPICY BEEF",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d988769-ONION",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876b-MUSHROOM",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11137102c0b0d98876d-SWEETCORN",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d98876f-GREEN PEPPER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988771-JALAPENO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11237102c0b0d988773-FRESH GINGER",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988775-FRESH TOMATO",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11337102c0b0d988777-GREEN CHILLI",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e452c672a66e93ae9b92-FRESH GARLIC",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e822c672a66e93ae9f08-BLACK OLIVE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877b-PINEAPPLE",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877d-PANEER",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 1ST TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 2ND TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d321d",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c715c4c672a66e93af1221--SELECT 2ND TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e3eec672a66e93ae9b41-NO EXTRA TOPPINGS.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d988781-TANDOORI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988783-PERI PERI CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988785-BBQ CHICKEN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11537102c0b0d988787-LAMB DONNER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d988789-HAM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878b-SPICY PORK.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11637102c0b0d98878d-BACON.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d98878f-PEPPERONI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988791-SPICY BEEF.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11737102c0b0d988793-ONION.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988795-MUSHROOM.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988797-SWEETCORN.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11837102c0b0d988799-GREEN PEPPER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879b-JALAPENO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879d-FRESH GINGER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11937102c0b0d98879f-FRESH TOMATO.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a1-GREEN CHILLI.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4bac672a66e93ae9bd3-FRESH GARLIC.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5fa3ec672a66e93aeb063-BLACK OLIVE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11a37102c0b0d9887a6-PINEAPPLE.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887aa-PANEER.",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11437102c0b0d98877f-PLAIN CHICKEN.",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 2ND TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 3RD TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d321e",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c715c4c672a66e93af1221--SELECT 3RD TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e40ec672a66e93ae9b56-NO EXTRA TOPPINGS..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ae-TANDOORI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b1-PERI PERI CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b3-BBQ CHICKEN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11c37102c0b0d9887b6-LAMB DONNER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887b8-HAM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11d37102c0b0d9887ba-SPICY PORK..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887bc-BACON..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887be-PEPPERONI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11e37102c0b0d9887c0-SPICY BEEF..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c2-ONION..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11f37102c0b0d9887c4-MUSHROOM..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988885-SWEETCORN..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988887-GREEN PEPPER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d988889-JALAPENO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c13f37102c0b0d98888b-FRESH GINGER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888d-FRESH TOMATO..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14037102c0b0d98888f-GREEN CHILLI..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5e4dac672a66e93ae9be8-FRESH GARLIC..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c5fa66c672a66e93aeb089-BLACK OLIVE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988893-PINEAPPLE..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988895-PANEER..",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c11b37102c0b0d9887ac-PLAIN CHICKEN..",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 3RD TOPPING"
                }
            },
            {
                "CreatedDate": "0001-01-01T00:00:00Z",
                "Description": {
                    "en": "SELECT 4TH TOPPING"
                },
                "DisplayType": "EXPANDED",
                "ID": "67c7f03d9ba8a88fba2d321f",
                "MetaData": null,
                "ModifiedDate": "0001-01-01T00:00:00Z",
                "ModifierGroupID": "Deal-67c715c4c672a66e93af1221--SELECT 4TH TOPPING",
                "ModifierOptions": [
                    {
                        "Id": "67c5e42cc672a66e93ae9b78-NO EXTRA TOPPINGS...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d988899-TANDOORI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889b-PERI PERI CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14237102c0b0d98889e-BBQ CHICKEN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a3-LAMB DONNER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a7-HAM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888a9-SPICY PORK...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14337102c0b0d9888ab-BACON...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888ad-PEPPERONI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14437102c0b0d9888af-SPICY BEEF...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c15a37102c0b0d988940-ONION....",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b3-MUSHROOM...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b5-SWEETCORN...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14537102c0b0d9888b7-GREEN PEPPER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888b9-JALAPENO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bb-FRESH GINGER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14637102c0b0d9888bd-FRESH TOMATO...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c0-GREEN CHILLI...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c60226c672a66e93aeb919-BLACK OLIVE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14737102c0b0d9888c4-PINEAPPLE...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14837102c0b0d9888c6-PANEER...",
                        "Type": "ITEM"
                    },
                    {
                        "Id": "67c1c14137102c0b0d988897-PLAIN CHICKEN...",
                        "Type": "ITEM"
                    }
                ],
                "QuantityConstraintsRules": {
                    "Overrides": null,
                    "Quantity": {
                        "ChargeAbove": 1,
                        "IsMinPermittedOptional": true,
                        "MaxPermitted": 1,
                        "MaxPermittedUnique": 1,
                        "MinPermitted": 1,
                        "MinPermittedUnique": 0,
                        "RefundUnder": 0
                    }
                },
                "StoreID": "67c14076877eae2d49f80772",
                "Title": {
                    "en": "SELECT 4TH TOPPING"
                }
            }
        ],
        "Type": "MENU"
    }
    const selectedOrderType = "DELIVERY_BY_REWARDCONSUMER";
    const getThePrice = (price) => {
        let priceValue;

        switch (selectedOrderType) {
            case "delivery":
                priceValue = price.DeliveryPrice;
                break;
            case "table":
                priceValue = price.TablePrice;
                break;
            default:
                priceValue = price.PickupPrice;
                break;
        }
        return "$" + (priceValue / 100).toString();
    };
    const renderLayout = (menuItem, menuEntity) => {
        return (
            <div className="column">
                <div className="item-details">
                    <div className="item-dela-cont">
                        <img style={{ objectFit: !menuItem.ImageURL ? "fill" : "" }} alt="" src={menuItem.ImageURL ? menuItem.ImageURL : imagePlaceholder} className="item-image" onClick={() => { history.push('/item/' + menuEntity.ID + "/" + "new") }} />
                    </div>
                    <div className="item-des">
                        <div className="item-des-title">
                            <div className="name">{menuItem.Title.en}</div>
                            <div className="dotted-line"></div>

                            <div className="price">
                                {getThePrice(menuItem.PriceInfo.Price)}
                            </div></div>

                        <div className="details">{menuItem.Description.en}</div>
                    </div>
                </div>


            </div>
        )
    }


    return (
        <div className="Hospitality-navbar-Layout">
            {Categories.Categories && Categories.Categories.length > 0 && Categories.Categories.map((data, index) => {
                const dataLimit = limit < data.MenuEntities.length ? limit : data.MenuEntities.length

                return (
                    <div key={index} >
                        {showHeader && <div type="title" level={4} pattern="bold" className="title-menu" >{data?.Title.en}</div>}
                        <div className={`layout ${layout}`}>
                            {limit ? data.MenuEntities.slice(0, dataLimit).map(((menuEntity, key) => {
                                const menuItem = _.find(Categories.Items, { MenuItemID: menuEntity.ID });
                                if (menuItem) {
                                    return (
                                        <div className="column-styles" key={key}>
                                            {renderLayout(menuItem, menuEntity)}
                                        </div>
                                    )
                                }
                            })) :
                                data.MenuEntities.map(((menuEntity, key) => {
                                    const menuItem = _.find(Categories.Items, { MenuItemID: menuEntity.ID });
                                    if (menuItem) {
                                        return (
                                            <div className="column-styles" key={key}>
                                                {renderLayout(menuItem, menuEntity)}
                                            </div>
                                        )
                                    }
                                }))

                            }
                        </div>
                    </div>
                )
            })}
            {limit && <div className='button-container'><Button text={"LOAD MORE"} Secondary
                onClick={navigate} /></div>}
        </div >
    )
}
export default Layout