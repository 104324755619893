import React from "react";
import "./RestaurantContactus.scss"
import ContactHero from "../../components/ContactComponents/ContactHero";
import { useSelector } from "react-redux";
const RestaurantContactus = () => {
    const pages = useSelector((state) => state.companyData.pages)
    const page = pages.find((page) => page.PageId === "contactus")
    const Components = page?.Components || []
    return (
        <div className="RestaurantContactus">
            <ContactHero Components={Components} />
        </div>
    )
}
export default RestaurantContactus