import React from "react";
import "./CheckoutItem.scss"

const CheckoutItem = ({ item }) => {
    return (
        <div className="CheckoutItem">
            <div className="CheckoutItem-left">
                <div className="item-image" >
                    <img alt="" src={item.image} />
                </div>
                <div className="item-deatils-container">
                    <div className="item-name">
                        {item.title}
                    </div>
                    <div className="item-dec">
                        {item.sub}
                    </div>
                </div>
            </div>
            <div className="CheckoutItem-right">
                <div className="item-price">
                    £{item.price}
                </div>
            </div>
        </div>
    )

}
export default CheckoutItem