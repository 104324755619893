import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuTabs from "../../components/Tabs/MenuTabs";
import Layout from "../../components/layouts/Layout";
import PopupWrapper from "../../../../ResturatnWidget/PopupWrapper";
import SkeletonMenuLoader from "../../components/SkeletonMenuLoader";
import { getRestaurantMenuUICustomizations } from "../../../../../utilities/restaurantMenuCustomizationsUtil";
import {
    setRestaurantMenuUICustomizations,
    fetchAllRestaurantMenuData,
    setSelectedOrderType,
} from "../../../../../../../redux/resturantWidgetSlice";
import OrderPicker from "../../components/OrderPicker/OrderPicker";
import "./Menu.scss";
import Footer from "../../components/Footer/Footer";
import LocationFinder from "../../components/OrderPicker/LocationFinder/LocationFinder";
import Modal from "../../components/Modal/Modal";
import { LOCAL_STORAGE_ITEMS_NAMES } from "../../../RestaurantWidget/utilities/helper/helper";

const Menu = ({ menu }) => {
    const itemRefs = useRef([]);
    const tabRefs = useRef([]);
    const dispatch = useDispatch();
    const { selectedOrderType } = useSelector((state) => state.resturantData);
    const { menuData: { isLoading, storage: { mainMenus } }, defualtSelectedLocation, organizationID } = useSelector((state) => state.resturantData);
    const availableTypes = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) && JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)).orderTypes.filter(val => val !== "table")
    const [widgetState, setWidgetState] = useState({
        isPopup: null,
        layout: null,
        customizations: null,
        themeAvailable: false,
        isInitialLoading: true,
    });
    const [selectedItem, setSelectedItem] = useState(sessionStorage.getItem(`scroll`) ? sessionStorage.getItem(`scroll`) : "");
    const [addDeliveryLoc, setAddDeliveryLoc] = useState(false);
    const [deliverySuccess, setDeliverySuccess] = useState(false);
    const [deliveryFailed, setDeliveryFailed] = useState(false);

    const heightTop = window.innerWidth < 600 ? 240 : 120
    const heightScrollTop = window.innerWidth < 600 ? 180 : 100


    const initializeMenuCustomizations = useCallback(() => {
        localStorage.removeItem("QUANTITY")
        localStorage.removeItem("ITEM")
        localStorage.removeItem("MODQUANTITY")
        if (menu) {
            const menuData = JSON.parse(menu);
            const menuCustomizationValues = getRestaurantMenuUICustomizations(menuData);
            dispatch(setRestaurantMenuUICustomizations({ uiCustomizations: menuCustomizationValues }));
            setWidgetState((prevState) => ({
                ...prevState,
                customizations: menuData,
                isPopup: menuData?.selectedCard === 'popup',
                layout: menuData?.selectedLayout,
                themeAvailable: menuData?.selctedTheme !== "removeTheme",
            }));

        }
    }, [menu, dispatch]);

    const fetchMenuData = useCallback(() => {
        dispatch(fetchAllRestaurantMenuData({
            CloudLocationID: defualtSelectedLocation.CloudLocationID,
            OrganizationID: organizationID,
        }));

    }, [dispatch, defualtSelectedLocation, organizationID]);

    useEffect(() => {
        initializeMenuCustomizations();

    }, [initializeMenuCustomizations]);

    useEffect(() => {
        fetchMenuData();
        localStorage.setItem("ecom_del_view", "true")
    }, [fetchMenuData]);

    useEffect(() => {
        if (!isLoading) {
            setWidgetState((prevState) => ({ ...prevState, isInitialLoading: false }));

        }
    }, [isLoading]);
    useEffect(() => {
        if (!widgetState.isInitialLoading) {
            if (mainMenus && selectedItem === "") {
                if (selectedItem === "") {
                    setSelectedItem(mainMenus[0]?.menuID);
                    sessionStorage.setItem(`scroll`, mainMenus[0]?.menuID);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                } else {
                    let scroll = sessionStorage.getItem(`scroll`)
                    setSelectedItem(scroll);
                    const selectedItemPosition = itemRefs.current[scroll].getBoundingClientRect().top + window.scrollY - heightScrollTop || 0;
                    window.scrollTo(0, parseInt(selectedItemPosition, 10));
                }
            } else {
                if (itemRefs) {
                    let scroll = sessionStorage.getItem(`scroll`)
                    if (scroll && scroll !== undefined) {
                        setSelectedItem(scroll);
                        const selectedItemPosition = itemRefs?.current[scroll] !== undefined && itemRefs?.current[scroll]?.getBoundingClientRect()?.top + window.scrollY - heightScrollTop || 0;
                        window.scrollTo(0, parseInt(selectedItemPosition, 10));
                    }
                }
            }

        }
    }, [widgetState])

    useEffect(() => {
        const handleScroll = () => {

            const scrollPosition = window.scrollY + heightTop;
            let activeId = null;

            Object.entries(itemRefs.current).forEach(([id, item]) => {
                if (item) {
                    const { top, bottom } = item.getBoundingClientRect();
                    const itemTop = top + window.scrollY - heightTop;
                    const itemBottom = bottom + window.scrollY - heightTop;

                    if (itemTop <= scrollPosition && itemBottom >= scrollPosition) {
                        activeId = id;
                    }
                }
            });
            if (activeId) {
                setSelectedItem(activeId);
                sessionStorage.setItem(`scroll`, activeId);
                tabRefs.current[activeId]?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                });
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleSelect = (id) => {
        if (!itemRefs.current[id]) return;

        setSelectedItem(id);
        sessionStorage.setItem(`scroll`, id);

        const selectedItemPosition = itemRefs.current[id].getBoundingClientRect().top + window.scrollY - heightScrollTop;
        window.scrollTo({ top: selectedItemPosition, behavior: "smooth" });

        setTimeout(() => {
            tabRefs.current[id]?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center",
            });
        }, 200);
    };

    const getLayout = () => {
        if (window.innerWidth < 600) {
            return widgetState.layout === "layout1" || widgetState.layout === "layout3" ? "layout3" : widgetState.layout;
        }

        return widgetState.layout;
    };
    useEffect(() => {
        if (availableTypes && availableTypes.length === 1) {
            dispatch(setSelectedOrderType(availableTypes[0]))
        }
    }, [availableTypes])
    return (
        <div>
            <LocationFinder visibility={addDeliveryLoc}
                setAddDeliveryLoc={setAddDeliveryLoc}
                onPopupClose={() => {
                    setAddDeliveryLoc(false)
                }}
                setDeliverySuccess={setDeliverySuccess}
                setDeliveryFailed={setDeliveryFailed}
            />
            <Modal visibility={deliverySuccess} type="success" headerText="Great news! We deliver to your location" subText="Enjoy fresh and fast delivery straight to your doorstep. Place your order now!" onPopupClose={() => {
                setDeliverySuccess(false)
            }} />
            <Modal visibility={deliveryFailed} type="failed" headerText="Uh-oh! Looks like we can’t reach you" subText={`Sorry, we don’t currently deliver to your area. Stay tuned for updates!`} onPopupClose={() => {
                setDeliveryFailed(false)
            }} />
            <PopupWrapper isPopup={widgetState.isPopup}
            >
                {selectedOrderType === "" ? (

                    <OrderPicker setAddDeliveryLoc={setAddDeliveryLoc} />


                ) : widgetState.isInitialLoading ? (
                    <SkeletonMenuLoader />
                ) : (
                    <MenuTabs
                        isPopupWidget={widgetState.isPopup}
                        selectedItem={selectedItem}
                        onSelect={handleSelect}
                        tabRefs={tabRefs}
                    >

                        <div className="Menustyles">
                            <Layout layout={getLayout()} itemRefs={itemRefs} />
                            <Footer />
                        </div>

                    </MenuTabs>
                )}
            </PopupWrapper>
        </div>
    );
};

export default Menu;
