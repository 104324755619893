import React, { useState } from "react";
import "./ContactUs.scss"
import InputComponent from "../InputComponent/InputComponent";
import TextareaComponent from "../TextareaComponent/TextareaComponent";
import Button from "../Button/Button";
import { useSelector } from "react-redux";
import { sortByOrderAscending } from "../../helper/helper";
import { contactEnquiry } from "../../../../../utilities/apiRequests/witmegRetailPaxServerRequests";
const ContactUs = ({ components }) => {
    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [message, setMessage] = useState("")
    const companyDetails = useSelector((state) => state.companyData.company)
    const [errors, setErrors] = useState({
        fullName: "",
        email: "",
        message: ""
    })
    const [loading, setLoading] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    const validateEmail = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    const validateForm = () => {
        let valid = true;
        let newErrors = {
            fullName: "",
            email: "",
            message: ""
        }
        if (fullName.trim() === "") {
            newErrors.fullName = "*Required";
            valid = false;
        }
        if (message.trim() === "") {
            newErrors.message = "*Required";
            valid = false;
        }
        if (email.trim() === "") {
            newErrors.email = "*Required";
            valid = false;
        }
        else if (!validateEmail(email)) {
            newErrors.email = "*Invalid email format";
            valid = false;
        }
        setErrors(newErrors);
        return valid;
    };
    const handleContact = async () => {
        const data = {
            "WebsiteID": companyDetails?.ID || "",
            "Name": fullName,
            "Email": email,
            "PhoneNo": phoneNumber,
            "Message": message,
            "Subject": ""
        }
        if (validateForm() && !loading) {
            setLoading(true)
            let res = await contactEnquiry(data)
            if (res) {
                setEmail("")
                setFullName("")
                setMessage("")
                setPhoneNumber("")
                setLoading(false)
                setShowSuccess(true)
                setTimeout(() => {
                    document.querySelector('.succes-alert').classList.add('fade-out');
                    setTimeout(() => {
                        setShowSuccess(false)
                    }, 1000);
                }, 2000);
            } else {
                setLoading(false)
            }
        }
    }

    const getFormComponents = (component) => {
        switch (component.ID) {
            case "contact-deatils-section-header":
                return (
                    <div className="ContactUs-right-header">
                        Say hello
                    </div>
                );

            case "input-component":
                switch (component.Props.name) {
                    case "Name":
                        return (
                            <div className="form-input-container">
                                <InputComponent
                                    value={fullName}
                                    onChange={(e) => {
                                        setFullName(e.target.value)
                                        setErrors({ ...errors, fullName: "" })
                                    }}
                                    placeholder={component.Props.placeholder}
                                    isError={errors.fullName !== ""}
                                    errorMessage={errors.fullName}
                                />
                            </div>
                        );
                    case "Email":
                        return (
                            <div className="form-input-container">
                                <InputComponent
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setErrors({ ...errors, email: "" })

                                    }}
                                    placeholder={component.Props.placeholder}
                                    isError={errors.email !== ""}
                                    errorMessage={errors.email}
                                />
                            </div>
                        );
                    case "phoneNumber":
                        return (
                            <div className="form-input-container">
                                <InputComponent
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder={component.Props.placeholder}
                                />
                            </div>
                        );
                    default:
                        return null;
                }

            case "textarea-component":
                return (
                    component.Props.name === "Message" && (
                        <div className="form-input-container">
                            <TextareaComponent
                                value={message}
                                onChange={(e) => {
                                    setMessage(e.target.value)
                                    setErrors({ ...errors, message: "" })
                                }}
                                placeholder={component.Props.placeholder}
                                isError={errors.message !== ""}
                                errorMessage={errors.message}
                            />
                        </div>
                    )
                );

            case "button-component":
                return (
                    <>
                        {showSuccess && <div className="succes-alert">
                            ✔ Thank you for contacting us! We appreciate your message and will be in touch shortly .
                        </div>}
                        <div className="btn-container">
                            <Button text={component.Props.content} isLoading={loading} onClick={handleContact} />
                        </div>
                    </>)


            default:
                return <div />;
        }
    };




    const getFormDetails = (children) => {
        return (
            <div className="ContactUs-right">
                {sortByOrderAscending(children).map((component) => {
                    return getFormComponents(component)
                })}



            </div>
        )
    }
    const getContactComponents = (component) => {
        switch (component.ID) {
            case "contact-deatils-section-header":
                return <div className="ContactUs-left-header">
                    {component.Props.content}
                </div>
            case "contact-deatils-section-subheader":
                return <div className="ContactUs-left-sub">
                    {component.Props.content}
                </div>


            case "contact-deatils":
                return <div className="contact-content">
                    <div className="contact-left">
                        {companyDetails?.Phone !== "" && <div className="contact-container">
                            <div className="Contact-header ">Phone:</div>
                            <div className="contact-detail">{companyDetails?.Phone}</div>

                        </div>}
                        {companyDetails?.Email !== "" && <div className="contact-container">
                            <div className="Contact-header ">Email:</div>
                            <div className="contact-detail">{companyDetails?.Email}</div>
                        </div>}
                    </div>
                    <div className="contact-right">
                        <div className="contact-container">
                            <div className="Contact-header ">Head office:</div>

                            <div className="contact-detail">{companyDetails?.Name}<br />
                                {companyDetails?.AddressLine1},<br />
                                {companyDetails?.AddressLine2 !== "" && <>{companyDetails?.AddressLine2},<br /></>}
                                {companyDetails?.City}<br />
                                {companyDetails?.PostZipCode}<br />
                                {companyDetails?.Country}.</div>

                        </div>
                    </div>
                </div>
            default:
                return <div />
        }

    }
    const getContactDetails = (children) => {
        return (
            <div className="ContactUs-left">

                {sortByOrderAscending(children).map((component) => {
                    return getContactComponents(component)
                })}
            </div>
        )
    }
    const getComponents = (component) => {
        switch (component.ID) {
            case "contact-deatils-section":
                return getContactDetails(component.Components)

            case "contact-form-section":
                return getFormDetails(component.Components)
            default:
                return <div />
        }

    }
    return (
        <div className="ContactUs">
            {sortByOrderAscending(components).map((component) => {
                return getComponents(component)
            })}
        </div>
    )
}
export default ContactUs