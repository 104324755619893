const {
    NODE_ENV,
    REACT_APP_TOKENSERVICE_URL,
    REACT_APP__NEUTRIPOS_BASEURL,
    REACT_APP__LOYALTY_REGISTRY_BASEURL,
    REACT_APP__APPREGISTRY_BASEURL,
    REACT_APP_PAYMENT_BASE_URL,
    REACT_APP_PAYMENT_CLIENT_KEY,
    REACT_APP_PAYMENT_CLIENT_ENVIRONMENT,
    REACT_APP_LOYALTY_REGISTRY_KEY,
    REACT_APP_ENV,
    REACT_APP__WITMEG_ORGSERVER___BASEURL,
    REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL,
    REACT_APP_API_URL_cust,
    REACT_APP_CustjwtAud,
    REACT_APP_CustjwtIss,
    REACT_APP_CustjwtExp,
    REACT_APP_LoginAuthorizationKey,
    REACT_APP_LoginprivateKey,
    REACT_APP_encryptKey,
    REACT_APP_backendURL
} = process.env;

const globalValues = {
    baseURLS: {
        REACT_APP_TOKENSERVICE_URL: REACT_APP_TOKENSERVICE_URL,
        REACT_APP__NEUTRIPOS_BASEURL: REACT_APP__NEUTRIPOS_BASEURL,
        REACT_APP__LOYALTY_REGISTRY_BASEURL: REACT_APP__LOYALTY_REGISTRY_BASEURL,
        REACT_APP__APPREGISTRY_BASEURL: REACT_APP__APPREGISTRY_BASEURL,
        REACT_APP_PAYMENT_BASE_URL: REACT_APP_PAYMENT_BASE_URL,
        REACT_APP_PAYMENT_CLIENT_KEY: REACT_APP_PAYMENT_CLIENT_KEY,
        REACT_APP_PAYMENT_CLIENT_ENVIRONMENT: REACT_APP_PAYMENT_CLIENT_ENVIRONMENT,
        REACT_APP_LOYALTY_REGISTRY_KEY: REACT_APP_LOYALTY_REGISTRY_KEY,
        REACT_APP__WITMEG_ORGSERVER___BASEURL: REACT_APP__WITMEG_ORGSERVER___BASEURL,
        REACT_APP__RETAILPAXS__BASEURL: REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL,
        REACT_APP_API_URL_CUSTOMER: REACT_APP_API_URL_cust,
        REACT_APP_backendURL: REACT_APP_backendURL

    },

    environment: {
        CURRENT_ENVIRONMENT: NODE_ENV,
        IS_DEVELOPMENT: NODE_ENV === "development",
        ENV: REACT_APP_ENV,
    },
    jwtKeys: {
        REACT_APP_CustjwtAud: REACT_APP_CustjwtAud,
        REACT_APP_CustjwtIss: REACT_APP_CustjwtIss,
        REACT_APP_CustjwtExp: REACT_APP_CustjwtExp

    },
    keys: {
        REACT_APP_LoginAuthorizationKey: REACT_APP_LoginAuthorizationKey,
        REACT_APP_LoginprivateKey: REACT_APP_LoginprivateKey.replace(
            /\\n/g,
            "\n"
        ),
        REACT_APP_encryptKey: REACT_APP_encryptKey
    }
};
export default globalValues;
