import React from "react";
import "./JumbledImageContainer.scss";





const JumbledImageContainer = ({ components }) => {
    return (
        <div className="jumbled-container">
            <div className="jumbled-container-top">
                <img alt="" src={components.image1} className="image-1" />
                <img alt="" src={components.image2} className="image-2" />

            </div>
            <div className="jumbled-container-bottom">
                <img alt="" src={components.image3} className="image-2" />
                <img alt="" src={components.image4} className="image-1" />

            </div>
        </div>
    );
};

export default JumbledImageContainer;
