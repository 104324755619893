import React, { useEffect, useState } from "react";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import globalValues from "../../global/globalValues";
;

const PaymentComponent = ({ paymentMethods = {
    "brands": [
        "cup",
        "discover",
        "jcb",
        "maestro",
        "mc",
        "visa"
    ],
    "name": "Credit Card",
    "type": "scheme"
}, }) => {
    const paymentMethod = {
        "paymentMethods": [
            paymentMethods
        ]
    }

    const makeInstantPayment = async (data) => {


    }
    const handleTransaction = (stage, response) => {
        if (stage === "Authorised" || stage === "Received" || stage === "AuthenticationNotRequired") {

        }
        else if (stage === "ChallengeShopper" || stage === "PresentToShopper" || stage === "AuthenticationNotRequired" || stage === "RedirectShopper" || stage === "IdentifyShopper") {


        } else {

        }
    }
    //handleRedirect
    useEffect(() => {
        const initCheckout = async () => {
            const configuration = {
                paymentMethodsResponse: paymentMethod,
                clientKey: globalValues.baseURLS.REACT_APP_PAYMENT_CLIENT_KEY,
                locale: "en-US",
                environment: globalValues.baseURLS.REACT_APP_PAYMENT_CLIENT_ENVIRONMENT,
                analytics: {
                    enabled: true,
                },
                onSubmit: (state, dropin) => {
                    if (state.data.storePaymentMethod === false) {
                        makeInstantPayment(state.data)
                            .then((response) => {
                                if (response.action) {
                                    dropin.handleAction(response.action);
                                } else {
                                    handleTransaction(response.resultCode, response)
                                }
                            })
                    }
                },
                onError: () => {
                    console.log("error in payment")
                },
                paymentMethodsConfiguration: {
                    card: {
                        hasHolderName: true,
                        holderNameRequired: true,
                        enableStoreDetails: true,
                        hideCVC: false,
                        showPayButton: true,
                    },
                },
            };
            try {
                const checkout = await AdyenCheckout(configuration)
                checkout
                    .create("card", {
                        openFirstPaymentMethod: false,
                    })
                    .mount("#card-container")
            } catch (error) {
                console.log("Error in Card payment")
            }

        };
        if (paymentMethods) initCheckout();
    }, [paymentMethods]);
    return <div id='card-container'></div>;
};
export default PaymentComponent;
