import React from "react";

const VideoPlayer = ({ src, height }) => {
    return (
        <div style={{ height }}>
            <video className='react-video-player'
                style={{ height: "100%", objectFit: 'cover', borderRadius: "20px", width: "100%" }} autoPlay={true} loop={true} muted={true} controls={false} src={src} />

        </div>
    )
}
export default VideoPlayer