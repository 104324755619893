import React from "react";
import "./SearchItem.scss"
const SearchItem = ({ item, onClick }) => {
    return (
        <div className="SearchItem" onClick={onClick}>
            <div className="SearchItem-Image">
                <img alt="" src={item.image} />
            </div>
            <div className="SearchItem-details">
                <div className="SearchItem-title">{item.title}</div>
                <div className="SearchItem-price"> £{item.price}</div>
            </div>
        </div>
    )
}
export default SearchItem