import React, { useEffect, useState } from "react";
import "./OrderPicker.scss"
import deliveryIc from "../../assets/delivery.svg"
import takawayIc from "../../assets/takeaway.svg"
import { LOCAL_STORAGE_ITEMS_NAMES } from "../../../RestaurantWidget/utilities/helper/helper";
import { useDispatch } from "react-redux";
import { setSelectedOrderType } from "../../../../../../../redux/resturantWidgetSlice";

const OrderPicker = ({ setAddDeliveryLoc }) => {
    const dispatch = useDispatch()
    const defaultOrderType = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) && JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)).defaultOrderType
    const availableTypes = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) && JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)).orderTypes

    const [selectedType, setSelectedType] = useState(defaultOrderType)
    useEffect(() => {
        sessionStorage.clear()
    }, [])
    const orderTypes = [
        { name: "Collection", type: "collection", icon: takawayIc },
        { name: "Delivery", type: "delivery", icon: deliveryIc },
    ]
    const filteredData = orderTypes.filter(item => availableTypes?.includes(item.type));
    const handleType = () => {
        if (selectedType === "delivery") {
            setAddDeliveryLoc(true)
        } else {
            dispatch(setSelectedOrderType(selectedType))
        }
    }
    return (
        <div className="OrderPicker-Menu">
            <div className="header-text">Choose Your Order Type</div>
            <div className="sub-text">Select an option that best suits your preferences.</div>
            <div className="order-type-containers">
                {filteredData.map((orderType, index) => {
                    return (<div key={index} className="order-type-container" onClick={() => {
                        setSelectedType(orderType.type)
                    }} >
                        <div className="order-type-container-left">
                            {selectedType === orderType.type ? <div className="radio-type-selected"><div className="radio-type-selected-inner" /></div> : <div className="radio-type" />}<div className="order-text"
                            >{orderType.name}</div></div>
                        <div className="order-type-container-right"><img alt="" src={orderType.icon} /></div>

                    </div>)
                })}
            </div>
            <div className="proceed-btn" onClick={handleType}>Proceed</div>

        </div>
    )
}
export default OrderPicker