import React from "react";
import "./RadioButton.scss";

const RadioButton = ({ name, value, checked, onChange, label }) => {
    return (
        <div className="radio-container">
            <input
                type="radio"
                id={`radio-${value}`}
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                className="radio-input"
            />
            <label htmlFor={`radio-${value}`} className="radio-label">
                <span className="radio-custom"></span>
                {label}
            </label>
        </div>
    );
};

export default RadioButton;
