import React from 'react';
import { Popup } from 'witmeg-ui-system';
import "./Popup.scss"
import cart from "../../assets/cart-blue.svg"

const DeletePopup = ({ visibility, onDeleteConfirm, onPopupClose, isLoading }) => {

    return (
        <Popup
            className='Popup-Menu'
            modalVisible={visibility}
            modalTitle=""
            mask={true}
            centered
            onCancel={onPopupClose}
            closable={false}>
            <div className='popup-inner'>
                <div className='inner-container'>
                    <div className='icon-container'><img alt='' src={cart} />
                        <div className='close-cont'>X</div></div>

                </div>
                <div className='delete-text'>Are you sure want to remove it from cart?</div>
                <div className='delete-btn-conatiner' onClick={onDeleteConfirm}>
                    {isLoading && <div className='loader-btn' />}
                    Remove
                </div>
                <div className='cancel-btn-conatiner' onClick={onPopupClose}>
                    Cancel
                </div>
            </div>
        </Popup>
    );
}

export default DeletePopup;