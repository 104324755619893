import React from "react";
import "./Input.scss"

const Input = ({ label, required, placeholder, value, onChange, errorMessage }) => {
    return (
        <div className="Input-Jack">
            <div className="label">{label}{required && <span>*</span>}</div>
            <div className="input-container">
                <input placeholder={placeholder} value={value} onChange={onChange} />
            </div>
            <div className="error-input">{errorMessage}</div>

        </div>
    )
}
export default Input