import React from "react";
import ContactForm from "./ContactForm/ContactForm";
import { sortByOrderAscending } from "../../helper/helper";

const ContactFormContainer = ({ Components }) => {
    const getComponents = (component) => {
        switch (component.ID) {
            case "form-header":
                return <div className="header-text-jack">Contact us</div>
            case "form-content":
                return <div className="menu-component-header">Get in touch</div>
            case "form-sub-content":
                return <div className="menu-comp-sub-header">For more info or inquiry about our restaurant, please feel free to get in touch with us.</div>
            case "contactForm":
                return <ContactForm />

            default:
                return <div />
        }

    }
    return (
        <div className="contact-form-header">
            {sortByOrderAscending(Components).map((component, key) => {
                return (<div key={key}>
                    {getComponents(component)}
                </div>)
            })}

        </div>
    )
}
export default ContactFormContainer