import React from "react";
import "./Modal.scss"
import Button from "../Button/Button";
import success from "../../assets/images/tick-mark.png"
import failed from "../../assets/images/remove.png"
import alert from "../../assets/images/caution.png"

const Modal = ({ header, subText, isSuccess, isFailed, isAlert, onClick }) => {
    return (
        <div className="Modal-Casi">
            <div className="Modal-Casi-content">
                <div className="modal-img-container">
                    <img alt="" src={isSuccess ? success : isFailed ? failed : isAlert ? alert : success} />
                </div>
                <div className="modal-header-text">{header}</div>
                <div className="modal-sub-text">{subText}</div>
                <div className="modal-btn-cont">
                    <Button
                        text={"OK"}
                        width={"50%"} onClick={onClick} />
                </div>

            </div >

        </div >
    )
}
export default Modal