import React from "react";
import delivery from "../../assets/images/fast-delivery.png"
import returnEasy from "../../assets/images/return-box.png"
import shipping from "../../assets/images/shipping.png"
import custom from "../../assets/images/tape-measure.png"
const CustomList = ({ customContent }) => {
    const getIconType = (type) => {
        switch (type) {
            case 'delivery':
                return delivery
            case 'returnEasy':
                return returnEasy
            case 'shipping':
                return shipping
            case 'custom':
                return custom
            default:
                return delivery
        }
    }
    return (
        <> {customContent.map((content, key) => {
            return (
                <div className="item-content" key={key}>
                    <img className="item-conten-image" src={getIconType(content.Props.src)} alt="" />
                    <div className="item-content-text ">{content.Props.content}</div>
                </div>
            )
        })}</>
    )
}
export default CustomList