import React from "react";
import ItemComponent from "../../components/ItemComponent/ItemCopmonent";
import filter from "../../assets/images/filter.png"
import { sortByOrderAscending } from "../../helper/helper";
import { useHistory } from "react-router";

const ProductsContent = ({ toggleDrawer, components }) => {
    const history = useHistory()

    const products = [
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616161936148.jpeg",
            "title": "Mangalya Silk ",
            price: "100.00",
            id: 1,
            inStock: true,
            onSale: false,
            isNew: true

        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616162869639.jpeg",
            "title": "Thirumana Silk ",
            price: "120.00",
            id: 2,
            inStock: true,
            onSale: false,
            isNew: true


        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616166519893.jpeg",
            "title": "Thozhi Silk ",
            price: "90.00",
            id: 3,
            inStock: true,
            onSale: false,
            isNew: false




        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1616166902711.jpeg",
            "title": "Sitha Silk ",
            price: "220.00",
            id: 4,
            inStock: false,
            onSale: false,
            isNew: false



        },
        {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1625041966373.jpg",
            "title": "Sitha Silk ",
            price: "220.00",
            id: 5,
            inStock: true,
            onSale: true,
            isNew: false



        }, {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1625038454247.JPG",
            "title": "Sitha Silk ",
            price: "220.00",
            id: 6,
            inStock: true,
            onSale: true,
            isNew: false



        }, {
            "image": "https://cdn.neurolage.com/retailpacx/casipillaidesignercollection/retailpacx-1625038374020.JPG",
            "title": "Sitha Silk ",
            price: "220.00",
            id: 7,
            inStock: true,
            onSale: false,
            isNew: false

        },
    ]
    const getHeaderComponents = (component) => {
        switch (component.ID) {
            case "products-header":
                return <div className="header-text">{component.Props.content}
                </div>
            case "products-subheader":
                return <div className="subtext-content">
                    {component.Props.content}
                </div>
            default:
                return <div />
        }

    }
    const getProductsText = (productsComponent) => {
        return (
            <div className="header-content">
                {sortByOrderAscending(productsComponent).map((component, key) => {
                    return getHeaderComponents(component)

                })}
            </div>
        )
    }
    const getFilterContent = () => {
        return (
            <div className="product-content">
                <div className="filter-container" onClick={toggleDrawer}>
                    <img alt="" src={filter} />filter</div>
                <div className="product-count">{products.length} items</div>
            </div>
        )
    }
    const getProdctsContent = (props) => {
        return (
            <div className="products-list-container">
                {products.map((product, key) => {
                    return <ItemComponent key={key} item={product} layout={props.layout}
                        onClick={() => {
                            history.push(`/product/${product.id}`)
                        }} />
                })}
            </div>
        )
    }
    const getComponents = (component) => {
        switch (component.ID) {
            case "products-header-content":
                return getProductsText(component.Components)
            case "product-filter-content":
                return getFilterContent()
            case "product-content-section":
                return getProdctsContent(component.Props)
            default:
                return <div />
        }

    }
    return (
        <div className="EcommerceProduct-right">
            {sortByOrderAscending(components).map((component, key) => {
                return getComponents(component)

            })}

        </div>
    )
}
export default ProductsContent