import React from "react";
import Header from "../../components/Header/Header";
import TextContent from "../../components/TextContent/TextContent";
import { sortByOrderAscending } from "../../helper/helper";

const AboutusContent = ({ components }) => {
    const getComponents = (component) => {
        switch (component.ID) {
            case "Header":
                return <div className="EcommerceAboutus-header"><Header text={component.Props.content} fontSize={component.Props.fontSize} fontWeight={component.Props.fontWeight} /></div>
            case "TextContent":
                return <div className="content">
                    <TextContent text={component.Props.content} />
                </div>
            default:
                return <div />
        }

    }
    return (
        <div className="EcommerceAboutus-Content">
            {sortByOrderAscending(components).map((component, key) => {
                return (
                    getComponents(component)
                )
            })}
        </div>
    )
}
export default AboutusContent