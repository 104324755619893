import React from "react";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { sortByOrderAscending } from "../../helper/helper";
const HomeHeroVideo = ({ components }) => {

    const getComponents = (component) => {
        switch (component.ID) {
            case "hero-video-player":
                return <VideoPlayer height={component?.Props?.height}
                    src={component?.Props?.src} />


            default:
                return <div />
        }

    }

    return (
        <div className="EcommerceHome-hearo-video">
            {sortByOrderAscending(components).map((component) => {
                return getComponents(component)
            })}
        </div>
    )
}
export default HomeHeroVideo