import React from "react"
import "./ItemComponent.scss"
import wishlist from "../../assets/wishlist.svg"
import eye from "../../assets/eye.svg"
import plus from "../../assets/plus.svg"
import close from "../../assets/close.svg"



const ItemComponent = ({ item, layout, onClick, onClose, isClose }) => {
    const getStatus = () => {
        if (item.inStock && !item.inStock) {
            return "Out of stock"
        }
        if (item.isNew) {
            return "NEW"
        }
        if (item.onSale) {
            return "SALE"
        }
        return ""
    }
    const layout1 = () => {
        return (<div className="ItemComponent" >
            <div className="image-container">
                <img alt="" src={item.image} />
            </div>
            <div className="item-container">
                <div className="item-title">{item.title}</div>
                <div className="item-price">£{item.price}</div>
            </div>
            <div className="action-container-position">
                <div className="action-container">
                    <div className="action">
                        <img alt="" src={plus} className="icon" />

                    </div>
                    <div className="action" onClick={onClick}>
                        <img alt="" src={eye} className="icon" />
                    </div>
                    <div className="action">
                        <img alt="" src={wishlist} className="icon" />
                    </div>
                </div>
            </div>

        </div>)
    }
    const layout2 = () => {
        return (<div className="ItemComponent2" >
            <div className="image-container" >
                {getStatus() !== "" && <div className="status ">{getStatus()}</div>}
                <img alt="" src={item.image} onClick={onClick} />
                {isClose && <div className="close-container" onClick={onClose}>
                    <img alt="" src={close} className="icon" />
                </div>}
                <div className="action-container-position" onClick={onClick}>
                    <div className="action-container">SHOP NOW</div>
                </div>
            </div>
            <div className="item-container">
                <div className="item-title">{item.title}</div>
                <div className="item-price">£{item.price}</div>
            </div>


        </div >)
    }
    const getLayout = () => {
        switch (layout) {
            case "layout1":
                return layout1()
            case "layout2":
                return layout2()
            default:
                return layout1()

        }
    }

    return (
        getLayout()
    )
}
export default ItemComponent