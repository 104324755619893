import React from "react";
import "./RestaurantThemeDefaultWrapper.scss"
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import FloatingButton from "../components/FloatingButton/FloatingButton";

const RestaurantThemeDefaultWrapper = ({ children }) => {
    return (
        <div className="RestaurantThemeDefaultWrapper">
            <Navbar />
            {children}
            <FloatingButton />
            <Footer />

        </div>
    )
}
export default RestaurantThemeDefaultWrapper