import React from "react";
import { sortByOrderAscending } from "../../helper/helper";

const Content = ({ components }) => {

    const getComponents = (component) => {
        switch (component.ID) {
            case "Header":
                return <div className="header-text-jack">{component.Props.content}</div>
            case "TextContent":
                return <div className="content">
                    {component.Props.content}
                </div>
            default:
                return <div />
        }

    }
    return (

        <div className="About-content">
            {sortByOrderAscending(components).map((component, key) => {
                return (<div key={key}>
                    {getComponents(component)}
                </div>)
            })}

        </div>
    )
}
export default Content